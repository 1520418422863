import React, { useState, useEffect } from "react";
import _ from "lodash";
import { showNotification } from "@mantine/notifications";

import {
  Button,
  Table,
  Group,
  Text,
  Chip,
  Space,
  Title,
  Grid,
  Card,
  Divider,
  Tabs,
  Badge,
  LoadingOverlay,
  Menu,
  Switch,
  Box,
  Stack,
  Center,
} from "@mantine/core";

import { useCellRender } from "../hooks/useCellRender";

import {
  IconPhoto,
  IconPrinter,
  IconCameraSelfie,
  IconFile,
  IconUser,
  IconBuildingSkyscraper,
  IconAlertCircle,
  IconAlertTriangle,
  IconExclamationMark,
  IconCoin,
  IconRefresh,
} from "@tabler/icons";
import { Accordion, useMantineTheme } from "@mantine/core";
import { useFormatter } from "../hooks/useFomatter";
import { useServerApi } from "../hooks/userServerApi";
import { useForm } from "@mantine/form";
import useDeepCompareEffect from "../hooks/useDeepCompareEffect";
import ReactJson from "react-json-view";
import moment from "moment";
import RemoteSelect2 from "./remoteSelect2";
import { useForceUpdate } from "@mantine/hooks";

const MatchingLeft = ({
  matching,
  index,
  onConfirm,
  onUnConfirm,
  removeFromList,
  maxDiffAllow = 5,
  status,
}) => {
  const [cellRender] = useCellRender();
  const forceUpdate = useForceUpdate();

  useDeepCompareEffect(() => {
    forceUpdate();
  }, [matching]);

  const getButtonText = () => {
    if (!matching.diffAmount || matching.diffAmount == 0) {
      return `Confirm Payments ${cellRender.Currency(matching.matchedAmount)}`;
    } else {
      return `Confirm Payments ${cellRender.Currency(
        matching.matchedAmount
      )} ( ${cellRender.Currency(matching.diffAmount)} )`;
    }
  };

  return (
    <>
      <Card>
        <Group position="apart" align="flex-start">
          <div>
            <Text size="xs" color="dimmed" weight={400}>
              Bank Transaction
            </Text>
            <Group>
              <Title order={5}>{matching.bankTransaction?.code}</Title>
              <Badge color="blue" size="xs" radius={0}>
                {matching.status}
              </Badge>
            </Group>

            <Box mt="sm">
              <Text size="xs" color="dimmed" weight={500}>
                {matching.bankTransaction.detail}
              </Text>
            </Box>
          </div>

          <div>
            <Text size="xs" color="dimmed" weight={400}>
              Received Amount
            </Text>
            <Title order={5}>
              {cellRender.Currency(matching?.receivedAmount)}
            </Title>

            <Box mt="0">
              <Text size="xs" color="dimmed" weight={500} italic>
                {moment(matching.bankTransaction?.date).format("YYYY-MM-DD")}
              </Text>
            </Box>
          </div>
        </Group>
      </Card>

      {matching.status === "PENDING" && (
        <>
          <Button
            fullWidth
            color={matching.diffAmount <= maxDiffAllow ? "green" : "red"}
            size="xs"
            mt="sm"
            disabled={matching.matchedAmount == 0}
            onClick={() => onConfirm(index)}
          >
            {getButtonText()}
          </Button>

          {/* <Button size="xs" mt="sm" onClick={() => removeFromList(index)}>
            Remove
          </Button> */}
        </>
      )}

      {matching.status === "CONFIRM" && status !== "COMPLETED" && (
        <Button
          fullWidth
          color={"orange"}
          size="xs"
          mt="sm"
          disabled={matching.matchedAmount == 0}
          onClick={() => onUnConfirm(index)}
        >
          Un-Confirm
        </Button>
      )}
    </>
  );
};

const MatchingRight = ({
  matching,
  index,
  onAddDebitNote,
  onRemoveDebitNote,
}) => {
  const [cellRender] = useCellRender();
  const forceUpdate = useForceUpdate();

  const [selected, setSelected] = useState(null);

  const handleAdd = () => {
    if (!selected) return;
    onAddDebitNote(index, selected);
    setSelected(null);
  };

  const handleRemove = (dnIndex) => {
    onRemoveDebitNote(index, dnIndex);
  };

  return (
    <>
      {matching.debitNotes.map((dn, index) => (
        <Card mb="sm">
          <Group position="apart" align="flex-start">
            <div>
              <Group>
                <div>
                  <Text size="xs" color="dimmed" weight={400}>
                    Debit Note
                  </Text>
                  <Title order={6}>{dn.code}</Title>
                </div>
                <div>
                  <Text size="xs" color="dimmed" weight={400}>
                    Payment Status
                  </Text>
                  <Title order={6}>{dn.paymentStatus}</Title>
                </div>
              </Group>

              <Box mt="sm">
                <Text size="xs" color="dimmed" weight={500}>
                  {dn.detail}
                </Text>
              </Box>

              <Box mt="sm">
                <Text size="xs" color="dimmed" weight={500}>
                  Client
                </Text>
                <Title order={6}>{dn.client?.name}</Title>
              </Box>
            </div>

            <div>
              <Text size="xs" color="dimmed" weight={400}>
                Client Payable
              </Text>
              <Title order={5}>
                {cellRender.Currency(dn.total - dn.discountAmount)}
              </Title>

              <Box mt="0">
                <Text size="xs" color="dimmed" weight={500} italic>
                  {moment(dn.date).format("YYYY-MM-DD")}
                </Text>
              </Box>
              {matching.status === "PENDING" && (
                <Button
                  color="red"
                  size="xs"
                  mt="sm"
                  onClick={() => handleRemove(index)}
                  variant="default"
                >
                  Remove
                </Button>
              )}
            </div>
          </Group>
        </Card>
      ))}

      {matching.status === "PENDING" && (
        <Box>
          <RemoteSelect2
            width={500}
            size={"xs"}
            apiEntity="debitNote"
            labelField="code"
            labelRender={(item) =>
              `[ ${item.code} ] ${item.client?.name} :  ${cellRender.Currency(
                item.clientPayable
              )}`
            }
            valueField="_id"
            value={selected}
            searchFields={["code", "searchText"]}
            clearable={true}
            onDataChange={(value) => {
              // console.log("onDataChange", value);
              if (!value) return setSelected(null);
              setSelected(value);
            }}
            preQuery={{ paymentStatus: "WAIT" }}
            zIndex={1000}
            placeholder="Select Debit Note"
            sort={{ clientPayable: -1 }}
          />
          <Button
            color="blue"
            size="xs"
            my="md"
            fullWidth
            onClick={handleAdd}
            disabled={!selected}
          >
            Add
          </Button>
        </Box>
      )}
    </>
  );
};
export const MatchRow = ({
  matching,
  index,
  onAddDebitNote,
  onRemoveDebitNote,
  onConfirm,
  onUnConfirm,
  removeFromList,
  status,
}) => {
  return (
    <>
      {matching && (
        <Grid justify="center">
          <Grid.Col sm={6}>
            <MatchingLeft
              matching={matching}
              onConfirm={onConfirm}
              onUnConfirm={onUnConfirm}
              index={index}
              removeFromList={removeFromList}
              status={status}
            />
          </Grid.Col>
          <Grid.Col sm={6}>
            <MatchingRight
              matching={matching}
              index={index}
              onAddDebitNote={onAddDebitNote}
              onRemoveDebitNote={onRemoveDebitNote}
            />
          </Grid.Col>
        </Grid>
      )}
    </>
  );
};

const MatchingList = ({ matchings, onChanged, status }) => {
  const forceUpdate = useForceUpdate();

  const allDebiteNotes = matchings?.reduce((acc, m) => {
    return [...acc, ...m.debitNotes];
  }, []);

  // const rows = payments?.filter(({ wallet: w }) => w.type === type) ?? [];
  const updateMatchedAmount = (index) => {
    const matching = matchings[index];
    if (!matching) return;
    console.log("updateMatchedAmount", matching);

    matching.matchedAmount = matching.debitNotes.reduce(
      (acc, dn) => acc + dn.clientPayable,
      0
    );
    console.log("matching.matchedAmount =>", matching.matchedAmount);
    matching.diffAmount = matching.receivedAmount - matching.matchedAmount;
    onChanged(index);
  };

  const onAddDebitNote = (index, debitNote) => {
    if (!debitNote) return;
    if (!matchings[index]) return;

    //If already exist return
    if (allDebiteNotes.find((dn) => dn._id === debitNote._id)) {
      showNotification({
        title: "Debit Note already added",
        message: "Debit Note already added",
        color: "red",
      });
      return;
    }

    const matching = matchings[index];
    matching.debitNotes.push(debitNote);
    updateMatchedAmount(index);

    // onChanged(index);
  };

  const onRemoveDebitNote = (index, dnIndex) => {
    console.log("onRemoveDebitNote", index, dnIndex);
    const matching = matchings[index];
    if (!matching) return;

    const dn = matching.debitNotes[dnIndex];
    if (!dn) return;

    matching.debitNotes = matching.debitNotes.filter((d, i) => i !== dnIndex);
    updateMatchedAmount(index);
    // onChanged(index);
  };

  const onUnConfirm = (index) => {
    const matching = matchings[index];
    if (!matching) return;
    matching.status = "PENDING";
    forceUpdate();
    showNotification({
      title: "Un-Confirm Payment",
      message: "Un-Confirm Payment",
      color: "red",
    });
    onChanged(index);
  };

  const onConfirmMatch = (index) => {
    const matching = matchings[index];
    if (!matching) return;
    if (matching.debitNotes.length === 0) {
      showNotification({
        title: "Please add Debit Note",
        message: "Please add Debit Note",
        color: "red",
      });
      return;
    }
    matching.status = "CONFIRM";
    forceUpdate();
    showNotification({
      title: "Confirm Payment",
      message: "Confirm Payment",
      color: "green",
    });
    onChanged(index);
  };

  const removeFromList = (index) => {
    // console.log("removeFromList", index);
    matchings.splice(index, 1);
    matchings = [...matchings];
    updateMatchedAmount(index);
    // onChanged(index);
    forceUpdate();
  };

  return (
    <>
      {matchings && (
        <>
          {matchings.length === 0 && (
            <Card>
              <Title order={6}>{`There are no pending matchings`}</Title>
            </Card>
          )}
          {matchings.map((m, index) => (
            <>
              <MatchRow
                matching={m}
                index={index}
                onAddDebitNote={onAddDebitNote}
                onRemoveDebitNote={onRemoveDebitNote}
                onConfirm={onConfirmMatch}
                onUnConfirm={onUnConfirm}
                removeFromList={removeFromList}
                status={status}
              />
              <Divider variant="dashed" mt="xl" mb="xl" />
            </>
          ))}
        </>
      )}
    </>
  );
};

const MatchingTab = ({ matchings, form }) => {
  const sorted = matchings; //?.sort((a, b) => a.bids.length - b.bids.length);
  const confirmed = matchings?.filter((m) => m.status === "CONFIRM");
  const pending = matchings?.filter((m) => m.status === "PENDING");
  const status = form.values.status;

  const forceUpdate = useForceUpdate();

  const onMatchingChange = (index) => {
    const totalMatchedAmount = matchings.reduce(
      (acc, m) => acc + m.matchedAmount,
      0
    );
    const totalReceivedAmount = matchings.reduce(
      (acc, m) => acc + m.receivedAmount,
      0
    );

    // console.log("onMatchingChange =>", totalMatchedAmount, totalReceivedAmount);
    form.setFieldValue("numOfMatchings", matchings.length);
    form.setFieldValue("totalReceivedAmount", totalReceivedAmount);
    form.setFieldValue("totalMatchedAmount", totalMatchedAmount);
    forceUpdate();
  };
  return (
    <Tabs defaultValue="pending">
      <Tabs.List>
        <Tabs.Tab value="pending">{`Pending (${pending?.length})`}</Tabs.Tab>
        <Tabs.Tab value="confirm">{`Confirmed (${confirmed?.length})`}</Tabs.Tab>
      </Tabs.List>

      <Tabs.Panel value="pending" pt="xl">
        <MatchingList
          matchings={pending}
          onChanged={onMatchingChange}
          status={status}
        />
      </Tabs.Panel>

      <Tabs.Panel value="confirm" pt="xl">
        <MatchingList
          matchings={confirmed}
          onChanged={onMatchingChange}
          status={status}
        />
      </Tabs.Panel>
    </Tabs>
  );
};
const GIMatchingTable = ({ form, name }) => {
  const { matchings, _id, status } = form.values;
  const [cellRender] = useCellRender();
  const [formatter] = useFormatter();
  const [exporting, setExporting] = useState(false);
  const [isLoading, setLoading] = useState(false);
  const [exportingDetail, setExportingDetail] = useState(false);
  const [exportingOrderDetail, setExportingOrderDetail] = useState(false);
  const [exportingBankFile, setExportingBankFile] = useState(false);

  const [api] = useServerApi();

  const handleConfirm = async () => {
    const _id = _.get(form.values, "_id");
    if (!_id || exporting) return;

    try {
      await form.save();

      const ans = window.confirm(
        "Are you sure to confirm the payments? Once confirmed, the Debitnotes will be marked as paid."
      );
      if (!ans) return;
      setExporting(true);
      let result = await api.GIMatchPayment.confirm(_id);
      console.log(result);
      form.setFieldValue("status", "COMPLETED");
      setExporting(false);
    } catch (error) {
      //   console.log(error);
      setExporting(false);
    }
  };

  const handleExportDetail = async () => {
    if (!_id || exporting) return;
    try {
      setExporting(true);
      setExportingDetail(true);
      let result = await api.Payroll.exportDetail(_id);
      setExporting(false);
      setExportingDetail(false);

      // console.log(result);
      if (result.url) return window.open(result.url, "_blank");
      if (result.error) throw result.error;
    } catch (error) {
      console.log(error);
      setExporting(false);
      setExportingDetail(false);
    }
  };

  const unsetLoading = () => {
    setLoading(false);
    setExporting(false);
  };

  const handleGetUnMatch = async () => {
    if (!_id || exporting) return;
    try {
      setExporting(true);
      setLoading(true);
      let unmatches = await api.GIMatchPayment.getUnMatch(_id);

      if (_.isEmpty(unmatches)) {
        showNotification({
          title: "No un-match payment found",
          message: "No un-match payment found",
          color: "green",
        });

        return unsetLoading();
      }

      //Filter not in the list of form.values.matchings
      unmatches = unmatches.filter(
        (m) =>
          !form.values.matchings.find((fm) => fm.bankTransaction._id === m._id)
      );

      if (_.isEmpty(unmatches)) return unsetLoading();

      const data = unmatches.map((m) => {
        return {
          bankTransaction: m,
          receivedAmount: m.deposit,
          matchedAmount: 0,
          diffAmount: m.amount,
          debitNotes: [],
          status: "PENDING",
        };
      });

      // console.log("data", data);

      const matchings = [...form.values.matchings, ...data];

      const values = {
        ...form.values,
        matchings,
        numOfMatchings: matchings.length,
        totalReceivedAmount: matchings.reduce(
          (acc, m) => acc + m.receivedAmount,
          0
        ),
        totalMatchedAmount: matchings.reduce(
          (acc, m) => acc + m.matchedAmount,
          0
        ),
      };

      form.setValues(values);

      //Update data
      return unsetLoading();
    } catch (error) {
      //   console.log(error);
      setExporting(false);
      setLoading(false);
    }
  };

  const isGetPendingDisable = () => status === "COMPLETED";

  const confirmedMatching = matchings?.filter((m) => m.status === "CONFIRM");

  return (
    <>
      {/* <ReactJson src={form.values} style={{ background: "white" }} collapsed /> */}
      <Group position="apart" mt="xl">
        <span></span>
        <Group position="right" mb={"xl"}>
          <Button
            disabled={isGetPendingDisable() || exporting}
            loading={isLoading}
            onClick={() => handleGetUnMatch()}
            leftIcon={<IconRefresh size={14} />}
          >
            Get Un-Match Payment
          </Button>
          {/* <Button
            // color={"green"}
            disabled={exporting}
            loading={exportingDetail}
            onClick={() => handleExportDetail()}
            leftIcon={<IconFile size={14} />}
          >
            Print
          </Button> */}

          <Button
            color={"red"}
            disabled={
              exporting ||
              status === "COMPLETED" ||
              confirmedMatching?.length === 0
            }
            loading={exportingBankFile}
            onClick={() => handleConfirm()}
            leftIcon={<IconFile size={14} />}
          >
            Confirm
          </Button>
        </Group>
      </Group>
      <MatchingTab matchings={matchings} form={form} />
    </>
  );
};

export default GIMatchingTable;
