import _ from "lodash";
import React, { useRef, useEffect } from "react";

function deepCompareEquals(a, b) {
  return _.isEqual(a, b);
}

function useDeepCompareMemoize(value) {
  const ref = useRef();

  if (!deepCompareEquals(value, ref.current)) {
    ref.current = value;
  }
  return ref.current;
}

export default function useDeepCompareEffect(callback, dependencies) {
  useEffect(callback, dependencies.map(useDeepCompareMemoize));
}
