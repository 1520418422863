import { Button, Group } from "@mantine/core";
import React, { useState } from "react";
import _ from "lodash";

import NewTicketButton from "./newTicketButton";
import { useServerApi } from "../hooks/userServerApi";
import { useTranslation } from "react-i18next";

import QuotationConfirmButton from "./quotationConfirmButton";
import QuotationCopyDNButton from "./quotationCopyDNButton";
import QuotationEditButton from "./quotationEditButton";
const ORDER_STATUS_ALLOWED_TO_EDIT = ["DFT", "CAN", "RCV", "SUB", "PND", "AIP"];
const ORDER_STATUS_ALLOWED_TO_ADJUST = ["INF"];
const ORDER_STATUS_ALLOWED_TO_CONFIRM = ["SUB", "PND", "AIP"];

const QuotationPrintButton = ({ mainForm, disabled = false }) => {
  const [api] = useServerApi();
  const [printing, setPrinting] = useState(false);

  const print = async () => {
    try {
      const { _id } = mainForm.values;
      if (!_id) return;
      await mainForm.save();

      setPrinting(true);
      const result = await api.Quotation.print(_id);
      setPrinting(false);
      // console.log(result);
      if (!result || !result.data || !result.data.url) return;
      window.open(result.data.url);
      mainForm.setFieldValue("status", result.data.status);
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <Button
      fullWidth
      onClick={() => print()}
      loading={printing}
      disabled={disabled}
    >
      {printing ? "Printing" : "Print Quotation"}
    </Button>
  );
};

const QuotationPrintRNButton = ({ mainForm }) => {
  const [api] = useServerApi();
  const [printing, setPrinting] = useState(false);

  const print = async () => {
    try {
      const { _id } = mainForm.values;
      if (!_id) return;
      await mainForm.save();

      setPrinting(true);
      const result = await api.Quotation.print(_id, "RN");
      setPrinting(false);

      // console.log(result);
      if (!result || !result.data || !result.data.url) return;
      window.open(result.data.url);
      mainForm.setFieldValue("status", result.data.status);
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <Button fullWidth onClick={() => print()} loading={printing}>
      {printing ? "Printing" : "Print as Renewal Notice"}
    </Button>
  );
};

const OuotationTerminateButton = ({ mainForm, disabled = false }) => {
  const [api] = useServerApi();
  const terminate = () => {
    mainForm.updateAndSave({ status: "CAN" });
  };
  const { t } = useTranslation();

  return (
    <Button
      fullWidth
      disabled={disabled}
      color={"red"}
      onClick={() => terminate()}
    >
      {t("Cancel")}
    </Button>
  );
};

const QuotationActionList = ({ form, role }) => {
  const { status, code, consultants, _id } = form.values;

  const showConfirm = true;
  const showPrint = true;
  const showTerminate = true;
  const showCopy = true;
  const showEdit = true;

  const disabled = !_id;
  return (
    <>
      <Group mt={10}>
        {showPrint && (
          <QuotationPrintButton mainForm={form} disabled={disabled} />
        )}

        {showCopy && (
          <QuotationCopyDNButton
            mainForm={form}
            title="Copy to Debit Note"
            disabled={disabled}
          />
        )}

        {showConfirm && (
          <QuotationConfirmButton mainForm={form} disabled={disabled} />
        )}
        {showTerminate && (
          <OuotationTerminateButton mainForm={form} disabled={disabled} />
        )}
      </Group>
    </>
  );
};

export default QuotationActionList;
