import React, { useState, useEffect, useRef } from "react";
import { useServerApi } from "../hooks/userServerApi";
import _ from "lodash";

import {
  Timeline,
  Text,
  Avatar,
  TextInput,
  Group,
  ActionIcon,
  ScrollArea,
  List,
} from "@mantine/core";
import { IconCircleMinus, IconTrash } from "@tabler/icons";
import { DragDropContext, Droppable, Draggable } from "@hello-pangea/dnd";

const RemarkList = ({
  form,
  name,
  label,
  scrollHeight = 300,
  onEnterSave = true,
  disabled = false,
  ...props
}) => {
  const remarks = _.get(form.values, name) || [];
  const ref = useRef(null);

  const handleKeyDown = async (e) => {
    if (e.key === "Enter") {
      remarks.push(e.target.value);
      ref.current.value = "";
      if (onEnterSave) form.updateAndSave({ [name]: remarks });
      else form.setFieldValue(name, remarks);
    }
  };

  const remove = (index) => {
    // console.log(remarks, index);
    // remarks.findIndex();
    remarks.splice(index, 1);
    form.updateAndSave({ [name]: remarks });
    ref.current.value = "";
  };

  return (
    <>
      {/* entityId:{entityId} */}
      {label && (
        <Text size={props.size ?? "xs"} mb="xs">
          {label}
        </Text>
      )}
      {!disabled && <TextInput ref={ref} mb={20} onKeyDown={handleKeyDown} />}

      {!_.isEmpty(remarks) && (
        // <ScrollArea mt="xl" >
        <List>
          {remarks.map((remark, index) => (
            <List.Item key={index}>
              <Group position="apart" miw={600}>
                <Text size={"xs"}>{remark}</Text>
                {!disabled && (
                  <ActionIcon
                    mx={"sm"}
                    size={15}
                    color="dark"
                    onClick={() => remove(index)}
                  >
                    <IconCircleMinus />
                  </ActionIcon>
                )}
              </Group>
            </List.Item>
          ))}
        </List>
        // </ScrollArea>
      )}
    </>
  );
};

export default RemarkList;
