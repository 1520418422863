import {
  Table,
  TextInput,
  Checkbox,
  Group,
  Button,
  ActionIcon,
} from "@mantine/core";
import { IconTrash } from "@tabler/icons";
import { useState, useEffect } from "react";
import _ from "lodash";
import ReactJson from "react-json-view";

import { useForceUpdate } from "@mantine/hooks";
import FormSection from "./formSection";
import { useTranslation } from "react-i18next";
import TemplateSaveLoadButtons from "./templateSaveLoadButtons";

const ProductCheckList = ({ form, name, disabled, variant, ...props }) => {
  const forceUpdate = useForceUpdate();
  const { t } = useTranslation();
  const checkList = _.get(form.values, name) || [];

  const handleAdd = () => {
    form.setFieldValue(name, [
      ...checkList,
      { name: "", optional: false, frontier: true, provider: true },
    ]);
  };

  const handleRemove = (index) => {
    let list = [...checkList];
    list.splice(index, 1);
    form.setFieldValue(name, list);
  };

  const renderCheckBox = (row, name) => (
    <Checkbox
      value={row[name]}
      checked={row[name]}
      disabled={disabled}
      onChange={(_) => {
        row[name] = !row[name];
        forceUpdate();
      }}
    />
  );

  const checkrow = (row, index) => {
    return (
      <tr key={index}>
        <td>
          <TextInput
            disabled={disabled}
            variant={variant}
            value={row.name}
            onChange={(e) => {
              row.name = e.target.value;
              forceUpdate();
            }}
          />
        </td>

        <td> {renderCheckBox(row, "optional")} </td>
        <td> {renderCheckBox(row, "frontier")} </td>
        <td> {renderCheckBox(row, "provider")} </td>
        <td>
          {!disabled && (
            <ActionIcon onClick={() => handleRemove(index)}>
              <IconTrash size={14} />
            </ActionIcon>
          )}
        </td>
      </tr>
    );
  };

  const renderTable = () => (
    <Table {...props}>
      <thead>
        <tr>
          <th>{t("Documents")}</th>
          <th>{t("Optional")}</th>
          <th>{t("Internal")}</th>
          <th>{t("Provider")}</th>
          <th></th>
        </tr>
      </thead>
      <tbody>{checkList.map((row, index) => checkrow(row, index))}</tbody>
    </Table>
  );

  const renderPlaceHolder = () => <></>;
  const loadFromTemplate = (template) => {
    // console.log("loadFromTemplate", template?.content);
    form.setFieldValue(name, [...template?.content]);
  };
  return (
    <>
      <TemplateSaveLoadButtons
        templateCategory="Product CheckList"
        onLoadTemplate={loadFromTemplate}
        currentContent={checkList}
      />

      <Group>
        {/* <ReactJson src={_.get(form.values, name) } style={{background:'white'}} collapsed/> */}
        {!_.isEmpty(checkList) && renderTable()}
        {_.isEmpty(checkList) && renderPlaceHolder()}
        {!disabled && <Button onClick={handleAdd}> Add CheckList Item</Button>}
      </Group>
    </>
  );
};

export default ProductCheckList;
