import React from "react";
import InfoGridList from "./infoGridList";
import { useCellRender } from "../hooks/useCellRender";
import { DATA_DN_PAYMENT_STATUS } from "../data/options";
import { Alert, Group, Input, Text } from "@mantine/core";
import { IconAlertCircle } from "@tabler/icons";
import _ from "lodash";
import moment from "moment";

export default function DebitNotePaymentSummary({ form, name }) {
  const [cellRender] = useCellRender();
  const dn = form.values;
  const p = dn.payment;

  const status = DATA_DN_PAYMENT_STATUS.find(
    (s) => s.value === dn?.paymentStatus
  );

  const fields = [
    {
      title: "Payment Status",
      value: status ? (
        <Group>
          {cellRender.Order.status(dn?.paymentStatus)}
          {status?.label}
        </Group>
      ) : (
        "Unknown"
      ),
    },
    {
      title: "Pay Date",
      value: p?.payDate ? moment(p.payDate).format("LL") : "-",
    },
    {
      title: "Paid Amount",
      value: p?.amount ? cellRender.Currency(p.amount) : "-",
    },
    {
      title: "Method",
      value: p?.paymentMethod ?? "-",
    },
    {
      title: "Remark",
      value: p?.remark ?? "",
      singleLine: true,
    },
  ];

  return dn && <InfoGridList fields={fields} values={dn} />;
}
