import React, { useEffect, useState } from "react";
import {
  PageList,
  ENUM_FORM_DISPLAY,
  ENUM_FORM_LAYOUT_CONTAINER,
} from "../../components/pageList";
import ActionCellRender from "../../components/actionCellRender";
import { useCellRender } from "../../hooks/useCellRender";
import { useFormatter } from "../../hooks/useFomatter";
import { IconTrash, IconPencil, IconSettings, IconEye } from "@tabler/icons";

import FileList from "../../components/fileList";
import LinkageList from "../../components/linkageList";

import { ProductCommissionTable } from "../../components/productCommissionTable";
import ProductCheckList from "../../components/productCheckList";

import ProductBasicSummary from "../../components/productBasicSummary";
import ProductPaymentSummary from "../../components/productPaymentSummary";
import { useAuthUser } from "react-auth-kit";
import { useServerApi } from "../../hooks/userServerApi";
import { useForceUpdate } from "@mantine/hooks";
import _ from "lodash";

const Product = ({}) => {
  const apiEntity = "product";
  const [cellRender] = useCellRender();
  const [formatter] = useFormatter();
  const [filterQuery, setFilterQuery] = useState(null);
  const [api] = useServerApi();
  const forceUpdate = useForceUpdate();
  const [preQuery, setPreQuery] = useState({
    status: ["ACTIVE", "INACTIVE"], // just hide hidden products from consultant
  });
  const auth = useAuthUser();

  const formSchema = {
    title: "Product",
    display: {
      mode: ENUM_FORM_DISPLAY.LINK,
      // size: "xl",     //xs, sm, xl  ....100%
      // position:"right"
    },
    apiEntity,
    isMainForm: true,
    initialValues: {
      code: "",
      name: "",
      cname: "",
      status: "INACTIVE",

      category: null,
      type: null,
      provider: null,
      ageType: "",
      applicantAgeMin: 1,
      applicantAgeMax: 70,
      insuredAgeMin: 0,
      insuredAgeMax: 100,
      currency: "HKD",
      description: "",
      minInitialCharge: 0,
      variations: [],

      // termsOfService: true,
    },

    validate: {
      code: (value) =>
        value?.length < 2 ? "Code must have at least 2 letters" : null,
      name: (value) =>
        value?.length < 4 ? "Name must have at least 4 letters" : null,
      insuredWeeklyAge: (value) =>
        value >= 52 ? "Weekly Age must less than 52 week" : null,
    },

    layout: {
      containers: [
        {
          key: "t1",
          parent: null,
          type: ENUM_FORM_LAYOUT_CONTAINER.TABS,
          props: {
            variant: "default", //default | pills | outline
            defaultValue: "Details",
          },
          tab: [
            { key: "t1-1", label: "Details", icon: IconSettings },

            { key: "t1-4", label: "Resources", icon: IconSettings },
            { key: "t1-3", label: "Submission CheckList", icon: IconSettings },
            // {
            // 	key: "t1-5",
            // 	label: "Activities",
            // 	icon: IconSettings,
            // },
          ],
        },
        {
          key: "basicInfo",
          parent: "t1-1",
          type: ENUM_FORM_LAYOUT_CONTAINER.SECTION,
          props: {
            title: "Basic Info",
            description: "Please fill in the basic info of product",
          },
        },
        {
          key: "paymentInfo",
          parent: "t1-1",
          type: ENUM_FORM_LAYOUT_CONTAINER.SECTION,
          props: {
            title: "Payment Info",
            description: "Please fill in the Payment info of product",
          },
        },

        {
          key: "basicInfo-g",
          parent: "basicInfo",
          type: ENUM_FORM_LAYOUT_CONTAINER.GRID,
          cols: [
            {
              key: "basicInfo-g1",
              props: {
                span: 6,
              },
            },
            {
              key: "basicInfo-g2",
              props: {
                span: 6,
              },
            },
          ],
        },

        {
          key: "paymentInfo-g",
          parent: "paymentInfo",
          type: ENUM_FORM_LAYOUT_CONTAINER.GRID,
          cols: [
            {
              key: "paymentInfo-g1",
              props: {
                span: 6,
              },
            },
            {
              key: "paymentInfo-g2",
              props: {
                span: 6,
              },
            },
          ],
        },

        {
          key: "commissionTable-section",
          parent: "t1-1",
          type: ENUM_FORM_LAYOUT_CONTAINER.SECTION,
          props: {
            title: "Commission Tables",
            description: "Please fill in the Payment info of product",
          },
        },

        {
          key: "checkList-section",
          parent: "t1-3",
          type: ENUM_FORM_LAYOUT_CONTAINER.SECTION,
          props: {
            title: "CheckList",
            description: "Please fill in the Payment info of product",
          },
        },

        {
          key: "resource-file-section",
          parent: "t1-4",
          type: ENUM_FORM_LAYOUT_CONTAINER.SECTION,
          props: {
            title: "Files",
            description: "Please fill in the Payment info of product",
          },
        },
        {
          key: "resource-link-section",
          parent: "t1-4",
          type: ENUM_FORM_LAYOUT_CONTAINER.SECTION,
          props: {
            title: "Links",
            description: "Please fill in the Payment info of product",
          },
        },

        // {
        // 	key: "activities-section",
        // 	parent: "t1-5",
        // 	type: ENUM_FORM_LAYOUT_CONTAINER.SECTION,
        // 	props: {
        // 		title: "Activities",
        // 		description: "Please fill in the Payment info of product",
        // 	},
        // },
      ],

      fields: [
        {
          component: ProductBasicSummary,
          parent: "basicInfo",
          props: {},
        },
        {
          component: ProductPaymentSummary,
          parent: "paymentInfo",
          props: {},
        },

        {
          name: "commissionTables",
          component: ProductCommissionTable,
          parent: "commissionTable-section",
          props: {
            rateFactor: process.env.REACT_APP_COMMISSION_COMMON_FACTOR,
            firstYearMaxPercentage: 100,
            version: "CONSULTANT",
          },
        },

        {
          name: "checkList",
          component: ProductCheckList,
          parent: "checkList-section",
          props: {},
        },

        {
          name: "files",
          component: FileList,
          parent: "resource-file-section",
          props: {},
        },

        {
          name: "linkages",
          component: LinkageList,
          parent: "resource-link-section",
          props: {},
        },

        // {
        // 	name: "log",
        // 	component: ActivityLog,
        // 	parent: "activities-section",
        // 	props: {},
        // },
      ],
    },
  };
  const tableSchema = {
    showActionColumn: true,

    columns: [
      {
        field: "code",
        sortable: true,
        width: 200,
        cellRender: (col, data) => cellRender.ProductCode(data),
      },
      {
        field: "name",
        headerName: "Product",
        sortable: true,
        width: 400,

        cellRender: (col, data) => cellRender.ProductInfo(data),
        filterable: true,

        filter: {
          apiEntity: "product",
          field: "provider",
          label: "Provider",
          populate: {
            model: "Provider",
            select: "name cname code shortName",
            labelField: "code",
            sort: { code: 1 },
          },
          width: 300,
          height: 200,
        },
      },
      {
        field: "category",
        headerName: "Cateogry",
        sortable: true,
        filterable: true,
        filter: {
          apiEntity: "product",
          field: "category",
          label: "Product Cateogry",
          populate: {
            model: "ProductCategory",
            select: "name cname code",
            labelField: "name",
          },
          width: 300,
          height: 200,
        },
        cellRender: (col, data) => cellRender.ProductCategory(data.category),
      },
      {
        field: "type.name",
        headerName: "Type",
        sortable: true,
        filterable: true,
        filter: {
          apiEntity: "product",
          field: "type",
          label: "Product Type",
          width: 300,
          height: 300,

          populate: {
            model: "ProductType",
            select: "name cname code",
            labelField: "name",
            sort: { name: 1 },
          },
        },
        cellRender: (col, data) => cellRender.ProductType(data.type),
      },

      {
        field: "status",
        sortable: true,
        cellRender: (col, data) => cellRender.Status(data.status),
        // filterable: true,
        // filter: {
        //   apiEntity: "product",
        //   field: "status",
        //   label: "Status",
        //   width: 300,
        //   height: 200,
        // },
      },
      {
        field: "action",
        cellRender: ActionCellRender,
        isAction: true,
        actions: [
          {
            name: "show",
            icon: IconEye,
            props: {
              //   color:"red"
            },
          },
        ],
        actionMenu: [],
      },
    ],
    // searchableFields: ["code", "name", "cname", "email", "provider.code"],
    select: "code name cname status provider category type",

    searchableFields: [
      "code",
      "name",
      "cname",
      "status",
      "provider.code",
      "provider.cname",
      "provider.shortName",
      "type.name",
      "type.cname",
      "type.code",
    ],
  };

  const onFilterChange = (query) => {
    setFilterQuery(query);
  };

  const fetchOverridingPlan = async () => {
    try {
      if (!auth()._id) return;
      const result = await api.User.getOverridingPlan(auth()._id);
      if (!result.overridingPlan) return;
      const p = { ...preQuery };
      if (!_.isEmpty(result.overridingPlan.notProductCategory)) {
        p.category = { $nin: result.overridingPlan.notProductCategory };
      }
      if (!_.isEmpty(result.overridingPlan.notProductTypes)) {
        p.type = { $nin: result.overridingPlan.notProductTypes };
      }

      setPreQuery(p);
      // console.log("fetchOverridingPlan", result);
      // console.log("preQuery", preQuery);
    } catch (error) {
      console.log("fetchOverridingPlan error", error);
    }
  };

  useEffect(() => {
    fetchOverridingPlan();
  }, [auth()._id]);

  return (
    <>
      <PageList
        title={"Products"}
        apiEntity={apiEntity}
        tableSchema={tableSchema}
        formSchema={formSchema}
        filterQuery={filterQuery}
        readOnly={true}
        initSort={{ by: "code", order: "asc" }}
        // preQueryNotEmpty={true}
        preQuery={preQuery}
        // filter={{
        // 	component: ProductFilter,
        // 	props: {
        // 		onChange: onFilterChange,
        // 	},
        // }}
      />
    </>
  );
};

export default Product;
