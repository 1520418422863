import React, { useState, useCallback, useEffect } from "react";
import { createStyles, Avatar, Text, Group, Button } from "@mantine/core";

import ClientUpdateActionButton from "./clientUpdateActionButton";
import ClientChangeServiceRightButton from "./clientChangeServiceRightButton";
import ClientActiveEnableButton from "./clientActiveEnableButton";

import NewTicketButton from "./newTicketButton";
import { useAuthUser } from "react-auth-kit";
import ReactJson from "react-json-view";
import _ from "lodash";
import { useServerApi } from "../hooks/userServerApi";

const ClientActionList = ({ form, formAction, ...props }) => {
  const { status, code, consultants, _id } = form.values;
  const [showUpdateButton, setShowUpdateButton] = useState(false);
  const [api] = useServerApi();

  // console.log("ClientActionList", consultants);
  const auth = useAuthUser();

  const role = auth()?.userRole?.name?.toUpperCase() ?? "CONSULTANT";

  const handleAddWarning = () => {};

  const handleDeactive = () => {};

  const handleChangeServiceRight = () => {};

  const showEnableButton =
    role === "MANAGEMENT" ||
    role === "OPERATION" ||
    role === "GI OPERATION" ||
    role === "ACCOUNTING";
  const showChangeServiceButton =
    role === "MANAGEMENT" ||
    role === "OPERATION" ||
    role === "GI OPERATION" ||
    role === "ACCOUNTING";

  const checkClientOrder = async (id) => {
    if (!id) return;
    const result = await api.search({
      apiEntity: "order",
      searchQuery: {
        $and: [{ status: { $nin: ["DFT", "RCV"] } }, { client: id }],
      },
    });
    // console.log("checkClientOrder", result);
    if (result && _.isEmpty(result.docs)) setShowUpdateButton(true);
  };

  useEffect(() => {
    if (
      role === "MANAGEMENT" ||
      role === "OPERATION" ||
      role === "GI OPERATION"
    )
      setShowUpdateButton(true);
    else if (role === "CONSULTANT") {
      checkClientOrder(_id);
    }
  }, [role, _id]);
  return (
    <Group mt={10}>
      {/* Role: {role} */}
      {/* <ReactJson src={form.values} /> */}
      {showUpdateButton && <ClientUpdateActionButton mainForm={form} />}
      <NewTicketButton
        buttonType="fullWidth"
        code={code}
        entityId={_id}
        entity="client"
        ticketType={"TK-CLIENT"}
        members={consultants?.map((c) => c._id)}
      />
      {/* <Button fullWidth onClick={handleAddWarning}>
                Add Warning
            </Button> */}
      {showEnableButton && <ClientActiveEnableButton mainForm={form} />}
      {showChangeServiceButton && (
        <ClientChangeServiceRightButton color="red" mainForm={form} />
      )}
    </Group>
  );
};

export default ClientActionList;
