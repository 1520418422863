import React, { useState, useEffect } from "react";

import _ from "lodash";
import useDeepCompareEffect from "../hooks/useDeepCompareEffect";

import { useForceUpdate } from "@mantine/hooks";
import { Text, Textarea } from "@mantine/core";

import ReactQuill, { Quill } from "react-quill";
import "react-quill/dist/quill.snow.css";
import ImageResize from "quill-image-resize-module-react";
import ImageCompress from "quill-image-compress";

Quill.register("modules/imageResize", ImageResize);
Quill.register("modules/imageCompress", ImageCompress);

window.Quill = Quill;

const RichTextEditor = ({ form, name, label, mt = "xs" }) => {
  const quillRef = React.useRef(null);
  const c = _.get(form.values, name);
  const [editorContent, setEditorContent] = useState("");

  const toolBarOptions = [
    [{ header: [1, 2, false] }],
    ["bold", "italic", "underline", "strike", "blockquote"],
    [{ list: "ordered" }, { list: "bullet" }],
    ["link", "image"],
    ["clean"],
  ];
  const formats = [
    "header",
    "bold",
    "italic",
    "underline",
    "strike",
    "blockquote",
    "list",
    "bullet",
    "link",
    "image",
  ];

  const modules = {
    toolbar: toolBarOptions,
    imageResize: {
      parchment: Quill.import("parchment"),
      modules: ["Resize", "DisplaySize", "Toolbar"],
    },
    imageCompress: {
      quality: 0.7, // default
      maxWidth: 1000, // default
      maxHeight: 1000, // default
      imageType: "image/jpeg", // default
      debug: true, // default
    },
  };

  useDeepCompareEffect(() => {
    // console.log(c);
    if (editorContent) return;
    if (quillRef.current) {
      const quill = quillRef.current.getEditor();
      quill.clipboard.dangerouslyPasteHTML(0, c);
    }
  }, [c]);

  // const editorContent = _.get(form.values, name, "");

  // Function to handle changes in the editor content
  const handleEditorChange = (content) => {
    setEditorContent(content);
    form.setFieldValue(name, content);
  };

  return (
    <div>
      {/* {/* <JsonViewer src={{ content: editorContent }} /> */}
      {/* <JsonViewer src={{ content: editorContent }} /> */}
      {/* <Textarea value={editorContent} mih={500} minRows={50} /> */}
      <Text mt={mt} size="md">
        {label}
      </Text>
      <ReactQuill
        ref={quillRef}
        theme="snow"
        value={editorContent}
        onChange={handleEditorChange}
        placeholder="Write something..."
        formats={formats}
        modules={modules}
      />
    </div>
  );

  //   return (
  //     <div>
  //       <h1>Rich Text Editor</h1>
  //     </div>
  //   );
};

export default RichTextEditor;
