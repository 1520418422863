import React, { useState, useEffect } from "react";
import InfoGridList from "./infoGridList";
import { useCellRender } from "../hooks/useCellRender";
import _ from "lodash";
import { useTranslation } from "react-i18next";

export default function OrderPaymentDetail({ form, name }) {
  const [cellRender] = useCellRender();
  const order = name ? _.get(form.values, name) : form.values;
  const payment = order?.payment;

  const [fields, setFields] = useState([]);
  const { t } = useTranslation();

  useEffect(() => {
    if (!payment) return;
    const newFields = [
      {
        title: "Premium",
        value: cellRender.Currency(payment.premium, payment.currency),
      },

      {
        title: "Payment Term",
        value: payment.paymentTermUntilAge
          ? payment.paymentTermUntilAge > 128
            ? "Whole Life"
            : `Until Age ${payment.paymentTermUntilAge}`
          : payment.paymentTerm
          ? `${payment.paymentTerm} years`
          : "-",
      },

      { title: "Pay Mode", value: t(payment.payMode) ?? "-" },
      {
        title: "Payment Count",
        value: cellRender.NumberFormat(payment.payCount),
      },
      { title: "Policy Term", value: payment.policyTerm ?? "-" },
      {
        title: "Initial Payment",
        value: cellRender.Currency(
          payment.initialPayment,
          payment?.initialPaymentCurrency ?? "HKD"
        ),
      },

      {
        title: "Maturity Date",
        value: cellRender.Date(payment?.maturityDate ?? null, "LL"),
      },
      {
        title: "Pay Up Date",
        value: cellRender.Date(payment?.payUpDate ?? null, "LL"),
      },
      { title: "Pay By", value: t(payment?.payBy) ?? "-" },
      {
        title: "Sum Insured",
        value: cellRender.Currency(payment.sumInsured, payment.currency),
      },
    ];
    setFields(newFields);
  }, [payment]);

  return (
    payment && (
      <InfoGridList
        fields={fields}
        cols={4}
        values={payment}
        dataFontWeight={600}
      />
    )
  );
}
