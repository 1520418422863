import React from "react";
import InfoGridList from "./infoGridList";
import { useCellRender } from "../hooks/useCellRender";
import _ from "lodash";
const OrderProductDetail = ({ form, name }) => {
  const [cellRender] = useCellRender();
  const order = name ? _.get(form.values, name) : form.values;
  const product = order?.product;

  const fields = [
    { title: "Code", value: product?.code ?? "-" },
    {
      title: "Type",
      value: product?.type?.name + " " + product?.type?.cname ?? "-",
    },
    {
      title: "Provider",
      value: product?.provider?.shortName ?? product?.provider?.code,
    },
  ];

  return (
    product && (
      <>
        {cellRender.ProductInfo(product, "xs")}

        <InfoGridList
          fields={fields}
          values={product}
          dataFontWeight={600}
          mt="xl"
        />
      </>
    )
  );
};

export default OrderProductDetail;
