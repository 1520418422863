import React, { useState, useEffect } from "react";
import _ from "lodash";
import {
  TextInput,
  Select,
  Textarea,
  NumberInput,
  Group,
  Title,
  Text,
  Divider,
  Button,
} from "@mantine/core";
import ReactJson from "react-json-view";

import ArrayTable from "./arrayTable";
import { DATA_CURRENCY } from "../data/options";
import useDeepCompareEffect from "../hooks/useDeepCompareEffect";
import { useFormatter } from "../hooks/useFomatter";
import { useForceUpdate } from "@mantine/hooks";
import { IconAlertTriangle } from "@tabler/icons";
import { useTranslation } from "react-i18next";

const calculateTotal = (charges) => {
  if (_.isEmpty(charges)) return 0;
  const total = charges?.reduce((a, c) => a + (c.qty ?? 1) * c.unitPrice, 0);
  return +total.toFixed(2);
};

const QuotationChargeItems = ({ form, name }) => {
  const chargeItems = _.get(form.values, "chargeItems");
  const costTotal = _.get(form.values, "costTotal");

  const [total, setTotal] = useState(0);
  const [formatter] = useFormatter();
  const [discountAmount, setDiscountAmount] = useState(
    _.get(form.values, "discountAmount")
  );

  const forceUpdate = useForceUpdate();

  const rowInitValue = {
    item: "",
    currency: "HKD",
    qty: 1,
    unitPrice: 0,
    price: 0,
  };

  const fields = [
    {
      header: "Item",
      headerWidth: "50%",
      fieldName: "item",
      component: TextInput,
      props: {},
    },

    {
      header: "Qty",
      headerWidth: "10%",
      fieldName: "qty",
      component: NumberInput,
      props: {},
    },
    {
      header: "Currency",
      headerWidth: "12%",
      fieldName: "currency",
      component: Select,
      props: {
        data: DATA_CURRENCY,
        defaultValue: "HKD",
        searchable: true,
      },
    },
    {
      header: "Unit Price",
      headerWidth: "30%",
      fieldName: "unitPrice",
      component: NumberInput,
      props: {
        parser: formatter.currencyParser,
        formatter: formatter.currencyFormat,
        //  prefix="$"
        // decimalSeparator=","
        precision: 2,
      },
    },
  ];

  const onChargeChange = (charges) => {
    const total = calculateTotal(charges);
    setTotal(total);
  };

  useEffect(() => {
    onChargeChange(chargeItems);
  }, [chargeItems]);

  useEffect(() => {
    form.setFieldValue("total", total);
  }, [total]);

  const applyDiscount = () => {
    // console.log("discount", discountAmount);
    form.setFieldValue("discountAmount", discountAmount);
    forceUpdate();
  };

  const { t } = useTranslation();
  return (
    <>
      {total > 0 && form.values.costTotal === 0 && (
        <Group mb="xl">
          <IconAlertTriangle size="14" color="red" />
          <Text color={"red"} size="md">
            Cost Items is 0
          </Text>
        </Group>
      )}

      <Group align={"end"} mb="xl">
        <NumberInput
          precision={2}
          label="Exclusive Discount (HKD)"
          placeholder="10"
          value={_.get(form.values, "discountAmount")}
          onChange={setDiscountAmount}
          parser={formatter.currencyParser}
          formatter={formatter.currencyFormat}
        ></NumberInput>
        <Button size="xs" color="green" onClick={() => applyDiscount()}>
          {t("Apply Discount")}
        </Button>
      </Group>
      <ArrayTable
        form={form}
        name={name}
        rowInitValue={rowInitValue}
        fields={fields}
        onDataChange={onChargeChange}
      />
    </>
  );
};

export default QuotationChargeItems;
