import {
  Accordion,
  Button,
  Text,
  Title,
  Table,
  Select,
  Group,
} from "@mantine/core";
import React, { useState, useEffect } from "react";
import moment from "moment";
import { useServerApi } from "../hooks/userServerApi";
import { useTranslation } from "react-i18next";
const CpdIAReport = ({}) => {
  const [years, setYears] = useState([]);
  const [loading, setLoading] = useState(false);
  const [selected, setSelected] = useState();
  const { t } = useTranslation();
  const numYears = 3;
  const [api] = useServerApi();
  const currentYear = moment().year();
  const startYear = moment().isBefore(`${currentYear}-08-01`)
    ? currentYear
    : currentYear + 1;

  useEffect(() => {
    const yrs = [];

    for (let i = 0; i < numYears; i++) {
      let yr = startYear - i;
      yrs.push({
        label: `${yr - 1} - ${yr}`,
        value: yr,
      });
    }
    setYears(yrs);
    setSelected(startYear);
  }, []);

  const downloadIAReport = async () => {
    try {
      setLoading(true);
      console.log("Donwload IA", selected);
      const result = await api.Cpd.getIAYearReport(selected);
      setLoading(false);
      console.log(result);
      if (result && result.data && result.data.url) {
        window.open(result.data.url, "_blank");
      }
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  };

  return (
    <div>
      {/* {selected} */}
      <Text size="xs">{t("Please select the year of IA CPD records")}</Text>
      <Group mt="xs">
        <Select
          data={years}
          with={10}
          defaultValue={startYear}
          size="xs"
          onChange={(v) => setSelected(v)}
        />
      </Group>
      <Button
        size="xs"
        mt="xl"
        onClick={() => downloadIAReport()}
        loading={loading}
      >
        {t("Download")}
      </Button>
      <></>
    </div>
  );
};

export default CpdIAReport;
