import React, { useState } from "react";
import {
  Button,
  Checkbox,
  Group,
  keyframes,
  List,
  Title,
  Textarea,
  Select,
  NumberInput,
} from "@mantine/core";
import _ from "lodash";
import { useFormRender } from "../hooks/useFormRender";
import { useServerApi } from "../hooks/userServerApi";
import { ENUM_FORM_DISPLAY, ENUM_FORM_LAYOUT_CONTAINER } from "./pageList";
import { IconSettings } from "@tabler/icons";
import FormConfirmation from "./formConfirmation";
import { useDisclosure } from "@mantine/hooks";

import { Modal, Text } from "@mantine/core";
import { showNotification } from "@mantine/notifications";
import { IconExclamationMark, IconAlertCircle } from "@tabler/icons";
import { DatePicker } from "@mantine/dates";
import { DATA_PAYMENT_METHOD } from "../data/options";
import moment from "moment";
import DummyWidget from "./dummyWidget";
import { useFormatter } from "../hooks/useFomatter";
import ReactJson from "react-json-view";

const DebitNoteHoldCoverButton = ({ mainForm }) => {
  const [api] = useServerApi();
  const [loading, setLoading] = useState(false);

  const { _id: id } = mainForm.values;

  const [formatter] = useFormatter();
  const formSchema = {
    title: "Hold Cover",
    description: "Confirm payment received from client for Debit note.",

    display: {
      mode: ENUM_FORM_DISPLAY.MODAL,
      size: "1000px", //xs, sm, xl  ....100%
    },
    showSaveBar: true,
    closeAfterSave: true,
    initialValues: {
      date: moment().toISOString(),
      remark: "",
    },

    validate: {
      date: (value) => (!value ? "Please select the hold cover date" : null),
    },

    layout: {
      containers: [],
      fields: [
        {
          name: "date",
          component: DatePicker,
          type: "datePicker",
          props: {
            required: true,
            label: "Hold CoverDate",
            zIndex: 1000,
            placeholder: "Pick Date",
            allowFreeInput: true,
          },
        },

        {
          name: "remark",
          component: Textarea,
          props: {
            label: "Remark",
          },
        },
      ],
    },
  };

  const onSubmit = async ({ values, formMode }) => {
    try {
      formAction.close();
      setLoading(true);
      const result = await api.DebitNote.holdCover({
        id,
        holdCover: values,
      });
      mainForm.setFieldValue("holdCover", values);

      // console.log(result);
      // console.log("values", values);
      // console.log("dn.holdCover ", mainForm.values.holdCover);
      setLoading(false);

      if (!result.success) throw result.error;
    } catch (error) {
      showNotification({
        title: `Hold Cover Fail`,
        icon: <IconAlertCircle size={18} />,
        message: error,
      });
      setLoading(false);
    }
  };

  const [renderForm, formAction, formStatus, formSetting] = useFormRender(
    formSchema,
    null,
    onSubmit
  );

  const handleClick = () => {
    const holdCover = mainForm?.values.holdCover;
    formAction.open({ mode: "edit", data: holdCover });
  };

  return (
    <>
      {/* {values.total - mainForm.values.discountAmount} */}
      {/* <ReactJson src={mainForm.values} style={{ background: "white" }} /> */}
      <Button
        fullWidth
        color={"green"}
        onClick={() => handleClick()}
        loading={loading}
      >
        Hold Cover
      </Button>
      {renderForm()}
    </>
  );
};

export default DebitNoteHoldCoverButton;
