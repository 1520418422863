import React from "react";
import {
  Container,
  Title,
  Group,
  Button,
  Text,
  Paper,
  Box,
  Space,
  Grid,
} from "@mantine/core";
import { Breadcrumbs, Anchor } from "@mantine/core";
import { showNotification } from "@mantine/notifications";

import { IconExclamationMark, IconCircleCheck } from "@tabler/icons";
import { useTranslation } from "react-i18next";

const items = [
  { title: "Home", href: "/" },
  { title: "Setting", href: "/setting/" },
  { title: "Reset Password", href: "/resetPassword" },
].map((item, index) => (
  <Anchor href={item.href} key={index}>
    {item.title}
  </Anchor>
));

export const Page = ({ title, size = "xl", children, actions }) => {
  const { t } = useTranslation();

  return (
    <Container size={size}>
      {/* <Breadcrumbs separator=">">{items}</Breadcrumbs> */}
      <Group position={"apart"}>
        <Title align="left">{t(title)}</Title>
        <Group>{actions}</Group>
      </Group>
      <Space h="xl" />
      {children}
    </Container>
  );
};

export const FormPage = ({ children, title, actions }) => {
  return (
    <form>
      <Page title={title} actions={actions()}>
        {children}
      </Page>
    </form>
  );
};

export const FormSectionCard = ({
  children,
  title,
  withPaper = true,
  h,
  ...props
}) => {
  const { t } = useTranslation();
  return (
    <Box
      mb="xl"
      {...props}
      style={{
        "@media print": {
          display: "block",
          "page-break-before": "always",
        },
      }}
    >
      <Text transform="uppercase" size="xs" mb="xs">
        {t(title)}
      </Text>
      {!withPaper && children}
      {withPaper && (
        <Paper shadow="md" p="xl" radius={0} h={h}>
          {children}
        </Paper>
      )}
    </Box>
  );
};

export const useFormNotification = () => {
  const notifyFormSuccess = ({
    title = "Saved",
    message = "Data submitted successfully.",
  }) => {
    return showNotification({
      title,
      // color: "red",
      icon: <IconCircleCheck size={18} />,
      message,
    });
  };

  const notifyFormError = ({
    title = "Error",
    message = "Form submit error",
  }) => {
    return showNotification({
      title,
      color: "red",
      icon: <IconExclamationMark size={18} />,
      message,
    });
  };

  return [notifyFormSuccess, notifyFormError];
};
