import React from "react";
import { ENUM_FORM_DISPLAY, ENUM_FORM_LAYOUT_CONTAINER } from "./pageList";
import { useFormRender } from "../hooks/useFormRender";
import { Button, Textarea } from "@mantine/core";
import { IconSettings } from "@tabler/icons";

import OrderReceiveCheckList from "./orderReceiveCheckList";
import OrderReceiveConfirm from "./orderReceiveConfirm";
import { useServerApi } from "../hooks/userServerApi";
import { showNotification } from "@mantine/notifications";
import RemoteSelect2 from "../components/remoteSelect2";

import ServiceCheckList from "../components/serviceCheckList";
import FormConfirmation from "../components/formConfirmation";
import moment from "moment";
import { useTranslation } from "react-i18next";
import { DatePicker } from "@mantine/dates";

const OrderServiceCompleteButton = ({ mainForm }) => {
  const [api] = useServerApi();
  const { t } = useTranslation();
  const formSchema = {
    name: "ReceiveOrderServiceRequest",
    title: "Complete Order Service",
    description: "Please follow the step to cancel the service",

    display: {
      mode: ENUM_FORM_DISPLAY.MODAL,
      size: "1000px", //xs, sm, xl  ....100%
    },

    apiEntity: "orderService",
    initialValues: {},

    validate: {},
    showSaveBar: false,

    layout: {
      containers: [
        {
          key: "t1",
          parent: null,
          type: ENUM_FORM_LAYOUT_CONTAINER.WIZARD,

          props: {
            variant: "default", //default | pills | outline
            defaultValue: "Service Info",
            orientation: "vertical",
            style: { minHeight: "400px" },
          },
          steps: [
            { key: "t1-1", label: "Service Info", icon: IconSettings },

            { key: "t1-7", label: "Confirmation", icon: IconSettings },
          ],
        },
        {
          key: "section-info",
          parent: "t1-1",
          type: ENUM_FORM_LAYOUT_CONTAINER.SECTION,
          props: {
            title: "Service Information",
            description: "Pleaes select the service type",
            withBorder: false,
            mt: 0,
            ml: 20,
          },
        },
        {
          key: "section-confirm",
          parent: "t1-7",
          type: ENUM_FORM_LAYOUT_CONTAINER.SECTION,
          props: {
            title: "Complete Confirmation",
            description: "Please confirm the creation of order",
            withBorder: false,
            mt: 0,
            ml: 20,
          },
        },
      ],

      fields: [
        {
          name: "serviceType",
          component: RemoteSelect2,
          parent: "section-info",
          props: {
            label: "Type",
            apiEntity: "orderServiceType",
            required: true,
            placeholder: "Search Type",
            valueField: "_id",
            labelField: "name",
            allowClear: true,
            labelRender: (data) => `${data.name} ${data.cname}`,
            multiple: false,
            searchFields: ["name", "code", "cname"],
            clearable: true,
            onDataChange: (value, form) => {
              //Set checkList
              form.setFieldValue(
                "list",
                value?.checkList?.map((c) => ({ item: c, checked: false }))
              );
              form.setFieldValue("serviceTypeSnapShot", value);
              return;
            },
          },
        },
        {
          name: "receieveDate",
          parent: "section-info",
          component: DatePicker,
          type: "datePicker",
          props: {
            label: "Receive Date",
            required: true,
            zIndex: 1000,
            placeholder: "Pick Date",
            allowFreeInput: true,
          },
        },

        {
          name: "submissionDate",
          parent: "section-info",
          component: DatePicker,
          type: "datePicker",
          props: {
            label: "Submission Date",
            required: true,
            zIndex: 1000,
            placeholder: "Pick Date",
            allowFreeInput: true,
          },
        },

        {
          name: "completeDate",
          parent: "section-info",
          component: DatePicker,
          type: "datePicker",
          props: {
            label: "Completion Date",
            required: true,
            zIndex: 1000,
            placeholder: "Pick Date",
            allowFreeInput: true,
          },
        },

        {
          name: "confirm",
          component: FormConfirmation,
          parent: "section-confirm",
          props: {
            successText: [
              "Please ONLY click the button when you confirm the order service is completed.",
            ],
          },
        },
      ],
    },
  };

  const onSubmit = async ({ values, formMode }) => {
    try {
      values.status = "COMPLETED";
      values.completedDate =
        values.completedDate ?? moment().format("YYYY-MM-DD");
      mainForm.updateAndSave(values);
      formAction.close();
    } catch (error) {}
  };

  const [renderForm, formAction, formStatus, formSetting] = useFormRender(
    formSchema,
    null,
    onSubmit
  );

  const handleActionClick = () => {
    const id = mainForm?.values._id;

    if (!id) return;
    formAction.open({ mode: "edit", id, data: mainForm.values });
  };

  return (
    <>
      {/* {mainForm?.values?.product?._id} */}
      <Button fullWidth onClick={handleActionClick} color="green">
        {t("Complete Service")}
      </Button>

      {renderForm()}
    </>
  );
};

export default OrderServiceCompleteButton;
