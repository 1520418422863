import React, { useState, useEffect } from "react";
import moment from "moment";
import { DatePicker } from "@mantine/dates";
import {
  IconTrash,
  IconCheck,
  IconCircleCheck,
  IconAlertOctagon,
  IconPencil,
  IconExclamationCircle,
} from "@tabler/icons";
import _ from "lodash";

import { ENUM_FORM_DISPLAY, ENUM_FORM_LAYOUT_CONTAINER } from "./pageList";
import LocalPageList from "./localPageList";
import FileList from "./fileList";
import ActionCellRender from "./actionCellRender";

import { useServerApi } from "../hooks/userServerApi";
import ReactJson from "react-json-view";
import DateOffPicker from "./dateOffPicker";
import { showNotification } from "@mantine/notifications";
import { useTranslation } from "react-i18next";
import { Group, Checkbox, Input } from "@mantine/core";

const ClientMajorInvestmentPortfolio = ({
  clientIDField = "_id",
  form: mainForm,
  label = "Major Investment Portfolio",
  disabled = false,
  ...props
}) => {
  const { t } = useTranslation();
  if (!mainForm.values.majorInvestmentPortfolio) {
    mainForm.values.majorInvestmentPortfolio = {};
  }

  return (
    <>
      <Input.Wrapper label={label}>
        <Group mt="xs">
          <Checkbox
            disabled={disabled}
            label={t("Real Estates")}
            {...mainForm.getInputProps("majorInvestmentPortfolio.realEstate", {
              type: "checkbox",
            })}
          />
          <Checkbox
            disabled={disabled}
            label={t("Stocks")}
            {...mainForm.getInputProps("majorInvestmentPortfolio.stock", {
              type: "checkbox",
            })}
          />
          <Checkbox
            disabled={disabled}
            label={t("Fund")}
            {...mainForm.getInputProps("majorInvestmentPortfolio.fund", {
              type: "checkbox",
            })}
          />
          <Checkbox
            disabled={disabled}
            label={t("Insurance")}
            {...mainForm.getInputProps("majorInvestmentPortfolio.insurance", {
              type: "checkbox",
            })}
          />

          <Checkbox
            disabled={disabled}
            label={t("Commodities")}
            {...mainForm.getInputProps("majorInvestmentPortfolio.commodities", {
              type: "checkbox",
            })}
          />
          <Checkbox
            disabled={disabled}
            label={t("Options")}
            {...mainForm.getInputProps("majorInvestmentPortfolio.options", {
              type: "checkbox",
            })}
          />
          <Checkbox
            disabled={disabled}
            label={t("Own Business")}
            {...mainForm.getInputProps("majorInvestmentPortfolio.business", {
              type: "checkbox",
            })}
          />
          <Checkbox
            disabled={disabled}
            label={t("Others")}
            {...mainForm.getInputProps("majorInvestmentPortfolio.other", {
              type: "checkbox",
            })}
          />
        </Group>
      </Input.Wrapper>
    </>
  );
};

export default ClientMajorInvestmentPortfolio;
