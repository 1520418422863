import React, { useState, useEffect } from "react";
import moment from "moment";
import { DatePicker } from "@mantine/dates";
import {
  IconTrash,
  IconCheck,
  IconCircleCheck,
  IconAlertOctagon,
  IconPencil,
  IconExclamationCircle,
} from "@tabler/icons";
import _ from "lodash";

import { ENUM_FORM_DISPLAY, ENUM_FORM_LAYOUT_CONTAINER } from "./pageList";
import LocalPageList from "./localPageList";
import FileList from "./fileList";
import ActionCellRender from "./actionCellRender";

import { useServerApi } from "../hooks/userServerApi";
import ReactJson from "react-json-view";
import DateOffPicker from "./dateOffPicker";
import { showNotification } from "@mantine/notifications";
import { useTranslation } from "react-i18next";
import { Group, Checkbox, Input } from "@mantine/core";

const ClientIncomeSource = ({
  clientIDField = "_id",
  form: mainForm,
  label = "Sources of Assets/Income",
  disabled = false,

  ...props
}) => {
  const { t } = useTranslation();
  if (!mainForm.values.incomeSources) {
    mainForm.values.incomeSources = {};
  }

  return (
    <>
      <Input.Wrapper label={label}>
        <Group mt="xs">
          <Checkbox
            label={t("Salary")}
            disabled={disabled}
            {...mainForm.getInputProps("incomeSources.salary", {
              type: "checkbox",
            })}
          />
          <Checkbox
            label={t("Sales/Commission")}
            disabled={disabled}
            {...mainForm.getInputProps("incomeSources.sales", {
              type: "checkbox",
            })}
          />
          <Checkbox
            label={t("Sales Revenue")}
            disabled={disabled}
            {...mainForm.getInputProps("incomeSources.salesRenue", {
              type: "checkbox",
            })}
          />
          <Checkbox
            label={t("Inheritance")}
            disabled={disabled}
            {...mainForm.getInputProps("incomeSources.inheritance", {
              type: "checkbox",
            })}
          />

          <Checkbox
            label={t("Gift")}
            disabled={disabled}
            {...mainForm.getInputProps("incomeSources.gift", {
              type: "checkbox",
            })}
          />
          <Checkbox
            label={t("Sold of Property")}
            disabled={disabled}
            {...mainForm.getInputProps("incomeSources.soldOfProperty", {
              type: "checkbox",
            })}
          />
          <Checkbox
            label={t("Investment")}
            disabled={disabled}
            {...mainForm.getInputProps("incomeSources.investment", {
              type: "checkbox",
            })}
          />
          <Checkbox
            label={t("Saving")}
            disabled={disabled}
            {...mainForm.getInputProps("incomeSources.saving", {
              type: "checkbox",
            })}
          />
          <Checkbox
            label={t("Others")}
            disabled={disabled}
            {...mainForm.getInputProps("incomeSources.other", {
              type: "checkbox",
            })}
          />
        </Group>
      </Input.Wrapper>
    </>
  );
};

export default ClientIncomeSource;
