import React, { useEffect, useState } from "react";
import { useServerApi } from "../hooks/userServerApi";
import { Input, Text, Group } from "@mantine/core";

const CreditNotePolicyDetailPreview = ({ form }) => {
  const [api] = useServerApi();
  const [order, setOrder] = useState(null);
  const policyNumber = form.values.policyNumber;
  const NO_ORDER_SELECTED_MESSAGE = "No order selected";
  const NO_ORDER_FOUND_MESSAGE = "No order found";

  const fetchDetail = async () => {
    try {
      if (!policyNumber) return;
      const result = await api.search({
        apiEntity: "order",
        searchQuery: {
          policyNumber,
        },
        sort: { createdAt: -1 },
      });

      if (result.docs && result.docs.length > 0) {
        setOrder(result.docs[0]);
        form.setFieldValue("order", result.docs[0]);
      } else {
        setOrder(null);
        form.setFieldValue("order", null);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    fetchDetail();
  }, [policyNumber]);

  return (
    <>
      {!order && (
        <Text size="xs" color="red">
          {NO_ORDER_SELECTED_MESSAGE}
        </Text>
      )}
      {order && (
        <>
          <Input.Wrapper label="Client" color="dimmed" mb="xs" size="xs">
            <Text size={"xs"}>{order?.client?.name}</Text>
          </Input.Wrapper>
          <Input.Wrapper label="Order" color="dimmed" mb="xs" size="xs">
            <Text size={"xs"}>{order?.code}</Text>
          </Input.Wrapper>

          <Input.Wrapper label="Product" color="dimmed" mb="xs" size="xs">
            <Text size={"xs"}>{order?.product?.name}</Text>
          </Input.Wrapper>
        </>
      )}
    </>
  );
};

export default CreditNotePolicyDetailPreview;
