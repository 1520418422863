import React, { useState, useEffect } from "react";
import { Page } from "../../layout/page";
import RemoteSelect2 from "../../components/remoteSelect2";
import { useFormRender } from "../../hooks/useFormRender";
import { useServerApi } from "../../hooks/userServerApi";
import {
  PageList,
  ENUM_FORM_DISPLAY,
  ENUM_FORM_LAYOUT_CONTAINER,
} from "../../components/pageList";
import {
  TextInput,
  Checkbox,
  Switch,
  Select,
  Textarea,
  Group,
  Indicator,
  Table,
  Text,
  Box,
  Grid,
  Tabs,
  ScrollArea,
  FileButton,
} from "@mantine/core";
import { IconTrash, IconPencil, IconSettings } from "@tabler/icons";
import ActionCellRender from "../../components/actionCellRender";
import { DatePicker, TimeInput } from "@mantine/dates";
import { DATA_CALENDAR_EVENT_TYPE } from "../../data/options";
import TimeSelect from "../../components/timeSelect";
import moment from "moment";
import ImagePicker from "../../components/imagePicker";
import { getEventColor } from "../../components/eventSection";
import { useCellRender } from "../../hooks/useCellRender";

import FileList from "../../components/fileList";
import remoteSelect2 from "../../components/remoteSelect2";

const apiEntity = "clientPromotion";
const initialValues = {
  type: "companyEvent",
  submitDateRange: {
    start: "",
    end: "",
  },
  files: [],
  url: "",
  bgImage: "",
  inForceBefore: "",
};

const formSchema = {
  title: "Client Promotion",
  apiEntity,
  initialValues,
  closeAfterSave: true,

  display: {
    mode: ENUM_FORM_DISPLAY.MODAL,

    size: "xl", //xs, sm, xl  ....100%
    position: "right",
  },
  layout: {
    containers: [
      {
        key: "main-tab",
        type: ENUM_FORM_LAYOUT_CONTAINER.TABS,
        parent: null,

        props: {
          variant: "default", //default | pills | outline
          defaultValue: "Promotion Detail",
        },
        tab: [
          {
            key: "tab-detail",
            label: "Promotion Detail",
            icon: IconSettings,
          },
          {
            key: "tab-files",
            label: "Files",
            icon: IconSettings,
          },
        ],
      },
    ],
    fields: [
      {
        name: "provider",
        component: RemoteSelect2,
        parent: "tab-detail",

        props: {
          name: "provider",
          apiEntity: "provider",
          required: true,
          label: "Provider",
          placeholder: "AIA",
          valueField: "_id",
          labelField: "name",
          // labelRender: (data) => data.name,
          searchFields: ["name", "code"],
          sort: {
            by: "name",
            order: "desc",
          },
        },
      },
      {
        name: "title",
        component: TextInput,
        parent: "tab-detail",

        props: {
          required: true,
          label: "Program Title",
          placeholder: "Incentive Program Title",
        },
      },

      {
        name: "url",
        component: TextInput,
        parent: "tab-detail",

        props: {
          required: true,
          label: "Website URL",
          placeholder: "http://www.axa.com/...",
        },
      },

      {
        name: "visible",
        component: Switch,
        parent: "tab-detail",
        props: {
          label: "Visible",
          type: "checkbox",
          mb: 30,
        },
      },

      {
        name: "bgImage",
        component: ImagePicker,
        parent: "tab-detail",

        props: {
          label: "Background Image",
          updateAndSave: false,
          w: "100%",
          h: "100%",
          mih: "200px",
        },
      },
      {
        name: "files",
        component: FileList,
        parent: "tab-files",

        props: {
          title: "Files",
          columnSpan: 12,
        },
      },

      {
        name: "submitDateRange.start",
        component: DatePicker,
        parent: "tab-detail",

        type: "datePicker",

        props: {
          required: true,
          label: "Submit Date (Start)",
          placeholder: "Date",
          zIndex: 1000,
          allowFreeInput: true,
        },
      },
      {
        name: "submitDateRange.end",
        component: DatePicker,
        type: "datePicker",
        parent: "tab-detail",

        props: {
          required: true,
          label: "Submit Date (End)",
          placeholder: "Date",
          zIndex: 1000,
          allowFreeInput: true,
        },
      },

      {
        name: "inForceBefore",
        component: DatePicker,
        type: "datePicker",
        parent: "tab-detail",

        props: {
          required: true,
          label: "Inforced Date",
          placeholder: "Date",
          zIndex: 1000,
          allowFreeInput: true,
        },
      },

      {
        name: "description",
        component: Textarea,
        parent: "tab-detail",

        props: {
          required: true,
          label: "Description",
          placeholder: "",
        },
      },
    ],
  },
};
const ConsultantIncentive = () => {
  const [cellRender] = useCellRender();
  const tableSchema = {
    showActionColumn: true,

    columns: [
      {
        field: "provider",
        sortable: true,
        cellRender: (col, data) => cellRender.ProviderInfo(data.provider),
      },
      {
        field: "title",
        sortable: true,
      },
      {
        field: "submitDateRange",
        sortable: false,
        headerName: "Submit Date Range",
        cellRender: (col, data) =>
          `${moment(data.submitDateRange?.start).format("LL")} ~ ${moment(
            data.submitDateRange?.end
          ).format("LL")}`,
      },
      {
        field: "url",
        headerName: "Detail URL",
        cellRender: (col, data) => (
          <a href={data.url} target="_blank" style={{ textDecoration: "none" }}>
            <Text color={"dimmed"} lineClamp={1}>
              {data.url}
            </Text>
          </a>
        ),
      },
      {
        field: "date",
        sortable: true,
        headerName: "Created",
        cellRender: (col, data) => moment(data.createdAt).format("LL"),
      },

      {
        field: "action",
        cellRender: ActionCellRender,
        isAction: true,
        actions: [
          {
            name: "edit",
            icon: IconPencil,
            props: {
              //   color:"red"
            },
          },
        ],
        actionMenu: [
          {
            name: "delete",
            label: "Delete data",
            icon: IconTrash,
            props: {
              color: "red",
            },
          },
        ],
      },
    ],
    searchableFields: ["title", "description"],
  };
  return (
    <>
      {
        <PageList
          title={"Client Promotion"}
          apiEntity={apiEntity}
          tableSchema={tableSchema}
          formSchema={formSchema}
        />
      }
    </>
  );
};

export default ConsultantIncentive;
