import React, { useState } from "react";
import { Button, Textarea } from "@mantine/core";
import { useServerApi } from "../hooks/userServerApi";
import { ENUM_FORM_DISPLAY } from "./pageList";
import { useFormRender } from "../hooks/useFormRender";
import MessageFiller from "./messageFiller";
import _ from "lodash";

const PrintCancelNoticeButton = ({ mainForm }) => {
  const [api] = useServerApi();
  const [printing, setPrinting] = useState(false);

  const formSchema = {
    title: "Print Cancel Notice",
    description: "Please fill in the details",
    display: {
      mode: ENUM_FORM_DISPLAY.MODAL,
      size: "xl",
    },
    layout: {
      containers: [],
      fields: [
        {
          name: "content",
          component: MessageFiller,
          props: {
            templateCategory: "RenewalCancelNotice",
            label: "Message To Client",
            placeholder: "Enter content",
            required: true,
            minRows: 10,
          },
        },
      ],
    },
  };

  const onSubmit = async ({ values }) => {
    try {
      const rejection = mainForm.values.rejection;
      if (!rejection) mainForm.values.rejection = {};
      mainForm.values.rejection.messageToClient = values.content;

      await mainForm.save();

      setPrinting(true);
      const result = await api.Renewal.printCancelNotice(mainForm.values._id);
      setPrinting(false);

      formAction.close();

      if (!result || !result.data || !result.data.url) return;
      window.open(result.data.url);
    } catch (error) {
      console.log(error);
    }
  };

  const [renderForm, formAction, formStatus, formSetting] = useFormRender(
    formSchema,
    null,
    onSubmit
  );

  const handleActionClick = () => {
    const id = mainForm?.values._id;
    if (!id) return;
    formAction.open({
      mode: "edit",
      id,
      data: {
        content: mainForm.values.rejection?.messageToClient,
      },
    });
  };

  return (
    <>
      <Button fullWidth onClick={handleActionClick} loading={printing}>
        {printing ? "Printing" : "Print Cancel Notice"}
      </Button>

      {renderForm()}
    </>
  );
};

export default PrintCancelNoticeButton;
