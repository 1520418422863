import { PageList } from "../../components/pageList.js";
import React from "react";

import ActionCellRender from "../../components/actionCellRender.js";
import { IconPencil, IconTrash, IconSettings } from "@tabler/icons";
import {
  ENUM_FORM_DISPLAY,
  ENUM_FORM_LAYOUT_CONTAINER,
} from "../../components/pageList.js";
import { useCellRender } from "../../hooks/useCellRender.js";
import FileList from "../../components/fileList.js";
import ActivityLog from "../../components/activityLog.js";
import RemoteSelect2 from "../../components/remoteSelect2.js";
import _ from "lodash";

import {
  Select,
  Checkbox,
  TextInput,
  Text,
  Switch,
  Textarea,
} from "@mantine/core";
import { DatePicker } from "@mantine/dates";
import moment from "moment";
import AutoCodeInput from "../../components/autoCodeInput.js";
import FormConfirmation from "../../components/formConfirmation.js";
import GIMatchingTable from "../../components/giMatchingTable";
import GISettlementStats from "../../components/giSettlmentStats";
import GISettlementTable from "../../components/giSettlementTable";
import { DATA_PAYMENT_METHOD } from "../../data/options";

const apiEntity = "giSettlement";

const initialValues = {
  consultants: [],
  date: moment().toISOString(),

  status: "PENDING",
};

export const addFormSchema = {
  name: "giSettlement",
  title: "New GI Settlement",
  description: "Please follow the step to create a New GI Settlement",
  display: {
    mode: ENUM_FORM_DISPLAY.MODAL,
  },
  apiEntity,
  showSaveBar: false,

  initialValues,
  validate: {},

  layout: {
    containers: [
      {
        key: "t1",
        parent: null,
        type: ENUM_FORM_LAYOUT_CONTAINER.WIZARD,
        props: {
          variant: "default", //default | pills | outline
          defaultValue: "Basic Info",
          orientation: "vertical",
          style: { minHeight: "400px" },
        },
        steps: [
          {
            key: "t1-1",
            label: "Basic Info",
            icon: IconSettings,
          },
          // { key: "t1-2", label: "Import Data", icon: IconSettings },

          { key: "t1-3", label: "Confirm", icon: IconSettings },
        ],
      },
      {
        key: "section-info",
        parent: "t1-1",
        type: ENUM_FORM_LAYOUT_CONTAINER.SECTION,
        props: {
          // title: "Payroll Info",
          description: "Please fill in the basic information of the payroll",
          withBorder: false,
          withPaper: false,
          ml: 0,
          mt: 0,
          p: 0,
        },
      },

      {
        key: "section-confirm",
        parent: "t1-3",
        type: ENUM_FORM_LAYOUT_CONTAINER.SECTION,
        props: {
          description: "Please confirm the statement details",
          withBorder: false,
          withPaper: false,
          ml: 0,
          mt: 0,
          p: 0,
        },
      },
    ],

    fields: [
      {
        name: "code",
        component: AutoCodeInput,
        parent: "section-info",
        props: {
          required: true,
          placeholder: "PR-202212",
          label: "Patch ID",
        },
      },

      {
        name: "settleDate",
        component: DatePicker,
        parent: "section-info",
        type: "datePicker",
        props: {
          label: "Settlement Date",
          required: true,
          defaultValue: moment().toDate(),
        },
      },

      {
        name: "provider",
        component: RemoteSelect2,
        parent: "section-info",
        props: {
          name: "provider",
          apiEntity: "provider",
          // required: true,
          label: "Provider (Empty for all)",
          placeholder: "AIA",
          valueField: "_id",
          labelField: "name",
          labelRender: (data) => `[${data.code}] ${data.name}`,
          searchFields: ["name", "code"],
          pageSize: 10000,
          sort: {
            by: "code",
            order: "asc",
          },
        },
      },

      {
        name: "remark",
        component: Textarea,
        parent: "section-info",
        props: {
          placeholder: "",
          label: "Remark",
        },
      },

      {
        name: "confirm",
        component: FormConfirmation,
        parent: "section-confirm",
        props: {
          successText: ["Please confirm if to create the new payroll."],
        },
      },

      // {
      //     name: "rows",
      //     component: PayrollImporter,
      //     parent: "section-import",
      //     props: {},
      // },
    ],
  },
};

const GISettlement = ({}) => {
  const [cellRender] = useCellRender();

  const tableSchema = {
    showActionColumn: true,
    columns: [
      {
        field: "code",
        headerName: "Code",
        sortable: true,
        cellRender: (col, data) => cellRender.DebitNoteCode(data),
      },
      {
        field: "createdAt",
        headerName: "Date",
        sortable: true,
        cellRender: (col, data) => moment(data.createdAt).format("LL"),
      },
      {
        field: "numOfSettlement",
        headerName: "Number of Settlements",
        sortable: true,
      },

      {
        field: "status",
        headerName: "Status",
        sortable: true,
        cellRender: (col, data) => (
          <>{data.status ? cellRender.Status(data.status) : ""}</>
        ),
      },
      {
        field: "action",
        cellRender: ActionCellRender,
        isAction: true,
        actions: [
          {
            name: "edit",
            icon: IconPencil,
            props: {
              //   color:"red"
            },
          },
        ],
      },
    ],
  };

  const editFormSchema = {
    title: "GI Settlement",
    titleField: "code",
    display: {
      mode: ENUM_FORM_DISPLAY.LINK,
      size: "xl", //xs, sm, xl  ....100%
      // position:"right"
    },
    apiEntity,
    initialValues,
    layout: {
      containers: [
        {
          key: "main-tab",
          parent: null,
          type: ENUM_FORM_LAYOUT_CONTAINER.TABS,
          props: {
            variant: "default", //default | pills | outline
            defaultValue: "Settlements",
          },
          tab: [
            {
              key: "tab1",
              label: "Settlements",
              icon: IconSettings,
            },

            {
              key: "tab2",
              label: "Files",
              icon: IconSettings,
            },

            {
              key: "tab3",
              label: "Logs",
              icon: IconSettings,
            },
          ],
        },

        {
          key: "section-files",
          parent: "tab2",
          type: ENUM_FORM_LAYOUT_CONTAINER.SECTION,
          props: {
            title: "Files",
            titleOrder: 5,
            // description: "Please assign the corresponding consultant",
            withBorder: false,
            minHeight: "0px",
            mt: 20,
            mb: 20,
          },
        },

        {
          key: "section-stats",
          parent: "tab1",
          type: ENUM_FORM_LAYOUT_CONTAINER.BOX,
          props: {
            mt: 50,
            mb: 50,
          },
        },

        {
          key: "basicInfo-g",
          parent: "tab1",
          type: ENUM_FORM_LAYOUT_CONTAINER.GRID,
          cols: [
            {
              key: "basicInfo-g1",
              props: {
                span: 5,
              },
            },
            {
              key: "basicInfo-g2",
              props: {
                span: 3,
              },
            },
            {
              key: "basicInfo-g3",
              props: {
                span: 3,
              },
            },
          ],
        },

        {
          key: "section-table",
          parent: "tab1",
          type: ENUM_FORM_LAYOUT_CONTAINER.BOX,
          props: {
            mt: 50,
          },
        },

        {
          key: "section-log",
          parent: "tab3",
          type: ENUM_FORM_LAYOUT_CONTAINER.SECTION,
          props: {
            title: "Activity Log",
            titleOrder: 5,
            // description: "Please assign the corresponding consultant",
            withBorder: false,
            minHeight: 0,

            mt: 20,
            mb: 20,
          },
        },
      ],

      fields: [
        {
          name: "stats",
          parent: "section-stats",
          component: GISettlementStats,
          props: {
            cols: 3,
          },
        },

        {
          name: "settleDate",
          component: DatePicker,
          parent: "basicInfo-g2",
          type: "datePicker",

          props: {
            label: "Settlement Date",
            required: true,
            defaultValue: moment().toDate(),
          },
        },

        {
          name: "provider",
          component: RemoteSelect2,
          parent: "basicInfo-g1",
          props: {
            name: "provider",
            apiEntity: "provider",
            // required: true,
            label: "Provider (Empty for all)",
            placeholder: "AIA",
            valueField: "_id",
            labelField: "name",
            labelRender: (data) => `[${data.code}] ${data.name}`,
            searchFields: ["name", "code"],
            pageSize: 10000,
            sort: {
              by: "code",
              order: "asc",
            },
          },
        },

        {
          name: "remark",
          component: Textarea,
          parent: "basicInfo-g1",
          props: {
            placeholder: "",
            label: "Remark",
          },
        },
        {
          name: "paymentMethod",
          component: Select,
          parent: "basicInfo-g3",
          props: {
            label: "Payment Method",
            defaultValue: "Bank Transfer",
            data: DATA_PAYMENT_METHOD,
          },
        },

        {
          name: "table",
          parent: "section-table",
          component: GISettlementTable,
          props: {},
        },

        {
          name: "files",
          component: FileList,
          parent: "section-files",
          props: {},
        },

        {
          name: "logs",
          component: ActivityLog,
          parent: "section-log",
          props: {},
        },
      ],
    },
  };

  const formSchema = {
    add: addFormSchema,
    edit: editFormSchema,
  };

  return (
    <PageList
      title={"GI Settlements"}
      apiEntity={apiEntity}
      tableSchema={tableSchema}
      formSchema={formSchema}
      searchByTextField={true}
    />
  );
};

export default GISettlement;
