import React, { useState, useEffect } from "react";
import _ from "lodash";
import { showNotification } from "@mantine/notifications";

import {
  Button,
  Table,
  Group,
  Text,
  Chip,
  Space,
  Title,
  Grid,
  Card,
  Divider,
  Tabs,
  Badge,
  LoadingOverlay,
  Menu,
  Switch,
  Box,
  Stack,
  Center,
  ActionIcon,
} from "@mantine/core";

import { useCellRender } from "../hooks/useCellRender";

import { IconFile, IconRefresh, IconTrash } from "@tabler/icons";
import { Accordion, useMantineTheme } from "@mantine/core";
import { useFormatter } from "../hooks/useFomatter";
import { useServerApi } from "../hooks/userServerApi";
import { useForm } from "@mantine/form";
import useDeepCompareEffect from "../hooks/useDeepCompareEffect";
import ReactJson from "react-json-view";
import moment from "moment";
import RemoteSelect2 from "./remoteSelect2";
import { useForceUpdate } from "@mantine/hooks";

const RowHeader = ({
  settlement,
  status,
  mainStatus,
  onRemoveSettlement,
  onConfirmSettlement,
  onUnConfirmSettlement,
}) => {
  const [cellRender] = useCellRender();
  const showUnConfirm = mainStatus !== "COMPLETED";
  return (
    <>
      {settlement && (
        <Grid justify="space-between">
          <Grid.Col span={5}>
            <>
              {settlement && (
                <div>{cellRender.ProductInfo(settlement.provider)}</div>
              )}
            </>
          </Grid.Col>
          <Grid.Col span={3}>
            <>
              {settlement.debitNotes && (
                <>
                  <Title order={3}>{settlement.debitNotes.length}</Title>
                  <Text size={"xs"} color="dimmed">
                    Total DebitNotes
                  </Text>
                </>
              )}
            </>
          </Grid.Col>
          <Grid.Col span={showUnConfirm ? 3 : 4}>
            <div>
              <Title order={3}>
                {cellRender.Currency(settlement.totalAmount)}
              </Title>

              <Text size={"xs"} color="dimmed">
                Total Settle Amount
              </Text>
            </div>
          </Grid.Col>
          <Grid.Col span={showUnConfirm ? 1 : 0}>
            {onUnConfirmSettlement && showUnConfirm && (
              <Button
                onClick={() => onUnConfirmSettlement(settlement.provider?._id)}
                size="xs"
                variant="default"
                mb={"xs"}
              >
                UnConfirm
              </Button>
            )}

            {onConfirmSettlement && (
              <Button
                onClick={() => onConfirmSettlement(settlement.provider?._id)}
                size="xs"
                variant="default"
                mb={"xs"}
              >
                Confirm
              </Button>
            )}
            {onRemoveSettlement && (
              <Button
                onClick={() => onRemoveSettlement(settlement.provider?._id)}
                size="xs"
                variant="default"
                mb={"xs"}
                c="red"
              >
                Remove
              </Button>
            )}
          </Grid.Col>
        </Grid>
      )}
    </>
  );
};

const RowContent = ({ settlement, onRemoveDebitNote }) => {
  const { debitNotes } = settlement;
  const [cellRender] = useCellRender();
  const [api] = useServerApi();

  return (
    <>
      <Divider
        mt={"xl"}
        mb={"xl"}
        my="xs"
        label="Debit Notes"
        labelPosition="center"
        variant="dashed"
      />
      <Table fontSize={"sm"} striped highlightOnHover>
        <thead>
          <tr>
            <th>#</th>
            <th>DN. </th>
            <th>Date</th>
            <th>Policy No.</th>
            <th>Product</th>
            <th>Client Payable</th>
            <th>Client Payment</th>
            <th>Net Preimum</th>

            <th></th>
          </tr>
        </thead>
        <tbody>
          {debitNotes &&
            debitNotes.map((n, index) => (
              <tr key={index}>
                <td>{index + 1}</td>
                <td>{n.code}</td>
                <td>{moment(n.date).format("LL")}</td>
                <td>{n.policyNumber}</td>
                <td>
                  <Text lineClamp={1}>{n.product.name}</Text>
                </td>
                <td>{cellRender.Currency(n.clientPayable)}</td>
                <td>{cellRender.Currency(n.payment?.amount)}</td>
                <td>{cellRender.Currency(n.costTotal)}</td>
                <td>
                  {onRemoveDebitNote && (
                    <ActionIcon
                      variant="filled"
                      onClick={() => onRemoveDebitNote(n)}
                    >
                      <IconTrash size={13}></IconTrash>
                    </ActionIcon>
                  )}
                </td>
                {/* <td>{cellRender.Currency(b.paidAmount, b.paidCurrency)}</td> */}
              </tr>
            ))}
        </tbody>
      </Table>
    </>
  );
};

export const SettlementRow = ({
  settlement,
  index,
  status,
  mainStatus,
  onRemoveSettlement,
  onConfirmSettlement,
  onUnConfirmSettlement,
  updateSettlementData,
}) => {
  const forceUpdate = useForceUpdate();

  const handleRemoveDN = async (removeDN) => {
    const ans = window.confirm(`Remove debitNote ${removeDN.code}?`);
    if (!ans) return;
    settlement.debitNotes = settlement.debitNotes.filter(
      (dn) => dn._id !== removeDN._id
    );
    updateSettlementData();
    forceUpdate();
  };

  return (
    <>
      {settlement && (
        <Accordion.Item value={settlement.provider._id}>
          <Accordion.Control>
            <RowHeader
              settlement={settlement}
              onRemoveSettlement={onRemoveSettlement}
              onConfirmSettlement={onConfirmSettlement}
              onUnConfirmSettlement={onUnConfirmSettlement}
              status={status}
              mainStatus={mainStatus}
            />
          </Accordion.Control>
          <Accordion.Panel>
            <RowContent
              settlement={settlement}
              onRemoveDebitNote={status === "CONFIRM" ? null : handleRemoveDN}
            />
          </Accordion.Panel>
        </Accordion.Item>
      )}
    </>
  );
};

const SettlementList = ({
  settlements,
  status,
  form,
  handleConfirmSettlement,
  handleRemoveSettlement,
  handleUnConfirmSettlement,
  updateSettlementData,
}) => {
  const forceUpdate = useForceUpdate();

  return (
    <>
      {settlements && (
        <>
          {settlements.length === 0 && (
            <Card>
              <Title order={6}>{`There are no settlements`}</Title>
            </Card>
          )}

          <Accordion variant="separated">
            {settlements.map((s, index) => (
              <>
                <SettlementRow
                  settlement={s}
                  status={status}
                  mainStatus={_.get(form?.values, "status")}
                  index={index}
                  onRemoveSettlement={
                    status === "PENDING"
                      ? () => handleRemoveSettlement(s)
                      : null
                  }
                  onConfirmSettlement={
                    status === "PENDING"
                      ? () => handleConfirmSettlement(s)
                      : null
                  }
                  onUnConfirmSettlement={
                    status === "CONFIRM"
                      ? () => handleUnConfirmSettlement(s)
                      : null
                  }
                  updateSettlementData={updateSettlementData}
                />
              </>
            ))}
          </Accordion>
        </>
      )}
    </>
  );
};

const MainTab = ({ settlements, form, updateSettlementData }) => {
  const forceUpdate = useForceUpdate();

  const confirm = settlements?.filter((m) => m.status === "CONFIRM");
  const pending = settlements?.filter((m) => m.status === "PENDING");

  const handleConfirmSettlement = (settlement) => {
    if (!settlement) return;
    settlement.status = "CONFIRM";
    updateSettlementData(settlements);

    forceUpdate();
  };

  const handleUnConfirmSettlement = (settlement) => {
    if (!settlement) return;
    settlement.status = "PENDING";
    updateSettlementData(settlements);
    forceUpdate();
  };

  const handleRemoveSettlement = (settlement) => {
    // console.log("handleRemoveSettlement", settlement);
    if (!settlement) return;
    const newSettlement = settlements.filter(
      (m) => m.provider._id !== settlement.provider._id
    );

    form.setFieldValue("settlements", newSettlement);
    updateSettlementData(newSettlement);
    forceUpdate();
  };

  return (
    <Tabs defaultValue="pending">
      <Tabs.List>
        <Tabs.Tab value="pending">{`Pending (${pending?.length})`}</Tabs.Tab>
        <Tabs.Tab value="confirm">{`Confirm (${confirm?.length})`}</Tabs.Tab>
      </Tabs.List>

      <Tabs.Panel value="pending" pt="xl">
        <SettlementList
          settlements={pending}
          status="PENDING"
          form={form}
          handleConfirmSettlement={handleConfirmSettlement}
          handleRemoveSettlement={handleRemoveSettlement}
          updateSettlementData={updateSettlementData}

          // onChanged={onMatchingChange}
          // status={status}
        />
      </Tabs.Panel>
      <Tabs.Panel value="confirm" pt="xl">
        <SettlementList
          settlements={confirm}
          status="CONFIRM"
          form={form}
          handleUnConfirmSettlement={handleUnConfirmSettlement}
          updateSettlementData={updateSettlementData}
          // onChanged={onMatchingChange}
          // status={status}
        />
      </Tabs.Panel>
    </Tabs>
  );
};
const GISettlementTable = ({ form, name }) => {
  const { settlements, _id, status } = form.values;
  const [cellRender] = useCellRender();
  const [formatter] = useFormatter();
  const [exporting, setExporting] = useState(false);
  const [isLoading, setLoading] = useState(false);
  const [exportingDetail, setExportingDetail] = useState(false);
  const [exportingOrderDetail, setExportingOrderDetail] = useState(false);
  const [exportingBankFile, setExportingBankFile] = useState(false);
  const forceUpdate = useForceUpdate();

  const [api] = useServerApi();

  const handleConfirm = async () => {
    const _id = _.get(form.values, "_id");
    if (!_id || exporting) return;

    try {
      await form.save();

      const ans = window.confirm(
        "Are you sure to confirm the settlements? Once confirmed, the Debitnotes will be marked as SETTLED. And bids will be released."
      );
      if (!ans) return;
      setExporting(true);
      await form.save();

      let result = await api.GISettlement.confirm(_id);
      // console.log(result);
      setExporting(false);

      if (result.error) throw result.error;

      form.setFieldValue("status", "COMPLETED");
      setExporting(false);
    } catch (error) {
      showNotification({
        title: "Confirm Fail",
        message: error,
        color: "red",
      });

      setExporting(false);
    }
  };

  const handleExportDetail = async () => {
    if (!_id || exporting) return;
    try {
      setExporting(true);
      setExportingDetail(true);
      let result = await api.Payroll.exportDetail(_id);
      setExporting(false);
      setExportingDetail(false);

      // console.log(result);
      if (result.url) return window.open(result.url, "_blank");
      if (result.error) throw result.error;
    } catch (error) {
      console.log(error);
      setExporting(false);
      setExportingDetail(false);
    }
  };

  const unsetLoading = () => {
    setLoading(false);
    setExporting(false);
  };

  const updateSettlementData = (settlements) => {
    const cofirmedSettlement = settlements.filter(
      (m) => m.status === "CONFIRM"
    );
    const pendingSettlement = settlements.filter((m) => m.status === "PENDING");

    const numOfSettlement = settlements.length;
    const pendingAmount = pendingSettlement.reduce(
      (acc, m) => acc + m.totalAmount,
      0
    );
    const confirmedAmount = cofirmedSettlement.reduce(
      (acc, m) => acc + m.totalAmount,
      0
    );
    const totalAmount = pendingAmount + confirmedAmount;

    form.setFieldValue("numOfSettlement", numOfSettlement);
    form.setFieldValue("pendingAmount", pendingAmount);
    form.setFieldValue("confirmedAmount", confirmedAmount);
    form.setFieldValue("totalAmount", totalAmount);
  };

  const handleGetPendingSettlements = async () => {
    if (!_id || exporting) return;
    try {
      setExporting(true);
      setLoading(true);
      const provider = _.get(form?.values, "provider");
      const settlements = await api.GISettlement.getPending(_id, provider);

      if (_.isEmpty(settlements)) {
        showNotification({
          title: "No pending settlement found",
          message: "No pending settlement found",
          color: "green",
        });

        return unsetLoading();
      }

      if (_.isEmpty(settlements)) return unsetLoading();
      // console.log("settlements", settlements);

      form.setFieldValue("settlements", settlements);
      updateSettlementData(settlements);
      forceUpdate();

      //Update data
      return unsetLoading();
    } catch (error) {
      //   console.log(error);
      setExporting(false);
      setLoading(false);
    }
  };

  const isGetPendingDisable = () => status === "COMPLETED";

  return (
    <>
      {/* <ReactJson src={form.values} style={{ background: "white" }} collapsed /> */}
      <Group position="apart" mt="xl">
        <span></span>
        <Group position="right" mb={"xl"}>
          <Button
            disabled={isGetPendingDisable() || exporting}
            loading={isLoading}
            onClick={() => handleGetPendingSettlements()}
            leftIcon={<IconRefresh size={14} />}
          >
            Get Pending Settlements
          </Button>
          {/* <Button
            // color={"green"}
            disabled={exporting}
            loading={exportingDetail}
            onClick={() => handleExportDetail()}
            leftIcon={<IconFile size={14} />}
          >
            Print
          </Button> */}

          <Button
            color={"red"}
            disabled={
              exporting || status === "COMPLETED" || settlements?.length === 0
            }
            loading={exportingBankFile}
            onClick={() => handleConfirm()}
            leftIcon={<IconFile size={14} />}
          >
            Confirm
          </Button>
        </Group>
      </Group>
      <MainTab
        settlements={settlements}
        form={form}
        updateSettlementData={updateSettlementData}
      />
    </>
  );
};

export default GISettlementTable;
