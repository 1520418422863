import {
  Group,
  Paper,
  Space,
  Text,
  SimpleGrid,
  UnstyledButton,
  Accordion,
  Center,
} from "@mantine/core";
import React, { useEffect, useState } from "react";
import { useServerApi } from "../hooks/userServerApi";
import _ from "lodash";

import {
  IconUsers,
  IconUser,
  IconCircleCheck,
  IconNotes,
  IconTableImport,
  IconCirclePlus,
  IconUserCircle,
  IconBuildingBank,
  IconCircleMinus,
  IconActivity,
  IconCircleX,
  IconHeart,
  IconReceipt2,
  IconAlertCircle,
  IconSquareDot,
  IconInfoCircle,
} from "@tabler/icons";

import CardStat from "./cardStat";
import { useFormatter } from "../hooks/useFomatter";
import ReactJson from "react-json-view";

const GISettlementStats = ({ form, name, cols = 5, ...props }) => {
  const [formatter] = useFormatter();

  const {
    code,
    totalAmount,
    numOfSettlement,
    status,
    matchings,
    pendingAmount,
    confirmedAmount,
    settlements,
  } = form.values;

  const confirm = settlements?.filter((m) => m.status === "CONFIRM") ?? 0;

  const pending = settlements?.filter((m) => m.status === "PENDING") ?? 0;

  const stats = [
    {
      icon: IconNotes,
      color: "#00b341",
      label: "Batch No.",
      msg: code,
    },
    {
      icon: IconReceipt2,
      color: "#8278F2",
      label: "Pending",
      msg: pending.length,
    },
    {
      icon: IconReceipt2,
      color: "#8278F2",
      label: "Pending  Amount",
      msg: formatter.currency(pendingAmount),
    },

    {
      icon: IconInfoCircle,
      color: "#00abfb",
      label: "Status",
      msg: status,
    },
    {
      icon: IconReceipt2,
      color: "#8278F2",
      label: "Confirm",
      msg: confirm.length,
    },
    {
      icon: IconReceipt2,
      color: "#8278F2",
      label: "Confirm  Amount",
      msg: formatter.currency(confirmedAmount),
    },
  ];

  return (
    <>
      {/* <ReactJson src={form.values} style={{ background: "white" }} /> */}
      <SimpleGrid
        cols={cols}
        breakpoints={[
          { maxWidth: "sm", cols: 1 },
          { maxWidth: "md", cols: 2 },
        ]}
        {...props}
      >
        {stats?.map((stat, index) => (
          <CardStat key={index} stat={stat} />
        ))}
      </SimpleGrid>
    </>
  );
};

export default GISettlementStats;
