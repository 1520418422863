import React from "react";
import { ENUM_FORM_DISPLAY, ENUM_FORM_LAYOUT_CONTAINER } from "./pageList";
import { useFormRender } from "../hooks/useFormRender";
import { Button } from "@mantine/core";
import { IconSettings } from "@tabler/icons";

import OrderReceiveCheckList from "./orderReceiveCheckList";
import OrderReceiveConfirm from "./orderReceiveConfirm";
import { useServerApi } from "../hooks/userServerApi";
import { showNotification } from "@mantine/notifications";

const OrderReceiveActionButton = ({ mainForm, label = "Receive Order" }) => {
  const [api] = useServerApi();
  const formSchema = {
    title: "Receive Order",
    description: "Please follow the step to complete the order receive process",

    display: {
      mode: ENUM_FORM_DISPLAY.MODAL,
      size: "xl", //xs, sm, xl  ....100%
    },
    showSaveBar: false,
    closeAfterSave: true,

    layout: {
      containers: [
        {
          key: "t1",
          parent: null,
          type: ENUM_FORM_LAYOUT_CONTAINER.WIZARD,
          props: {
            variant: "default", //default | pills | outline
            defaultValue: "Checklist",
            orientation: "vertical",
            style: { minHeight: "400px" },
          },
          steps: [
            { key: "t1-1", label: "Checklist", icon: IconSettings },
            { key: "t1-2", label: "Receive", icon: IconSettings },
          ],
        },
        {
          key: "section-checklist",
          parent: "t1-1",
          type: ENUM_FORM_LAYOUT_CONTAINER.SECTION,
          props: {
            title: "Document CheckList",
            description:
              "Pleaes make sure the documents in the checklist are all ready",
            withBorder: false,
            mt: 0,
            ml: 20,
          },
        },

        {
          key: "section-confirm",
          parent: "t1-2",
          type: ENUM_FORM_LAYOUT_CONTAINER.SECTION,
          props: {
            title: "Confirm Receive",
            description: "Please confirm all the required document received.",
            withBorder: false,
            mt: 0,
            ml: 20,
          },
        },
      ],

      fields: [
        {
          name: "checkList",
          component: OrderReceiveCheckList,
          parent: "section-checklist",
          props: {},
        },
        {
          name: "confrim",
          component: OrderReceiveConfirm,
          parent: "section-confirm",
          props: {
            successText: ["Please confirm to receive the order."],
          },
        },
      ],
    },
  };

  const onSubmit = async ({ values, formMode }) => {
    // mainForm.updateAndSave({ status: "RCV" });
    // formAction.close();
    try {
      const { receiveCheckList, receivedDate } = values;
      console.log("On Submit Receive Order", receiveCheckList);

      const result = await api.Order.receiveOrder(
        values._id,
        receiveCheckList,
        receivedDate
      );

      // console.log(result);
      if (!result.order) return;
      await mainForm.reload();
      showNotification({
        title: `${values?.code} Received Successfully`,
        message: `Order received.`,
      });
      formAction.close();

      return;

      mainForm.setFieldValue("status", result.order.status);
      mainForm.setFieldValue("receiveCheckList", result.order.receiveCheckList);
      mainForm.setFieldValue("riders", result.order.riders);
      formAction.close();
      showNotification({
        title: `${values?.code} Received Successfully`,
        message: `Order received.`,
      });
    } catch (error) {}
  };

  const [renderForm, formAction, formStatus, formSetting] = useFormRender(
    formSchema,
    null,
    onSubmit
  );

  const handleActionClick = () => {
    const id = mainForm?.values._id;
    if (!id) return;
    formAction.open({ mode: "edit", id, data: mainForm.values });
  };

  return (
    <>
      {/* {mainForm?.values?.product?._id} */}
      <Button fullWidth onClick={handleActionClick}>
        {label}
      </Button>

      {renderForm()}
    </>
  );
};

export default OrderReceiveActionButton;
