import moment from 'moment'

export const useUserFriendlyDate = () =>{

    const userFriendlyDate = ( date , format ='LL' ) => {
        if ( moment().diff(date, 'd') > 2 )
            return moment(date).format(format)

        return moment(date).fromNow()
    }         

    return [ userFriendlyDate ]


}