import React, { useState, useEffect, lazy, Suspense } from "react";
import _ from "lodash";
import { showNotification } from "@mantine/notifications";

import {
  Button,
  Table,
  Group,
  Text,
  Chip,
  Space,
  Title,
  Grid,
  Card,
  Divider,
  Tabs,
  Badge,
  LoadingOverlay,
  Menu,
  Switch,
  Loader,
  Center,
  TextInput,
  NumberInput,
} from "@mantine/core";
import {
  IconTrash,
  IconCheck,
  IconCircleCheck,
  IconChecks,
} from "@tabler/icons";

import { useCellRender } from "../hooks/useCellRender";
import { useTranslation } from "react-i18next";
import {
  IconPhoto,
  IconPrinter,
  IconCameraSelfie,
  IconAlertCircle,
  IconAlertTriangle,
  IconExclamationMark,
  IconCoin,
} from "@tabler/icons";
import { Accordion, useMantineTheme } from "@mantine/core";
import { useFormatter } from "../hooks/useFomatter";
import { useServerApi } from "../hooks/userServerApi";
import { useForceUpdate, useSetState } from "@mantine/hooks";

import DataDisplayRow from "./dataDisplayRow";

const CommissionRowColumnRight = lazy(() =>
  import("./commissionRowColumnRight")
);

const CommissionRowDetailCard = ({ row, rowActions }) => {
  const [formatter] = useFormatter();
  const forceupdate = useForceUpdate();
  return (
    <Suspense fallback={<div>Loading...</div>}>
      <Card mb={"sm"}>
        <Group position="apart">
          <div>
            <Title order={4}>{row.policy}</Title>
            <Text size="sm" color="dimmed" weight={400}>
              Statement Policy
              {/* {row.planCode} */}
            </Text>
          </div>
          <div>
            <Title order={4}>{formatter.currency(row.totalRcv)}</Title>
            <Text size="sm" color="dimmed" weight={400}>
              Received
            </Text>
          </div>
        </Group>

        <Divider mt={"sm"} mb={"sm"}></Divider>
        <DataDisplayRow
          label="Policy No."
          value={row.policy}
          editable={row.status === "PENDING"}
          onChange={(v) => {
            row.policy = v?.trim();
            forceupdate();
          }}
        />
        {/* <DataDisplayRow label="Plan Code." value={row.planCode} /> */}

        <DataDisplayRow
          label="Policy Currency"
          value={row.premiumCurrency}
          editable={row.status === "PENDING"}
          onChange={(v) => {
            row.premiumCurrency = v?.trim()?.toUpperCase();
            forceupdate();
          }}
        />
        <DataDisplayRow
          label={`Premium Received (${row.premiumCurrency})`}
          value={row.premiumReceived}
          editable={row.status === "PENDING"}
          type="NUMBER"
          props={{
            precision: 2,
            parser: formatter.currencyParser,
            formatter: formatter.currencyFormat,
          }}
          onChange={(v) => {
            row.premiumReceived = v;
            forceupdate();
          }}
        />
        <DataDisplayRow
          label="Commission Received (HKD)"
          value={row.totalRcv}
          editable={row.status === "PENDING"}
          type="NUMBER"
          props={{
            precision: 2,
            parser: formatter.currencyParser,
            formatter: formatter.currencyFormat,
          }}
          onChange={(v) => {
            row.totalRcv = v;
            forceupdate();
          }}
        />
        {/* <Divider mt={"xs"} mb={"xs"} variant="dashed"></Divider> */}

        {/* <DataDisplayRow
        label="1st Yr Preimum"
        value={formatter.currency(row.f_preimum)}
      />
      <DataDisplayRow
        label="1st Yr Commission"
        value={formatter.currency(row.f_commission)}
      />
      <DataDisplayRow
        label="1st Yr Override"
        value={formatter.currency(row.f_override)}
      />
      <DataDisplayRow
        label="Renew Preimum"
        value={formatter.currency(row.r_preimum)}
      />
      <DataDisplayRow
        label="Renew Commission"
        value={formatter.currency(row.r_commission)}
      />
      <DataDisplayRow
        label="Renew Override"
        value={formatter.currency(row.r_override)}
      /> */}
      </Card>
    </Suspense>
  );
};

const CommissionRowIssueCard = ({ row, rowActions }) => {
  const [loading, setLoading] = useState(false);
  const {
    handleVerifyRow,
    handleUndoRow,
    handleAcceptPayment,
    handleLinkPayment,
    handleVerifyRowAmount,
    size = "xs",
  } = rowActions;

  const getVerifyAction = async () => {
    try {
      setLoading(true);
      if (!row.error) await handleVerifyRow(row);

      if (row.error.code === "ERR_LINK_ORDER_ISSUE") await handleVerifyRow(row);
      if (row.error.code === "ERR_CURRENCY_NOT_MATCH")
        await handleVerifyRow(row);
      if (row.error.code === "ERR_AMOUNT_NOT_MATCH")
        await handleVerifyRowAmount(row);

      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  return (
    <Suspense fallback={<div>Loading...</div>}>
      <Card>
        <Group position="apart">
          {row.status === "CONFIRMED" && (
            <Group>
              <IconCircleCheck color="green" />
              <div>
                <Text size={size}>Confirmed</Text>
                <Text color={"dimmed"} weight={400} size={size}>
                  This statment row is totally confirmed. The related commission
                  bids have been fulfilled.
                </Text>
              </div>
            </Group>
          )}
          {row.status === "VERIFIED" && (
            <Group>
              <IconCircleCheck color="green" />
              <div>
                <Text size={size}>Verified</Text>
                <Text color={"dimmed"} weight={400} size={size}>
                  This statment row is totally verified.
                </Text>
              </div>
            </Group>
          )}
          {row.status === "PENDING" && (
            <Group>
              <IconAlertCircle color="yellow" />
              <div>
                <Text size={size}>
                  {row.error?.title ?? "Please try verify again"}
                </Text>
                <Text color={"dimmed"} weight={400} size={size}>
                  {row.error?.detail}
                </Text>
              </div>
            </Group>
          )}
          {row.status === "PENDING" && (
            <Group>
              {/* {row.error.code} */}
              {row.error && row.error.code === "ERR_AMOUNT_NOT_MATCH" && (
                <Button
                  onClick={async () => await getVerifyAction()}
                  color="green"
                  size={size}
                  loading={loading}
                >
                  Verify
                </Button>
              )}

              {row.error &&
                (row.error.code === "ERR_LINK_ORDER_ISSUE" ||
                  row.error.code === "ERR_CURRENCY_NOT_MATCH") && (
                  <Button
                    onClick={async () => await getVerifyAction()}
                    color="green"
                    size={size}
                    loading={loading}
                  >
                    Retry
                  </Button>
                )}
            </Group>
          )}
          {row.status === "VERIFIED" && (
            <Group>
              <Button
                onClick={() => handleUndoRow(row)}
                color="red"
                size={size}
              >
                Undo
              </Button>
            </Group>
          )}
          {/* 
          {row.status === "CONFIRMED" && (
            <Group>
              <Button
                size="xs"
                onClick={() => handleUndoRow(row)}
                color="red"
                size={size}
              >
                Undo
              </Button>
            </Group>
          )} */}
        </Group>
      </Card>
    </Suspense>
  );
};

const CommissionRowOrderCard = ({ id, row }) => {
  const [formatter] = useFormatter();
  const order = row.orderSnapShot;
  return (
    order && (
      <Suspense fallback={<div>Loading...</div>}>
        <Card>
          <Group position="apart">
            <div>
              <Title order={4}>{order.code}</Title>
              <Text size="sm" color="dimmed" weight={400}>
                Match Order
              </Text>
            </div>
            <div>
              <Title order={4}>{formatter.currency(row.totalAccepted)}</Title>
              <Text size="sm" color="dimmed" weight={400}>
                {"Total Accepeted"}
              </Text>
            </div>
          </Group>
          <Divider mb={"xs"} mt={"xs"} />
          <>
            <DataDisplayRow label="Product" value={order.product?.name} />
            <DataDisplayRow
              label="Payment Term"
              value={order.payment?.paymentTerm}
            />
            <DataDisplayRow label="Pay mode" value={order.payment?.payMode} />
            <DataDisplayRow
              label="Premium"
              value={formatter.currency(order.payment?.premium)}
            />
            <DataDisplayRow label="Currency" value={order.payment?.currency} />
            <DataDisplayRow label="Policy No." value={order.policyNumber} />
            <DataDisplayRow label="Client" value={order.client?.name} />
          </>
        </Card>
      </Suspense>
    )
  );
};

const CommissionRowColumnLeft = ({ row, rowActions }) => {
  return (
    <Suspense fallback={<div>Loading...</div>}>
      <CommissionRowDetailCard row={row} rowActions={rowActions} />
      <CommissionRowIssueCard
        row={row}
        rowActions={rowActions}
        // handleVerifyRow={handleVerifyRow}
        // handleUndoRow={handleUndoRow}
      />
    </Suspense>
  );
};

const CommissionStatementTable = ({ form, formAction }) => {
  const [formatter] = useFormatter();
  const [api] = useServerApi();
  const [confirming, setConfirming] = useState(false);

  const { rows, code, period, policyNotFound, notMatch } = form.values;

  const id = _.get(form.values, "_id");
  const [missingRows, setMissingRows] = useState([]);

  const pendingRows = rows?.filter((r) => r.status === "PENDING") ?? [];
  const verifiedRows = rows?.filter((r) => r.status === "VERIFIED") ?? [];
  const confirmedRows = rows?.filter((r) => r.status === "CONFIRMED") ?? [];

  const getRowStatusIcon = (status) => {
    const ICON_SIZE = 30;
    switch (status) {
      case "PENDING":
        return <IconAlertCircle size={ICON_SIZE} color="yellow" />;

      case "VERIFIED":
        return <IconCircleCheck size={ICON_SIZE} color="green" />;

      case "CONFIRMED":
        return <IconCoin size={ICON_SIZE} color="yellow" />;
    }
  };

  const getErrorColor = (code) => {
    if (code === "ERR_AMOUNT_NOT_MATCH") return "yellow";
    if (code === "ERR_CURRENCY_NOT_MATCH") return "blue";
    if (code === "ERR_LINK_ORDER_ISSUE") return "red";

    return "red";
  };

  const RowHeader = ({ row, focused }) => {
    return (
      <Card mb="md">
        <Group position="apart">
          <Group noWrap align="flex-start">
            <Badge radius={0} variant="outline" size="md">
              {row.rowNumber - 1}
            </Badge>

            <div>
              <Group noWrap>
                <Text>{row.policy}</Text>
                {/*Error title*/}
                {row.error && (
                  <Badge radius={0} color={getErrorColor(row.error?.code)}>
                    <Group spacing={"xs"}>
                      <IconAlertTriangle size={12}></IconAlertTriangle>
                      {row.error?.title ?? "---"}
                    </Group>
                  </Badge>
                )}

                {/*Amount in buffer*/}
                {row.diffPercent && !row.error && (
                  <Badge color={"blue"}> {row.diffPercent}% </Badge>
                )}
                {/*Amount out of buffer */}
                {row.diffPercent && row.error && (
                  <Badge color={"red"}> {row.diffPercent}% </Badge>
                )}
              </Group>

              <Text size="sm" color="dimmed" weight={400}>
                {row.provider}
              </Text>
            </div>
          </Group>

          <Group noWrap>
            {getRowStatusIcon(row.status)}
            <div>
              <Title order={5}>{formatter.currency(row.totalRcv)}</Title>
              <Text size="sm" color="dimmed" weight={400}>
                Received
              </Text>
            </div>
          </Group>
        </Group>
      </Card>
    );
  };

  const updateRows = async (rows, saveForm = true) => {
    for (const row of rows) {
      await updateRow(row, false);
    }
    //Save the change first
    if (!saveForm) return;
    await form.save();
  };

  const updateRow = async (newRow, saveForm = true) => {
    let index = rows.findIndex((r) => r._id === newRow._id);
    if (index == -1) return;

    if (_.isEqual(newRow, rows[index])) return;
    rows[index] = newRow;
    await form.setFieldValue("rows", rows);

    //Save the change first
    if (!saveForm) return;
    await form.save();
  };

  const changeStatus = async () => {
    // const s = status == "PENDING" ? "CONFIRMED" : "PENDING";
    // await form.setFieldValue("status", "CONFIRMED");
    // formAction.save();
    form.values.status =
      form.values.status === "CONFIRMED" ? "PENDING" : "CONFIRMED";
    await form.save();
  };

  const handleAcceptPayment = async (row) => {
    const result = await api.CommissionStatement.rowAcceptPayment(row);
    updateRow(result.data?.row);
  };

  const showVerifiyNotification = (row) => {
    if (!_.isEmpty(row.error)) {
      return showNotification({
        icon: <IconExclamationMark size={18} />,
        color: "red",
        title: row.error.title,
        message: row.error.detail,
      });
    } else {
      return showNotification({
        message: `Statement row ${row.rowNumber} verified successfully.`,
        icon: <IconCheck size={18} />,
        title: "Verified OK",
      });
    }
  };

  const handleVerifyRow = async (row) => {
    const result = await api.CommissionStatement.verifyRow(row);
    // console.log("handleVerifyRow", result.data?.row);
    updateRow(result.data?.row);
    showVerifiyNotification(result.data?.row);
  };

  const handleVerifyRowAmount = async (row) => {
    const result = await api.CommissionStatement.verifyRowAmount(row);
    const r = result.data?.row;
    updateRow(r);
    showVerifiyNotification(r);
  };

  const handleUndoRow = async (row) => {
    const result = await api.CommissionStatement.resetRow(row);
    updateRow(result.data?.row);
  };

  const handleLinkPayment = async (row) => {
    const nr = await api.CommissionStatement.linkMorePayment(row);
    updateRow(nr);
  };

  const handleConfirmPayments = async () => {
    //Confirm Verified Row
    setConfirming(true);
    const result = await api.CommissionStatement.confirmAllVerifiedRow(id);
    // console.log("handleConfirmPayments", result);
    const { verifiedRows } = result;
    updateRows(verifiedRows, false);
    setConfirming(false);
  };

  const RowContent = ({ row }) => {
    return (
      <Grid justify="center">
        <Grid.Col sm={6}>
          <CommissionRowColumnLeft row={row} rowActions={rowActions} />
        </Grid.Col>
        <Grid.Col sm={6}>
          <CommissionRowColumnRight row={row} rowActions={rowActions} />
        </Grid.Col>
      </Grid>
    );
  };

  const RowsList = ({ rows, status }) => {
    const [current, setCurrent] = useState(null);

    return (
      <Suspense fallback={<Loader variant="bars" />}>
        {/* <Accordion variant="separated"> */}
        {!rows && <Loader variant="bars" />}
        {rows && rows.length === 0 && (
          <Card>
            <Title order={6}>
              {`There are no ${status.toLowerCase()} commission`}
            </Title>
          </Card>
        )}

        {rows?.map((row, index) => (
          <>
            <RowHeader
              row={row}
              total={rows.length}
              focused={current === index}
            />

            <RowContent row={row} />

            <Divider variant="dashed" mt="xl" mb="xl" />
          </>
        ))}
      </Suspense>
    );
  };

  const downloadPendingCase = async () => {
    try {
      const result = await api.CommissionStatement.downloadPendingCase(id);
      if (result.url) window.open(result.url, "_blank");
    } catch (error) {
      console.log(error);
    }
  };

  const fetchMissingPayment = async () => {
    try {
      if (!rows) return;
      const list = await api.CommissionStatement.getMissingPayment(
        period,
        rows
      );
      setMissingRows(list);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    // if (period) fetchMissingPayment();
  }, [period, rows]);

  const MissingList = () => {
    return (
      <>
        <Accordion variant="separated">
          {/* Missing: {missingRows.length} */}
          {(!missingRows || missingRows.length === 0) && (
            <Card>
              <Title order={6}>{`There are no missing commission`}</Title>
            </Card>
          )}
          {missingRows &&
            missingRows.map((row, index) => (
              <Card mb={"xl"} key={index}>
                <Group position="apart">
                  <Group noWrap>
                    <Chip radius={0}> {index + 1}</Chip>

                    <div>
                      <Group>
                        <Text>{row.code}</Text>
                        <Badge>{row.expectedPeriod}</Badge>
                      </Group>
                      <Group>
                        <Text size="sm" color="dimmed" weight={400}>
                          {row.provider?.name} - {row.order?.product?.code}
                        </Text>
                      </Group>
                    </div>

                    {/* <Chip color={"blue"}>
                                        Peirod : {row.expectedPeriod}
                                    </Chip> */}
                  </Group>

                  <Group noWrap>
                    {getRowStatusIcon("MISSING")}
                    <div>
                      <Title order={5}>
                        {formatter.currency(row.unsettledBaseAmount)}
                      </Title>
                      <Text size="sm" color="dimmed" weight={400}>
                        Unsettled
                      </Text>
                    </div>
                  </Group>
                </Group>
              </Card>
            ))}
        </Accordion>
      </>
    );
  };

  const rowActions = {
    handleVerifyRow,
    handleVerifyRowAmount,
    handleUndoRow,
    handleAcceptPayment,
    handleLinkPayment,
    updateRow,
  };

  const retryAllPendingCase = async () => {
    try {
      for (const r of pendingRows) {
        const result = await api.CommissionStatement.verifyRow(r);
        updateRow(result.data?.row, false);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const { t: translate } = useTranslation();

  return (
    <>
      {/* Expected: {period} */}

      <Group position="right">
        <Button size="xs" onClick={() => downloadPendingCase()}>
          {translate("Download Pending Cases")}
        </Button>

        {/* <Button size="xs" onClick={() => retryAllPendingCase()}>
          Retry All Pending Cases
        </Button> */}
        <Button
          size="xs"
          color={form.values.status === "CONFIRMED" ? "red" : "green"}
          onClick={() => changeStatus()}
        >
          {translate(
            `Mark As ${
              form.values.status === "PENDING" ? "Complete" : "Pending"
            }`
          )}
        </Button>
      </Group>

      <Tabs defaultValue="pending">
        <Tabs.List>
          <Tabs.Tab value="pending" icon={<IconAlertCircle size={14} />}>
            Pending
          </Tabs.Tab>
          <Tabs.Tab value="missing" icon={<IconAlertTriangle size={14} />}>
            Missing
          </Tabs.Tab>
          <Tabs.Tab value="verified" icon={<IconCircleCheck size={14} />}>
            Verified
          </Tabs.Tab>
          <Tabs.Tab value="confirmed" icon={<IconChecks size={14} />}>
            Confirmed
          </Tabs.Tab>
        </Tabs.List>

        <Tabs.Panel value="pending" pt="xl">
          <RowsList rows={pendingRows} status="PENDING" />
        </Tabs.Panel>

        <Tabs.Panel value="missing" pt="xl">
          <Group position="right" mb={"xl"}>
            <Button onClick={fetchMissingPayment} size="xs">
              Refresh
            </Button>
          </Group>
          <MissingList />
        </Tabs.Panel>

        <Tabs.Panel value="verified" pt="xl">
          <Group position="right" mb={"xl"}>
            <Button
              size="xs"
              onClick={handleConfirmPayments}
              loading={confirming}
            >
              {confirming ? "Confirming" : "Confirm Payments"}
            </Button>
          </Group>

          <RowsList rows={verifiedRows} status="VERIFIED" />
        </Tabs.Panel>

        <Tabs.Panel value="confirmed" pt="xl">
          <RowsList rows={confirmedRows} status="CONFIRMED" />
        </Tabs.Panel>
      </Tabs>
    </>
  );
};

export default CommissionStatementTable;
