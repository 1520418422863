import React, { useState, useEffect } from "react";
import _ from "lodash";
import { useServerApi } from "../hooks/userServerApi";
import { ActionIcon, Divider, Menu, Title } from "@mantine/core";
import {
  createStyles,
  Table,
  Progress,
  Anchor,
  Text,
  Group,
  ScrollArea,
  SimpleGrid,
  Button,
  Chip,
  Badge,
} from "@mantine/core";
import { useFormatter } from "../hooks/useFomatter";
import { useCellRender } from "../hooks/useCellRender";

import {
  IconUsers,
  IconUser,
  IconCircleCheck,
  IconHourglass,
  IconNotes,
  IconTableImport,
  IconCirclePlus,
  IconUserCircle,
  IconBuildingBank,
  IconCircleMinus,
  IconActivity,
  IconCircleX,
  IconHeart,
  IconReceipt2,
  IconAlertCircle,
  IconDots,
  IconDotsVertical,
  IconRefresh,
} from "@tabler/icons";

import CardStat from "./cardStat";
import { showNotification } from "@mantine/notifications";
import InfoGridList from "./infoGridList";

const OrderCommissionPaymentTable = ({ payments, mt = "xl" }) => {
  const [formatter] = useFormatter();
  const [cellRender] = useCellRender();

  const rows = payments?.map((payment, index) => {
    return (
      <tr key={payment.code}>
        <td>#{index + 1}</td>
        <td>{payment.expectedPeriod}</td>
        <td>{payment.year}</td>

        <td>{payment.code}</td>

        <td>{formatter.currency(payment.premium, payment.baseCurrency)}</td>

        <td>{formatter.percentage(payment.commissionRate)}</td>
        <td>
          {formatter.currency(payment.expectedBaseAmount, payment.baseCurrency)}
        </td>
        <td> {formatter.currency(payment.receivedPaidAmount)}</td>
        <td>{cellRender.Status(payment.status)}</td>
      </tr>
    );
  });

  return (
    <ScrollArea sx={{ height: 800 }} mt={mt}>
      <Table sx={{ minWidth: 800 }} verticalSpacing="xs">
        <thead>
          <tr>
            <th>#</th>
            <th>Period</th>
            <th>Year</th>

            <th>Code</th>
            <th>Premium</th>
            <th>Rate</th>
            <th>Expected</th>
            <th>Received</th>

            <th>Status</th>
          </tr>
        </thead>
        <tbody>{rows}</tbody>
      </Table>
    </ScrollArea>
  );
};

const OrderCommissionPaymentList = ({ form }) => {
  const { _id: orderId, status, payment } = form.values;
  const isCertainPayment = _.get(form.values, "isCertainPayment");

  const [payments, setPayments] = useState([]);
  const [api] = useServerApi();
  const [loading, setLoading] = useState(false);
  const [fetching, setFetching] = useState(false);
  const [formatter] = useFormatter();

  const [infoData, setInfoData] = useState({});

  //Fetch Commission Payment for the order if exist
  const fetch = async () => {
    if (!orderId) return;
    try {
      setFetching(true);
      const data = await api.Order.getCommissionPayment(orderId);
      // console.log("fetch", data);
      let payments = data.commissionPayments ?? [];
      setPayments(payments);
      setInfoData({
        paymentCount: payments.length ?? 0,
        totalCommission: payments?.reduce(
          (a, c) => a + c.expectedBaseAmount,
          0
        ),
        waiting: payments?.filter((r) => r.status === "WAITING")?.length ?? 0,
        partial: payments?.filter((r) => r.status === "PARTIAL")?.length ?? 0,
        received: payments?.filter((r) => r.status === "RECEIVED")?.length ?? 0,

        fyPremium: payment.yearPremium ?? emptyStr,
        fyRate: payment.yearRate ?? emptyStr,
        fyConsultantCommission: payment.yearConsultantCommission ?? emptyStr,
        fyCommission: payment.yearCommission ?? emptyStr,
        baseCurrency: payments[0]?.baseCurrency,
      });

      setFetching(false);
    } catch (error) {
      console.log(error);
    }
  };

  const generateCommissionPayment = async () => {
    try {
      if (!orderId) return;
      setLoading(true);
      const data = await api.Order.generateCommissionPayment(orderId);
      // console.log("generateCommissionPayment", data);
      setPayments(data.commissionPayments ?? []);

      showNotification({
        title: `Create successfully`,
        message: `Total ${data.commissionPayments?.length} commission payments generated successfully`,
      });
      setLoading(false);
    } catch (error) {
      console.log(error);
      showNotification({
        title: `Create Fail`,
        message: error.message,
        color: "red",
      });
      setLoading(false);
    }
  };

  useEffect(() => {
    fetch();
  }, [orderId]);

  const isShow = () => {
    const showOnlyStatus = ["INF", "WTH", "SUR", "TER"];
    if (!status || !showOnlyStatus.includes(status)) return false;
    return true;
  };

  const emptyStr = "--";

  const infoFields = [
    { title: "Total Payment Count", value: infoData.paymentCount },
    {
      title: "Total Commission",
      value: formatter.currency(
        infoData.totalCommission,
        infoData.baseCurrency
      ),
    },

    {
      title: "First Year Premium",
      value: formatter.currency(infoData.fyPremium),
    },
    { title: "First Year Rate", value: formatter.percentage(infoData.fyRate) },
    {
      title: "First Year Commission (Total)",
      value: formatter.currency(infoData.fyCommission),
    },
    {
      title: "First Year Commission (Consultant)",
      value: formatter.currency(infoData.fyConsultantCommission),
    },
    { title: "Waiting ", value: infoData.waiting },
    { title: "Partial ", value: infoData.partial },
    { title: "Received ", value: infoData.received },
  ];

  return (
    <>
      {!isShow() && (
        <Text size={"xs"}>
          Sorry, there are no commission payment data for this order currently.
        </Text>
      )}
      {isShow() && (
        <>
          <InfoGridList fields={infoFields} values={infoData} />
          <Divider mt="xl" mb="xl" variant="dashed" />
          <Group position="apart" mt={"xl"}>
            <Group spacing="xs">
              <Badge radius={0}>
                V{(form.values?.commission?.table?.index ?? 0) + 1}
              </Badge>
              <Badge color={isCertainPayment ? "green" : "red"} radius={0}>
                {isCertainPayment ? "Certain" : "Un Certain"}
              </Badge>
            </Group>

            <Group spacing={0}>
              <ActionIcon onClick={() => fetch()}>
                <IconRefresh size={16} stroke={1.5} />
              </ActionIcon>
              <Menu transition="pop" withArrow position="bottom-end">
                <Menu.Target>
                  <ActionIcon>
                    <IconDotsVertical size={16} stroke={1.5} />
                  </ActionIcon>
                </Menu.Target>
                <Menu.Dropdown>
                  <Menu.Item
                    onClick={() => generateCommissionPayment()}
                    // disabled={!isCertainPayment}
                    color="red"
                  >
                    Re-Generate
                  </Menu.Item>
                </Menu.Dropdown>
              </Menu>
              {/* <Button
                size="xs"
                color="red"
                disabled={!isCertainPayment}
                loading={loading}
                onClick={() => generateCommissionPayment()}
              >
                Re-Generate
              </Button> */}
            </Group>
          </Group>
          <OrderCommissionPaymentTable payments={payments} mt={"xl"} />
        </>
      )}
    </>
  );
};

export default OrderCommissionPaymentList;
