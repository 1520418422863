import { Button, Text, TextInput, Select, Textarea } from "@mantine/core";
import React from "react";
import { useFormRender } from "../hooks/useFormRender";
import { addFormSchema } from "../pages/operation/order";

const OrderEditButton = ({ mainForm, label = "Edit Order" }) => {
  let formSchema = { ...addFormSchema };
  formSchema.title = "Order Details";

  const onSubmit = async ({ values, formMode }) => {
    console.log(
      "%corderEditButton.js line:9 values",
      "color: #007acc;",
      values
    );
    // mainForm.setValues(values);
    await mainForm.saveValues(values);
    await mainForm.reload();
  };

  const [renderForm, formAction, formStatus, formSetting] = useFormRender(
    formSchema,
    null,
    onSubmit
  );

  const handleActionClick = () => {
    const id = mainForm?.values._id;
    if (!id) return;
    formAction.open({ mode: "edit", id, data: mainForm.values });
  };

  return (
    <>
      {/* {JSON.stringify(mainForm.values.termination)} */}
      <Button fullWidth onClick={handleActionClick}>
        {label}
      </Button>
      {renderForm()}
    </>
  );
};

export default OrderEditButton;
