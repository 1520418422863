import React, { useState, useEffect } from "react";
import { Select } from "@mantine/core";
import { useServerApi } from "../hooks/userServerApi";

const RemoteSelect = ({
  form,
  name,
  apiEntity,
  label,
  valueField = "_id",
  labelField = "name",
  searchFields = ["name", "code"],
  labelRender,
  sort,
  pageSize = 20,
  clearable = true,
  preQuery,
  maxDropdownHeight = 280,
}) => {
  const [searchValue, onSearchChange] = useState("");
  const [searchQuery, setSearchQuery] = useState("");
  const [options, setOptions] = useState([]);
  const [api] = useServerApi();

  const value = form.values[name];

  const buildSearchQuery = (searchText, searchFields) => {
    //Remove sepecical character
    // const txt = const searchText.replace(")", "\\)").replace("(", "\\(");

    if (!searchText) {
      return preQuery ? preQuery : {};
    }

    //split the search text by space
    let txtArr = searchText.split(" ");
    txtArr = txtArr.map((txt) => handleRegexSpecialChar(txt)?.trim());

    let searchArr = [];
    txtArr.forEach((txt) => {
      searchArr = [
        ...searchArr,
        ...searchFields?.map((field) => ({
          [field]: { $regex: txt, $options: "i" },
        })),
      ];
    });

    // let searchArr = searchFields?.map((field) => ({
    //   [field]: { $regex: txt, $options: "i" },
    // }));

    if (!preQuery) return { $or: searchArr };
    return { $and: [preQuery, { $or: searchArr }] };
  };

  const handleRegexSpecialChar = (text) => {
    // console.log("handleRegexSpecialChar", text);
    const specialTxt = [
      "\\",
      "^",
      "$",
      ".",
      "|",
      "?",
      "*",
      "+",
      "(",
      ")",
      "[",
      "]",
      "{",
      "}",
    ];

    let result = text;
    specialTxt.forEach((special) => {
      result = result.replace(special, `\\${special}`);
    });
    return result;
  };

  const fetchOptions = async (query = {}) => {
    let result = await api.search({
      apiEntity,
      pageSize,
      currentPage: 1,
      sort,
      searchQuery: query,
      byAggregation: false,
    });

    if (!result.docs) return;
    const data = result.docs.map((item) => ({
      value: item[valueField],
      label: labelRender ? labelRender(item) : item[labelField],
      data: item,
    }));

    setOptions(data);
  };

  useEffect(() => {
    const query = buildSearchQuery(searchValue, searchFields);
    fetchOptions(query);
  }, [searchValue]);

  return (
    <Select
      label={label}
      data={options}
      clearable={clearable}
      searchable={true}
      searchValue={searchValue}
      onSearchChange={onSearchChange}
      maxDropdownHeight={maxDropdownHeight}
    />
  );
};

export default RemoteSelect;
