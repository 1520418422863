import React, { useEffect, useState } from "react";
import { useAuthUser } from "react-auth-kit";
import { useServerApi } from "../../hooks/userServerApi";
import { Tabs, Title } from "@mantine/core";
import { SimpleGrid } from "@mantine/core";

import { Page } from "../../layout/page";
import { PageList } from "../../components/pageList";
import DataTable from "../../components/dataTable";

import { useCellRender } from "../../hooks/useCellRender";
import moment from "moment";
import CardStat from "../../components/cardStat";
import { IconCurrencyDollar, IconGridDots } from "@tabler/icons";
import { useFormatter } from "../../hooks/useFomatter";
import { Group } from "@mantine/core";
import { useTranslation } from "react-i18next";

const Wallet = ({ id }) => {
  const apiEntity = "bid";
  const [api] = useServerApi();
  const [cellRender] = useCellRender();
  const [formatter] = useFormatter();
  const [balance, setBalance] = useState(0);

  const tableSchema = {
    showActionColumn: false,
    preQuery: {
      wallet: id,
    },
    searchableFields: ["code", "type", "expectedPeriod", "status"],
    columns: [
      {
        field: "type",
        headerName: "Type",
        sortable: true,

        cellRender: (column, data) => cellRender.BidType(data.type),
      },
      { field: "code", headerName: "Bid Code", sortable: true },
      { field: "level", headerName: "Level", sortable: true },
      {
        field: "expectedPeriod",
        headerName: "Expected Period",
        sortable: true,
      },

      {
        field: "createdAt",
        headerName: "Create At",
        sortable: true,
        cellRender: (column, data) => moment(data.createdAt).format("lll"),
      },

      {
        field: "paidAmount",
        headerName: "Amount",
        sortable: true,
        cellRender: (column, data) =>
          cellRender.Currency(data.paidAmount || data.expectedAmount),
      },
      { field: "status", headerName: "Status", sortable: true },
    ],
  };
  const formSchema = {};
  const BID_STATUS = [
    "OPENING",
    "FULFILLED",
    "RELEASED",
    "SETTLED",
    "TERMINATED",
  ];

  const [stats, setStats] = useState({});

  const fetchStats = async () => {
    try {
      const result = await api.Bid.getStatByWallet(id);

      // console.log(
      //   result.data.stats.OPENING.bids.filter((b) => b.expectedAmount < 0)
      // );
      if (!result.data) return;
      setStats(result.data.stats);
    } catch (error) {
      console.log(error);
    }
  };

  const fetchBalance = async () => {
    try {
      const result = await api.Wallet.getOpeningBalance(id);
      if (!result.success) return setBalance(0);
      console.log("fetchBalance", result);
      setBalance(result.data?.balance ?? 0);
    } catch (error) {
      console.log(error);
      setBalance(0);
    }
  };

  useEffect(() => {
    fetchStats();
    fetchBalance();
  }, [id]);

  const { t } = useTranslation();

  return (
    <>
      <Group mb={"xl"}>
        <CardStat
          stat={{
            icon: IconCurrencyDollar,
            color: "#00bfd8",
            label: "Opening Balance",
            msg: formatter.currency(balance),
          }}
        />
      </Group>
      <Tabs variant="default" defaultValue={"OPENING"}>
        <Tabs.List>
          {BID_STATUS.map((status, index) => (
            <Tabs.Tab key={status} value={status}>
              {t(status)}
            </Tabs.Tab>
          ))}
        </Tabs.List>
        {BID_STATUS.map((status, index) => {
          const ts = { ...tableSchema };
          ts.preQuery = {
            wallet: id,
            status: status,
          };

          return (
            <Tabs.Panel key={status} value={status} pt="xl">
              <SimpleGrid
                cols={4}
                mt="xl"
                breakpoints={[{ maxWidth: "sm", cols: 1 }]}
              >
                <CardStat
                  stat={{
                    icon: IconGridDots,
                    color: "#00bfd8",
                    label: "Bids",
                    msg: stats[status] ? stats[status].count : 0,
                  }}
                />
                <CardStat
                  stat={{
                    icon: IconCurrencyDollar,
                    color: "#ffec00",
                    label: "Total Amount",
                    msg: stats[status]
                      ? formatter.currency(stats[status].amount)
                      : 0,
                  }}
                />
              </SimpleGrid>
              <PageList
                apiEntity={apiEntity}
                tableSchema={ts}
                formSchema={formSchema}
                showAddButton={false}
                initSort={{ by: "expectedPeriod", order: "asc" }}
              />
            </Tabs.Panel>
          );
        })}
      </Tabs>
    </>
  );
};

const WalletPage = () => {
  const auth = useAuthUser();
  const [api] = useServerApi();
  const userId = auth().id;

  const [wallets, setWallets] = useState([]);
  const fetchWallets = async () => {
    try {
      const result = await api.search({
        apiEntity: "wallet",
        searchQuery: {
          $or: [{ user: userId }, { "members.user": userId }],
        },
      });

      // console.log("FetchWallet", result);
      setWallets(result.docs);
    } catch (error) {}
  };

  useEffect(() => {
    fetchWallets();
  }, [userId]);

  return (
    <Page title={"My Wallets"}>
      {wallets && (
        <Tabs defaultValue="0">
          <Tabs.List>
            {wallets.map((w, index) => (
              <>
                <Tabs.Tab key={"W" + index} value={index + ""}>
                  {w.code}
                </Tabs.Tab>
              </>
            ))}
          </Tabs.List>
          {wallets.map((w, index) => (
            <Tabs.Panel key={"T" + index} value={index + ""} pt="xl">
              <Wallet id={w._id} />
            </Tabs.Panel>
          ))}
        </Tabs>
      )}
    </Page>
  );
};

export default WalletPage;
