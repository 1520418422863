import { Avatar, Group, Text, Tooltip } from "@mantine/core";
import React from "react";
import { useFormatter } from "../hooks/useFomatter";

const UsersAvatar = ({
	users,
	maxDisplayUser = 6,
	radius = "xl",
	size = "sm",
}) => {
	const [formatter] = useFormatter();
	const userExtra = users.length - maxDisplayUser;

	return (
		<>
			{/* {JSON.stringify(users)} */}

			<Avatar.Group spacing="sm">
				{users.map((user, index) =>
					index < maxDisplayUser ? (
						<Avatar
							key={index}
							size={size}
							alt={user.name}
							src={`${process.env.REACT_APP_FILE_URL}/${user.image}`}
							radius={radius}
						>
							{formatter.nameInitial(user.name)}
						</Avatar>
					) : null
				)}
			</Avatar.Group>
		</>
	);
};

export default UsersAvatar;
