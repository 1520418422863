import React from "react";
import InfoGridList from "./infoGridList";
import { useCellRender } from "../hooks/useCellRender";
import { DATA_ORDER_STATUS } from "../data/options";
import { Alert, Group, Text, Box, TextInput } from "@mantine/core";
import { IconAlertCircle } from "@tabler/icons";
import _ from "lodash";
import moment from "moment";

import LocalPageList from "./localPageList";

export default function RenewalPreviousRecord({ form, name, textSize = "xs" }) {
  const [cellRender] = useCellRender();
  const previousOrderRecords = _.get(form.values, name) ?? [];

  const tableSchema = {
    columns: [
      {
        field: "signedDate",
        headerName: "Sign Date",
        cellRender: (col, data) => (
          <>{moment(data.order.signedDate).format("LL")} </>
        ),
      },

      {
        field: "code",
        headerName: "Order",
        cellRender: (col, data) => <>{data.order.code} </>,
      },
      {
        field: "policyNumber",
        headerName: "Policy No.",
        sortable: true,

        cellRender: (col, data) => <>{data.order.policyNumber}</>,
      },

      {
        field: "debitNote",
        headerName: "Debit Note",
        sortable: true,
        cellRender: (col, data) => <>{data.debitNote.code}</>,
      },
    ],
  };
  return (
    <>
      {/* <ReactJson src={previousOrderRecords} style={{ background: "white" }} /> */}
      <TextInput
        size="xs"
        mb={"xl"}
        label="Last Policy Number"
        required
        {...form.getInputProps("lastPolicyNumber")}
      />
      {!_.isEmpty(previousOrderRecords) && (
        <LocalPageList
          rows={previousOrderRecords}
          tableSchema={tableSchema}
          showSearch={false}
          readOnly={true}
        />
      )}
      {/* <JsonViewer src={previousOrderRecords} /> */}
      {/* previousOrderRecords:{previousOrderRecords.length} */}
    </>
  );
}
