import React, { useEffect, useState } from "react";
import {
  Container,
  Text,
  Title,
  Box,
  useMantineTheme,
  Group,
  Chip,
  Select,
} from "@mantine/core";
import { ResponsiveLine, Line } from "@nivo/line";
import _ from "lodash";
import { FormSectionCard } from "../../layout/page";
import { useServerApi } from "../../hooks/userServerApi";
import { lineData } from "../../data/sampleChartData";
import moment from "moment";

const periodFilterOptions = [
  "Year to Date",
  "1 Year",
  "2 Years",
  "5 Years",
  "All History",
];

const defaultChartProps = {
  margin: { top: 50, right: 100, bottom: 80, left: 60 },
  xScale: { type: "point" },
  yScale: {
    type: "linear",
    // min: "auto",
    max: "auto",
    stacked: true,
    reverse: false,
  },
  yFormat: " >-.0f",
  curve: "natural",
  axisTop: null,
  axisRight: null,

  axisBottom: {
    orient: "bottom",
    tickSize: 5,
    tickPadding: 5,
    tickRotation: 0,
    legend: "",
    legendOffset: 40,
    legendPosition: "middle",
  },
  axisLeft: {
    orient: "left",
    tickSize: 5,
    tickPadding: 5,
    tickRotation: 0,
    legend: "",
    legendOffset: -50,
    legendPosition: "middle",
  },
  enableGridY: false,
  enableGridX: false,
  colors: { scheme: "category10" },
  pointSize: 5,
  pointColor: { theme: "background" },
  pointBorderWidth: 2,
  pointBorderColor: { from: "serieColor" },
  pointLabelYOffset: -12,
  enableArea: true,
  areaOpacity: 0.05,
  areaBaselineValue: 0,
  useMesh: true,
  legends: [
    {
      anchor: "bottom-right",
      direction: "column",
      justify: false,
      translateX: 100,
      translateY: 0,
      itemsSpacing: 0,
      itemDirection: "left-to-right",
      itemWidth: 80,
      itemHeight: 20,
      itemOpacity: 0.75,
      symbolSize: 12,
      symbolShape: "circle",
      symbolBorderColor: "rgba(0, 0, 0, .5)",
      effects: [
        {
          on: "hover",
          style: {
            itemBackground: "rgba(0, 0, 0, .03)",
            itemOpacity: 1,
          },
        },
      ],
    },
  ],
};

const LineChart = ({
  title,
  height = 500,
  width = 800,
  description,
  chartProps,
  dataAggreation,
  useSampleData = true,
  showPeriodFilter = false,
  responseive = true,
  onPeriodChange,
  onDataChange,
}) => {
  const theme = useMantineTheme();
  const [api] = useServerApi();
  const [data, setData] = useState([]);

  const chartTheme = {
    textColor:
      theme.colorScheme === "dark"
        ? theme.colors.gray[1]
        : theme.colors.dark[8],
    grid: {
      line: {
        stroke:
          theme.colorScheme === "dark"
            ? theme.colors.gray[8]
            : theme.colors.gray[1],
      },
    },
  };

  const fetchData = async () => {
    try {
      if (useSampleData) {
        if (onDataChange) onDataChange(lineData);
        return setData(lineData);
      }
      if (!dataAggreation) return;

      console.log("LineChart fetchData , dataAggreation = ", dataAggreation);

      const { mapper, aggregations } = dataAggreation;
      if (_.isEmpty(aggregations)) return;

      let d = await api.aggregate(dataAggreation);
      if (onDataChange) onDataChange(d);

      console.log("fetch Line Chart Data=", d, mapper);

      if (mapper) {
        d = mapper(d);
      }
      setData(d);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    fetchData();
  }, [dataAggreation.aggregations]);

  const handlePeriodChange = (period) => {
    if (!onPeriodChange) return;

    const periodAggregate = getPeriodAggregate(period);
    onPeriodChange(periodAggregate);
  };

  const getPeriodAggregate = (period) => {
    let gte;
    switch (period) {
      case "Year to Date":
        gte = moment().startOf("year");
        break;
      case "1 Year":
        gte = moment().subtract(1, "year");
        break;
      case "2 Year":
        gte = moment().subtract(2, "year");
        break;
      case "5 Year":
        gte = moment().subtract(5, "year");
        break;
      case "All History":
        return null;

      default:
        gte = moment().subtract(10, "year");
        break;
    }

    return {
      $match: {
        $and: [
          {
            valuationDateString: {
              $gte: gte,
            },
          },
          {
            valuationDateString: {
              $lte: moment(),
            },
          },
        ],
      },
    };
  };

  return (
    <>
      {showPeriodFilter && (
        <Chip.Group
          position="right"
          onChange={handlePeriodChange}
          defaultValue={"1 Year"}
        >
          {periodFilterOptions.map((p) => (
            <Chip value={p} size="xs">
              {p}
            </Chip>
          ))}
        </Chip.Group>
      )}

      <FormSectionCard title={title} h={height} w={"100%"}>
        <Group position="apart">
          <Text size={"xs"} color="dimmed">
            {description}
          </Text>
        </Group>

        {responseive && (
          <ResponsiveLine
            data={data}
            theme={chartTheme}
            {..._.merge(defaultChartProps, chartProps)}
          />
        )}
        {!responseive && (
          <Line
            data={data}
            theme={chartTheme}
            width={width}
            height={height * 0.8}
            {..._.merge(defaultChartProps, chartProps)}
          />
        )}
      </FormSectionCard>
    </>
  );
};

export default LineChart;
