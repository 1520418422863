import React, { useState } from "react";
import {
  Button,
  Checkbox,
  Group,
  keyframes,
  List,
  Title,
  Textarea,
  Select,
  NumberInput,
} from "@mantine/core";
import _ from "lodash";
import { useFormRender } from "../hooks/useFormRender";
import { useServerApi } from "../hooks/userServerApi";
import { ENUM_FORM_DISPLAY, ENUM_FORM_LAYOUT_CONTAINER } from "./pageList";
import { IconSettings } from "@tabler/icons";
import FormConfirmation from "./formConfirmation";
import { useDisclosure } from "@mantine/hooks";

import { Modal, Text } from "@mantine/core";
import { showNotification } from "@mantine/notifications";
import { IconExclamationMark, IconAlertCircle } from "@tabler/icons";
import { DatePicker } from "@mantine/dates";
import { DATA_PAYMENT_METHOD } from "../data/options";
import moment from "moment";
import DummyWidget from "./dummyWidget";
import { useFormatter } from "../hooks/useFomatter";

const DebitNoteUnSettlePaymentButton = ({ mainForm }) => {
  const [api] = useServerApi();
  const [loading, setLoading] = useState(false);

  const {
    _id: id,
    code,
    total,
    costTotal,
    signedBy,
    consultants,
  } = mainForm.values;
  const [formatter] = useFormatter();

  const handleClick = async () => {
    try {
      const ans = window.confirm(
        "Are you sure to un-settle this payment? All the fufilled bids will be deleted."
      );
      if (!ans) return;
      const result = await api.DebitNote.unSettle(id);
      console.log(result);

      setLoading(true);
      mainForm.setFieldValue("paymentStatus", "PAID");
      setLoading(false);

      showNotification({
        title: "DebitNote Unsettled.",
        message: "DebitNote has been unsettled successfully.",
        color: "green",
      });
    } catch (e) {
      console.log(e);
      setLoading(false);
      showNotification({
        title: "Un Settle Error",
        message: e,
        color: "red",
      });
    }
  };

  return (
    <>
      <Button fullWidth color={"red"} onClick={handleClick} loading={loading}>
        Un Settle Payment
      </Button>
    </>
  );
};

export default DebitNoteUnSettlePaymentButton;
