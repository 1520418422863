import React, {
  useState,
  useEffect,
  useRef,
  useMemo,
  useImperativeHandle,
  useCallback,
} from "react";

import _ from "lodash";
import { Button, Select, MultiSelect, Box, Group, Paper } from "@mantine/core";
import { useServerApi } from "../hooks/userServerApi";
import { useForceUpdate } from "@mantine/hooks";
import ReactJson from "react-json-view";
import useDeepCompareEffect from "../hooks/useDeepCompareEffect";
import MultiRemoteSelect from "./multiRemoteSelect";
import { Grid } from "ag-grid-community";
import { DATA_AGE_TYPES } from "../data/options";
import { useTranslation } from "react-i18next";
// window.addEventListener("error", function (e) {
//   console.error(e.message);
//   // prevent React's listener from firing
//   e.stopImmediatePropagation("ResizeObserver loop limit exceeded");
//   // prevent the browser's console error message
//   e.preventDefault();
// });

const RemoteSelect2 = (
  {
    form,
    name,
    label,
    value,
    sort,
    apiEntity,
    valueField = "_id",
    labelField,
    labelRender,
    placeholder = "Pick One",
    clearable = true,
    searchFields = ["code"],
    preQuery,
    // required,
    multiple = false,
    onDataChange,
    onKeydown,
    clearAfterChange = false,
    disabled = false,
    variant = "default",
    pageSize = 20,
    zIndex = 1000,
    width = "100%",
    ...props
  },
  ref
) => {
  useImperativeHandle(
    ref,
    () => {
      return {
        clear: clearSelect,
      };
    },
    []
  );
  const [fieldValue, setFieldValue] = useState(); //selected value
  const [options, setOptions] = useState([]); //Converted to Options
  const [data, setData] = useState([]); //Orginal data sets

  const [searchQuery, setSearchQuery] = useState({});
  const [api] = useServerApi();

  const currentPage = 1; //Always display first page
  const { t: translate } = useTranslation();
  const getDisplayValue = () => {
    let v = value || _.get(form?.values, name);
    if (!v) return null;

    if (!multiple) {
      // console.log("getDisplayValue1", name, v);
      v = typeof v === "object" ? v._id : v;
    }
    if (multiple && Array.isArray(v)) {
      // console.log("getDisplayValue2", name, v);

      v = v.map((value) => value._id || value);
    }
    return v;
  };

  const displayValue = getDisplayValue();

  useDeepCompareEffect(() => {
    setFieldValue(displayValue);
  }, [displayValue]);

  const handleChange = useCallback((v) => {
    // console.log("handleChange", v);
    setFieldValue(v);
    setFormValue(v);
    notifyDataChange(v);
  });

  const clearSelect = () => {
    setFieldValue(null);
  };

  const setFormValue = (v) => {
    if (!form || !name) return;
    // if (!value || _.isEmpty(value)) return;
    form.setFieldValue(name, v);
  };

  const notifyDataChange = (v) => {
    if (!_.isFunction(onDataChange)) return;
    if (_.isEmpty(data)) return;
    onDataChange(
      data.find((d) => _.get(d, valueField) === v),
      form
    );
    if (clearAfterChange) {
      setFieldValue(null);
    }
  };

  const getLabel = (data) => {
    // console.log("getLabel", name, data);
    if (labelRender) return labelRender(data);
    if (labelField) return _.get(data, labelField);
    return "";
  };

  const fetchFieldValueData = async () => {
    if (!fieldValue) return;
    // console.log("fetchFieldValueData before", name, options, fieldValue);
    if (options.findIndex((o) => o.value === fieldValue) !== -1) return;

    let result = await api.search({
      apiEntity,
      pageSize,
      currentPage: 1,
      sort,
      searchQuery: { [valueField]: fieldValue },
      byAggregation: false,
    });
    // console.log("fetchFieldValueData", name, result);
    const rows = result.docs;
    if (_.isEmpty(rows)) return;

    let o = rows.map((r) => ({
      value: _.get(r, valueField),
      label: getLabel(r),
    }));
    setOptions([...o, ...options]);
  };

  const fetchData = async () => {
    try {
      if (_.isEmpty(searchQuery) && !_.isEmpty(preQuery)) return;
      let result = await api.search({
        apiEntity,
        pageSize,
        currentPage,
        sort,
        searchQuery,
        byAggregation: false,
      });

      handleFetchResult(result.docs);
      fetchFieldValueData();
    } catch (e) {
      setOptions([]);
      setData([]);
    }
  };

  const handleFetchResult = (rows) => {
    setData(rows);
    const options = rows.map((r) => ({
      value: _.get(r, valueField),
      label: getLabel(r),
    }));
    setOptions(options);
  };

  const handleRegexSpecialChar = (text) => {
    // console.log("handleRegexSpecialChar", text);
    const specialTxt = [
      "\\",
      "^",
      "$",
      ".",
      "|",
      "?",
      "*",
      "+",
      "(",
      ")",
      "[",
      "]",
      "{",
      "}",
    ];

    let result = text;
    specialTxt.forEach((special) => {
      result = result.replace(special, `\\${special}`);
    });
    return result;
  };

  const buildSearchQuery = (searchText, searchFields) => {
    //Remove sepecical character
    // const txt = const searchText.replace(")", "\\)").replace("(", "\\(");

    if (!searchText) {
      return preQuery ? preQuery : {};
    }

    //split the search text by space
    let txtArr = searchText.split(" ");
    txtArr = txtArr.map((txt) => handleRegexSpecialChar(txt)?.trim());

    let searchArr = [];
    txtArr.forEach((txt) => {
      searchArr = [
        ...searchArr,
        ...searchFields?.map((field) => ({
          [field]: { $regex: txt, $options: "i" },
        })),
      ];
    });

    if (!preQuery) return { $or: searchArr };
    return { $and: [preQuery, { $or: searchArr }] };
  };

  const handleSearchChange = useCallback(
    (searchText) => {
      let query = buildSearchQuery(searchText, searchFields);
      setSearchQuery(query);
    },
    [searchFields]
  );

  useEffect(() => {
    // console.log("fetchData");
    fetchData();
  }, [searchQuery]);

  // useEffect(() => {
  //   fetchFieldValueData();
  // }, [fieldValue]);

  return (
    <>
      {/* {JSON.stringify(fieldValue)} */}
      {/* fieldValue:{JSON.stringify(fieldValue)}
      displayValue: {JSON.stringify(displayValue)} <br></br> */}
      {/* multiple: {multiple ? "true" : "false"} */}
      {/* sort: {JSON.stringify(sort)} <br></br>  */}
      {/* {pageSize} */}
      {multiple ? (
        <MultiRemoteSelect
          form={form}
          name={name}
          label={label}
          value={value}
          sort={sort}
          pageSize={pageSize}
          apiEntity={apiEntity}
          valueField={valueField}
          labelField={labelField}
          labelRender={labelRender}
          searchFields={searchFields}
          required={props.required}
        />
      ) : (
        /* <MultiSelect
						// {...props}
						value={fieldValue}
						label={label}
						placeholder={placeholder}
						clearable
						searchable
						onChange={handleChange}
						onSearchChange={handleSearchChange}
						onKeyDown={onKeydown}
						data={options}
						disabled={disabled}
						variant={variant}
					/> */

        <Select
          // {...props}
          value={fieldValue}
          label={label}
          placeholder={translate(placeholder)}
          clearable={true}
          required={props.required}
          searchable
          onChange={handleChange}
          onSearchChange={handleSearchChange}
          onKeyDown={onKeydown}
          data={options}
          disabled={disabled}
          variant={variant}
          searchFields={searchFields}
          zIndex={zIndex}
          width={width}
        />
      )}
    </>
  );
};

export default React.forwardRef(RemoteSelect2);
