import React from "react";
import { ENUM_FORM_DISPLAY, ENUM_FORM_LAYOUT_CONTAINER } from "./pageList";
import { useFormRender } from "../hooks/useFormRender";
import { Button, Text, TextInput, Select, Textarea } from "@mantine/core";
import { DatePicker } from "@mantine/dates";
import { IconExclamationMark, IconSettings } from "@tabler/icons";
import DateOffPicker from "./dateOffPicker";
import { useServerApi } from "../hooks/userServerApi";
import { DATA_POLICY_DELIVER_METHODS } from "../data/options";
import { showNotification } from "@mantine/notifications";
import { IconCheck } from "@tabler/icons";

const OrderEditPolicyButton = ({ mainForm, label = "Edit Policy" }) => {
  const [api] = useServerApi();
  const formSchema = {
    title: "Edit Policy",
    description: "Please follow the step to print the cover sheet",

    display: {
      mode: ENUM_FORM_DISPLAY.MODAL,
      size: "xl", //xs, sm, xl  ....100%
    },
    showSaveBar: true,
    closeAfterSave: true,

    initialValues: {
      policyDeliverMethod: "Electronic",
    },

    layout: {
      containers: [
        {
          key: "section-confirm",
          parent: null,
          type: ENUM_FORM_LAYOUT_CONTAINER.SECTION,
          props: {
            // title: "Confirm Policy",
            description:
              "Please enter the form to complete the policy confirmation",
            withBorder: false,
            mt: 0,
            ml: 20,
          },
        },
        {
          key: "grid",
          parent: "section-confirm",
          type: ENUM_FORM_LAYOUT_CONTAINER.GRID,
          cols: [
            {
              key: "g1",
              props: {
                span: 6,
                mt: 0,
              },
            },
            {
              key: "g2",
              props: {
                span: 6,
                mt: 0,
              },
            },
          ],
        },
      ],

      fields: [
        {
          name: "policyNumber",
          component: TextInput,
          parent: "g1",
          props: {
            label: "Policy Number",
          },
        },

        {
          name: "policyCommenceDate",
          component: DatePicker,
          type: "datePicker",
          parent: "g1",
          props: {
            required: true,
            label: "Policy Commence Date",
            zIndex: 1000,
            placeholder: "Pick Date",
            allowFreeInput: true,
          },
        },
        {
          name: "policyIssueDate",
          component: DatePicker,
          type: "datePicker",
          parent: "g1",
          props: {
            required: true,
            label: "Policy Issue Date",
            zIndex: 1000,
            placeholder: "Pick Date",
            allowFreeInput: true,
          },
        },

        {
          name: "policyCoolingOffDate",
          component: DateOffPicker,
          type: "datePicker",
          parent: "g2",
          props: {
            required: true,
            relatedField: "policyIssueDate",
            daysOff: 21,
            label: "Cooling Off Expire Date",
            zIndex: 1000,
            placeholder: "Pick Date",
            allowFreeInput: true,
          },
        },

        {
          name: "policyRemark",
          component: Textarea,
          parent: "g2",
          props: {
            label: "Remark",
            minRows: 4,
          },
        },
      ],
    },
  };

  const onSubmit = async ({ values, formMode }) => {
    // console.log("onSubmit", values._id);
    try {
      mainForm.setValues(values);
      await mainForm.saveValues(values);
      formAction.close();
      showNotification({
        title: `Order ${values?.code} policy updated successfully.`,
        icon: <IconCheck size={18} />,
        message: "Data Saved",
      });
    } catch (error) {
      showNotification({
        title: `Order ${values?.code} policy updated error.`,
        icon: <IconExclamationMark size={18} />,
        message: error?.msg ?? error,
      });
    }
  };

  const [renderForm, formAction, formStatus, formSetting] = useFormRender(
    formSchema,
    null,
    onSubmit
  );

  const handleActionClick = () => {
    const id = mainForm?.values._id;
    // console.log('%corderPrintCoverSheetActionButton.js line:86 object', 'color: #007acc;', id);
    if (!id) return;
    formAction.open({ mode: "edit", id, data: mainForm.values });
  };

  return (
    <>
      <Button fullWidth onClick={handleActionClick}>
        {label}
      </Button>

      {renderForm()}
    </>
  );
};

export default OrderEditPolicyButton;
