import React from "react";
import InfoGridList from "./infoGridList";
import { useCellRender } from "../hooks/useCellRender";
import { DATA_CN_STATUS } from "../data/options";
import { Alert, Group, Input, Text } from "@mantine/core";
import { IconAlertCircle } from "@tabler/icons";
import _ from "lodash";
import moment from "moment";

export default function CreditNotePaymentSummary({ form, name }) {
  const [cellRender] = useCellRender();
  const cn = form.values;

  const fields = [
    {
      title: "Provider Pay Date",
      value: cn?.payDate ? moment(cn.payDate).format("LL") : "-",
    },
    {
      title: "Total Credit To Client",
      value: cn?.totalCreditToClient
        ? cellRender.Currency(cn.totalCreditToClient)
        : "-",
    },
    {
      title: "Total Credit To Broker",
      value: cn?.totalCreditToBroker
        ? cellRender.Currency(cn.totalCreditToBroker)
        : "-",
    },
    {
      title: "Balance",
      value:
        cn?.totalCreditToBroker && cn?.totalCreditToClient
          ? cellRender.Currency(cn.totalCreditToBroker - cn.totalCreditToClient)
          : "-",
    },
    // {
    //   title: "Method",
    //   value: cn?.paymentMethod ?? "-",
    // },
    // {
    //   title: "Remark",
    //   value: cn?.remark ?? "",
    //   singleLine: true,
    // },
  ];

  return cn && <InfoGridList fields={fields} values={cn} />;
}
