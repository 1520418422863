import { Menu, Group, ActionIcon, Text, Button } from "@mantine/core";
import {
  IconDots,
  IconSettings,
  IconMessageCircle,
  IconPhoto,
  IconSearch,
  IconArrowsLeftRight,
  IconTrash,
} from "@tabler/icons";

import { useTranslation } from "react-i18next";

const renderActionButtons = (actions, data, onActionBtnClick, rowIndex) => {
  return actions.map((action, index) => (
    <ActionIcon
      key={index}
      onClick={() => onActionBtnClick({ action: action.name, data, rowIndex })}
    >
      <action.icon
        size={action.iconSize ?? 16}
        stroke={action.stroke ?? 1.5}
        {...action.props}
      />
    </ActionIcon>
  ));
};

const renderActionMenu = (actionMenu, data, onActionBtnClick, rowIndex) => {
  if (!actionMenu || actionMenu.length == 0) return;

  return (
    <Menu transition="pop" withArrow position="bottom-end">
      <Menu.Target>
        <ActionIcon>
          <IconDots size={16} stroke={1.5} />
        </ActionIcon>
      </Menu.Target>
      <Menu.Dropdown py={"xs"}>
        {actionMenu.map((action, index) =>
          action.icon ? (
            <Menu.Item
              key={index}
              icon={<action.icon size={16} stroke={1.5} />}
              {...action.props}
              onClick={() =>
                onActionBtnClick({ action: action.name, data, rowIndex })
              }
            >
              <Text size="xs">{action.label}</Text>
            </Menu.Item>
          ) : (
            <Menu.Item
              key={index}
              {...action.props}
              onClick={() =>
                onActionBtnClick({ action: action.name, data, rowIndex })
              }
            >
              <Text size="xs">{action.label}</Text>
            </Menu.Item>
          )
        )}
      </Menu.Dropdown>
    </Menu>
  );
};

const ActionCellRender = (column, rowIndex, data, onActionBtnClick) => {
  return (
    <Group>
      {/* { JSON.stringify(column)} */}
      {renderActionButtons(column.actions, data, onActionBtnClick, rowIndex)}
      {renderActionMenu(column.actionMenu, data, onActionBtnClick, rowIndex)}
    </Group>
  );
};

export default ActionCellRender;
