import React, { useState, useEffect } from "react";
import InfoGridList from "./infoGridList";
import { useCellRender } from "../hooks/useCellRender";
import ReactJson from "react-json-view";
import _ from "lodash";

import { Button, Title, Text, Chip } from "@mantine/core";
import { useServerApi } from "../hooks/userServerApi";
import { useTranslation } from "react-i18next";

const OrderProductVariation = ({ form: mainForm }) => {
  const product = _.get(mainForm.values, "productSnapShot");
  const productVariations = _.get(mainForm.values, "productVariations") || [];
  const { t } = useTranslation();
  const handleChange = (variation, _id) => {
    // console.log("handelChange", _id)
    let pv = productVariations?.find((v) => v.name === variation.name);
    let selectedOption = variation.options.find((option) => option._id === _id);

    // console.log("pv",pv)
    if (pv) {
      pv._id = selectedOption._id;
      pv.label = selectedOption.label;
    } else {
      productVariations.push({
        name: variation.name,
        _id: selectedOption._id,
        label: selectedOption.label,
      });
    }

    mainForm.setFieldValue("productVariations", productVariations);
  };

  const getSelectedVariation = (variationName) => {
    let pv = productVariations?.find((v) => v.name === variationName);
    if (!pv) return null;
    return pv._id;
  };

  const VariationSelect = ({ variation }) => {
    let options = variation.options;
    return (
      <>
        {/* <ReactJson src={mainForm.values} style={{ background: "white" }} /> */}
        <Title order={6} mb={"sm"}>
          {variation.name}
        </Title>
        <Chip.Group
          value={getSelectedVariation(variation.name)}
          onChange={(_id) => handleChange(variation, _id)}
          multiple={false}
          mb={"xl"}
        >
          {options.length > 0 &&
            options.map((option) => (
              <Chip key={option._id} value={option._id}>
                {option.label}
              </Chip>
            ))}
        </Chip.Group>
      </>
    );
  };

  return (
    <>
      {/* <ReactJson src={productVariations} collapsed style={{background:"white"}} name="selected variation"/> */}
      {/* <ReactJson
        src={product?.variations}
        collapsed
        style={{ background: "white" }}
        name="all possible variation"
      />
      <ReactJson
        src={product}
        collapsed
        style={{ background: "white" }}
        name="Product Snapshot"
      /> */}
      {_.isEmpty(product?.variations) && (
        <Text size={"xs"}>
          {t("There are no variations for this product.")}
        </Text>
      )}
      {product?.variations?.map((variation) => (
        <VariationSelect key={variation._id} variation={variation} />
      ))}
    </>
  );
};

export default OrderProductVariation;
