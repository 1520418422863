import React, { useState } from "react";
import {
  Button,
  Checkbox,
  Group,
  keyframes,
  List,
  Title,
  Textarea,
  Select,
  NumberInput,
  TextInput,
} from "@mantine/core";
import _ from "lodash";
import { useFormRender } from "../hooks/useFormRender";
import { useServerApi } from "../hooks/userServerApi";
import { ENUM_FORM_DISPLAY, ENUM_FORM_LAYOUT_CONTAINER } from "./pageList";
import { IconSettings } from "@tabler/icons";
import FormConfirmation from "./formConfirmation";
import { useDisclosure } from "@mantine/hooks";

import { Modal, Text } from "@mantine/core";
import { showNotification } from "@mantine/notifications";
import { IconExclamationMark, IconAlertCircle } from "@tabler/icons";
import { DatePicker } from "@mantine/dates";
import { DATA_PAYMENT_METHOD } from "../data/options";
import moment from "moment";
import DummyWidget from "./dummyWidget";
import { useFormatter } from "../hooks/useFomatter";
import ReactJson from "react-json-view";

const DebitNoteConfirmPolicyButton = ({ mainForm }) => {
  const [api] = useServerApi();
  const [loading, setLoading] = useState(false);
  const dn = mainForm.values;

  const { _id: id } = mainForm.values;

  const formSchema = {
    title: "DebitNote Confirm Policy",
    description: "Confirm payment received from client for Debit note.",

    display: {
      mode: ENUM_FORM_DISPLAY.MODAL,
      size: "xl", //xs, sm, xl  ....100%
    },
    showSaveBar: true,
    closeAfterSave: true,
    initialValues: {
      date: moment().toISOString(),
      remark: "",
    },

    validate: {
      confirmedAt: (value) =>
        !value ? "Please select the policy confirm date" : null,
      policyNumber: (value) =>
        !value ? "Please enter the policy number" : null,
    },

    layout: {
      containers: [],
      fields: [
        {
          name: "confirmedAt",
          component: DatePicker,
          type: "datePicker",
          props: {
            required: true,
            label: "Policy Confirm Date",
            zIndex: 1000,
            placeholder: "Pick Date",
            allowFreeInput: true,
          },
        },
        {
          name: "policyNumber",
          component: TextInput,
          props: {
            required: true,
            label: "Policy Number",
            placeholder: "Enter Policy Number",
          },
        },
      ],
    },
  };

  const onSubmit = async ({ values, formMode }) => {
    try {
      formAction.close();
      setLoading(true);
      // console.log(" confirm policy > onSubmit >values", values);
      const result = await api.DebitNote.confirmPolicy({
        id,
        dn: values,
      });

      // console.log(" confirm policy > onSubmit >result", result);
      if (!result || !result.data || !result.data.dn) return;

      mainForm.setFieldValue("confirmedAt", result.data.dn.confirmedAt);
      mainForm.setFieldValue("policyNumber", result.data.dn.policyNumber);
      mainForm.setFieldValue("status", result.data.dn.status);

      showNotification({
        title: `DN updated and confirmed`,
        icon: <IconAlertCircle size={18} />,
        message: "Done",
      });

      setLoading(false);
      if (!result.success) throw result.error;
    } catch (error) {
      showNotification({
        title: `Hold Cover Fail`,
        icon: <IconAlertCircle size={18} />,
        message: error.message,
      });
      setLoading(false);
    }
  };

  const [renderForm, formAction, formStatus, formSetting] = useFormRender(
    formSchema,
    null,
    onSubmit
  );

  const handleClick = () => {
    // const holdCover = mainForm?.values.holdCover;
    formAction.open({ mode: "edit", data: mainForm?.values });
  };

  return (
    <>
      {/* {values.total - mainForm.values.discountAmount} */}
      {/* <ReactJson src={mainForm.values} style={{ background: "white" }} /> */}
      <Button
        fullWidth
        color={"green"}
        onClick={() => handleClick()}
        loading={loading}
      >
        Confirm Policy
      </Button>
      {renderForm()}
    </>
  );
};

export default DebitNoteConfirmPolicyButton;
