import React from "react";
import InfoGridList from "./infoGridList";
import { useCellRender } from "../hooks/useCellRender";
import { DATA_ORDER_STATUS } from "../data/options";
import { Badge, Group } from "@mantine/core";
import _ from "lodash";
import { useTranslation } from "react-i18next";
export default function ProductPaymentSummary({ form }) {
  const [cellRender] = useCellRender();
  const product = form.values;
  const dataFontWeight = 600;
  const { t } = useTranslation();

  const fields = [
    {
      title: "Currency",
      array: product.currency,
    },
    {
      title: "Pay Mode",
      array: product.payModes,
    },
    { title: "Pay Term (Min)", value: product.payTermMin },
    { title: "Pay Term (Max)", value: product.payTermMax },

    { title: "Min Premium", value: product.minPremium },
    { title: "Min Insured", value: product.minInsured },
    { title: "Min Initial Charge", value: product.minInitialCharge },

    { title: "Insured Age Range", value: product?.insuredAgeRange?.join("  ") },
    { title: "Insured Weekly Age", value: product.insuredWeeklyAge },
  ];
  return <InfoGridList fields={fields} values={product} />;
}
