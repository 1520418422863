import React, { useState, useEffect } from "react";
import moment from "moment";
import { DatePicker } from "@mantine/dates";
import {
  IconTrash,
  IconCheck,
  IconCircleCheck,
  IconAlertOctagon,
  IconPencil,
  IconSettings,
  IconCross,
} from "@tabler/icons";

import _ from "lodash";
import { useTranslation } from "react-i18next";
import RemoteSelect2 from "../components/remoteSelect2";

import {
  Switch,
  TextInput,
  Select,
  Textarea,
  NumberInput,
  Group,
  Text,
  Button,
  Loader,
} from "@mantine/core";
import FormConfirmation from "./formConfirmation";

import { DATA_GENDERS, DATA_RELATIONSHIP } from "../data/options";
import { useFormRender } from "../hooks/useFormRender";

import { ENUM_FORM_DISPLAY, ENUM_FORM_LAYOUT_CONTAINER } from "./pageList";
import LocalPageList from "./localPageList";
import FileList from "./fileList";
import ActionCellRender from "./actionCellRender";
import { useFormatter } from "../hooks/useFomatter";

import { useServerApi } from "../hooks/userServerApi";
import ReactJson from "react-json-view";
import { useCellRender } from "../hooks/useCellRender";
import useDeepCompareEffect from "../hooks/useDeepCompareEffect";
import { useForceUpdate, randomId } from "@mantine/hooks";
import DataTable from "./dataTable";
import Order from "../pages/operation/order";
import OrderAddServiceButton from "./orderAddServiceButton";
import DummyWidget from "./dummyWidget";
import ServiceCheckList from "./serviceCheckList";
import { useAuthUser } from "react-auth-kit";

const OrderServiceList = ({ form: mainForm, ...props }) => {
  const orderId = _.get(mainForm.values, "_id");
  const services = _.get(mainForm.values, "services");
  const [formatter] = useFormatter();

  const [rows, setRows] = useState(services);
  const [api] = useServerApi();
  const [cellRender] = useCellRender();
  const forceUpdate = useForceUpdate();
  const [printing, setPrinting] = useState(false);
  const auth = useAuthUser();
  const role = auth()?.userRole?.name?.toUpperCase();

  const printSubmitCoverSheetFormSchema = {
    name: "printSubmitCoverSheetFormSchema",
    title: "Print Order Service Cover Sheet",
    description: "Please follow the step to create a New Order Service",

    display: {
      mode: ENUM_FORM_DISPLAY.MODAL,
      size: "1000px", //xs, sm, xl  ....100%
    },

    apiEntity: "order",
    initialValues: {},

    validate: {},
    showSaveBar: false,

    layout: {
      containers: [
        {
          key: "t1",
          parent: null,
          type: ENUM_FORM_LAYOUT_CONTAINER.WIZARD,

          props: {
            variant: "default", //default | pills | outline
            defaultValue: "Service Info",
            orientation: "vertical",
            style: { minHeight: "400px" },
          },
          steps: [
            { key: "t1-1", label: "Service Info", icon: IconSettings },
            { key: "t1-2", label: "CheckList", icon: IconSettings },
            { key: "t1-7", label: "Confirmation", icon: IconSettings },
          ],
        },
        {
          key: "section-info",
          parent: "t1-1",
          type: ENUM_FORM_LAYOUT_CONTAINER.SECTION,
          props: {
            title: "Service Information",
            description: "Pleaes select the service type",
            withBorder: false,
            mt: 0,
            ml: 20,
          },
        },
        {
          key: "section-checkList",
          parent: "t1-2",
          type: ENUM_FORM_LAYOUT_CONTAINER.SECTION,
          props: {
            title: "CheckList",
            withBorder: false,
            mt: 0,
            ml: 20,
          },
        },

        {
          key: "section-confirm",
          parent: "t1-7",
          type: ENUM_FORM_LAYOUT_CONTAINER.SECTION,
          props: {
            title: "Confirmation",
            description: "Please confirm the creation of order",
            withBorder: false,
            mt: 0,
            ml: 20,
          },
        },
      ],

      fields: [
        {
          name: "serviceType",
          component: RemoteSelect2,
          parent: "section-info",
          props: {
            label: "Type",
            apiEntity: "orderServiceType",
            required: true,
            placeholder: "Search Type",
            valueField: "_id",
            labelField: "name",
            allowClear: true,
            labelRender: (data) => `${data.name} ${data.cname}`,
            multiple: false,
            searchFields: ["name", "code", "cname"],
            clearable: true,
            onDataChange: (value, form) => {
              //Set checkList
              form.setFieldValue(
                "list",
                value?.checkList?.map((c) => ({ item: c, checked: false }))
              );
              form.setFieldValue("serviceTypeSnapShot", value);
              return;
            },
          },
        },
        {
          name: "receieveDate",
          parent: "section-info",
          component: DatePicker,
          type: "datePicker",
          props: {
            label: "Receive Date",
            required: true,
            zIndex: 1000,
            placeholder: "Pick Date",
            allowFreeInput: true,
          },
        },

        {
          name: "submissionDate",
          parent: "section-info",
          component: DatePicker,
          type: "datePicker",
          props: {
            label: "Submission Date",
            required: true,
            zIndex: 1000,
            placeholder: "Pick Date",
            allowFreeInput: true,
          },
        },
        {
          name: "description",
          parent: "section-info",
          component: Textarea,
          props: {
            label: "Description",
          },
        },
        {
          name: "checkList",
          parent: "section-checkList",
          component: ServiceCheckList,
          props: {},
        },
        {
          name: "confirm",
          component: FormConfirmation,
          parent: "section-confirm",
          props: {
            successText: [
              "Please click confirm to create the service.",
              "Please goto Service tab and print out the service cover sheet.",
            ],
          },
        },
      ],
    },
  };

  const printCoverSheetFormSchema = {
    name: "printSubmitCoverSheetFormSchema",
    title: "Print Order Service Cover Sheet",
    description: "Please follow the step to print the cover sheet",

    display: {
      mode: ENUM_FORM_DISPLAY.MODAL,
      size: "1000px", //xs, sm, xl  ....100%
    },

    apiEntity: "order",
    initialValues: {},

    validate: {},
    showSaveBar: false,

    layout: {
      containers: [
        {
          key: "t1",
          parent: null,
          type: ENUM_FORM_LAYOUT_CONTAINER.WIZARD,

          props: {
            variant: "default", //default | pills | outline
            defaultValue: "Service Info",
            orientation: "vertical",
            style: { minHeight: "400px" },
          },
          steps: [
            { key: "t1-1", label: "Service Info", icon: IconSettings },
            { key: "t1-2", label: "CheckList", icon: IconSettings },
            { key: "t1-7", label: "Confirmation", icon: IconSettings },
          ],
        },
        {
          key: "section-info",
          parent: "t1-1",
          type: ENUM_FORM_LAYOUT_CONTAINER.SECTION,
          props: {
            title: "Service Information",
            description: "Pleaes select the service type",
            withBorder: false,
            mt: 0,
            ml: 20,
          },
        },
        {
          key: "section-checkList",
          parent: "t1-2",
          type: ENUM_FORM_LAYOUT_CONTAINER.SECTION,
          props: {
            title: "CheckList",
            withBorder: false,
            mt: 0,
            ml: 20,
          },
        },

        {
          key: "section-confirm",
          parent: "t1-7",
          type: ENUM_FORM_LAYOUT_CONTAINER.SECTION,
          props: {
            title: "Confirmation",
            description: "Please confirm the creation of order",
            withBorder: false,
            mt: 0,
            ml: 20,
          },
        },
      ],

      fields: [
        {
          name: "serviceType",
          component: RemoteSelect2,
          parent: "section-info",
          props: {
            label: "Type",
            apiEntity: "orderServiceType",
            required: true,
            placeholder: "Search Type",
            valueField: "_id",
            labelField: "name",
            allowClear: true,
            labelRender: (data) => `${data.name} ${data.cname}`,
            multiple: false,
            searchFields: ["name", "code", "cname"],
            clearable: true,
            onDataChange: (value, form) => {
              //Set checkList
              form.setFieldValue(
                "list",
                value?.checkList?.map((c) => ({ item: c, checked: false }))
              );
              form.setFieldValue("serviceTypeSnapShot", value);
              return;
            },
          },
        },

        {
          name: "description",
          parent: "section-info",
          component: Textarea,
          props: {
            label: "Description",
          },
        },
        {
          name: "checkList",
          parent: "section-checkList",
          component: ServiceCheckList,
          props: {},
        },
        {
          name: "confirm",
          component: FormConfirmation,
          parent: "section-confirm",
          props: {
            successText: [
              "Please click confirm to create the service.",
              "Please goto Service tab and print out the service cover sheet.",
            ],
          },
        },
      ],
    },
  };

  useEffect(() => {
    setRows(services);
  }, [services]);

  const tableSchema = {
    hidePagination: true,
    columns: [
      // {   field: '_id',       headerName:"ID"            },
      {
        field: "status",
        headerName: "Status",
        cellRender: (col, data) => (
          <Group>
            {cellRender.Order.status(data.status)}
            {data.code}
          </Group>
        ),
      },
      {
        field: "serviceType",
        headerName: "Type",
        cellRender: (col, data) =>
          data.serviceType?.name ?? data.serviceTypeSnapShot?.name,
      },
      {
        field: "createdAt",
        headerName: "Create At",
        cellRender: (col, data) => moment(data.createdAt).format("YYYY-MM-DD"),
      },
      {
        field: "submissionDate",
        headerName: "Submit At",
        cellRender: (col, data) =>
          data.submissionDate
            ? moment(data.submissionDate).format("YYYY-MM-DD")
            : "",
      },
      {
        field: "action",
        cellRender: ActionCellRender,
        isAction: true,
        actions: [
          // {
          //   name: "edit",
          //   icon: IconPencil,
          //   props: {
          //     //   color:"red"
          //   },
          // },
        ],
        actionMenu:
          role === "CONSULTANT"
            ? [
                {
                  name: "printCoverSheet",
                  label: "Print Cover Sheet",

                  props: {
                    size: "xs",
                  },
                },
                {
                  name: "cancel",
                  label: "Cancel Service",
                  props: {
                    color: "red",
                    size: "xs",
                  },
                },
              ]
            : [
                {
                  name: "printCoverSheet",
                  label: "Print Cover Sheet",
                  props: {
                    size: "xs",
                  },
                },
                {
                  name: "receive",
                  label: "Mark As Received",
                  props: {
                    size: "xs",
                  },
                },
                {
                  name: "printSubmitCoverSheet",
                  label: "Print Submit Cover Sheet",
                  props: {
                    size: "xs",
                  },
                },

                // {
                //   name: "submit",
                //   label: "Mark As Submitted",
                //   props: {
                //     size: "xs",
                //   },
                // },
                {
                  name: "cancel",
                  label: "Cancel Service",
                  props: {
                    color: "red",
                    size: "xs",
                  },
                },
              ],
      },
    ],
  };

  const changeServiceStatus = async (service, status) => {
    try {
      const result = await api.updateById({
        apiEntity: "orderService",
        values: {
          status,
        },
        id: service._id,
      });

      const r = rows.find((r) => r._id === service._id);

      r.status = status;
      setRows([...rows]);
    } catch (error) {}
  };

  const handleActionBtnClick = ({ action, data, rowIndex }) => {
    console.log("handleActionBtnClick", action, data, rowIndex);

    switch (action) {
      case "cancel":
        return changeServiceStatus(data, "CAN");
      case "receive":
        return changeServiceStatus(data, "RCV");
      case "submit":
        return changeServiceStatus(data, "SUB");
      case "printCoverSheet":
        return printServiceCoverSheet(data, true);
      case "printSubmitCoverSheet":
        return printServiceCoverSheet(data, false);
    }
  };

  const onAdded = (data) => {
    // console.log("OnAdded", data);
    const newRows = [...rows, data.orderService];
    setRows(newRows);
    mainForm.setFieldValue("services", newRows);
  };

  const onPrintSubmissionCoverSheetSubmit = async ({ values, formMode }) => {
    try {
      // console.log(values);
      submitFormAction.close();
      formAction.close();

      setPrinting(true);
      const result = await api.OrderService.printSubmitCoverSheet(values);
      setPrinting(false);

      // console.log("resultxx", result.service);

      if (result.service) {
        const r = rows.find((r) => r._id === result.service._id);
        r.status = result.service.status;
        r.checkList = result.service.checkList;
        r.submissionDate = result.service.submissionDate;
        r.receieveDate = result.service.receieveDate;
        setRows([...rows]);
        // console.log(rows, result.service);
      }

      if (result.url) {
        window.open(result.url, "_blank");
      }
    } catch (error) {
      setPrinting(false);
    }
  };

  const [
    renderSubmitCoverSheetForm,
    submitFormAction,
    submitFormStatus,
    submitFormSetting,
  ] = useFormRender(
    printSubmitCoverSheetFormSchema,
    null,
    onPrintSubmissionCoverSheetSubmit
  );

  const [renderCoverSheetForm, formAction, formStatus, formSetting] =
    useFormRender(
      printCoverSheetFormSchema,
      null,
      onPrintSubmissionCoverSheetSubmit
    );

  const printServiceCoverSheet = async (service, internal = true) => {
    // console.log("printServiceCoverSheet", service, internal);
    service.internal = internal;
    if (internal) {
      formAction.open({
        mode: "edit",
        id: mainForm.values._id,
        data: service,
      });
    }

    if (!internal) {
      submitFormAction.open({
        mode: "edit",
        id: mainForm.values._id,
        data: service,
      });
    }
  };

  const { t } = useTranslation();

  return (
    <>
      {/* <ReactJson src={rows} collapsed style={{ background: "white" }} /> */}
      {/* <ReactJson src={auth()} collapsed style={{ background: "white" }} /> */}
      {/* Role: {role} */}
      {_.isEmpty(rows) && (
        <Group mb="xl">
          <Text size="xs">{t("No Services records found")}</Text>
        </Group>
      )}
      {!_.isEmpty(rows) && (
        <DataTable
          data={rows ?? []}
          columns={tableSchema.columns}
          hidePagination={true}
          hideSearchBar={true}
          // onRowDoubleClick={handleOnEdit}
          onActionBtnClick={handleActionBtnClick}
        />
      )}
      {!printing && (
        <OrderAddServiceButton mainForm={mainForm} onAdded={onAdded} />
      )}
      {printing && (
        <Group spacing={"xs"}>
          <Loader size={"xs"} /> <Text size="xs"> Printing</Text>
        </Group>
      )}
      {renderSubmitCoverSheetForm()}
      {renderCoverSheetForm()}
    </>
  );
};

export default OrderServiceList;
