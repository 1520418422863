import React, { useState, useEffect } from "react";
import _ from "lodash";
import {
  Button,
  TextInput,
  Grid,
  Table,
  Text,
  ActionIcon,
  Group,
  Textarea,
} from "@mantine/core";
import ReactJson from "react-json-view";
import { useForceUpdate } from "@mantine/hooks";
import {
  IconCirclePlus,
  IconExchange,
  IconMultiplier1x,
  IconTrash,
} from "@tabler/icons";
import useDeepCompareEffect from "../hooks/useDeepCompareEffect";
import { Component } from "ag-grid-community";
import ArrayTable from "./arrayTable";
import TemplateSaveLoadButtons from "./templateSaveLoadButtons";
const QuotationDescription = ({ form, name }) => {
  const description = _.get(form.values, name) || [];
  const rowInitValue = {
    title: "",
    detail: "",
  };

  const fields = [
    {
      header: "Title",
      headerWidth: "20%",
      fieldName: "title",
      component: TextInput,
      props: {},
    },
    {
      header: "Detail",
      headerWidth: "65%",
      fieldName: "detail",
      component: Textarea,
      props: {
        minRows: 1,
        autosize: true,
        py: 0,
      },
    },
  ];

  const loadFromTemplate = (template) => {
    form.setFieldValue(name, template.content ?? []);
  };

  return (
    <>
      <TemplateSaveLoadButtons
        form={form}
        name={name}
        templateCategory="Quotation Description"
        onLoadTemplate={loadFromTemplate}
        currentContent={description}
        mb={"sm"}
      />

      <ArrayTable
        form={form}
        name={name}
        rowInitValue={rowInitValue}
        fields={fields}
      />
    </>
  );
};

export default QuotationDescription;
