import React, { useState, useEffect } from "react";
import ActionCellRender from "../../components/actionCellRender";
import {
  IconTrash,
  IconPencil,
  IconSettings,
  IconAlertTriangle,
  IconCircleCheck,
} from "@tabler/icons";
import {
  PageList,
  ENUM_FORM_DISPLAY,
  ENUM_FORM_LAYOUT_CONTAINER,
} from "../../components/pageList";
import { useCellRender } from "../../hooks/useCellRender";
import OrderPageStats from "../../components/orderPageStats";
import FileList from "../../components/fileList";
import ActivityLog from "../../components/activityLog.js";
import RemoteSelect2 from "../../components/remoteSelect2";
import FollowupActionTable from "../../components/followupActionTable.js";
import ClientRiskStats from "../../components/clientRiskStats";
import ClientAMLList from "../../components/clientAMLList";
import ClientRPQList from "../../components/clientRPQList";
import OrderApplicantDetail from "../../components/orderApplicantDetail";
import OrderSummary from "../../components/orderSummary";
import OrderPolicy from "../../components/orderPolicy";
import OrderPaymentDetail from "../../components/orderPaymentDetail";
import OrderProductDetail from "../../components/orderProductDetail";
import OrderConsultantList from "../../components/orderConsultantList";
import OrderRiderList from "../../components/orderRiderList";
import OrderServiceList from "../../components/orderServiceList";
import OrderRelatedPersonDetail from "../../components/orderRelatedPersonDetail";
import OrderInsuredDetail from "../../components/orderInsuredDetail";
import OrderActionList from "../../components/orderActionList";
import { DATA_ORDER_TYPE, DATA_COUNTRY } from "../../data/options";
import Chips from "../../components/chips";
import { useNavigate } from "react-router-dom";

import ConsultantList from "../../components/consultantList";
import OrderPaymentDetailForm from "../../components/orderPaymentDetailForm";
import OrderProductVariation from "../../components/orderProductVariation";
import ProductSelection from "../../components/productSelection";
import OrderQuotationList from "../../components/orderQuotationList";
import OrderInvoiceList from "../../components/orderInvoiceList";
import OrderCommissionPaymentList from "../../components/orderCommissionPaymentList";
import OrderProfolio from "../../components/orderProfolio";
import FormConfirmation from "../../components/formConfirmation";
import { useAccessRight } from "../../hooks/useAccessRight";

import {
  Select,
  Checkbox,
  TextInput,
  Switch,
  Text,
  List,
  ThemeIcon,
  Group,
  Badge,
} from "@mantine/core";
import _ from "lodash";
import { DatePicker } from "@mantine/dates";
import { useAuthUser } from "react-auth-kit";
import OrderBeneficiaryList from "../../components/orderBeneficiaryList";
import OrderInsuredList from "../../components/orderInsuredList";
import ConsultantSelect from "../../components/consultantSelect";
import OrderClientSelect from "../../components/orderClientSelect";
import OrderAddressSelect from "../../components/orderAddressSelect";
import moment from "moment";
import RemarkList from "../../components/remarkList";
import { useServerApi } from "../../hooks/userServerApi";
import DummyWidget from "../../components/dummyWidget";
import OrderTimeline from "../../components/orderTimeline";
import OrderPendingCase from "../../components/orderPendingCase";
import OrderAlert from "../../components/orderAlert";
import { useTranslation } from "react-i18next";

const apiEntity = "order";
const initialValues = {
  type: "NEW",
  name: "",
  payment: {},
  termination: {},
  signedPlace: "Hong Kong",
  consultants: [],
  signedDate: "", //moment().format("YYYY-MM-DD"),
};

export const notAllowAddFormSchema = {
  name: "notAllowAddOrderSchema",
  title: "Notification",
  description: "Sorry. You are not allowed to create new order anymore.",

  display: {
    mode: ENUM_FORM_DISPLAY.MODAL,
    size: "1000px", //xs, sm, xl  ....100%
  },

  apiEntity,
  initialValues,
  validate: {},
  showSaveBar: false,
  layout: {
    containers: [
      {
        key: "section-notice",
        parent: null,
        type: ENUM_FORM_LAYOUT_CONTAINER.SECTION,
        props: {
          title: "",
          description: "You are not allowed to create order.",
          withBorder: false,
          mt: 0,
          ml: 20,
        },
      },
    ],

    fields: [
      {
        name: "notification",
        component: DummyWidget,
        parent: "section-notice",
        props: {
          dummyRender: (formValues) => (
            <>
              <List mt={"xl"} spacing="xs" size="sm" center>
                <List.Item>
                  Sorry. You are not allowed to create new order now.
                </List.Item>
                <List.Item>
                  It is probably the CPD doesn't meet the requirement.
                </List.Item>
                <List.Item>
                  Please contact management to resolve the issue.
                </List.Item>
              </List>
            </>
          ),
        },
      },
    ],
  },
};
export const addFormSchema = {
  name: "AddOrderSchema",
  title: "New Order",
  description: "Please follow the step to create a New Order",

  display: {
    mode: ENUM_FORM_DISPLAY.MODAL,
    size: "1000px", //xs, sm, xl  ....100%
  },

  apiEntity,
  initialValues,

  validate: {
    client: (value) => (!value ? "Please select Client" : null),
    product: (value) => (!value ? "Please select Product" : null),
    consultants: (value) => {
      if (_.isEmpty(value)) return "Please select Consultant";

      return null;
    },
    // "payment.premium": (value) =>
    // 	value <= 0 || !value ? "Premium must > 0" : null,
    // "payment.currency": (value) =>
    // 	_.isEmpty(value) ? "Currency is empty" : null,
    // "payment.payMode": (value) =>
    // 	_.isEmpty(value) ? "Please select Payment Mode" : null,
    // "payment.paymentTerm": (value) =>
    // 	value <= 0 || !value ? "Please select Payment Term" : null,
    signedDate: (value) => {
      if (value && moment(value).isAfter(moment()))
        return "Please make sure Signed Date is before today.";
      return null;
    },

    error: (value) => {
      // console.log("error", value);
      return value;
    },
  },
  showSaveBar: false,

  layout: {
    containers: [
      {
        key: "t1",
        parent: null,
        type: ENUM_FORM_LAYOUT_CONTAINER.WIZARD,

        props: {
          variant: "default", //default | pills | outline
          defaultValue: "Order Type",
          orientation: "vertical",
          style: { minHeight: "400px" },
        },
        steps: [
          { key: "t1-1", label: "Order Type", icon: IconSettings },
          { key: "t1-10", label: "Policy", icon: IconSettings },

          { key: "t1-2", label: "Client", icon: IconSettings },
          { key: "t1-9", label: "Insured", icon: IconSettings },
          { key: "t1-4", label: "Consultants", icon: IconSettings },

          { key: "t1-3", label: "Product", icon: IconSettings },
          {
            key: "t1-8",
            label: "Product Variation",
            icon: IconSettings,
          },
          { key: "t1-5", label: "Sign Detail", icon: IconSettings },
          {
            key: "t1-6",
            label: "Payment Details",
            icon: IconSettings,
          },
          { key: "t1-7", label: "Confirmation", icon: IconSettings },
        ],
      },
      {
        key: "section-type",
        parent: "t1-1",
        type: ENUM_FORM_LAYOUT_CONTAINER.SECTION,
        props: {
          title: "Order Types",
          description: "Pleaes select the order type",
          withBorder: false,
          mt: 0,
          ml: 20,
        },
      },
      {
        key: "section-policy",
        parent: "t1-10",
        type: ENUM_FORM_LAYOUT_CONTAINER.SECTION,
        props: {
          title: "Policy",
          description: "",
          withBorder: false,
          mt: 0,
          ml: 20,
        },
      },
      {
        key: "section-client",
        parent: "t1-2",
        type: ENUM_FORM_LAYOUT_CONTAINER.SECTION,
        props: {
          title: "Client",
          description: "Pleaes select the client",
          withBorder: false,
          mt: 0,
          ml: 20,
        },
      },
      // {
      // 	key: "section-insured",
      // 	parent: "t1-9",
      // 	type: ENUM_FORM_LAYOUT_CONTAINER.SECTION,
      // 	props: {
      // 		title: "Insured",
      // 		description: "Pleaes fillin the insured details",
      // 		withBorder: false,
      // 		mt: 0,
      // 		ml: 20,
      // 	},
      // },
      {
        key: "section-consultant",
        parent: "t1-4",
        type: ENUM_FORM_LAYOUT_CONTAINER.SECTION,
        props: {
          title: "Consultants",
          description: "Please select the consultants",
          withBorder: false,
          mt: 0,
          ml: 20,
        },
      },

      {
        key: "section-product",
        parent: "t1-3",
        type: ENUM_FORM_LAYOUT_CONTAINER.SECTION,
        props: {
          title: "Product",
          description: "Please select the product",
          withBorder: false,
          mt: 0,
          ml: 20,
        },
      },

      {
        key: "section-variation",
        parent: "t1-8",
        type: ENUM_FORM_LAYOUT_CONTAINER.SECTION,
        props: {
          title: "Product Variation",
          description: "Please select the product variation",
          withBorder: false,
          mt: 0,
          ml: 20,
        },
      },

      {
        key: "section-sign",
        parent: "t1-5",
        type: ENUM_FORM_LAYOUT_CONTAINER.SECTION,
        props: {
          title: "Sign Person",
          description: "Please select consultant who sign the order",
          withBorder: false,
          mt: 0,
          ml: 20,
        },
      },
      {
        key: "section-payment",
        parent: "t1-6",
        type: ENUM_FORM_LAYOUT_CONTAINER.SECTION,
        props: {
          title: "Payment Details",
          description: "Please fillin the payment details",
          withBorder: false,
          mt: 0,
          ml: 20,
        },
      },
      {
        key: "section-insured",
        parent: "t1-9",
        type: ENUM_FORM_LAYOUT_CONTAINER.SECTION,
        props: {
          title: "Insured",
          description: "Please fillin the insured details",
          withBorder: false,
          mt: 0,
          ml: 20,
        },
      },
      {
        key: "section-confirm",
        parent: "t1-7",
        type: ENUM_FORM_LAYOUT_CONTAINER.SECTION,
        props: {
          title: "Confirmation",
          description: "Please confirm the creation of order",
          withBorder: false,
          mt: 0,
          ml: 20,
        },
      },

      {
        key: "payment-g",
        parent: "section-payment-details",
        type: ENUM_FORM_LAYOUT_CONTAINER.GRID,
        cols: [
          {
            key: "payment-g1",
            props: {
              span: 6,
            },
          },
          {
            key: "payment-g2",
            props: {
              span: 6,
            },
          },
        ],
      },
    ],

    fields: [
      {
        name: "type",
        component: Chips,
        parent: "section-type",
        props: {
          required: true,
          // label: "Order Types",
          labelOrder: 5,
          // description: "Please Select the type of order.",
          items: DATA_ORDER_TYPE,
          defaultValue: "NEW",
          multiple: false,
        },
      },
      {
        name: "policyNumber",
        component: TextInput,
        parent: "section-policy",
        props: {
          // required: true,
          label: "Policy Number",
        },
      },

      {
        name: "client",
        component: RemoteSelect2,
        parent: "section-client",
        props: {
          apiEntity: "client",
          required: true,
          placeholder: "Search Client By Name / HKID/ BR ",
          valueField: "_id",
          labelField: "name",
          allowClear: true,
          labelRender: (data) =>
            `${data.name} ${data.cname} [ ${data.id ?? " -- "} ]`,
          //   labelRender: (data) => `${data.cname}`,
          multiple: false,
          // preQuery: { $and: [ {isConsultant : true}, {isActive: true} ] },
          // preQuery:  {isConsultant : true} ,
          searchFields: ["name", "id", "cname"],
          clearable: true,
          onDataChange: (value, form) => {
            // console.log("Client Change", value);
            //Set Consultant
            if (!value || _.isEmpty(value.consultants))
              form.setFieldValue("consultants", []);
            else {
              form.setFieldValue(
                "consultants",
                value.consultants.map((c) => c._id)
              );
            }
            //Set Clientsnap shot for search
            form.setFieldValue("clientSnapShot", value ?? {});
          },
          sort: {
            by: "name",
            order: "asc",
          },
        },
      },
      {
        name: "client2",
        component: OrderClientSelect,
        parent: "section-client",
        props: {},
      },

      {
        name: "product",
        component: ProductSelection,
        parent: "section-product",
        props: {},
      },

      {
        name: "consultants",
        component: RemoteSelect2,
        parent: "section-consultant",
        props: {
          apiEntity: "user",
          required: true,
          // label: "Consultant",
          placeholder: "Please select consultant(s)",
          valueField: "_id",
          labelField: "name",
          labelRender: (data) =>
            `${data.name} ${data.aliasName ? `,${data.aliasName}` : ""}  ${
              data.cname
            } (${data.staffId ?? " -- "})`,
          multiple: true,
          preQuery: {
            $and: [{ isConsultant: true }, { isActive: true }],
          },
          pageSize: 2000,
          searchFields: [
            "aliasName",
            "name",
            "staffId",
            "cname",
            "email",
            "phone",
          ],
          sort: {
            by: "name",
            order: "asc",
          },
        },
      },
      {
        name: "consultantList",
        component: ConsultantList,
        parent: "section-consultant",
        props: {
          field: "consultants",
        },
      },

      {
        name: "signedDate",
        component: DatePicker,
        parent: "section-sign",
        type: "datePicker",
        props: {
          required: true,
          label: "Sign Date",
          zIndex: 1000,
          // placeholder: "Pick Date",
          allowFreeInput: true,
        },
      },

      {
        name: "signedPlace",
        component: Select,
        parent: "section-sign",
        props: {
          required: true,
          label: "Sign Place",
          placeholder: "",
          data: DATA_COUNTRY,
          defaultValue: "Hong Kong",
          searchable: true,
        },
      },
      {
        name: "signedBy",
        component: RemoteSelect2,
        parent: "section-sign",
        props: {
          apiEntity: "user",
          required: true,
          label: "Sign By",
          placeholder: "Please select consultant who sign the order",
          valueField: "_id",
          labelField: "name",
          labelRender: (data) =>
            `${data.name} ${data.aliasName ? `,${data.aliasName}` : ""} (${
              data.staffId ?? " -- "
            })`,
          multiple: false,
          preQuery: {
            $and: [{ isConsultant: true }, { status: "ACTIVE" }],
          },
          searchFields: ["name", "staffId", "cname", "email", "aliasName"],
          sort: {
            by: "name",
            order: "asc",
          },
        },
      },
      {
        name: "signedBy2",
        component: RemoteSelect2,
        parent: "section-sign",
        props: {
          apiEntity: "user",
          required: false,
          label: "Sign By (2nd)",
          placeholder: "Please select consultant who sign the order",
          valueField: "_id",
          labelField: "name",
          labelRender: (data) =>
            `${data.name}, ${data.aliasName} (${data.staffId ?? " -- "})`,
          multiple: false,
          preQuery: {
            $and: [{ isConsultant: true }, { status: "ACTIVE" }],
          },
          searchFields: ["name", "staffId", "cname", "email", "aliasName"],
          sort: {
            by: "name",
            order: "asc",
          },
        },
      },

      {
        name: "consultantList",
        component: ConsultantList,
        parent: "section-sign",
        props: {
          field: "signedBy",
        },
      },
      {
        name: "hasNoPayment",
        component: Switch,
        parent: "section-payment",
        props: {
          label: "This order has no confirmed payment details (e.g. MPF)",
          mb: "xl",
          size: "xs",
        },
      },
      {
        name: "payment",
        component: OrderPaymentDetailForm,
        parent: "section-payment",
        props: {},
      },

      {
        name: "variation",
        component: OrderProductVariation,
        parent: "section-variation",
        props: {},
      },

      {
        name: "confirm",
        component: FormConfirmation,
        parent: "section-confirm",
        props: {},
      },
      {
        name: "insured",
        component: OrderInsuredList,
        parent: "section-insured",
        props: {},
      },
    ],
  },
};

export default function Order({ role = "MANAGEMENT" }) {
  const [cellRender] = useCellRender();
  const auth = useAuthUser();
  const [preQuery, setPreQuery] = useState();
  const navigate = useNavigate();
  const [api] = useServerApi();
  const userRoleName = auth().userRole?.name?.toUpperCase() ?? "";
  const currentURL = window.location.pathname;
  const [disableCreateOrder, setDisableCreateOrder] = useState(false);
  const [getAccessRight, accessRight] = useAccessRight();

  const editFormSchema = {
    display: {
      mode: ENUM_FORM_DISPLAY.LINK,
    },
    title: "Order",
    titleField: "code",
    apiEntity,
    initialValues,

    layout: {
      containers: [
        {
          key: "main-g",
          parent: null,
          type: ENUM_FORM_LAYOUT_CONTAINER.GRID,
          props: {
            gutter: "xl",
            columns: 24,
          },
          cols: [
            {
              key: "main-g1",
              props: {
                span: 7,
              },
            },
            {
              key: "main-g2",
              props: {
                span: 17,
              },
            },
          ],
        },
        {
          key: "section-applicant",
          parent: "main-g1",
          type: ENUM_FORM_LAYOUT_CONTAINER.SECTION,
          props: {
            title: "Applicant Details",
            // titleOrder: 5,
            // description: "Please assign the corresponding consultant",
            withBorder: false,
            mt: 0,
            mb: "md",
          },
        },

        {
          key: "section-consultant",
          parent: "main-g1",
          type: ENUM_FORM_LAYOUT_CONTAINER.BOX,
          props: {
            mt: 0,
            mb: 0,
          },
        },

        {
          key: "section-action-list",
          parent: "main-g1",
          type: ENUM_FORM_LAYOUT_CONTAINER.SECTION,
          props: {
            title: "Action List",
            // titleOrder: 5,
            // description: "Please assign the corresponding consultant",
            withBorder: false,
            minHeight: "0px",
            mt: 0,
            mb: 20,
          },
        },

        {
          key: "main-tab",
          parent: "main-g2",
          type: ENUM_FORM_LAYOUT_CONTAINER.TABS,
          props: {
            variant: "default", //default | pills | outline
            defaultValue: "Overview",
          },
          tab: [
            {
              key: "tab-overview",
              label: "Overview",
              //icon: IconSettings,
            },
            {
              key: "tab-service",
              label: "Service",
              //icon: IconSettings,
            },
            {
              key: "tab-insured",
              label: "Insured",
              // icon: IconSettings,
            },
            {
              key: "tab-beneficiary",
              label: "Beneficiary",
              // icon: IconSettings,
            },
            {
              key: "tab-risk",
              label: "Risk Control",
              // icon: IconSettings,
            },
            {
              key: "tab-profolio",
              label: "Portfolio",
              // icon: IconSettings,
            },
            {
              key: "tab-files",
              label: "Files",
              //icon: IconSettings
            },
            // {
            //   key: "tab-documents",
            //   label: "Document",
            //   // icon: IconSettings,
            // },
            {
              key: "tab-commission",
              label: "Commission",
              showTab: () => {
                return role !== "CONSULTANT";
              },
              // icon: IconSettings,
            },
            {
              key: "tab-logs",
              label: "Logs",
              // icon: IconSettings
            },
          ],
        },

        {
          key: "section-alert",
          parent: "tab-overview",
          type: ENUM_FORM_LAYOUT_CONTAINER.EMPTY,
          props: {
            titleOrder: 5,
            // description: "Please assign the corresponding consultant",
            withBorder: false,
            minHeight: 0,

            mt: 20,
            mb: 20,
          },
        },
        {
          key: "section-summary",
          parent: "tab-overview",
          type: ENUM_FORM_LAYOUT_CONTAINER.SECTION,
          props: {
            title: "Order Summary",
            titleOrder: 5,
            // description: "Please assign the corresponding consultant",
            withBorder: false,
            minHeight: 0,
            mt: 0,
            mb: 20,
          },
        },
        {
          key: "section-service",
          parent: "tab-service",
          type: ENUM_FORM_LAYOUT_CONTAINER.SECTION,
          props: {
            title: "Order Services",
            titleOrder: 5,
            // description: "Please assign the corresponding consultant",
            withBorder: false,
            minHeight: 0,
            mt: 0,
            mb: 20,
          },
        },
        {
          key: "section-timeline",
          parent: "tab-overview",
          type: ENUM_FORM_LAYOUT_CONTAINER.SECTION,
          props: {
            title: "Order Timeline",
            titleOrder: 5,
            // description: "Please assign the corresponding consultant",
            withBorder: false,
            minHeight: 0,

            mt: 20,
            mb: 20,
          },
        },
        {
          key: "section-policy",
          parent: "tab-overview",
          type: ENUM_FORM_LAYOUT_CONTAINER.SECTION,
          props: {
            title: "Policy Info",
            titleOrder: 5,
            // description: "Please assign the corresponding consultant",
            withBorder: false,
            minHeight: 0,

            mt: 20,
            mb: 20,
          },
        },

        {
          key: "section-product",
          parent: "tab-overview",
          type: ENUM_FORM_LAYOUT_CONTAINER.SECTION,
          props: {
            title: "Product",
            titleOrder: 5,
            // description: "Please assign the corresponding consultant",
            withBorder: false,
            minHeight: 0,
            mt: 20,
            mb: 20,
          },
        },

        {
          key: "section-payment",
          parent: "tab-overview",
          type: ENUM_FORM_LAYOUT_CONTAINER.SECTION,
          props: {
            title: "Payment",
            titleOrder: 5,
            // description: "Please assign the corresponding consultant",
            withBorder: false,
            minHeight: 0,
            mt: 20,
            mb: 20,
          },
        },
        {
          key: "section-riders",
          parent: "tab-overview",
          type: ENUM_FORM_LAYOUT_CONTAINER.SECTION,
          props: {
            title: "Riders",
            titleOrder: 5,

            withBorder: false,
            minHeight: 0,
            mt: 20,
            mb: 20,
          },
        },
        {
          key: "section-pending",
          parent: "tab-overview",
          type: ENUM_FORM_LAYOUT_CONTAINER.SECTION,
          props: {
            title: "Pending Cases",
            titleOrder: 5,
            // description: "Please assign the corresponding consultant",
            withBorder: false,
            minHeight: 0,

            mt: 20,
            mb: 20,
          },
        },

        {
          key: "section-remarks",
          parent: "tab-overview",
          type: ENUM_FORM_LAYOUT_CONTAINER.SECTION,
          props: {
            title: "Order Remarks",
            titleOrder: 5,
            // description: "Please assign the corresponding consultant",
            withBorder: false,
            minHeight: 0,
            mt: 20,
            mb: 20,
          },
        },

        {
          key: "section-address",
          parent: "tab-overview",
          type: ENUM_FORM_LAYOUT_CONTAINER.SECTION,
          props: {
            title: "Order Address",
            titleOrder: 5,
            // description: "Please assign the corresponding consultant",
            withBorder: false,
            minHeight: 0,
            mt: 20,
            mb: 20,
          },
        },

        // {
        //   key: "section-followup",
        //   parent: "tab-overview",
        //   type: ENUM_FORM_LAYOUT_CONTAINER.SECTION,
        //   props: {
        //     title: "Followup Tickets",
        //     titleOrder: 5,
        //     // description: "Please assign the corresponding consultant",
        //     withBorder: false,
        //     minHeight: 0,

        //     mt: 20,
        //     mb: 20,
        //   },
        // },

        {
          key: "section-insured",
          parent: "tab-insured",
          type: ENUM_FORM_LAYOUT_CONTAINER.SECTION,
          props: {
            title: "Insured Details",
            titleOrder: 5,
            // description: "Please assign the corresponding consultant",
            withBorder: false,
            minHeight: 0,

            mt: 20,
            mb: 20,
          },
        },
        {
          key: "section-beneficiary",
          parent: "tab-beneficiary",
          type: ENUM_FORM_LAYOUT_CONTAINER.SECTION,
          props: {
            title: "Beneficiary Details",
            titleOrder: 5,
            // description: "Please assign the corresponding consultant",
            withBorder: false,
            minHeight: 0,

            mt: 20,
            mb: 20,
          },
        },

        {
          key: "section-risk-stats",
          parent: "tab-risk",
          type: ENUM_FORM_LAYOUT_CONTAINER.SECTION,
          props: {
            title: "Risk Summary",
            minHeight: "0px",
          },
        },
        {
          key: "section-risk-pep",
          parent: "tab-risk",
          type: ENUM_FORM_LAYOUT_CONTAINER.SECTION,
          props: {
            title: "PEP",
          },
        },

        {
          key: "section-risk-aml",
          parent: "tab-risk",
          type: ENUM_FORM_LAYOUT_CONTAINER.SECTION,
          props: {
            title: "AML",
            minHeight: "0px",
          },
        },
        {
          key: "section-risk-rpq",
          parent: "tab-risk",
          type: ENUM_FORM_LAYOUT_CONTAINER.SECTION,
          props: {
            title: "RPQ",
            minHeight: "0px",
          },
        },

        // {
        //   key: "section-quotation",
        //   parent: "tab-documents",
        //   type: ENUM_FORM_LAYOUT_CONTAINER.SECTION,
        //   props: {
        //     title: "Quotation",
        //     minHeight: "0px",
        //   },
        // },
        // {
        //   key: "section-invoice",
        //   parent: "tab-documents",
        //   type: ENUM_FORM_LAYOUT_CONTAINER.SECTION,
        //   props: {
        //     title: "Invoice",
        //     minHeight: "0px",
        //   },
        // },

        {
          key: "section-log",
          parent: "tab-logs",
          type: ENUM_FORM_LAYOUT_CONTAINER.SECTION,
          props: {
            title: "Order Log",
            titleOrder: 5,
            // description: "Please assign the corresponding consultant",
            withBorder: false,
            mt: 20,
            mb: 20,
          },
        },

        // {
        //     key: "section-profolio",
        //     parent: "tab-profolio",
        //     type: ENUM_FORM_LAYOUT_CONTAINER.SECTION,
        //     props: {
        //         // title: "Fund Profolio",
        //         titleOrder: 5,
        //         // description: "Please assign the corresponding consultant",
        //         withBorder: false,
        //         minHeight: "0px",
        //         // mt: 20,
        //         mb: 20,
        //     },
        // },

        {
          key: "section-files",
          parent: "tab-files",
          type: ENUM_FORM_LAYOUT_CONTAINER.SECTION,
          props: {
            title: "Files",
            titleOrder: 5,
            // description: "Please assign the corresponding consultant",
            withBorder: false,
            minHeight: "0px",
            mt: 20,
            mb: 20,
          },
        },

        {
          key: "section-commission-payment",
          parent: "tab-commission",
          type: ENUM_FORM_LAYOUT_CONTAINER.SECTION,
          props: {
            title: "Commission Payments",
            titleOrder: 5,
            // description: "Please assign the corresponding consultant",
            withBorder: false,
            minHeight: "0px",
            mt: 20,
            mb: 20,
          },
        },
      ],

      fields: [
        {
          name: "files",
          component: FileList,
          parent: "section-files",
          props: {
            disabled: accessRight !== 15,
          },
        },

        {
          name: "log",
          component: ActivityLog,
          parent: "section-log",
          props: {},
        },

        // {
        //   name: "followup",
        //   component: FollowupActionTable,
        //   parent: "section-followup",
        //   props: {},
        // },

        {
          name: "pendingCases",
          component: OrderPendingCase,
          parent: "section-pending",
          props: {},
        },
        {
          name: "client",
          component: OrderApplicantDetail,
          parent: "section-applicant",
          props: {},
        },

        {
          name: "orderRemarks",
          component: RemarkList,
          parent: "section-remarks",
          props: {
            scrollHeight: 100,
            disabled: accessRight !== 15,
          },
        },

        {
          name: "riders",
          component: OrderRiderList,
          parent: "section-riders",
          props: {},
        },

        {
          name: "services",
          component: OrderServiceList,
          parent: "section-service",
          props: {},
        },
        {
          name: "riskStats",
          component: ClientRiskStats,
          parent: "section-risk-stats",
          props: {
            clientIDField: "client._id",
          },
        },

        {
          name: "riskAML",
          component: ClientAMLList,
          parent: "section-risk-aml",
          props: {
            clientIDField: "client._id",
          },
        },

        {
          name: "riskRPQ",
          component: ClientRPQList,
          parent: "section-risk-rpq",
          props: {
            clientIDField: "client._id",
          },
        },
        {
          name: "client?.pep?.isPep",
          component: Checkbox,
          parent: "section-risk-pep",
          props: {
            label: "is Political Exposure Person? (PEP)",
            type: "checkbox",
            variant: "filled",
            disabled: true,
          },
        },
        {
          name: "client?.pep?.remark",
          component: TextInput,
          parent: "section-risk-pep",
          props: {
            label: "Remark",
            variant: "filled",
            disabled: true,
          },
        },

        // {
        //   name: "quotation",
        //   component: OrderQuotationList,
        //   parent: "section-quotation",
        //   props: {
        //     clientIDField: "client._id",
        //   },
        // },

        // {
        //   name: "riskRPQ",
        //   component: OrderInvoiceList,
        //   parent: "section-invoice",
        //   props: {
        //     clientIDField: "client._id",
        //   },
        // },
        {
          name: "alert",
          component: OrderAlert,
          parent: "section-alert",
          props: {
            mt: 0,
            mb: 0,
            p: 0,
          },
        },
        {
          component: OrderSummary,
          parent: "section-summary",
          props: {},
        },
        {
          component: OrderTimeline,
          parent: "section-timeline",
          props: {},
        },

        {
          name: "profolio",
          component: OrderProfolio,
          parent: "tab-profolio",
          props: {},
        },

        {
          component: OrderPolicy,
          parent: "section-policy",
          props: {},
        },

        {
          component: OrderProductDetail,
          parent: "section-product",
          props: {},
        },
        {
          component: OrderPaymentDetail,
          parent: "section-payment",
          props: {},
        },

        {
          name: "address",
          component: OrderAddressSelect,
          parent: "section-address",
          props: {
            disabled: accessRight !== 15,
          },
        },

        {
          name: "consultants",
          component: OrderConsultantList,
          parent: "section-consultant",
          props: {},
        },

        {
          name: "insured",
          component: OrderInsuredList,
          parent: "section-insured",
          props: {},
        },
        {
          name: "beneficiary",
          component: OrderBeneficiaryList,
          parent: "section-beneficiary",
          props: {},
        },

        {
          name: "orderAction",
          component: OrderActionList,
          parent: "section-action-list",
          props: {
            role: userRoleName,
          },
        },

        {
          name: "commissionPayments",
          component: OrderCommissionPaymentList,
          parent: "section-commission-payment",
          props: {},
        },
      ],
    },
  };
  const fetchUser = async () => {
    try {
      if (!auth || !auth().id) return;
      const user = await api.getById({ apiEntity: "user", id: auth().id });
      setDisableCreateOrder(user.disableCreateOrder);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (
      userRoleName != "GI OPERATION" &&
      userRoleName != "OPERATION" &&
      userRoleName != "ACCOUNTING" &&
      userRoleName != "MANAGEMENT"
    ) {
      if (currentURL.includes("operation"))
        navigate(currentURL.replace("operation", "consultant"));

      if (currentURL.includes("management"))
        navigate(currentURL.replace("management", "consultant"));
    }
  }, [userRoleName, currentURL]);

  useEffect(() => {
    fetchUser();
  }, [auth]);

  const actionList = editFormSchema.layout.fields.find(
    (f) => f.name === "orderAction"
  );

  const reviseSchemaForConsultant = () => {
    // console.log("Revise Schema for consultant", role, auth());
    //Consultant
    const q = { consultants: auth().id };
    setPreQuery(q);
    initialValues.consultants = [auth().id];
    initialValues.signedBy = auth().id;

    //Clients
    const clientField = addFormSchema.layout.fields.find(
      (f) => f.name === "client"
    );
    clientField.props.preQuery = { consultants: auth().id };

    //Set Action List
    actionList.props.role = role;

    const tabLayout = editFormSchema.layout.containers.find(
      (c) => c.key === "main-tab"
    );
    tabLayout.tab = tabLayout.tab.filter((t) => t.key != "tab-commission");
    // console.log("Tab Layout", tabLayout);
  };

  useEffect(() => {
    switch (role) {
      case "CONSULTANT":
        reviseSchemaForConsultant();
        break;

      case "CHANNEL":
        reviseSchemaForConsultant();
        break;

      case "OPERATION":
        setPreQuery({ isGI: { $in: [null, false] } });
        // initialValues.signedBy = auth().id;
        //Set Action List
        actionList.props.role = role;

        break;

      case "ACCOUNT":
        setPreQuery({});
        // initialValues.signedBy = auth().id;
        //Set Action List
        actionList.props.role = "OPERATION";
        break;
      case "MANAGEMENT":
        setPreQuery({});
        // initialValues.signedBy = auth().id;
        //Set Action List
        actionList.props.role = "OPERATION";
        break;

      case "GI-OPERATION":
        setPreQuery({ isGI: true });
        // setPreQuery({});

        // initialValues.signedBy = auth().id;
        //Set Action List
        actionList.props.role = role;

        break;
    }
  }, [role]);

  const tableSchema = {
    showActionColumn: true,
    // preQuery,
    select: [
      "code product policyNumber client consultants status createdAt signedAt type pendingCases",
    ],
    columns: [
      {
        field: "code",
        headerName: "Order",
        sortable: true,
        cellRender: (col, order) => cellRender.Order.code(order),
        filterable: true,
        filter: {
          apiEntity: "order",
          field: "status",
          label: "Order Status",
          width: 250,
          height: 300,
        },
      },

      {
        field: "pendingCases",
        headerName: "Pending",
        sortable: true,
        cellRender: (col, order) => {
          const pendingCases =
            order.pendingCases?.filter((c) => !c.completed) ?? [];
          const completedCases =
            order.pendingCases?.filter((c) => c.completed) ?? [];
          return _.isEmpty(pendingCases) ? (
            _.isEmpty(completedCases) ? (
              ""
            ) : (
              <Badge color="green" radius={0} size="xs">
                <Group spacing={1} noWrap>
                  <IconCircleCheck size={15} />
                  {`${completedCases?.length} DONE`}
                </Group>
              </Badge>
            )
          ) : (
            <Badge color="red" radius={0} size="xs">
              <Group spacing={1} noWrap>
                <IconAlertTriangle size={15} />
                {`${pendingCases?.length} PENDING`}
              </Group>
            </Badge>
          );
        },
      },

      {
        field: "product",
        headerName: "Product",
        sortable: true,
        cellRender: (col, data) => cellRender.ProductInfo(data.product),
        filterable: true,
        filter: {
          apiEntity: "order",
          field: "productCategory",
          label: "Product Category",
          populate: {
            model: "ProductCategory",
            select: "name cname code shortName",
            labelField: "name",
            sort: { code: 1 },
          },

          width: 250,
          height: 300,
        },
      },
      {
        field: "policyNumber",
        headerName: "Policy No.",
        sortable: true,

        cellRender: (col, data) => <>{data.policyNumber}</>,
      },
      {
        field: "client",
        sortable: true,
        cellRender: (col, data) => cellRender.ClientInfo(data.client, true),
      },
      {
        field: "consultants",
        headerName: "Consultants",
        sortable: true,
        cellRender: (col, data) => cellRender.Users(data.consultants, 3, true),
      },

      // {   field: 'followup', sortable: true,   },
      // {
      // 	field: "status",
      // 	sortable: true,
      // 	cellRender: (col, data) => cellRender.Status(data.status),
      // },

      {
        field: "action",
        cellRender: ActionCellRender,
        isAction: true,
        actions: [
          {
            name: "edit",
            icon: IconPencil,
            props: {
              //   color:"red"
            },
          },
        ],
        actionMenu: [
          // {
          //   name: "delete",
          //   label: "Delete data",
          //   icon: IconTrash,
          //   props: {
          //     color: "red",
          //   },
          // },
        ],
      },
    ],

    searchableFields: [
      "code",
      "policyNumber",
      "status",
      // "client.name",
      // "client.cname",
      // "client.id",
      // "client.email",
      // "product.code",
      // "product.name",
      // "product.cname",
      // "consultants.staffId",
      // "consultants.name",
      // "consultants.aliasName",

      "clientSnapShot.name",
      "clientSnapShot.cname",
      "clientSnapShot.id",
      "clientSnapShot.email",
      "clientSnapShot.phone",
      "productSnapShot.code",
      "productSnapShot.name",
      "productSnapShot.cname",
      // "productSnapShot.category",
    ],
  };
  const formSchema = {
    // add: addFormSchema,
    add: disableCreateOrder ? notAllowAddFormSchema : addFormSchema,
    edit: editFormSchema,
  };

  return (
    <>
      {/* role: {role} */}
      {preQuery && (
        <>
          <PageList
            title={"Orders"}
            apiEntity={apiEntity}
            tableSchema={tableSchema}
            formSchema={formSchema}
            showImport={false}
            initSort={{ by: "code", order: "desc" }}
            searchByTextField={false}
            preQuery={preQuery}
            // preQueryNotEmpty={true}
            stats={{
              component: OrderPageStats,
              props: {
                mb: 35,
                role,
              },
            }}
          />
        </>
      )}
    </>
  );
}
