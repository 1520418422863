import React from "react";
import InfoGridList from "./infoGridList";
import { useCellRender } from "../hooks/useCellRender";
import { DATA_ORDER_STATUS } from "../data/options";
import { Alert, Group } from "@mantine/core";
import { IconAlertCircle } from "@tabler/icons";
import _ from "lodash";
import moment from "moment";
import { useTranslation } from "react-i18next";

export default function OrderServiceSummary({ form }) {
  const [cellRender] = useCellRender();
  const service = form.values;
  const dataFontWeight = 600;
  const status = DATA_ORDER_STATUS.find((s) => s.value === service?.status);
  const { t } = useTranslation();
  const fields = [
    {
      title: "Service Code",
      value: <Group>{service?.code}</Group>,
    },
    {
      title: "Service Type",
      value: (
        <Group>
          {service?.serviceType?.name} {service?.serviceType?.cname}
        </Group>
      ),
    },
    {
      title: "Status",
      value: status ? (
        <Group>
          {cellRender.Order.status(service?.status)}
          {t(status?.label)}
        </Group>
      ) : (
        "Unknown"
      ),
    },
    {
      title: "Created At",
      value: <Group>{moment(service?.createdAt).format("YYYY-MM-DD")}</Group>,
    },

    {
      title: "Received At",
      value: (
        <Group>{moment(service?.receieveDate).format("YYYY-MM-DD")}</Group>
      ),
    },

    {
      title: "Submitted At",
      value: (
        <Group>{moment(service?.submissionDate).format("YYYY-MM-DD")}</Group>
      ),
    },

    {
      title: "Completed At",
      value: (
        <Group>{moment(service?.completionDate).format("YYYY-MM-DD")}</Group>
      ),
    },
    {
      title: "Description",
      value: <Group>{service?.description}</Group>,
    },
  ];

  return <InfoGridList fields={fields} values={service} />;
}
