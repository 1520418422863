import _ from "lodash";
import { Text, Title, Divider, Group, ThemeIcon, Badge } from "@mantine/core";
import React from "react";
import moment from "moment";
import InfoGridList from "./infoGridList";
import { useCellRender } from "../hooks/useCellRender";

import { IconClick, IconCash, IconHeart } from "@tabler/icons";
import { useTranslation } from "react-i18next";
export default function ClientBasicInfo({ form }) {
  const [cellRender] = useCellRender();
  const client = form.values;
  const dataFontWeight = 600;
  const { t } = useTranslation();
  const emptyStr = "-";

  const fields = [
    { title: "Name", value: client.name ?? emptyStr },
    { title: "Chinese Name", value: client.cname ?? emptyStr },
    { title: "Client Type", value: t(client.type) ?? emptyStr },
    { title: "Gender", value: t(client.gender) ?? emptyStr },
    { title: "ID Type", value: t(client.idType) ?? emptyStr },
    { title: "ID No.", value: client.id ?? emptyStr },

    { title: "Contact Number", value: client.phone ?? emptyStr },
    { title: "Email", value: client.email ?? emptyStr },

    {
      title: "Date of Birth",
      value: cellRender.Date(client.birthday, "YYYY-MM-DD") ?? emptyStr,
    },
    { title: "Place of Birth", value: t(client.placeOfBirth) ?? emptyStr },
    { title: "Nationality", value: t(client.nationality) ?? emptyStr },

    { title: "Marital Status", value: t(client.maritalStatus) ?? emptyStr },
    { title: "Education", value: t(client.educationLevel) ?? emptyStr },
    { title: "Year Salary", value: client.yearlySalary ?? emptyStr },

    { title: "Status", value: t(client.status) ?? emptyStr },
    { title: "Code", value: client.code ?? emptyStr },

    {
      title: "Created At",
      value: cellRender.Date(client.createdAt, "YYYY-MM-DD", dataFontWeight),
    },
  ];
  return <InfoGridList fields={fields} values={client} />;
}
