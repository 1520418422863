export const DATA_CURRENCY = [
  { value: "HKD", label: "HKD" },
  { value: "USD", label: "USD" },
  { value: "EUR", label: "EUR" },
  { value: "GBP", label: "GBP" },
  { value: "RMB", label: "RMB" },
  { value: "AUD", label: "AUD" },
  { value: "CAD", label: "CAD" },
  { value: "JPY", label: "JPY" },
  { value: "CHF", label: "CHF" },
  { value: "SGD", label: "SGD" },
];

export const DATA_INCENTIVE_TYPE = [
  {
    value: "INCENTIVE",
    label: "INCENTIVE",
  },
  {
    value: "CHARGE",
    label: "CHARGE",
  },
];

export const DATA_BANK_TRANSACTION_TYPE = [
  {
    value: "WITHDRAWAL",
    label: "Withdrawal",
  },
  {
    value: "DEPOSIT",
    label: "Deposit",
  },
];

export const DATA_RENEWAL_REJECTION_REASON = [
  { value: "by_provider", label: "By Provider" },
  { value: "by_client", label: "By Client" },
  { value: "other", label: "Others" },
];

export const DATA_PRODUCT_DEFAULT_CHECKLIST = [
  {
    optional: true,
    frontier: true,
    provider: true,
    _id: "642548fb59f8091d30f4cacc",
    name: "Client Service Agreement with FNA",
  },
  {
    optional: true,
    frontier: true,
    provider: true,
    _id: "642548fb59f8091d30f4cacd",
    name: "Client Service Agreement with MPF",
  },
  {
    optional: false,
    frontier: true,
    provider: true,
    _id: "6453504375b1112af17b8af1",
    name: "Client's Identity (ID / Passport )",
  },
  {
    optional: true,
    frontier: true,
    provider: true,
    _id: "6453504375b1112af17b8af2",
    name: "Client Address Proof (within 3 months)",
  },
  {
    optional: true,
    frontier: true,
    provider: true,
    _id: "6453504375b1112af17b8af3",

    name: "Client Service Agreement (ILAS)",
  },
  {
    optional: true,
    frontier: true,
    provider: true,
    _id: "6453504375b1112af17b8af4",
    name: "Frontier Risk Profile Questionnaire (ILAS)",
  },
  {
    optional: true,
    frontier: true,
    provider: true,
    _id: "6453504375b1112af17b8af5",
    name: "AML Assessment Form (CDD) ",
  },
  {
    optional: true,
    frontier: true,
    provider: true,
    _id: "6453504375b1112af17b8af6",
    name: "Enhanced Due Diligence Form (EDD)",
  },
  {
    optional: true,
    frontier: true,
    provider: true,
    _id: "6453504375b1112af17b8af7",
    name: "Application Form",
  },
  {
    optional: true,
    frontier: true,
    provider: true,
    _id: "6453504375b1112af17b8af8",
    name: "Proposal ",
  },
  {
    optional: false,
    frontier: true,
    provider: true,
    _id: "6453504375b1112af17b8af9",
    name: "FNA",
  },
  {
    optional: true,
    frontier: true,
    provider: true,
    _id: "6453504375b1112af17b8afa",
    name: "Provider Risk Profile Questionnaire (ILAS)",
  },
  {
    optional: true,
    frontier: true,
    provider: true,
    _id: "6453504375b1112af17b8afb",
    name: "Import Facts statement and applicant's declarations (ILAS)",
  },
  {
    optional: true,
    frontier: true,
    provider: true,
    _id: "6453504375b1112af17b8afc",
    name: "Import Facts statement and applicant's declarations (Premium Financing)",
  },
  {
    optional: true,
    frontier: true,
    provider: true,
    _id: "6453504375b1112af17b8afd",
    name: "Import Facts statement and applicant's declarations (MCV)",
  },
  {
    optional: false,
    frontier: true,
    provider: true,
    _id: "6453504375b1112af17b8afe",
    name: "Direct Debit Authorization (DDA)",
  },
  {
    optional: false,
    frontier: true,
    provider: true,
    _id: "6453504375b1112af17b8aff",
    name: "Initial Payment  (Credit Card/ Bank Slip/ Cheque/ Online Payment)",
  },
  {
    optional: true,
    frontier: true,
    provider: true,
    _id: "6453504375b1112af17b8b00",
    name: "Supplementary Documents",
  },
  {
    optional: true,
    frontier: true,
    provider: true,
    _id: "6453504375b1112af17b8b03",
    name: "External Transfer Form",
  },
  {
    optional: true,
    frontier: true,
    provider: true,
    _id: "6453504375b1112af17b8b02",
    name: "Medical Report",
  },
  {
    optional: true,
    frontier: true,
    provider: true,
    _id: "6453504375b1112af17b8b01",
    name: "Others:",
  },
];

export const DATA_NUMBER_COMPARISON = [
  { value: "=", label: "= (Equal)" },
  { value: "<>", label: "<> (Between)" },
  { value: ">", label: "> (Larger)" },
  { value: ">=", label: ">= (Larger or Equal)" },
  { value: "<", label: "< (Smaller)" },
  { value: "<=", label: "<= (Smaller or Equal)" },
];

export const DATA_CALENDAR_EVENT_TYPE = [
  { value: "companyEvent", label: "Company Event" },
  { value: "inTraining", label: "Internal Training" },
  { value: "meeting", label: "Meeting" },
];

export const DATA_ROUND_ON = [
  { value: "RATE", label: "Rate" },
  { value: "MONEY", label: "Money" },
];

export const DATA_ADDRESS_TYPE = [
  { value: "residental", label: "Residental" },
  { value: "correspondence", label: "Correspondence" },
];

export const DATA_JURISDICTION = [
  { value: "Hong Kong", label: "Hong Kong 香港" },
  { value: "Macau", label: "Macau 澳門" },
  { value: "Singapore", label: "Singapore 新加坡" },
  { value: "Bermuda", label: "Bermuda 百慕達" },
];

export const DATA_PRODUCT_STATUS = [
  { value: "ACTIVE", label: "Active 可用" },
  { value: "INACTIVE", label: "InActive 停用" },
  { value: "HIDDEN", label: "Hidden 隐蔽" },
];

export const DATA_PRODUCT_AGETYPES = [
  { value: "PREVIOUS", label: "Previous Birthday" },
  { value: "NEXT", label: "Next Birthday" },

  { value: "NEAREST", label: "Nearest Birthday" },
];

export const DATA_RELATIONSHIP = [
  { value: "Single Person", label: "Single Person", group: "Other" },

  //Spouse
  { value: "Husband", label: "Husband", group: "Spouse" },
  { value: "Wife", label: "Wife", group: "Spouse" },

  //Parent
  { value: "Father", label: "Father", group: "Parent" },
  { value: "Mother", label: "Mother", group: "Parent" },
  { value: "Legal Guardian", label: "Legal Guardian", group: "Parent" },

  //Sibling
  { value: "Brother", label: "Brother", group: "Sibling" },
  { value: "Sister", label: "Sister", group: "Sibling" },

  //Child
  { value: "Son", label: "Son", group: "Child" },
  { value: "Daughter", label: "Daughter", group: "Child" },

  // Extended family
  { value: "Grandparent", label: "Grandparent", group: "Extended" },
  { value: "Grandfather", label: "Grandfather", group: "Extended" },
  { value: "Grandson", label: "Grandson", group: "Extended" },
  { value: "Granddaughter", label: "Granddaughter", group: "Extended" },
  { value: "Uncle", label: "Uncle", group: "Extended" },
  { value: "Aunt", label: "Aunt", group: "Extended" },
  { value: "Cousin", label: "Cousin", group: "Extended" },
  { value: "Nephew", label: "Nephew", group: "Extended" },
  { value: "Niece", label: "Niece", group: "Extended" },
];

export const DATA_OVERRIDING_LEVEL_TYPE = [
  { value: "upper", label: "Up Line 上線" },
  { value: "userGroup", label: "User Group 小組" },
  { value: "user", label: "User 指定用戶" },
];

export const DATA_MARIAGE_STATUS = [
  { value: "Single", label: "Single 单身 " },
  { value: "Married", label: "Married 已婚 " },
  { value: "Divorced", label: "Divorced 离婚 " },
  { value: "Widowed", label: "Widowed 丧偶 " },
  { value: "Undetermined", label: "Undetermined 未确定 " },
];
export const DATA_BANK_WITH_CODE = [
  {
    value: "003",
    label: "[ 003 ] Standard Chartered Hong Kong 渣打銀行(香港)",
  },
  {
    value: "004",
    label: "[ 004 ] Hongkong and Shanghai Banking Corporation 香港上海滙豐銀行",
  },
  {
    value: "009",
    label: "[ 009 ] China Construction Bank (Asia) 中國建設銀行(亞洲)",
  },
  { value: "012", label: "[ 012 ] Bank of China (Hong Kong) 中國銀行(香港)" },
  { value: "015", label: "[ 015 ] Bank of East Asia 東亞銀行" },
  { value: "016", label: "[ 016 ] DBS Bank (Hong Kong) 星展銀行(香港)" },
  {
    value: "018",
    label: "[ 018 ] China CITIC Bank International 中信銀行國際",
  },
  { value: "020", label: "[ 020 ] CMB Wing Lung Bank 招商永隆銀行" },
  { value: "024", label: "[ 024 ] Hang Seng Bank 恒生銀行" },
  { value: "025", label: "[ 025 ] Shanghai Commercial Bank 上海商業銀行" },
  {
    value: "027",
    label: "[ 027 ] Bank of Communications (Hong Kong) 交通銀行(香港)",
  },
  { value: "028", label: "[ 028 ] Public Bank (Hong Kong) 大眾銀行(香港)" },
  { value: "035", label: "[ 035 ] OCBC Wing Hang Bank 華僑永亨銀行" },
  { value: "038", label: "[ 038 ] Tai Yau Bank 大有銀行" },
  { value: "039", label: "[ 039 ] Chiyu Banking Corporation 集友銀行" },
  { value: "040", label: "[ 040 ] Dah Sing Bank 大新銀行" },
  { value: "041", label: "[ 041 ] Chong Hing Bank 創興銀行" },
  { value: "043", label: "[ 043 ] Nanyang Commercial Bank 南洋商業銀行" },
  { value: "061", label: "[ 061 ] Tai Sang Bank 大生銀行" },
  {
    value: "072",
    label:
      "[ 072 ] Industrial and Commercial Bank of China (Asia) 中國工商銀行(亞洲)",
  },
  { value: "128", label: "[ 128 ] Fubon Bank (Hong Kong) 富邦銀行(香港)" },
  { value: "250", label: "[ 250 ] Citibank (Hong Kong) Limited" },
  { value: "385", label: "[ 385 ] PING AN BANK CO., LTD." },
  { value: "387", label: "[ 387 ] ZA BANK LIMITED" },
  { value: "388", label: "[ 388 ] LIVI VB LIMITED" },
  { value: "389", label: "[ 389 ] MOX BANK LIMITED" },
  { value: "390", label: "[ 390 ] WELAB BANK LIMITED" },
  { value: "391", label: "[ 391 ] FUSION BANK LIMITED" },
  {
    value: "392",
    label: "[ 392 ] PING AN ONECONNECT BANK (HONG KONG) LIMITED",
  },
  { value: "393", label: "[ 393 ] ANT BANK (HONG KONG) LIMITED" },
  { value: "395", label: "[ 395 ] AIRSTAR BANK LIMITED" },
  { value: "238", label: "[ 238 ] China Merchants Bank 招商銀行" },
  {
    value: "239",
    label: " [ 239 ] TAIPEI FUBON COMMERCIAL BANK CO., LTD 台北富邦商業銀行",
  },
  {
    value: "206",
    label: "[ 206 ] CHANG HWA COMMERCIAL BANK 彰化商業銀行",
  },
  {
    value: "353",
    label: "[ 353 ] CHINA MINSHENG BANKING CORP 中國民生銀行",
  },

  {
    value: "236",
    label: "[ 236 ] CATHAY UNITED BANK 國泰世華商業銀行",
  },
  {
    value: "CUC",
    label: "[ CUC ] The Currency Cloud Limited",
  },
];

export const DATA_ID_TYPE = [
  { value: "HKID", label: "HK ID 香港身份證" },
  { value: "CNID", label: "Mainland China ID 大陸身份證" },
  { value: "MID No.", label: "Macau ID 澳門身份證" },
  { value: "BR No.", label: "BR No. 商業登記證" },
  { value: "Passport", label: "Passport 護照" },
  { value: "Other", label: "Other 其他" },
];

export const DATA_REPORT_DATE_TYPE = [
  { value: "submissionDate", label: "Submit Date" },
  { value: "signedDate", label: "Sign Date" },
];

export const DATA_GENDERS = [
  { value: "Male", label: "Male 男性 " },
  { value: "Female", label: "Female 女性 " },
];

export const DATA_AGE_TYPES = [
  { value: "PREVIOUS", label: "Previous Birthday 前一个生日 " },
  { value: "NEXT", label: "Next Birthday 下一个生日 " },
  { value: "NEAREST", label: "Nearest Birthday 最近的生日 " },
];

export const DATA_CLIENT_TYPES = [
  { label: "Individual 個人", value: "INDIVIDUAL" },
  { label: "Corporate 公司", value: "CORPORATE" },
  { label: "Staff 員工", value: "STAFF" },
  { label: "Consultant 經紀", value: "CONSULTANT" },
];

export const DATA_RANK_BY = [
  { label: "Case 訂單數量", shortName: "CASE", value: "BY_CASE" },
  { label: "First Year Premium 首年保費", shortName: "FYP", value: "BY_FYP" },
  {
    label: "First Year Commission (Consultant) 首年顧問佣金",
    shortName: "FYC",
    value: "BY_FYCC",
  },
];

export const DATA_LANGUAGE = [
  {
    value: "CANTONESE",
    label: "Cantonese 粵語 ",
  },
  {
    value: "MANDARINE",
    label: "Mandarine 普通話 ",
  },
  {
    value: "ENGLISH",
    label: "English 英語 ",
  },
  {
    value: "JAPANESE",
    label: "Japanese 日語 ",
  },
  {
    value: "FRENCH",
    label: "French 法語 ",
  },
  {
    value: "Other",
    label: "Other 其他 ",
  },
];

export const DATA_PAY_MODES = [
  { value: "MONTHLY", label: "Monthly" },
  { value: "QUARTERLY", label: "Quarterly" },
  { value: "SEMIANNUALLY", label: "Semi Annually" },
  { value: "ANNUALLY", label: "Annually" },
  { value: "LUMPSUM", label: "Lump Sum" },
];
export const DATA_ALLOWANCE_PAY_MODES = [
  { value: "WEEKLY", label: "Weekly" },
  { value: "MONTHLY", label: "Monthly" },
  { value: "QUARTERLY", label: "Quarterly" },
  { value: "ANNUALLY", label: "Annually" },
];

export const DATA_USER_TYPES = [
  { value: "BACKOFFICE", label: "Back Office" },
  { value: "CONSULTANT", label: "Consultant" },
  { value: "CHANNEL", label: "Channel" },
];

export const DATA_COUNTRY = [
  { value: "Australia", label: "Australia 澳大利亞" },
  { value: "Austria", label: "Austria 奧地利" },
  { value: "Belgium", label: "Belgium 比利時" },
  { value: "Brazil", label: "Brazil 巴西" },
  { value: "Canada", label: "Canada 加拿大" },
  { value: "China", label: "China 中國" },
  { value: "Denmark", label: "Denmark 丹麥" },
  { value: "Egypt", label: "Egypt 埃及" },
  { value: "Finland", label: "Finland 芬蘭" },
  { value: "France", label: "France 法國" },
  { value: "Germany", label: "Germany 德國" },
  { value: "Hong Kong", label: "Hong Kong 香港" },
  { value: "India", label: "India 印度" },
  { value: "Indonesia", label: "Indonesia 印度尼西亞" },
  { value: "Ireland", label: "Ireland 愛爾蘭" },
  { value: "Israel", label: "Israel 以色列" },
  { value: "Italy", label: "Italy 意大利" },
  { value: "Japan", label: "Japan 日本" },
  { value: "Macau", label: "Macau 澳門" },
  { value: "Malaysia", label: "Malaysia 馬來西亞" },
  { value: "Mexico", label: "Mexico 墨西哥" },
  { value: "Netherlands", label: "Netherlands 荷蘭" },
  { value: "New Zealand", label: "New Zealand 紐西蘭" },
  { value: "Norway", label: "Norway 挪威" },
  { value: "Pakistan", label: "Pakistan 巴基斯坦" },
  { value: "Poland", label: "Poland 波蘭" },
  { value: "Portugal", label: "Portugal 葡萄牙" },
  { value: "Russia", label: "Russia 俄羅斯" },
  { value: "Singapore", label: "Singapore 新加坡" },
  { value: "South Africa", label: "South Africa 南非" },
  { value: "South Korea", label: "South Korea 南韓" },
  { value: "Spain", label: "Spain 西班牙" },
  { value: "Sweden", label: "Sweden 瑞典" },
  { value: "Switzerland", label: "Switzerland 瑞士" },
  { value: "Taiwan", label: "Taiwan 台灣" },
  { value: "Thailand", label: "Thailand 泰國" },
  { value: "Turkey", label: "Turkey 土耳其" },
  { value: "United Kingdom", label: "United Kingdom 英國" },
  { value: "United States", label: "United States 美國" },
  { value: "Vietnam", label: "Vietnam 越南" },
  { value: "Other", label: "Other 其他" },
];

export const DATA_SALUATATION = [
  {
    value: "Mr.",
    label: "Mr. 先生 ",
  },
  {
    value: "Ms.",
    label: "Ms. 女士 ",
  },
  {
    value: "Mrs.",
    label: "Mrs. 夫人 ",
  },
  {
    value: "Corporate",
    label: "Corporate 公司",
  },
  {
    value: "Other",
    label: "Other 其他",
  },
  {
    value: "",
    label: "-- ",
  },
];

export const DATA_NATIONALITY = [
  {
    value: "Australian",
    label: "Australian 澳洲籍 ",
  },
  {
    value: "British",
    label: "British 英國籍 ",
  },
  {
    value: "Canadian",
    label: "Canadian 加拿大籍 ",
  },
  {
    value: "Chinese",
    label: "Chinese 中國籍 ",
  },
  {
    value: "German",
    label: "German 德國籍 ",
  },
  {
    value: "Indonesian",
    label: "Indonesian 印尼籍 ",
  },
  {
    value: "Indian",
    label: "Indian 印度籍 ",
  },
  {
    value: "Japanese",
    label: "Japanese 日本籍 ",
  },
  {
    value: "Malaysian",
    label: "Malaysian 馬來西亞籍 ",
  },
  {
    value: "New Zealander",
    label: "New Zealander 紐西蘭籍 ",
  },
  {
    value: "Pakistani",
    label: "Pakistani 巴基斯坦籍 ",
  },
  {
    value: "Singaporean",
    label: "Singaporean 新加坡籍 ",
  },
  {
    value: "South Korean",
    label: "South Korean 南韓籍 ",
  },
  {
    value: "Taiwanese",
    label: "Taiwanese 台灣籍 ",
  },
  {
    value: "Thai",
    label: "Thai 泰國籍 ",
  },
  {
    value: "US",
    label: "United States 美國籍 ",
  },
  {
    value: "Vietnamese",
    label: "Vietnamese 越南籍 ",
  },
  {
    value: "Other",
    label: "Other 其他籍 ",
  },
];

export const DATA_MAIL_TYPE = [
  { value: "Email", lable: "Email" },
  { value: "Post", lable: "Post" },
  { value: "Email & Post", lable: "Email & Post" },
  { value: "None", lable: "None" },
];

export const DATA_EDUCATION_LEVEL = [
  {
    value: "Primary level or below",
    label: "Primary level or below 小學或以下 ",
  },
  {
    value: "Secondary",
    label: "Secondary 中學 ",
  },
  {
    value: "Non-Degree Tertiary",
    label: "Non-Degree Tertiary 非學位專上 ",
  },
  {
    value: "University or above",
    label: "University or above 大學或以上 ",
  },
];

export const DATA_TICKET_ASSIGN_METHODS = [
  { value: "ALL", label: "All Memebers" },
  { value: "Random Member", label: "Random member" },
  { value: "Lesser Case", label: "Less Case Member" },
];

export const DATA_REPORT_PRODUCT_TYPE = [
  { value: "original", label: "Provider" },
  // { value: "9550", label: "95% / 50%" },
];
export const DATA_QUO_REQUEST_STATUS = [
  { value: "DFT", label: "Draft" },
  { value: "CAN", label: "Cancelled" },
  { value: "DONE", label: "Quotation Done" },
  { value: "CONFIRM", label: "Confirmed by Client" },
  { value: "COPIEDDN", label: "Debit Note Done" },
];

export const DATA_CN_STATUS = [
  { value: "DFT", label: "Draft" },
  { value: "RCV", label: "Broker Received" },
  { value: "SETTLED", label: "Settled" },
  { value: "CAN", label: "Cancelled" },
  { value: "WAIT", label: "Waiting Settle" },
];

export const DATA_DN_STATUS = [
  { value: "DFT", label: "Draft" },
  { value: "WAIT", label: "Waiting Confirm" },
  { value: "CAN", label: "Cancelled" },
  { value: "HOLD", label: "Hold Cover" },
  { value: "CONFIRMED", label: "Policy Confirmed" },
  { value: "SETTLED", label: "Settled" }, // will remove
  { value: "EXP", label: "Expired" },
];

export const DATA_DN_PAYMENT_STATUS = [
  { value: "DFT", label: "Draft" },
  { value: "WAIT", label: "Waiting Payment" },
  { value: "PAID", label: "Paid" },
  { value: "SETTLED", label: "Settled" },
];

export const DATA_QUO_STATUS = [
  { value: "DFT", label: "Draft" },
  { value: "WAIT", label: "Waiting Confrim" },
  { value: "CAN", label: "Cancelled" },
  { value: "CONFIRM", label: "Confirmed" },
];

export const DATA_ORDER_STATUS = [
  { value: "DFT", label: "Draft" },
  { value: "CAN", label: "Cancelled" },
  { value: "RCV", label: "Received from consultant" },
  { value: "SUB", label: "Submitted to provider" },
  { value: "PND", label: "In Pending" },
  { value: "AIP", label: "Application in Process" },
  { value: "ACT", label: "ACT" },

  { value: "INF", label: "Inforced" },
  { value: "UNKNOWN", label: "Unknown" },

  { value: "SUS", label: "Payment Suspended" },
  { value: "SUR", label: "Surrender" },
  { value: "LAP", label: "Lapsed" },
  { value: "COL", label: "Cool Off" },
  { value: "REJ", label: "Rejected By Provider" },
  { value: "WTH", label: "Withdraw Application" },
  { value: "TRO", label: "Transfered out" },
  { value: "TER", label: "Terminated" },
  { value: "EXP", label: "Expired" },

  { value: "OTH", label: "Other" },
];

export const DATA_TERMINATION_TYPE = [
  { value: "SUR", label: "Surrender" },
  { value: "LAP", label: "Lapsed" },
  { value: "COL", label: "Cool Off" },
  { value: "REJ", label: "Rejected By Provider" },
  { value: "WTH", label: "Withdraw Application" },
  { value: "TRO", label: "Transfer Out" },
  { value: "CAN", label: "Cancelled" },
];

export const DATA_POLICY_DELIVER_METHODS = [
  { value: "Electronic", label: "Electronic" },
  { value: "By Provider", label: "By Provider" },
  { value: "By Froniter", label: "By Froniter" },
];

export const DATA_ORDER_TYPE = [
  { label: "New Biz", value: "NEW" },
  { label: "Transfer In", value: "TRANSFER" },
];

export const DATA_PAYMENT_METHOD = [
  { value: "DDA", label: "AutoPay DDA 自動過數" },
  { value: "Bank Transfer", label: "Bank Transfer 銀行過戶" },
  { value: "Cash", label: "Cash 現金" },
  { value: "Cheque", label: "Cheque 支票" },
  { value: "Credit Card", label: "Credit Card 信用咭" },
  { value: "FPS", label: "FPS" },
  { value: "Online Banking", label: "Online Banking 網上銀行" },
  { value: "No Payment", label: "No Payment 沒付款" },
  { value: "Others", label: "Others 其他" },
];

export const DATA_CONSULTANT_PAYMENT_METHOD = [
  { value: "Bank Transfer", label: "Bank Transfer" },
  { value: "Cash", label: "Cash" },
  { value: "Cheque", label: "Cheque" },
];

export const DATA_BANK = [
  { value: "003", label: "Standard Chartered Hong Kong 渣打銀行(香港)" },
  {
    value: "004",
    label: "Hongkong and Shanghai Banking Corporation 香港上海滙豐銀行",
  },
  {
    value: "009",
    label: "China Construction Bank (Asia) 中國建設銀行(亞洲)",
  },
  { value: "012", label: "Bank of China (Hong Kong) 中國銀行(香港)" },
  { value: "015", label: "Bank of East Asia 東亞銀行" },
  { value: "016", label: "DBS Bank (Hong Kong) 星展銀行(香港)" },
  { value: "018", label: "China CITIC Bank International 中信銀行國際" },
  { value: "020", label: "CMB Wing Lung Bank 招商永隆銀行" },
  { value: "024", label: "Hang Seng Bank 恒生銀行" },
  { value: "025", label: "Shanghai Commercial Bank 上海商業銀行" },
  {
    value: "027",
    label: "Bank of Communications (Hong Kong) 交通銀行(香港)",
  },
  { value: "028", label: "Public Bank (Hong Kong) 大眾銀行(香港)" },
  { value: "035", label: "OCBC Wing Hang Bank 華僑永亨銀行" },
  { value: "038", label: "Tai Yau Bank 大有銀行" },
  { value: "039", label: "Chiyu Banking Corporation 集友銀行" },
  { value: "040", label: "Dah Sing Bank 大新銀行" },
  { value: "041", label: "Chong Hing Bank 創興銀行" },
  { value: "043", label: "Nanyang Commercial Bank 南洋商業銀行" },
  { value: "061", label: "Tai Sang Bank 大生銀行" },
  {
    value: "072",
    label: "Industrial and Commercial Bank of China (Asia) 中國工商銀行(亞洲)",
  },
  { value: "128", label: "Fubon Bank (Hong Kong) 富邦銀行(香港)" },
  { value: "250", label: "Citibank (Hong Kong) Limited" },
  { value: "385", label: "PING AN BANK CO., LTD." },
  { value: "387", label: "ZA BANK LIMITED" },
  { value: "388", label: "LIVI VB LIMITED" },
  { value: "389", label: "MOX BANK LIMITED" },
  { value: "390", label: "WELAB BANK LIMITED" },
  { value: "391", label: "FUSION BANK LIMITED" },
  { value: "392", label: "PING AN ONECONNECT BANK (HONG KONG) LIMITED" },
  { value: "393", label: "ANT BANK (HONG KONG) LIMITED" },
  { value: "395", label: "AIRSTAR BANK LIMITED" },
  { value: "238", label: "China Merchants Bank 招商銀行" },
  {
    value: "239",
    label: "TAIPEI FUBON COMMERCIAL BANK CO., LTD 台北富邦商業銀行",
  },
  {
    value: "206",
    label: "CHANG HWA COMMERCIAL BANK 彰化商業銀行",
  },
  {
    value: "353",
    label: "CHINA MINSHENG BANKING CORP 中國民生銀行",
  },

  {
    value: "236",
    label: "CATHAY UNITED BANK 國泰世華商業銀行",
  },
  {
    value: "CUC",
    label: "The Currency Cloud Limited",
  },
];
