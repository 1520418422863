import React from "react";
import {
  Text,
  TextField,
  Group,
  TextInput,
  Checkbox,
  Card,
} from "@mantine/core";
import { useForceUpdate } from "@mantine/hooks";
import RemoteSelect2 from "./remoteSelect2";
import { FormSectionCard } from "../layout/page";
import { useTranslation } from "react-i18next";

const ProviderLoginInfoRow = ({ item, form, name }) => {
  const forceUpdate = useForceUpdate();
  const { t } = useTranslation();
  return (
    <FormSectionCard>
      <Group grow>
        <RemoteSelect2
          apiEntity="provider"
          label={t("Provider")}
          value={item.content["provider"]}
          searchFields={["name", "code", "shortName"]}
          labelRender={(item) =>
            `[${item.code}] ${item.shortname ?? item.name}`
          }
          onDataChange={(data) => {
            console.log(data);
            item.content["provider"] = data._id;
          }}
          pageSize={1000}
          sort={{ by: "name", order: "asc" }}
        />
        <TextInput
          placeholder="http://www.aia.com.hk"
          label={t("Url Address")}
          value={item.content["url"]}
          miw={400}
          onChange={(e) => {
            item.content["url"] = e.target.value;
            forceUpdate();
          }}
        />
      </Group>
      <Group grow mt="md">
        <TextInput
          placeholder={t("Username")}
          label={t("Login Name / Email")}
          value={item.content["username"]}
          onChange={(e) => {
            item.content["username"] = e.target.value;
            forceUpdate();
          }}
        />
        <TextInput
          placeholder={t("Password")}
          label={t("Password")}
          value={item.content["password"]}
          onChange={(e) => {
            item.content["password"] = e.target.value;
            forceUpdate();
          }}
        />
      </Group>
    </FormSectionCard>
  );
};

export default ProviderLoginInfoRow;
