import React from "react";

import {
  TextInput,
  Select,
  Text,
  Avatar,
  Textarea,
  NumberInput,
  Switch,
} from "@mantine/core";

import {
  PageList,
  ENUM_FORM_DISPLAY,
  ENUM_FORM_LAYOUT_CONTAINER,
} from "../../components/pageList";

import RemoteSelect2 from "../../components/remoteSelect2";
import ActionCellRender from "../../components/actionCellRender";
import { IconTrash, IconPencil, IconSettings } from "@tabler/icons";
import { DATA_ROUND_ON } from "../../data/options";
import OverridingTable from "../../components/overridingTable";
import OverridingTester from "../../components/overridingTester";

const apiEntity = "commissionOverridingPlan";

const tableSchema = {
  showActionColumn: true,
  columns: [
    { field: "name", headerName: "Name" },
    { field: "desc", headerName: "Description" },

    {
      field: "action",
      cellRender: ActionCellRender,
      isAction: true,
      actions: [
        {
          name: "edit",
          icon: IconPencil,
          props: {
            //   color:"red"
          },
        },
      ],
      actionMenu: [
        {
          name: "delete",
          label: "Delete data",
          icon: IconTrash,
          props: {
            color: "red",
          },
        },
      ],
    },
  ],

  searchableFields: ["code", "name"],
};

const formSchema = {
  title: "Commission Overriding Plan",
  display: {
    mode: ENUM_FORM_DISPLAY.LINK,
  },
  apiEntity,
  initialValues: {
    name: "",
    roundOnRate: true,
    useInternalCurrency: true,
    rateRoundDigit: 3,
    moneyRoundDigit: 2,

    status: "ACTIVE",
    table: [
      {
        userGroup: null,
        isRemainLevel: false,
        row: 1,
        level: 0,
        index: 1,
        percentage: 0,
      },
    ],
  },

  layout: {
    containers: [
      {
        key: "main-tab",
        parent: null,
        type: ENUM_FORM_LAYOUT_CONTAINER.TABS,
        props: {
          variant: "default", //default | pills | outline
          defaultValue: "Plan Details",
        },
        tab: [
          {
            key: "tab-detail",
            label: "Plan Details",
            icon: IconSettings,
          },
          { key: "tab-preview", label: "Tester", icon: IconSettings },
          { key: "tab-product", label: "Product Control", icon: IconSettings },
        ],
      },
      {
        key: "info-section",
        parent: "tab-detail",
        type: ENUM_FORM_LAYOUT_CONTAINER.SECTION,
        props: {
          title: "Basic Info",
          description: "Please fill in the Payment info of product",
        },
      },

      {
        key: "level-section",
        parent: "tab-detail",
        type: ENUM_FORM_LAYOUT_CONTAINER.SECTION,
        props: {
          title: "Overriding Levels",
          description: "Please fill in the overriding levels ifno",
        },
      },

      {
        key: "test-section",
        parent: "tab-preview",
        type: ENUM_FORM_LAYOUT_CONTAINER.SECTION,
        props: {
          title: "Plan Tester",
          description: "You can test the overrding plan",
        },
      },
      {
        key: "product-section",
        parent: "tab-product",
        type: ENUM_FORM_LAYOUT_CONTAINER.SECTION,
        props: {
          title: "Product Settings",
          description: "You can set the product settings here",
        },
      },
      {
        key: "info-g",
        parent: "info-section",
        type: ENUM_FORM_LAYOUT_CONTAINER.GRID,
        props: {
          gutter: "xl",
        },
        cols: [
          {
            key: "info-g1",
            props: {
              span: 6,
            },
          },
          {
            key: "info-g2",
            props: {
              span: 6,
            },
          },
        ],
      },
    ],
    fields: [
      {
        name: "name",
        parent: "info-g1",
        component: TextInput,
        props: {
          required: true,
          label: "Plan Name",
          placeholder: "Basic Plan 1",
        },
      },
      {
        name: "desc",
        parent: "info-g2",
        component: Textarea,
        props: {
          label: "Description",
        },
      },
      {
        name: "roundOnRate",
        parent: "info-g2",
        component: Switch,
        props: {
          type: "checkbox",
          label: "Round On Rate",
        },
      },

      {
        name: "useInternalExchangeRate",
        parent: "info-g2",
        component: Switch,
        props: {
          type: "checkbox",
          label: "Use Internal Currency Exchange Rate",
        },
      },

      {
        name: "allowSkipProviderRate",
        parent: "info-g2",
        component: Switch,
        props: {
          type: "checkbox",
          label: "Allow Direct on Premium (Skip Product Rate)",
        },
      },

      {
        name: "moneyRoundDigit",
        parent: "info-g1",
        component: NumberInput,
        props: {
          label: "Money Round Digit",
          required: true,
          min: 0,
          max: 5,
        },
      },

      {
        name: "rateRoundDigit",
        parent: "info-g1",
        component: NumberInput,
        props: {
          label: "Rate Round Digit",
          required: true,
          min: 0,
          max: 5,
        },
      },
      {
        name: "table",
        parent: "level-section",
        component: OverridingTable,
        props: {
          lable: "Overrding Table",
        },
      },

      {
        name: "notProductCategory",
        parent: "product-section",

        component: RemoteSelect2,
        props: {
          label: "Not allow to sell product category",
          apiEntity: "productCategory",

          placeholder: "Search",
          valueField: "_id",
          labelField: "name",
          allowClear: true,
          labelRender: (data) => `${data.name} ${data.cname}`,
          multiple: true,
          pageSize: 200,
          // preQuery: { $and: [ {isConsultant : true}, {isActive: true} ] },
          // preQuery:  {isConsultant : true} ,
          searchFields: ["name", "code", "cname"],
          clearable: true,
          sort: {
            by: "name",
            order: "asc",
          },
        },
      },
      {
        name: "notProductTypes",
        parent: "product-section",
        component: RemoteSelect2,
        props: {
          label: "Not allow to sell product types",
          apiEntity: "productType",

          placeholder: "Search",
          valueField: "_id",
          labelField: "name",
          allowClear: true,
          labelRender: (data) => `${data.name} ${data.cname}`,
          multiple: true,
          pageSize: 200,
          // preQuery: { $and: [ {isConsultant : true}, {isActive: true} ] },
          // preQuery:  {isConsultant : true} ,
          searchFields: ["name", "code", "cname"],
          clearable: true,
          sort: {
            by: "name",
            order: "asc",
          },
        },
      },

      {
        name: "table",
        parent: "test-section",
        component: OverridingTester,
        props: {},
      },
    ],
  },
};

const CommissionOverridingPlan = () => {
  return (
    <PageList
      title={"Commission Overriding Plan"}
      apiEntity={apiEntity}
      tableSchema={tableSchema}
      formSchema={formSchema}
    />
  );
};

export default CommissionOverridingPlan;
