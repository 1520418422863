import React, { useState, useEffect } from "react";
import _ from "lodash";
import {
  TextInput,
  Select,
  Textarea,
  NumberInput,
  Group,
  Title,
  Text,
  Divider,
  Grid,
} from "@mantine/core";
import ReactJson from "react-json-view";

import ArrayTable from "./arrayTable";
import { DATA_CURRENCY } from "../data/options";
import useDeepCompareEffect from "../hooks/useDeepCompareEffect";
import { useFormatter } from "../hooks/useFomatter";
import { useForm } from "@mantine/form";

const CreditNoteRefundSummary = ({ form, name }) => {
  const totalCreditToClient = _.get(form.values, "totalCreditToClient");
  const totalCreditToBroker = _.get(form.values, "totalCreditToBroker");
  const balance = _.get(form.values, "balance");
  const [formatter] = useFormatter();

  return (
    <>
      <Divider my="xl" />

      <Group position="right">
        <div>
          <Group position="apart" w={400}>
            <Text size={"xs"}>Total Credit To Client </Text>
            <Title order={3}>{formatter.currency(totalCreditToClient)}</Title>
          </Group>

          <Group position="apart" w={400} mt="md">
            <Text size={"xs"}>Total Credit To Broker </Text>
            <NumberInput
              value={totalCreditToBroker}
              onChange={(value) => {
                form.setFieldValue("totalCreditToBroker", value);
                form.setFieldValue("balance", value - totalCreditToClient);
              }}
              placeholder="Total Credit To Broker"
              min={0}
              step={0.01}
              parser={formatter.currencyParser}
              formatter={formatter.currencyFormat}
              precision={2}
            />
          </Group>
          <Divider mt="xl" mb="md" />
          <Group position="apart" w={400}>
            <Text size={"xs"}>Balance </Text>
            <Title order={3}>{formatter.currency(balance ?? 0)}</Title>
          </Group>
        </div>
      </Group>
    </>
  );
};

export default CreditNoteRefundSummary;
