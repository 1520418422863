import React, {
  useState,
  useEffect,
  useImperativeHandle,
  useRef,
  useCallback,
} from "react";
import {
  createStyles,
  Table,
  ScrollArea,
  Select,
  UnstyledButton,
  Group,
  Text,
  Center,
  TextInput,
  Pagination,
  Space,
  Button,
  Menu,
  ActionIcon,
  Badge,
  Paper,
  HoverCard,
  Checkbox,
  Tooltip,
  Divider,
} from "@mantine/core";
import {
  IconSelector,
  IconChevronDown,
  IconChevronUp,
  IconSearch,
  IconDots,
  IconCross,
  IconClearAll,
  IconLetterX,
  IconSquareLetterX,
  IconX,
  IconFilter,
  IconTrash,
  IconSelect,
  IconListCheck,
  IconListDetails,
} from "@tabler/icons";
import _ from "lodash";
import { useDebouncedValue, useForceUpdate } from "@mantine/hooks";
import useDeepCompareEffect from "../hooks/useDeepCompareEffect";
import ReactJson from "react-json-view";
import { useTranslation } from "react-i18next";
import { useServerApi } from "../hooks/userServerApi";
import { theme } from "../theme";
import { useLocalStorage } from "@mantine/hooks";

const useStyles = createStyles((theme) => ({
  // search:{
  //     marginBottom: '30px'
  // },

  // header: {
  //   position: 'sticky',
  //   top: 0,
  //   backgroundColor: theme.colorScheme === 'dark' ? theme.colors.dark[7] : theme.white,
  //   transition: 'box-shadow 150ms ease',
  //   zIndex:100,

  //   '&::after': {
  //     content: '""',
  //     position: 'absolute',
  //     left: 0,
  //     right: 0,
  //     bottom: 0,
  //     borderBottom: `1px solid ${
  //       theme.colorScheme === 'dark' ? theme.colors.dark[3] : theme.colors.gray[2]
  //     }`,
  //   },
  // },

  scrollArea: {
    // border:"1px solid #373A40"
  },

  scrolled: {
    boxShadow: theme.shadows.sm,
  },

  // th:{
  //     width: "fit-content"
  // }
}));

function Th({
  children,
  reversed,
  sorted,
  onSort,
  onFilter,
  col,
  tableFilterQuery,
}) {
  const { classes } = useStyles();

  const [api] = useServerApi();
  const [possibleValues, setPossibleValues] = useState([]);
  const [selectedValues, setSelectedValues] = useState([]);
  const { t: translate } = useTranslation();

  const fetchDistinctValues = async () => {
    try {
      const values = await api.getDistinctValues({
        apiEntity: col.filter?.apiEntity,
        field: col.filter?.field,
        populate: col.filter?.populate,
      });
      values?.forEach((v) => {
        v.label = translate(v.label);
      });
      // console.log("Possible Values", values);

      //Values: [{ label: "All", value: "" }, ...values]
      // console.log("fetchDistinctValues", values);
      setPossibleValues(values);
      col.possibleValues = values;

      if (
        !_.isEmpty(tableFilterQuery) &&
        _.has(tableFilterQuery, col.filter.field)
      ) {
        setSelectedValues(tableFilterQuery[col.filter.field].$in ?? []);
      } else {
        setSelectedValues(values?.map((v) => v?.value));
      }
    } catch (e) {
      console.log(e);
    }
  };
  useDeepCompareEffect(() => {
    if (!col.filterable || !col.filter.field) return;

    fetchDistinctValues();
  }, [col?.filter?.field]);

  const Icon = sorted
    ? reversed
      ? IconChevronUp
      : IconChevronDown
    : IconSelector;
  return (
    <th className={classes.th} width={col.width}>
      <Group spacing={"xs"} noWrap>
        <UnstyledButton onClick={onSort} className={classes.control}>
          <Group position="apart" noWrap>
            <Text weight={500} size="xs">
              {children}
            </Text>
            <Center className={classes.icon}>
              <Icon size={14} stroke={1.5} />
            </Center>
          </Group>
        </UnstyledButton>
        {col.filterable && (
          <HoverCard shadow="md" width={col.filter.width ?? 200}>
            <HoverCard.Target>
              <Group spacing={1} noWrap>
                <ActionIcon
                  variant="transparent"
                  title="Filter"
                  onClick={() => console.log("filter")}
                >
                  <IconFilter size={14} stroke={1.5} />
                </ActionIcon>
                {selectedValues?.length !== possibleValues?.length && (
                  <Text>({selectedValues?.length})</Text>
                )}
              </Group>
            </HoverCard.Target>
            <HoverCard.Dropdown p={0}>
              {/* {selectedValues.length} */}
              {/* <ReactJson
                src={tableFilterQuery}
                style={{ background: "white" }}
              /> */}
              <ScrollArea
                style={{
                  width: col.filter.width,
                  height: col.filter.height,
                }}
              >
                <Group spacing={0} mr="sm" pl={8} position="right">
                  <Tooltip label={translate("Check All")}>
                    <ActionIcon
                      variant="transparent"
                      title="Filter"
                      ml={-5}
                      onClick={() => {
                        setSelectedValues(possibleValues?.map((v) => v.value));
                        onFilter(
                          col.filter.field,
                          possibleValues?.map((v) => v.value)
                        );
                      }}
                    >
                      <IconListCheck size={20} stroke={1} />
                    </ActionIcon>
                  </Tooltip>
                  <Tooltip label={translate("Clear All")}>
                    <ActionIcon
                      variant="transparent"
                      title="Filter"
                      onClick={() => {
                        setSelectedValues([]);
                        onFilter(col.filter.field, []);
                      }}
                    >
                      <IconListDetails size={20} stroke={1} />
                    </ActionIcon>
                  </Tooltip>
                </Group>
                <Divider></Divider>
                <Checkbox.Group
                  defaultValue={
                    col.filter.defaultChecked ??
                    possibleValues?.map((v) => v.value)
                  }
                  orientation="vertical"
                  size="xs"
                  spacing={"xs"}
                  onChange={(values) => {
                    setSelectedValues(values);
                    onFilter(col.filter.field, values, col.possibleValues);
                  }}
                  value={selectedValues}
                >
                  {possibleValues?.map((v, index) => (
                    <Checkbox
                      key={index}
                      value={v.value}
                      label={v.label}
                      size="xs"
                      pl={8}
                    />
                  ))}
                </Checkbox.Group>
              </ScrollArea>
            </HoverCard.Dropdown>
          </HoverCard>
        )}
      </Group>
    </th>
  );
}

const SearchBar = ({ onChange = console.log, searchText }) => {
  const { classes, cx } = useStyles();
  const { t } = useTranslation();
  return (
    <>
      <TextInput
        placeholder={t("Search")}
        mb="md"
        icon={<IconSearch size={14} stroke={1.5} />}
        value={searchText}
        clearable={true}
        onChange={(e) => {
          onChange(e.currentTarget.value);
        }}
        rightSection={
          searchText && (
            <UnstyledButton
              onClick={() => {
                onChange("");
              }}
            >
              <IconX size={14} stroke={1.5} />{" "}
            </UnstyledButton>
          )
        }
        // className={classes.search}
      />
    </>
  );
};

const PaginationBar = ({
  pageSize,
  onPageSizeChange,
  pagination,
  onPageChange,
  currentPage,
}) => {
  useEffect(() => {
    pagination.page = currentPage;
  }, [currentPage]);
  const { t: translate } = useTranslation();
  return (
    <>
      {/* {JSON.stringify(pagination)} */}
      {pagination && (
        <Group position="right">
          <Group>
            <Pagination
              size="xs"
              page={pagination.page}
              total={pagination.totalPage}
              siblings={1}
              onChange={onPageChange}
            />
            <Select
              data={[
                { value: 10, label: "10" },
                { value: 20, label: "20" },
                { value: 30, label: "30" },
                { value: 40, label: "40" },
                { value: 50, label: "50" },
                //   { value: 100, label: "100" },
              ]}
              value={pageSize}
              onChange={onPageSizeChange}
              size="xs"
            />
            <Text size="xs" color="dimmed">
              {pagination.total} {translate("Records")}
            </Text>
          </Group>
        </Group>
      )}
    </>
  );
};

const DataTable = (
  {
    data = [],
    columns = [],
    hidePagination = false,
    hideSearchBar = false,
    minHeight = "200px",
    pageSize = 10,
    pagination = { total: 10 },
    searchText = "",
    onPageSizeChange = () => {},
    onPageChange = () => {},
    onSearchChange = () => {},
    onSortChange = () => {},
    onFilterChange = () => {},
    onActionBtnClick = () => {},
    onRowDoubleClick = () => {},
    tableFilterQuery = {},
    currentPage = 1,
    padding = "xl",
    tableHeader,
    showRefreshButton = false,
  },
  ref
) => {
  useImperativeHandle(ref, () => {
    return {
      // refresh: ()=>fetchData()
    };
  });

  const { classes, cx } = useStyles();
  const [scrolled, setScrolled] = useState(false);

  const [sortBy, setSortBy] = useState(null);
  const [reverseSortDirection, setReverseSortDirection] = useState(false);

  const [rows, setRows] = useState([]);

  const forceUpdate = useForceUpdate();

  const setSorting = (field) => {
    const reversed = field === sortBy ? !reverseSortDirection : false;
    setReverseSortDirection(reversed);
    setSortBy(field);
  };

  const setDataRow = () => {
    // console.log("datatable effect", data);
    if (!data) return;
    setRows(
      data?.map((d, index) => (
        <tr
          key={index}
          // onDoubleClick={() => onRowDoubleClick({ data: d, rowIndex: index })}
          // onDoubleClick={() => console.log("click")}
        >
          {columns?.map((col, index_c) => (
            <td key={index_c}>
              {!col.cellRender && _.get(d, col.field)}
              {col.cellRender && !col.isAction && col.cellRender(col, d)}
              {col.cellRender &&
                col.isAction &&
                col.cellRender(col, index, d, onActionBtnClick)}
            </td>
          ))}
        </tr>
      ))
    );
    forceUpdate();
  };
  useEffect(() => {
    setDataRow();
  }, [data]);

  useEffect(() => {
    onSortChange(sortBy, reverseSortDirection);
  }, [sortBy, reverseSortDirection]);

  const { t } = useTranslation();
  const TableHeader = () => (
    <thead className={cx(classes.header, { [classes.scrolled]: scrolled })}>
      <tr>
        {columns.map((col, index) => {
          return col.sortable ? (
            <Th
              key={col.field + index}
              sorted={sortBy === col.field}
              reversed={reverseSortDirection}
              onSort={() => setSorting(col.field)}
              onFilter={(field, values, possibleValues) => {
                onFilterChange(field, values, possibleValues);
              }}
              col={col}
              tableFilterQuery={tableFilterQuery}
              width={col.width}
            >
              {t(col.headerName ?? _.capitalize(col.field))}
            </Th>
          ) : (
            <th key={col.field + index}>
              {t(col.headerName ?? _.capitalize(col.field))}
            </th>
          );
        })}
      </tr>
    </thead>
  );

  return (
    <>
      {/* {JSON.stringify(data)} */}
      {/* {JSON.stringify(columns)} */}
      {/* {sortBy} - {reverseSortDirection?'true':'false'} */}
      {/* <ReactJson src={data} collapsed style={{ background: "white" }} /> */}

      <Paper p={padding}>
        {!hideSearchBar && (
          <>
            <SearchBar onChange={onSearchChange} searchText={searchText} />
            <Space h={"md"} />
          </>
        )}
        {tableHeader && <tableHeader.component {...tableHeader.props} />}
        {/* <ScrollArea sx={{ minHeight }} className={classes.scrollArea}> */}
        {/* onScrollPositionChange={({ y }) => setScrolled(y !== 0)}> */}
        <Table
          //striped
          highlightOnHover
          // horizontalSpacing="md"
          // verticalSpacing="xs"
          // miw={"100%"}
          // sx={{ tableLayout: "fixed", minWidth: 700 }}
          fontSize={"xs"}
        >
          <TableHeader />
          <tbody>{rows}</tbody>
        </Table>
        {/* </ScrollArea> */}
        {showRefreshButton && (
          <Button
            onClick={() => setDataRow()}
            size="xs"
            mt="xl"
            variant="light"
          >
            Refresh
          </Button>
        )}

        <Space h={"xl"} />
        {!hidePagination && (
          <PaginationBar
            pageSize={pageSize}
            onPageSizeChange={onPageSizeChange}
            pagination={pagination}
            onPageChange={onPageChange}
            currentPage={currentPage}
          />
        )}
      </Paper>
    </>
  );
};

export default React.forwardRef(DataTable);
