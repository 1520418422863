import React, { useState, useEffect } from "react";
import moment from "moment";
import { DatePicker } from "@mantine/dates";
import {
  IconTrash,
  IconCheck,
  IconCircleCheck,
  IconAlertOctagon,
  IconPencil,
  IconExclamationCircle,
} from "@tabler/icons";
import _ from "lodash";
import { showNotification } from "@mantine/notifications";

import { ENUM_FORM_DISPLAY, ENUM_FORM_LAYOUT_CONTAINER } from "./pageList";
import LocalPageList from "./localPageList";
import FileList from "./fileList";
import ActionCellRender from "./actionCellRender";

import { useServerApi } from "../hooks/userServerApi";
import { NumberInput, Textarea } from "@mantine/core";
import { useAccessRight } from "../hooks/useAccessRight";

const ClientRPQList = ({ clientIDField = "_id", form: mainForm, ...props }) => {
  const clientID = _.get(mainForm.values, clientIDField);

  const [rows, setRows] = useState([]);
  const [api] = useServerApi();
  const [getAccessRight, accessRight] = useAccessRight();
  const disabled = accessRight !== 15;

  const tableSchema = {
    hidePagination: true,
    columns: [
      // {   field: '_id',       headerName:"ID"            },
      {
        field: "signDate",
        headerName: "Type",
        cellRender: (col, data) => "RPQ",
      },

      {
        field: "signDate",
        headerName: "Signed Date",
        cellRender: (col, data) => moment(_.get(data, col.field)).format("LL"),
      },
      { field: "riskLevel", headerName: "Risk Level" },
      {
        field: "expiryDate",
        headerName: "Expiry Date",
        cellRender: (col, data) => moment(_.get(data, col.field)).format("LL"),
      },
      {
        field: "expiryDate",
        headerName: "Valid",
        cellRender: (col, data) =>
          moment().diff(_.get(data, col.field)) > 0 ? (
            <IconAlertOctagon color={"red"} />
          ) : (
            <IconCircleCheck color={"green"} />
          ),
      },

      {
        cellRender: ActionCellRender,
        isAction: true,
        actions: [
          {
            name: "edit",
            label: "Edit data",
            icon: IconPencil,
            props: {},
          },
          {
            name: "delete",
            label: "Delete data",
            icon: IconTrash,
            props: {},
          },
        ],
        actionMenu: [],
      },
    ],
    searchableFields: ["code", "name", "cname", "email"],
  };

  const formSchema = {
    title: "RPQ",
    description: "please fill in the form",

    display: {
      mode: ENUM_FORM_DISPLAY.MODAL,
      size: "xl",
    },
    initialValues: {},

    layout: {
      containers: [
        {
          key: "basicInfo-g",
          parent: null,
          type: ENUM_FORM_LAYOUT_CONTAINER.GRID,
          cols: [
            {
              key: "basicInfo-g1",
              props: {
                span: 6,
              },
            },
            {
              key: "basicInfo-g2",
              props: {
                span: 6,
              },
            },
          ],
        },
        {
          key: "file-section",
          parent: null,
          type: ENUM_FORM_LAYOUT_CONTAINER.SECTION,
          props: {
            title: "Files",
          },
        },
      ],

      fields: [
        {
          name: "signDate",
          component: DatePicker,
          parent: "basicInfo-g1",
          type: "datePicker",
          props: {
            required: true,
            label: "Sign Date",
            zIndex: 1000,
            placeholder: "Pick Date",
            allowFreeInput: true,
          },
        },
        {
          name: "expiryDate",
          component: DatePicker,
          parent: "basicInfo-g2",
          type: "datePicker",
          props: {
            required: true,
            label: "Expiry Date",
            zIndex: 1000,
            placeholder: "Pick Date",
            allowFreeInput: true,
          },
        },
        {
          name: "riskLevel",
          component: NumberInput,
          parent: "basicInfo-g2",

          props: {
            label: "Risk Level",
            default: 5,
          },
        },
        {
          name: "remark",
          component: Textarea,
          parent: "basicInfo-g1",

          props: {
            label: "Remark",
            default: "",
          },
        },
        {
          name: "files",
          component: FileList,
          parent: "file-section",
          props: {},
        },
      ],
    },
  };

  const onFormSubmit = async (values) => {
    try {
      setRows(values);
      await api.Client.updateRiskRPQ(clientID, values);
      showNotification({
        title: `Client RPQ record updatd successfully`,
        message: "Update Success",
      });
    } catch (error) {
      showNotification({
        title: `Client RPQ Update fail`,
        icon: <IconExclamationCircle size={18} />,
        color: "red",
        message: error,
      });
    }
  };

  const initRowData = async () => {
    if (!clientID) return;
    let result = await api.Client.getRiskRPQ(clientID);

    if (!result) return;
    setRows(result.data?.rpq ?? []);
  };

  useEffect(() => {
    initRowData();
  }, [clientID]);

  return (
    <LocalPageList
      rows={rows}
      onFormSubmit={onFormSubmit}
      tableSchema={tableSchema}
      formSchema={formSchema}
      readOnly={disabled}
      {...props}
    />
  );
};

export default ClientRPQList;
