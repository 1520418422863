import React, { useEffect, useState } from "react";

import {
  Text,
  Group,
  Card,
  Grid,
  Image,
  Avatar,
  Title,
  UnstyledButton,
  ActionIcon,
} from "@mantine/core";
import { FormSectionCard } from "../layout/page";
import moment from "moment";
import _ from "lodash";
import { useServerApi } from "../hooks/userServerApi";
import { IconExternalLink, IconNews } from "@tabler/icons";
import { PromotionCard } from "./clientPromotionSection";
import { useTranslation } from "react-i18next";

const IncentiveCard = ({ incentive }) => {
  const {
    bgImage,
    title,
    description,
    provider,
    submitDateRange,
    inForceBefore,
    createdAt,
    files,
    url,
  } = incentive;

  const [onOver, setOnOver] = useState(false);

  const openFile = (file) => {
    // if (!file.url) return;
    if (!file.link) return;
    const url = `${process.env.REACT_APP_FILE_URL}${file.link}`;
    window.open(url, "_blank");
    // window.open(file.url, "_blank");
  };

  const openUrl = () => {
    if (!url) return;
    window.open(url, "_blank");
  };
  const img = `${process.env.REACT_APP_FILE_URL}${bgImage ?? provider.image}`;
  return (
    <Card
      withBorder
      shadow="sm"
      radius="md"
      w={"300px"}
      // style={{ borderColor: file.selected ? "#A5D8FF" : "" }}
      style={{ cursor: "pointer" }}
      onMouseOver={() => setOnOver(true)}
      onMouseLeave={() => setOnOver(false)}
    >
      <Card.Section
        onClick={(e) => {
          if (_.isEmpty(files)) return;
          openFile(files[0]);
        }}
        style={{ cursor: "pointer" }}
      >
        {!onOver && (
          <Image
            src={
              bgImage ? `${process.env.REACT_APP_FILE_URL}/${bgImage}` : null
            }
            height={100}
            withPlaceholder
            placeholder={
              <Text size="xs" lineClamp={5} mx="xs">
                {description}
              </Text>
            }
          />
        )}

        {onOver && (
          <Image
            src={null}
            height={100}
            withPlaceholder
            placeholder={
              <Text size="xs" mx="xs" lineClamp={5}>
                {description}
              </Text>
            }
          />
        )}
      </Card.Section>

      <Group position="apart">
        <Group noWrap mt={"xs"}>
          <Avatar
            src={
              provider
                ? `${process.env.REACT_APP_FILE_URL}/${provider?.image}`
                : null
            }
            radius={100}
          />
          <span>
            <Text size={"xs"} weight={800} lineClamp={1}>
              {title}
            </Text>

            {/* <Text color="dimmed" size="xs">
								{provider.shortName}
							</Text> */}
            <Text size={"xs"}>
              {`${moment(submitDateRange.start).format(
                "YYYY/MM/DD"
              )} ~ ${moment(submitDateRange.end).format("YYYY/MM/DD")} `}
            </Text>
          </span>
        </Group>
        <ActionIcon onClick={openUrl} size="xs">
          <IconExternalLink />
        </ActionIcon>
      </Group>
    </Card>
  );
};

const ConsultantIncentvieSection = ({ title }) => {
  const [api] = useServerApi();
  const [incentive, setIncentive] = useState([]);
  const { t } = useTranslation();
  const NO_INCENTIVE_MSG = t(
    "There are currently no consultant incentive programs."
  );

  const fetchIncentive = async () => {
    const data = await api.ConsultantIncentive.getLatest();
    setIncentive(data);
    // console.log("fetchIncentive", data);
  };

  useEffect(() => {
    fetchIncentive();
  }, []);

  return (
    <FormSectionCard withPaper={false} title={title}>
      {_.isEmpty(incentive) && <Text>{NO_INCENTIVE_MSG}</Text>}
      {!_.isEmpty(incentive) && (
        <Group>
          {incentive?.map((p) => (
            <PromotionCard promotion={p} key={p._id} />
          ))}
        </Group>
      )}
    </FormSectionCard>
  );
};

export default ConsultantIncentvieSection;
