import { SimpleGrid, Text, Title, Group, Badge } from "@mantine/core";
import React from "react";
import _ from "lodash";
import { useTranslation } from "react-i18next";
const FieldDisplay = ({
  title,
  titleTextSize,
  dataTextSize,
  value,
  render,
  dataFontWeight,
  t,
}) => (
  <div>
    <Text size={titleTextSize} color={"dimmed"}>
      {t(title)}
    </Text>
    {_.isFunction(render) && render()}
    {value && (
      <Text size={dataTextSize} weight={dataFontWeight}>
        {value}
      </Text>
    )}
    {_.isNull(value) && !render && <Text size={"xs"}>{"-"}</Text>}
  </div>
);
const ArrayDisplay = ({ title, titleTextSize, arr, t }) => (
  <div>
    <Text size={titleTextSize} color={"dimmed"}>
      {t(title)}
    </Text>

    <Group spacing={"xs"}>
      {_.isArray(arr) &&
        arr.map((c, index) => (
          <Badge key={index} radius={0} size="md">
            {t(c)}
          </Badge>
        ))}
    </Group>
    {_.isEmpty(arr) && <Text size={"xs"}>{"-"}</Text>}
  </div>
);
const InfoGridList = ({
  fields,
  cols = 3,
  spacing = "md",
  titleTextSize = "xs",
  dataTextSize = "sm",
  values,
  dataFontWeight = 600,
  ...props
}) => {
  const { t } = useTranslation();
  return (
    !_.isEmpty(fields) &&
    values && (
      <SimpleGrid cols={cols} spacing={spacing} {...props}>
        {fields?.map(({ title, value, render, array }, index) => (
          <>
            {array && (
              <ArrayDisplay
                key={index}
                title={title}
                arr={array}
                titleTextSize={titleTextSize}
                dataTextSize={dataTextSize}
                t={t}
              />
            )}
            {!array && (
              <FieldDisplay
                key={index}
                title={title}
                value={value}
                render={render}
                dataFontWeight={dataFontWeight}
                titleTextSize={titleTextSize}
                dataTextSize={dataTextSize}
                t={t}
              />
            )}
          </>
        ))}
      </SimpleGrid>
    )
  );
};

export default InfoGridList;
