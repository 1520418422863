import React from "react";
import { ENUM_FORM_DISPLAY, ENUM_FORM_LAYOUT_CONTAINER } from "./pageList";
import { useFormRender } from "../hooks/useFormRender";
import { Button, Textarea } from "@mantine/core";
import { IconSettings } from "@tabler/icons";

import OrderReceiveCheckList from "./orderReceiveCheckList";
import OrderReceiveConfirm from "./orderReceiveConfirm";
import { useServerApi } from "../hooks/userServerApi";
import { showNotification } from "@mantine/notifications";
import RemoteSelect2 from "../components/remoteSelect2";

import ServiceCheckList from "../components/serviceCheckList";
import FormConfirmation from "../components/formConfirmation";
import moment from "moment";
import { useTranslation } from "react-i18next";

const OrderServiceCancelButton = ({ mainForm }) => {
  const [api] = useServerApi();

  const { t } = useTranslation();
  const formSchema = {
    name: "ReceiveOrderServiceRequest",
    title: "Cancel Order Service",
    description: "Please follow the step to cancel the service",

    display: {
      mode: ENUM_FORM_DISPLAY.MODAL,
      size: "1000px", //xs, sm, xl  ....100%
    },

    apiEntity: "orderService",
    initialValues: {},

    validate: {},
    showSaveBar: false,

    layout: {
      containers: [
        {
          key: "t1",
          parent: null,
          type: ENUM_FORM_LAYOUT_CONTAINER.WIZARD,

          props: {
            variant: "default", //default | pills | outline
            defaultValue: "Confirmation",
            orientation: "vertical",
            style: { minHeight: "400px" },
          },
          steps: [{ key: "t1-7", label: "Confirmation", icon: IconSettings }],
        },

        {
          key: "section-confirm",
          parent: "t1-7",
          type: ENUM_FORM_LAYOUT_CONTAINER.SECTION,
          props: {
            title: "Cancel Confirmation",
            description: "Please confirm the creation of order",
            withBorder: false,
            mt: 0,
            ml: 20,
          },
        },
      ],

      fields: [
        {
          name: "confirm",
          component: FormConfirmation,
          parent: "section-confirm",
          props: {
            successText: [
              "Please ONLY click the button when you confirm to cancel the order service.",
            ],
          },
        },
      ],
    },
  };

  const onSubmit = async ({ values, formMode }) => {
    try {
      values.status = "CAN";
      values.receieveDate = moment().format("YYYY-MM-DD");
      mainForm.updateAndSave(values);
      formAction.close();
    } catch (error) {}
  };

  const [renderForm, formAction, formStatus, formSetting] = useFormRender(
    formSchema,
    null,
    onSubmit
  );

  const handleActionClick = () => {
    const id = mainForm?.values._id;

    if (!id) return;
    formAction.open({ mode: "edit", id, data: mainForm.values });
  };

  return (
    <>
      {/* {mainForm?.values?.product?._id} */}
      <Button fullWidth onClick={handleActionClick} color="red">
        {t("Cancel Order Service")}
      </Button>

      {renderForm()}
    </>
  );
};

export default OrderServiceCancelButton;
