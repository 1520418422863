import {
  Accordion,
  Button,
  Text,
  Title,
  Table,
  Select,
  Group,
} from "@mantine/core";
import React, { useState, useEffect } from "react";
import moment from "moment";
import { useServerApi } from "../hooks/userServerApi";
import { useTranslation } from "react-i18next";

const ReportWallet = ({ name, apiEnti }) => {
  const [loading, setLoading] = useState(false);
  const [api] = useServerApi();
  const { t } = useTranslation();

  const downloadReport = async () => {
    try {
      setLoading(true);
      const result = await api.Wallet.getReport();
      setLoading(false);

      if (result && result.data && result.data.url) {
        window.open(result.data.url, "_blank");
      }
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  };

  return (
    <div>
      <Text size="xs">
        {t("Please click the below button to download the wallet report.")}
      </Text>

      <Button
        size="xs"
        mt="xl"
        onClick={() => downloadReport()}
        loading={loading}
      >
        {t("Download")}
      </Button>
      <></>
    </div>
  );
};

export default ReportWallet;
