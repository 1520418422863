import { Button, Group } from "@mantine/core";
import React, { useState } from "react";
import _ from "lodash";

import NewTicketButton from "./newTicketButton";
import { useServerApi } from "../hooks/userServerApi";
import DebitNoteConfirmPaymentButton from "./debitNoteConfirmPaymentButton";
import DebitNoteSettlePaymentButton from "./debitNoteSettlePaymentButton";
import DebitNoteUnSettlePaymentButton from "./debitNoteUnSettlePaymentButton";
import QuotationConfirmButton from "./quotationConfirmButton";
import { useDisclosure } from "@mantine/hooks";
import ReactJson from "react-json-view";
import DebitNoteEditButton from "./debitNoteEditButton";
import DebitNoteAdjustButton from "./debitNoteAdjustButton";
import DebitNoteHoldCoverButton from "./debitNoteHoldCoverButton";
import DebitNoteConfirmPolicyButton from "./debitNoteConfirmPolicyButton";

import { showNotification } from "@mantine/notifications";
import { IconAlertCircle } from "@tabler/icons";

const STATUS_ALLOWED_TO_EDIT = ["DFT", "WAIT", "CAN"];
const STATUS_ALLOWED_TO_ADJUST = ["HOLD", "CONFIRMED", "SETTLED"];
const STATUS_ALLOWED_TO_PRINT_RECEIPT = ["PAID", "SETTLED", "EXP"];
const STATUS_ALLOWED_TO_COVER = ["DFT", "WAIT", "CAN", "HOLD"];
const STATUS_ALLOWED_TO_TERMINATE = ["DFT", "WAIT", "CAN", "HOLD", "CONFIRMED"];
const PAYMENT_STATUS_ALLOWED_TO_CONFIRM = ["DFT", "WAIT"];
const PAYMENT_STATUS_ALLOWED_TO_SETTLE = ["PAID"];
const PAYMENT_STATUS_ALLOWED_TO_UNSETTLE = ["SETTLED"];
const PAYMENT_STATUS_ALLOWED_TO_PRINT_RECEIPT = ["PAID", "SETTLED"];

const PrintButton = ({ mainForm }) => {
  const [api] = useServerApi();
  const [printing, setPrinting] = useState(false);

  const print = async () => {
    try {
      const { _id } = mainForm.values;
      if (!_id) return;

      setPrinting(true);
      await mainForm.save();
      // console.log("print DN", mainForm.values);
      await new Promise((r) => setTimeout(r, 300));

      let result = await api.DebitNote.print(_id);
      // console.log(result.data.url);
      if (!result || !result.data || !result.data.url) return;
      window.open(result.data.url, "_blank");
      mainForm.setFieldValue("status", result.data.status);
      mainForm.setFieldValue("paymentStatus", result.data.paymentStatus);
      setPrinting(false);
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <Button fullWidth onClick={() => print()} loading={printing}>
      {printing ? "Printing" : "Print DebitNote"}
    </Button>
  );
};

const PrintReceiptButton = ({ mainForm }) => {
  const [api] = useServerApi();
  const [printing, setPrinting] = useState(false);

  const print = async () => {
    try {
      const { _id } = mainForm.values;
      if (!_id) return;

      setPrinting(true);
      await mainForm.save();
      // console.log("print Receipt", mainForm.values);
      await new Promise((r) => setTimeout(r, 300));

      let result = await api.DebitNote.printReceipt(_id);
      // console.log(result.data.url);
      if (!result || !result.data || !result.data.url) return;
      window.open(result.data.url, "_blank");

      setPrinting(false);
    } catch (error) {
      console.log(error);
      setPrinting(false);
      showNotification({
        title: `Print Receipt Fail`,
        icon: <IconAlertCircle size={18} />,
        color: "red",
        message: error,
      });
    }
  };

  return (
    <Button fullWidth onClick={() => print()} loading={printing}>
      {printing ? "Printing" : "Print Receipt"}
    </Button>
  );
};

const PrintRNButton = ({ mainForm }) => {
  const [api] = useServerApi();
  const [printing, setPrinting] = useState(false);

  const print = async () => {
    try {
      const { _id } = mainForm.values;
      if (!_id) return;

      setPrinting(true);
      await mainForm.save();
      // console.log("print DN", mainForm.values);
      await new Promise((r) => setTimeout(r, 300));

      let result = await api.DebitNote.printRenewalNotice(_id);
      // console.log(result.data.url);
      if (!result || !result.data || !result.data.url) return;
      window.open(result.data.url, "_blank");
      mainForm.setFieldValue("status", result.data.status);
      setPrinting(false);
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <Button fullWidth onClick={() => print()} loading={printing}>
      {printing ? "Printing" : "Print Renewal Notice"}
    </Button>
  );
};

const TerminateButton = ({ mainForm }) => {
  const [api] = useServerApi();
  const terminate = () => {
    mainForm.updateAndSave({ status: "CAN" });
  };

  return (
    <Button fullWidth color={"red"} onClick={() => terminate()}>
      Terminate
    </Button>
  );
};

const DebitNoteActionList = ({ form, role }) => {
  const {
    status,
    paymentStatus,
    code,
    consultants,
    _id,
    signedBy,
    costTotal,
    total,
  } = form.values;

  const DEBITNOTE_STATUS = [
    "DFT", // Initial Draft
    "WAIT", //Waiting to confirm
    "CAN", //Cancel / reject by client or provider
    "HOLD", //Hold Cover by Provider (Temp Confirm)
    "CONFIRMED", //Policy Confirmed
    "SETTLED", //will remove (ToDo)
    "EXP", //Expired. Policy Finish
  ];
  const DEBITNOTE_PAYMENT_STATUS = ["DFT", "WAIT", "PAID", "SETTLED"];

  const showConfirmPayment =
    paymentStatus === "WAIT" || paymentStatus === "DFT";

  const showPrint = true;

  const allowToEdit = STATUS_ALLOWED_TO_EDIT.includes(status);
  const allowToAdjust = STATUS_ALLOWED_TO_ADJUST.includes(status);
  const allowToCover = STATUS_ALLOWED_TO_COVER.includes(status);
  const allowToConfirmPayment =
    PAYMENT_STATUS_ALLOWED_TO_CONFIRM.includes(paymentStatus);
  const allowToSettlePayment =
    PAYMENT_STATUS_ALLOWED_TO_SETTLE.includes(paymentStatus);
  const allowToUnSettlePayment =
    PAYMENT_STATUS_ALLOWED_TO_UNSETTLE.includes(paymentStatus);

  const allowToPrintReceipt =
    PAYMENT_STATUS_ALLOWED_TO_PRINT_RECEIPT.includes(paymentStatus);

  const allowToTerminate = STATUS_ALLOWED_TO_TERMINATE.includes(status);

  const showSettle = true; //status === "PAID";
  const showUnSettle = true; //paymentStatus === "SETTLED";

  return (
    <>
      {/* <ReactJson src={form.values} style={{ background: "white" }} /> */}
      <Group mt={10}>
        {<PrintButton mainForm={form} />}
        {/* {<PrintRNButton mainForm={form} />} */}
        {allowToPrintReceipt && <PrintReceiptButton mainForm={form} />}

        {allowToEdit && <DebitNoteEditButton mainForm={form} />}
        {allowToAdjust && <DebitNoteAdjustButton mainForm={form} />}

        <NewTicketButton
          buttonType="fullWidth"
          code={code}
          entityId={_id}
          entity="debitNote"
          ticketType={"TK-GI-DEBIT"}
          members={consultants?.map((c) => c._id)}
        />

        {allowToCover && <DebitNoteHoldCoverButton mainForm={form} />}
        {<DebitNoteConfirmPolicyButton mainForm={form} />}
        {allowToConfirmPayment && (
          <DebitNoteConfirmPaymentButton mainForm={form} />
        )}
        {allowToSettlePayment && (
          <DebitNoteSettlePaymentButton mainForm={form} />
        )}
        {allowToUnSettlePayment && (
          <DebitNoteUnSettlePaymentButton mainForm={form} />
        )}

        {allowToTerminate && <TerminateButton mainForm={form} />}
      </Group>
    </>
  );
};

export default DebitNoteActionList;
