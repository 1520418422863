import React, { useEffect, useState, useRef } from "react";
import {
  Button,
  TextInput,
  Group,
  NumberInput,
  Badge,
  Switch,
} from "@mantine/core";

import ReactJson from "react-json-view";
import _ from "lodash";
import ConsultantList from "./consultantList";
import { useServerApi } from "../hooks/userServerApi";
import { useFormRender } from "../hooks/useFormRender";
import { ENUM_FORM_DISPLAY, ENUM_FORM_LAYOUT_CONTAINER } from "./pageList";
import WalletMembers from "./walletMembers";
import WalletShareChecker from "./walletShareChecker";
const formSchema = {
  title: "Group Wallet ",

  display: {
    mode: ENUM_FORM_DISPLAY.INSIDE,
  },

  apiEntity: "wallet",
  title: "Wallet",
  // showSaveButton: true,
  // saveButtonText: "Save Change",
  saveWithNotification: false,
  initialValues: {
    type: "GROUP",
    members: [],
  },

  layout: {
    containers: [
      {
        key: "main-g",
        parent: null,
        type: ENUM_FORM_LAYOUT_CONTAINER.GRID,
        cols: [
          {
            key: "main-g1",
            props: {
              span: 8,
            },
          },
          {
            key: "main-g2",
            props: {
              span: 4,
            },
          },
        ],
      },
    ],

    fields: [
      {
        name: "members",
        component: WalletMembers,
        parent: "main-g1",
        props: {
          label: "Members",
        },
      },
      {
        name: "members",
        component: WalletShareChecker,
        parent: "main-g2",
      },
      // {
      //   name: "active",
      //   component: Switch,
      //   parent: "main-g2",
      //   props: {
      //     label: "Active",
      //     mt: "xl",
      //   },
      // },
    ],
  },
};

const GroupWallet = ({ form: mainForm, name, ...props }) => {
  const walletId = _.get(mainForm.values, name);
  const groupdId = _.get(mainForm.values, "_id");

  const [api] = useServerApi();

  useEffect(() => {
    if (walletId) return formAction.open({ mode: "edit", id: walletId });
  }, [walletId]);

  const createWallet = async () => {
    formAction.open({ mode: "add" });
    const values = {
      type: "GROUP",
      userGroup: groupdId,
      members: [],
    };
    let result = await api.add({ apiEntity: "wallet", values });
    if (!result.success) return;
    mainForm.setFieldValue(name, result.data._id);
    mainForm.save();
  };

  const onSubmitSucces = (o) => {
    console.log("onSubmitSucces", o);
  };

  const [renderForm, formAction, formStatus, formSetting] = useFormRender(
    formSchema,
    onSubmitSucces
  );

  return (
    <>
      {!walletId && (
        <Button size="xs" onClick={createWallet} disabled={!groupdId}>
          Create
        </Button>
      )}

      {renderForm()}
    </>
  );
};

export default GroupWallet;
