import React, { useState, useEffect, useRef } from "react";
import { useServerApi } from "../hooks/userServerApi";
import _ from "lodash";
import cx from "clsx";
import classes from "./DndListHandle.module.css";
import { useTranslation } from "react-i18next";
import {
  Timeline,
  Text,
  Avatar,
  Group,
  ActionIcon,
  ScrollArea,
  Box,
  Grid,
  List,
  Button,
} from "@mantine/core";
import {
  IconCircleMinus,
  IconTrash,
  IconGripVertical,
  IconPencil,
} from "@tabler/icons";
import { DragDropContext, Droppable, Draggable } from "@hello-pangea/dnd";
import { useForceUpdate, useListState } from "@mantine/hooks";
import useDeepCompareEffect from "../hooks/useDeepCompareEffect";

const OrderList = ({
  form,
  name,
  label,
  scrollHeight = 300,
  onEnterSave = false,
  rowFields = [],
  defaultRowValue = {},
  addLabel = "Add",
  row,

  ...props
}) => {
  const list = _.get(form.values, name) || [];
  const [items, setItems] = useState(
    list.map((r, index) => ({
      id: `item-${index}`,
      content: r,
      edit: false,
    }))
  );
  const ref = useRef(null);
  const forceUpdate = useForceUpdate();

  useDeepCompareEffect(() => {
    setItems(
      list.map((r, index) => ({
        id: `item-${index}`,
        content: r,
        edit: false,
      }))
    );
  }, [list]);

  const handleKeyDown = async (e) => {
    if (e.key === "Enter") {
      list.push(e.target.value);
      ref.current.value = "";
      if (onEnterSave) form.updateAndSave({ [name]: list });
      else form.setFieldValue(name, list);
      setItems(
        list.map((r, index) => ({
          id: `item-${index}`,
          content: r,
          edit: false,
        }))
      );
    }
  };

  const handleAdd = () => {
    list.push({ ...defaultRowValue });
    if (onEnterSave) form.updateAndSave({ [name]: list });
    else form.setFieldValue(name, list);
    setItems(
      list.map((row, index) => ({
        id: `item-${index}`,
        content: row,
        edit: true,
      }))
    );
  };

  const remove = (index) => {
    // console.log("Remove", list, name, index);
    list.splice(index, 1);
    // form.updateAndSave({ [name]: list });
    // ref.current.value = "";
    if (onEnterSave) form.updateAndSave({ [name]: list });
    else form.setFieldValue(name, list);
    setItems(
      list.map((r, index) => ({
        id: `item-${index}`,
        content: r,
        edit: false,
      }))
    );
  };

  const edit = (index) => {
    items[index].edit = !items[index].edit;
    forceUpdate();
  };

  const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    // console.log("reorder", startIndex, endIndex, result);
    result.splice(endIndex, 0, removed);
    // console.log("reorder", startIndex, endIndex, result);
    return [...result];
  };

  // // a little function to help us with reordering the result
  // const reorderBug = (list, startIndex, endIndex) => {
  //   const result = Array.from(list);
  //   const [removed] = result.splice(startIndex, 1);
  //   result.splice(endIndex, 0, removed);

  //   return result;
  // };

  const grid = 8;

  const getItemStyle = (isDragging, draggableStyle) => ({
    // some basic styles to make the items look a bit nicer
    userSelect: "none",
    // padding: grid * 2,
    margin: `0 0 ${grid}px 0`,

    // change background colour if dragging
    // background: isDragging ? "lightgreen" : "grey",

    // styles we need to apply on draggables
    ...draggableStyle,
  });

  const onDragEnd = (result) => {
    // dropped outside the list
    if (!result.destination) {
      return;
    }

    const newItems = reorder(
      items,
      result.source.index,
      result.destination.index
    );
    setItems(newItems);
    form.setFieldValue(name, [...newItems.map((i) => i.content)]);
    // forceUpdate();
  };

  const renderViewRow = (item) => {
    return (
      <Group noWrap>
        {/* <JsonViewer src={item} /> */}
        {/* <JsonViewer src={rowFields} /> */}
        {rowFields.map((r) => (
          <r.viewComponent>{item.content[r.name]}</r.viewComponent>
        ))}
      </Group>
    );
  };

  const renderEditRow = (item, index) => {
    return <row.component item={item} index={index} />;
  };

  const { t } = useTranslation();

  return (
    <Box {...props}>
      {/* entityId:{entityId} */}
      {/* {<JsonViewer src={_.get(form.values, name)} />} */}

      {label && (
        <Text size={props.size ?? "xs"} mb="xs">
          {label}
        </Text>
      )}
      {/* <TextInput ref={ref} mb={20} onKeyDown={handleKeyDown} /> */}

      <DragDropContext onDragEnd={onDragEnd}>
        <Droppable droppableId="droppable">
          {(provided, snapshot) => (
            <div {...provided.droppableProps} ref={provided.innerRef}>
              {items.map((item, index) => (
                <Draggable
                  key={`item-${index}`}
                  draggableId={`item-${index}`}
                  index={index}
                >
                  {(provided, snapshot) => (
                    <div
                      ref={provided.innerRef}
                      {...provided.draggableProps}
                      {...provided.dragHandleProps}
                      style={getItemStyle(
                        snapshot.isDragging,
                        provided.draggableProps.style
                      )}
                    >
                      <Grid columns={24} align="center">
                        <Grid.Col span={1}>
                          <IconGripVertical size={15}></IconGripVertical>
                        </Grid.Col>
                        <Grid.Col span={22}>
                          {renderEditRow(item, index)}
                        </Grid.Col>
                        <Grid.Col span={1}>
                          <ActionIcon
                            // mx={"sm"}
                            size={"xs"}
                            onClick={() => remove(index)}
                            variant="default"
                          >
                            <IconCircleMinus />
                          </ActionIcon>
                        </Grid.Col>
                      </Grid>
                    </div>
                  )}
                </Draggable>
              ))}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext>
      <Button mt={"xl"} variant="default" size="xs" onClick={() => handleAdd()}>
        {t(addLabel)}
      </Button>
    </Box>
  );
};

export default OrderList;
