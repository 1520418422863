import React, { useState, useEffect } from "react";
import ActionCellRender from "../../components/actionCellRender";
import {
  IconTrash,
  IconPencil,
  IconSettings,
  IconAlertTriangle,
  IconCircleCheck,
} from "@tabler/icons";
import {
  PageList,
  ENUM_FORM_DISPLAY,
  ENUM_FORM_LAYOUT_CONTAINER,
} from "../../components/pageList";
import { useCellRender } from "../../hooks/useCellRender";
import OrderPageStats from "../../components/orderPageStats";
import FileList from "../../components/fileList";
import ActivityLog from "../../components/activityLog.js";
import RemoteSelect2 from "../../components/remoteSelect2";
import FollowupActionTable from "../../components/followupActionTable.js";
import ClientRiskStats from "../../components/clientRiskStats";
import ClientAMLList from "../../components/clientAMLList";
import ClientRPQList from "../../components/clientRPQList";
import OrderApplicantDetail from "../../components/orderApplicantDetail";
import OrderSummary from "../../components/orderSummary";
import OrderServiceSummary from "../../components/orderServiceSummary";
import OrderPolicy from "../../components/orderPolicy";
import OrderPaymentDetail from "../../components/orderPaymentDetail";
import OrderProductDetail from "../../components/orderProductDetail";
import OrderConsultantList from "../../components/orderConsultantList";
import OrderRiderList from "../../components/orderRiderList";
import OrderServiceList from "../../components/orderServiceList";
import OrderRelatedPersonDetail from "../../components/orderRelatedPersonDetail";
import OrderInsuredDetail from "../../components/orderInsuredDetail";
import OrderServiceActionList from "../../components/orderServiceActionList";
import { DATA_ORDER_TYPE, DATA_COUNTRY } from "../../data/options";
import Chips from "../../components/chips";
import { useNavigate } from "react-router-dom";

import ConsultantList from "../../components/consultantList";
import OrderPaymentDetailForm from "../../components/orderPaymentDetailForm";
import OrderProductVariation from "../../components/orderProductVariation";
import ProductSelection from "../../components/productSelection";
import OrderQuotationList from "../../components/orderQuotationList";
import OrderInvoiceList from "../../components/orderInvoiceList";
import OrderCommissionPaymentList from "../../components/orderCommissionPaymentList";
import OrderProfolio from "../../components/orderProfolio";
import FormConfirmation from "../../components/formConfirmation";
import {
  Select,
  Checkbox,
  TextInput,
  Switch,
  Text,
  List,
  ThemeIcon,
  Group,
  Badge,
} from "@mantine/core";
import _ from "lodash";
import { DatePicker } from "@mantine/dates";
import { useAuthUser } from "react-auth-kit";
import OrderBeneficiaryList from "../../components/orderBeneficiaryList";
import OrderInsuredList from "../../components/orderInsuredList";
import ConsultantSelect from "../../components/consultantSelect";
import OrderClientSelect from "../../components/orderClientSelect";
import OrderAddressSelect from "../../components/orderAddressSelect";
import moment from "moment";
import RemarkList from "../../components/remarkList";
import { useServerApi } from "../../hooks/userServerApi";
import DummyWidget from "../../components/dummyWidget";
import OrderTimeline from "../../components/orderTimeline";
import OrderPendingCase from "../../components/orderPendingCase";
import OrderAlert from "../../components/orderAlert";

const OrderService = ({}) => {
  const apiEntity = "orderService";
  const [cellRender] = useCellRender();
  const auth = useAuthUser();
  const [preQuery, setPreQuery] = useState();
  const navigate = useNavigate();
  const [api] = useServerApi();
  const userRoleName = auth().userRole?.name?.toUpperCase() ?? "";
  const currentURL = window.location.pathname;
  const [disableCreateOrder, setDisableCreateOrder] = useState(false);

  const tableSchema = {
    showActionColumn: true,

    columns: [
      {
        field: "code",
        headerName: "Order Service",
        sortable: true,
        cellRender: (col, data) => cellRender.Order.serviceCode(data),
      },
      {
        field: "order.code",
        headerName: "Order",
        sortable: true,
        cellRender: (col, data) => cellRender.Order.code(data.orderId),
      },

      {
        field: "product",
        headerName: "Product",
        sortable: true,
        cellRender: (col, data) =>
          cellRender.ProductInfo(data.orderId?.product),
      },
      {
        field: "policyNumber",
        headerName: "Policy No.",
        sortable: true,

        cellRender: (col, data) => <>{data.orderId?.policyNumber}</>,
      },
      {
        field: "client",
        sortable: true,
        cellRender: (col, data) =>
          cellRender.ClientInfo(data.orderId.clientSnapShot, true),
      },
      {
        field: "consultants",
        headerName: "Consultants",
        sortable: true,
        cellRender: (col, data) =>
          cellRender.Users(data.orderId.consultants, 3, true),
      },

      {
        field: "action",
        cellRender: ActionCellRender,
        isAction: true,
        actions: [
          {
            name: "edit",
            icon: IconPencil,
            props: {
              //   color:"red"
            },
          },
        ],
        actionMenu: [
          // {
          //   name: "delete",
          //   label: "Delete data",
          //   icon: IconTrash,
          //   props: {
          //     color: "red",
          //   },
          // },
        ],
      },
    ],
    searchableFields: [
      "status",
      "code",
      "serviceTypeSnapShot.name",
      "order.policyNumber",
    ],
  };

  const editFormSchema = {
    display: {
      mode: ENUM_FORM_DISPLAY.LINK,
    },
    title: "Order Service",
    titleField: "code",
    apiEntity,
    initialValues: {},

    layout: {
      containers: [
        {
          key: "main-g",
          parent: null,
          type: ENUM_FORM_LAYOUT_CONTAINER.GRID,
          props: {
            gutter: "xl",
            columns: 24,
          },
          cols: [
            {
              key: "main-g1",
              props: {
                span: 7,
              },
            },
            {
              key: "main-g2",
              props: {
                span: 17,
              },
            },
          ],
        },
        {
          key: "section-applicant",
          parent: "main-g1",
          type: ENUM_FORM_LAYOUT_CONTAINER.SECTION,
          props: {
            title: "Applicant Details",
            // titleOrder: 5,
            // description: "Please assign the corresponding consultant",
            withBorder: false,
            mt: 0,
            mb: "md",
          },
        },

        {
          key: "section-consultant",
          parent: "main-g1",
          type: ENUM_FORM_LAYOUT_CONTAINER.BOX,
          props: {
            mt: 0,
            mb: 0,
          },
        },

        {
          key: "section-action-list",
          parent: "main-g1",
          type: ENUM_FORM_LAYOUT_CONTAINER.SECTION,
          props: {
            title: "Actions",
            // titleOrder: 5,
            // description: "Please assign the corresponding consultant",
            withBorder: false,
            minHeight: "0px",
            mt: 0,
            mb: 20,
          },
        },

        {
          key: "main-tab",
          parent: "main-g2",
          type: ENUM_FORM_LAYOUT_CONTAINER.TABS,
          props: {
            variant: "default", //default | pills | outline
            defaultValue: "Overview",
          },
          tab: [
            {
              key: "tab-overview",
              label: "Overview",
              //icon: IconSettings,
            },

            {
              key: "tab-files",
              label: "Files",
              //icon: IconSettings
            },

            {
              key: "tab-logs",
              label: "Logs",
              // icon: IconSettings
            },
          ],
        },

        {
          key: "section-service",
          parent: "tab-overview",
          type: ENUM_FORM_LAYOUT_CONTAINER.SECTION,
          props: {
            title: "Order Service",
            titleOrder: 5,
            // description: "Please assign the corresponding consultant",
            withBorder: false,
            minHeight: 0,
            mt: 0,
            mb: 20,
          },
        },

        {
          key: "section-remarks",
          parent: "tab-overview",
          type: ENUM_FORM_LAYOUT_CONTAINER.SECTION,
          props: {
            title: "Service Remarks",
            titleOrder: 5,
            // description: "Please assign the corresponding consultant",
            withBorder: false,
            minHeight: 0,
            mt: 20,
            mb: 20,
          },
        },
        {
          key: "section-summary",
          parent: "tab-overview",
          type: ENUM_FORM_LAYOUT_CONTAINER.SECTION,
          props: {
            title: "Order Summary",
            titleOrder: 5,
            // description: "Please assign the corresponding consultant",
            withBorder: false,
            minHeight: 0,
            mt: 0,
            mb: 20,
          },
        },

        {
          key: "section-timeline",
          parent: "tab-overview",
          type: ENUM_FORM_LAYOUT_CONTAINER.SECTION,
          props: {
            title: "Order Timeline",
            titleOrder: 5,
            // description: "Please assign the corresponding consultant",
            withBorder: false,
            minHeight: 0,

            mt: 20,
            mb: 20,
          },
        },
        {
          key: "section-policy",
          parent: "tab-overview",
          type: ENUM_FORM_LAYOUT_CONTAINER.SECTION,
          props: {
            title: "Policy Info",
            titleOrder: 5,
            // description: "Please assign the corresponding consultant",
            withBorder: false,
            minHeight: 0,

            mt: 20,
            mb: 20,
          },
        },

        {
          key: "section-product",
          parent: "tab-overview",
          type: ENUM_FORM_LAYOUT_CONTAINER.SECTION,
          props: {
            title: "Product",
            titleOrder: 5,
            // description: "Please assign the corresponding consultant",
            withBorder: false,
            minHeight: 0,
            mt: 20,
            mb: 20,
          },
        },

        {
          key: "section-payment",
          parent: "tab-overview",
          type: ENUM_FORM_LAYOUT_CONTAINER.SECTION,
          props: {
            title: "Payment",
            titleOrder: 5,
            // description: "Please assign the corresponding consultant",
            withBorder: false,
            minHeight: 0,
            mt: 20,
            mb: 20,
          },
        },

        {
          key: "section-log",
          parent: "tab-logs",
          type: ENUM_FORM_LAYOUT_CONTAINER.SECTION,
          props: {
            title: "Order Log",
            titleOrder: 5,
            // description: "Please assign the corresponding consultant",
            withBorder: false,
            mt: 20,
            mb: 20,
          },
        },

        {
          key: "section-files",
          parent: "tab-files",
          type: ENUM_FORM_LAYOUT_CONTAINER.SECTION,
          props: {
            title: "Files",
            titleOrder: 5,
            // description: "Please assign the corresponding consultant",
            withBorder: false,
            minHeight: "0px",
            mt: 20,
            mb: 20,
          },
        },
      ],

      fields: [
        {
          name: "orderId",
          component: OrderServiceSummary,
          parent: "section-service",
          props: {},
        },
        {
          name: "remarks",
          component: RemarkList,
          parent: "section-remarks",
          props: {
            scrollHeight: 100,
          },
        },

        {
          name: "files",
          component: FileList,
          parent: "section-files",
          props: {},
        },

        {
          name: "log",
          component: ActivityLog,
          parent: "section-log",
          props: {},
        },

        {
          name: "orderId.clientSnapShot",
          component: OrderApplicantDetail,
          parent: "section-applicant",
          props: {},
        },
        {
          name: "orderId",
          component: OrderTimeline,
          parent: "section-timeline",
          props: {},
        },

        {
          name: "orderId",
          component: OrderSummary,
          parent: "section-summary",
          props: {},
        },

        {
          name: "orderId",
          component: OrderPolicy,
          parent: "section-policy",
          props: {},
        },
        {
          name: "orderId",
          component: OrderProductDetail,
          parent: "section-product",
          props: {},
        },
        {
          name: "orderId",
          component: OrderPaymentDetail,
          parent: "section-payment",
          props: {},
        },
        {
          name: "actionList",
          component: OrderServiceActionList,
          parent: "section-action-list",
          props: {},
        },
      ],
    },
  };
  return (
    <PageList
      title={"Order Services"}
      apiEntity={apiEntity}
      tableSchema={tableSchema}
      formSchema={editFormSchema}
      showImport={false}
      initSort={{ by: "createdAt", order: "desc" }}
      showAddButton={false}
      //   searchByTextField={true}
      // stats={{
      //   component: OrderPageStats,
      //   props: {
      //     mb: 35,
      //     role,
      //   },
      // }}
    />
  );
};

export default OrderService;
