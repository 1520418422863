import { Button, Group } from "@mantine/core";
import React from "react";
import _ from "lodash";

import OrderConfirmPolicyButton from "./orderConfirmPolicyButton";
import OrderEditPolicyButton from "./orderEditPolicyButton";

import OrderReceiveActionButton from "./orderReceiveActionButton";
import OrderAddRiderActionButton from "./orderAddRiderActionButton";
import OrderAddServiceButton from "./orderAddServiceButton";

import OrderPrintSubmissionCoverSheetActionButton from "./orderPrintSubmissionCoverSheetActionButton";
import OrderPrintReceiveCoverSheetActionButton from "./orderPrintReceiveCoverSheetActionButton";

import OrderTerminateActionButton from "./orderTerminateActionButton";
import OrderEditButton from "./orderEditButton";
import OrderAdjustButton from "./orderAdjustButton";
import OrderReinstantiateButton from "./orderReinstantiateButton";

import NewTicketButton from "./newTicketButton";
import OrderDeliveryPolicyButton from "./orderDeliverPolicyButton";

import OrderServiceReceiveActionButton from "./orderServiceReceiveActionButton";
import OrderServicePrintCoverSheetButton from "./orderServicePrintCoverSheetButton";
import OrderServicePrintSubmitCoverSheetButton from "./orderServicePrintSubmitCoverSheetButton";
import OrderServiceCancelButton from "./orderServiceCancelButton";
import OrderServiceCompleteButton from "./orderServiceCompleteButton";

const ORDER_STATUS_ALLOWED_TO_EDIT = [
  "DFT",
  "CAN",
  "RCV",
  "SUB",
  "PND",
  "AIP",
  "UNKNOWN",
];

const ORDER_STATUS_ALLOW_CONSULTANT_TO_EDIT = ["DFT", "CAN"];
const ORDER_STATUS_ALLOWED_TO_EDIT_POLICY = [
  "INF",
  "RCV",
  "SUB",
  "PND",
  "AIP",
  "ACT",
];

const ORDER_STATUS_ALLOWED_TO_RECEIVE = ["DFT", "RCV"];
const ORDER_STATUS_ALLOWED_TO_ADJUST = ["INF"];
const ORDER_STATUS_ALLOWED_TO_CONFIRM = [
  "RCV",
  "SUB",
  "PND",
  "AIP",
  "ACT",
  "UNKNOWN",
];

const ORDER_STATUS_ALLOWED_TO_REINSTANT = [
  "TER",
  "WTH",
  "COL",
  "SUR",
  "REJ",
  "LAP",
];

const OrderServiceActionList = ({ form, role }) => {
  // let status = _.get(form.values, "status");
  const { status } = form.values;

  const showReceiveButton = true;
  const showReceiveCoverSheet = true;
  const showSubmitCoverSheet = true;
  const showCancelButton = true;

  return (
    <>
      {/* OrderServiceActionList */}
      <Group mt={10}>
        <OrderServicePrintCoverSheetButton mainForm={form} />

        {showReceiveButton && (
          <OrderServiceReceiveActionButton mainForm={form} />
        )}

        <OrderServicePrintSubmitCoverSheetButton mainForm={form} />
        {<OrderServiceCancelButton mainForm={form} />}
        {<OrderServiceCompleteButton mainForm={form} />}

        {/* {showReceiveCoverSheet && (
          <OrderServicePrintReceiveCoverSheetActionButton mainForm={form} />
        )}

        {showSubmitCoverSheet && (
          <OrderServicePrintSubmissionCoverSheetActionButton mainForm={form} />
        )} */}

        {/* <NewTicketButton
          buttonType="fullWidth"
          code={code}
          entityId={_id}
          entity="order"
          ticketType={"TK-ORDER"}
          members={consultants?.map((c) => c._id)}
        /> */}
      </Group>
    </>
  );
};

export default OrderServiceActionList;
