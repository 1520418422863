import React, { useState, useEffect } from "react";
import moment from "moment";
import { DatePicker } from "@mantine/dates";
import {
  IconTrash,
  IconCheck,
  IconCircleCheck,
  IconAlertOctagon,
  IconPencil,
  IconCross,
} from "@tabler/icons";
import _ from "lodash";

import {
  Switch,
  TextInput,
  Select,
  Textarea,
  NumberInput,
  Group,
  Text,
  Button,
} from "@mantine/core";
import { DATA_GENDERS, DATA_RELATIONSHIP } from "../data/options";

import { ENUM_FORM_DISPLAY, ENUM_FORM_LAYOUT_CONTAINER } from "./pageList";
import LocalPageList from "./localPageList";
import FileList from "./fileList";
import ActionCellRender from "./actionCellRender";
import { useFormatter } from "../hooks/useFomatter";

import { useServerApi } from "../hooks/userServerApi";
import ReactJson from "react-json-view";
import { useCellRender } from "../hooks/useCellRender";
import useDeepCompareEffect from "../hooks/useDeepCompareEffect";
import { useForceUpdate, randomId } from "@mantine/hooks";
import DataTable from "./dataTable";
import { useTranslation } from "react-i18next";

const OrderRiderList = ({ form: mainForm, ...props }) => {
  const orderId = _.get(mainForm.values, "_id");
  const [formatter] = useFormatter();

  //   const [rows, setRows] = useState([]);
  const [api] = useServerApi();
  const [cellRender] = useCellRender();
  const forceUpdate = useForceUpdate();
  const { t } = useTranslation();
  const tableSchema = {
    hidePagination: true,
    columns: [
      // {   field: '_id',       headerName:"ID"            },
      {
        field: "code",
        headerName: "Order Code",
        cellRender: (col, order) => (
          <a
            href={`/operation/order?id=${order._id}&mode=${"edit"}`}
            target="_blank"
            style={{ textDecoration: "none" }}
          >
            {order.code}
          </a>
        ),
      },
      {
        field: "product",
        headerName: "Product",
        cellRender: (col, data) => cellRender.ProductInfo(data.product),
      },
      {
        field: "payment.payMode",
        headerName: "Pay Mode",
      },
      {
        field: "payment.premium",
        headerName: "Premium",
        cellRender: (col, data) =>
          cellRender.Currency(data.payment.premium, data.payment.currency),
      },
      {
        field: "status",
        headerName: "Status",
        cellRender: (col, data) => cellRender.Order.status(data.status),
      },

      // {
      // 	field: "product",
      // 	headerName: "Product",
      // 	cellRender: (col, data) => cellRender.ProductInfo(product),
      // },
    ],
    searchableFields: ["code", "name", "cname", "email"],
  };

  const rows = _.get(mainForm.values, "riders") ?? [];
  //   setRows(riders);

  //   useEffect(() => {
  //      setRows(riders);
  //     forceUpdate();
  //   }, [riders]);

  return (
    <>
      {/* <ReactJson src={rows} collapsed style={{ background: "white" }} /> */}
      {_.isEmpty(rows) && (
        <Group>
          <Text size="xs">{t("No Rider Plans")}</Text>
        </Group>
      )}
      {!_.isEmpty(rows) && (
        <DataTable
          data={rows ?? []}
          columns={tableSchema.columns}
          hidePagination={true}
          hideSearchBar={true}
          // onRowDoubleClick={handleOnEdit}
          // onActionBtnClick={handleActionBtnClick}
          minHeight="0px"
        />
      )}

      {/* <LocalPageList
				rows={rows}
				tableSchema={tableSchema}
				readOnly={true}
				// btnLabel={rows?.length > 0 ? "Change Insured" : "Add Insured"}
				{...props}
			/>
			<Button onClick={() => initRowData()}>Refresh</Button> */}
    </>
  );
};

export default OrderRiderList;
