import {
  Button,
  NumberInput,
  Group,
  Text,
  Title,
  Table,
  SimpleGrid,
  Divider,
  Checkbox,
} from "@mantine/core";
import React, { useState } from "react";
import _ from "lodash";
import ReactJson from "react-json-view";
import { useServerApi } from "../hooks/userServerApi";
import { useFormatter } from "../hooks/useFomatter";
import { useTranslation } from "react-i18next";

const TestResultTable = ({ result, title = "Result" }) => {
  const [formatter] = useFormatter();
  const { t } = useTranslation();
  const TableHeader = () => (
    <thead>
      <tr>
        <th>#</th>
        <th>{t("Level")}</th>
        <th>{t("Percentage")}</th>
        <th>{t("Amount")}</th>
      </tr>
    </thead>
  );

  const TableRows = ({ result }) => {
    let rows = result.levelsCommission
      .sort((a, b) => b.level.index - a.level.index)
      .map((p) => {
        return {
          index: p.level.index,
          level: p.levelName,
          percentage: p.level.percentage,
          amount: p.totalAmount,
        };
      });
    return (
      <tbody>
        {rows.map((r, index) => (
          <tr key={index}>
            <td>{r.index}</td>
            <td>{r.level}</td>
            <td>{r.percentage}%</td>
            <td>{formatter.currency(r.amount)}</td>
          </tr>
        ))}
      </tbody>
    );
  };

  const TableFooter = ({ result }) => (
    <tfoot>
      <tr>
        <th></th>
        <th></th>
        <th></th>
        <th>
          {formatter.currency(
            result.levelsCommission.reduce((a, c) => a + c.totalAmount, 0)
          )}
        </th>
      </tr>
    </tfoot>
  );
  return (
    <>
      {/* <ReactJson
        src={result}
        name="result"
        collapsed
        style={{ background: "white" }}
      /> */}
      <Title order={4} mb={"xl"}>
        {t(title)}
      </Title>

      {/* <Group>
        <Text>
          Preimum Receivable : {formatter.currency(result.receiveable)}
        </Text>
      </Group> */}

      <Table>
        <TableHeader />
        <TableRows result={result} />
        <TableFooter result={result} />
      </Table>
    </>
  );
};

const OverridingTester = ({ form: mainForm, name }) => {
  const table = _.get(mainForm.values, name);
  const [preimum, setPreimum] = useState(330000);
  const [skipProviderRate, setSkipProviderRate] = useState(false);
  const [rate, setRate] = useState(6);
  const [internalExchangeRate, setInternalExchangeRate] = useState(7.8);
  const [providerExchangeRate, setProviderExchangeRate] = useState(7.8);
  const [api] = useServerApi();
  const [formatter] = useFormatter();
  const [testResult, setTestResult] = useState(null);
  const { t } = useTranslation();

  const genResult = async () => {
    let result = await api.getOverridingResult({
      preimum,
      rate,
      providerExchangeRate,
      internalExchangeRate,
      plan: mainForm.values,
    });
    // console.log(result.data);
    setTestResult(result.data);
  };

  // const handleKeyDown = () => {
  //   console.log("keyDown");
  // };
  return (
    <>
      {/* <ReactJson
        src={table}
        name="table"
        collapsed
        style={{ background: "white" }}
      /> */}
      <SimpleGrid cols={2}>
        <NumberInput
          label={t("Premium ($)")}
          defaultValue={preimum}
          precision={2}
          parser={formatter.currencyParser}
          formatter={formatter.currencyFormat}
          onChange={(v) => setPreimum(v)}
        />
        <NumberInput
          label={t("Provider Rate (%)")}
          precision={2}
          defaultValue={rate}
          placeholder={"50"}
          onChange={(v) => setRate(v)}
        />
        <NumberInput
          label={t("Provider Exchange Rate")}
          precision={5}
          defaultValue={7.8}
          placeholder={"50"}
          onChange={(v) => setProviderExchangeRate(v)}
        />
        <NumberInput
          label={t("Internal Exchange Rate")}
          precision={5}
          defaultValue={7.8}
          placeholder={"50"}
          onChange={(v) => setInternalExchangeRate(v)}
        />
      </SimpleGrid>

      <Button
        size="xs"
        mt={"xl"}
        mb={50}
        disabled={!preimum || !rate}
        onClick={genResult}
      >
        {t("Test")}
      </Button>

      {testResult && (
        <>
          <Divider mb={"xl"} />
          <TestResultTable result={testResult} title={t("Test Result")} />
        </>
      )}
    </>
  );
};

export default OverridingTester;
