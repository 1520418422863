import React, { useState } from "react";
import {
  Button,
  Group,
  Popover,
  TextInput,
  Modal,
  Text,
  ScrollArea,
  ActionIcon,
  Tooltip,
  Divider,
} from "@mantine/core";
import {
  IconArrowDownBar,
  IconDeviceFloppy,
  IconDotsVertical,
  IconDownload,
} from "@tabler/icons";
import { useServerApi } from "../hooks/userServerApi";
import { useDisclosure } from "@mantine/hooks";
import _ from "lodash";
import { useTranslation } from "react-i18next";

const TemplateSaveLoadButtons = ({
  form,
  name,
  templateCategory,
  onLoadTemplate,
  currentContent,
  mb,
}) => {
  const [saveTemplateOpen, setSaveTemplateOpen] = useState(false);
  const [templateName, setTemplateName] = useState();
  const [
    templateLoaderOpened,
    { open: openTemplateLoader, close: closeTemplateLoader },
  ] = useDisclosure(false);

  const [templates, setTemplates] = useState([]);
  const [api] = useServerApi();

  const saveTemplate = async () => {
    setSaveTemplateOpen(false);
    const res = await api.add({
      apiEntity: "template",
      values: {
        category: templateCategory,
        name: templateName,
        content: currentContent,
      },
    });
    console.log(res);
  };

  const loadTemplate = async () => {
    const res = await api.search({
      apiEntity: "template",
      searchQuery: { category: templateCategory },
      pageSize: 500,
      sort: { by: "name", order: "asc" },
    });
    // if (_.isEmpty(res.docs)) return;
    setTemplates(res.docs);
    openTemplateLoader();
  };

  const onRemoveTemplate = async (template) => {
    const confirm = window.confirm(
      "Are you sure to remove this template?",
      template.name
    );
    if (!confirm) return;
    const res = await api.removeById({
      apiEntity: "template",
      id: template._id,
    });
    loadTemplate();
  };

  const { t: translate } = useTranslation();
  return (
    <>
      <Modal
        opened={templateLoaderOpened}
        onClose={closeTemplateLoader}
        title={`${translate("Load")} ${translate(templateCategory)} ${translate(
          "template"
        )}`}
        scrollAreaComponent={ScrollArea.Autosize}
      >
        {/* Modal content */}
        <Divider mb="xl" />
        {_.isEmpty(templates) && (
          <Text>{translate("There are no templates.")}</Text>
        )}
        {templates.map((t, index) => (
          <Group key={index} position="apart" mb={"md"}>
            <Text>{t.name}</Text>
            <Group spacing={"xs"}>
              <Button
                variant="default"
                size="xs"
                onClick={() => {
                  onLoadTemplate(templates[index]);
                  closeTemplateLoader();
                }}
              >
                {translate("Select")}
              </Button>
              <Button
                variant="default"
                size="xs"
                c="red"
                onClick={() => onRemoveTemplate(t)}
              >
                {translate("Remove")}
              </Button>
            </Group>
          </Group>
        ))}
      </Modal>
      <Group position="right" mb={mb}>
        <Popover
          width={300}
          trapFocus
          position="bottom"
          withArrow
          shadow="md"
          opened={saveTemplateOpen}
          onChange={setSaveTemplateOpen}
        >
          <Popover.Target>
            <Tooltip label={translate("Save Template")}>
              <ActionIcon
                variant="default"
                aria-label="Save Template"
                onClick={() => setSaveTemplateOpen((o) => !o)}
              >
                <IconDeviceFloppy
                  style={{ width: "70%", height: "70%" }}
                  stroke={1.5}
                />
              </ActionIcon>
            </Tooltip>
          </Popover.Target>
          <Popover.Dropdown>
            <Group align="end">
              <TextInput
                label={translate("Template Name")}
                placeholder={translate("Name")}
                size="xs"
                onChange={(e) => setTemplateName(e.currentTarget.value)}
              />
              <Button variant="default" size="xs" onClick={saveTemplate}>
                {translate("Save")}
              </Button>
            </Group>
          </Popover.Dropdown>
        </Popover>
        <Tooltip label={translate("Load Template")} ml={0}>
          <ActionIcon variant="default" onClick={loadTemplate}>
            <IconDownload
              style={{ width: "70%", height: "70%" }}
              stroke={1.5}
            />
          </ActionIcon>
        </Tooltip>
      </Group>
    </>
  );
};

export default TemplateSaveLoadButtons;
