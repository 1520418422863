import React from "react";
import { Textarea } from "@mantine/core";
import TemplateSaveLoadButtons from "./templateSaveLoadButtons";

const MessageFiller = ({ form, name, templateCategory = "", ...props }) => {
  return (
    <>
      <TemplateSaveLoadButtons
        form={form}
        name={name}
        templateCategory={templateCategory}
      />
      <Textarea {...form.getInputProps(name)} {...props} />
    </>
  );
};

export default MessageFiller;
