import React from "react";
import InfoGridList from "./infoGridList";
import { useCellRender } from "../hooks/useCellRender";
import { DATA_DN_STATUS } from "../data/options";
import { Alert, Group } from "@mantine/core";
import { IconAlertCircle } from "@tabler/icons";
import _ from "lodash";
import moment from "moment";

export default function CredittNoteRelatedDocumentSection({ form, name }) {
  const [cellRender] = useCellRender();
  const cn = form.values;

  const fields = [
    {
      title: "DebitNote",
      value: cn?.debitNote ? (
        <a
          href={`/gi/debitNote?id=${cn.debitNote._id}&mode=${"edit"}`}
          target="_blank"
          style={{ textDecoration: "none" }}
        >
          {cn.debitNote.code}
        </a>
      ) : (
        "-"
      ),
    },
    {
      title: "Order",
      value: cn?.order ? (
        <a
          href={`/operation/order?id=${cn.order._id}&mode=${"edit"}`}
          target="_blank"
          style={{ textDecoration: "none" }}
        >
          {cn.order.code}
        </a>
      ) : (
        "-"
      ),
    },
  ];

  return cn && <InfoGridList fields={fields} values={cn} />;
}
