import React from "react";
import {
  PageList,
  ENUM_FORM_DISPLAY,
  ENUM_FORM_LAYOUT_CONTAINER,
} from "../../components/pageList";

import { useCellRender } from "../../hooks/useCellRender";
import { useFormatter } from "../../hooks/useFomatter";

import FollowupActionTable from "../../components/followupActionTable.js";
import ActivityLog from "../../components/activityLog.js";

import ActionCellRender from "../../components/actionCellRender";
import FileList from "../../components/fileList";
import CommissionStatementTable from "../../components/commissionStatementTable";
import CommissionStatementStat from "../../components/commissionStatementStat";

import { IconTrash, IconPencil, IconSettings } from "@tabler/icons";
import { TextInput } from "@mantine/core";
import RemoteSelect2 from "../../components/remoteSelect2";
import CommissionFileImporter from "../../components/commissionFileImporter";
import AutoCodeInput from "../../components/autoCodeInput";
import FormConfirmation from "../../components/formConfirmation";
import _ from "lodash";
import ReactJson from "react-json-view";
import moment from "moment";

const apiEntity = "commissionStatement";

const initialValues = {
  status: "PENDING",
  rows: [],
  total: 0,
  verified: 0,
  missing: 0,
  policyNotFound: 0,
  notMatch: 0,
  period: moment().format("YYYYMM"),
};

const addFormSchema = {
  title: "Import Statement",

  display: {
    mode: ENUM_FORM_DISPLAY.MODAL,
    size: "xl", //xs, sm, xl  ....100%
  },
  showSaveBar: false,
  apiEntity,
  initialValues,
  validate: {
    period: (value) =>
      value?.length !== 6 ? "Period Format is not correct (YYYYMM)" : null,
  },

  layout: {
    containers: [
      {
        key: "t1",
        parent: null,
        type: ENUM_FORM_LAYOUT_CONTAINER.WIZARD,
        props: {
          variant: "default", //default | pills | outline
          defaultValue: "Basic Info",
          orientation: "vertical",
          style: { minHeight: "400px" },
        },
        steps: [
          {
            key: "t1-1",
            label: "Basic Info",
            icon: IconSettings,
          },
          { key: "t1-2", label: "Import File", icon: IconSettings },

          { key: "t1-3", label: "Confirm", icon: IconSettings },
        ],
      },
      {
        key: "section-info",
        parent: "t1-1",
        type: ENUM_FORM_LAYOUT_CONTAINER.SECTION,
        props: {
          title: "Statment Basic Information",
          description: "Please fill in the basic information of the statement",
          withBorder: false,
          ml: 20,
          mt: 0,
        },
      },

      {
        key: "section-import",
        parent: "t1-2",
        type: ENUM_FORM_LAYOUT_CONTAINER.SECTION,
        props: {
          title: "Import File",
          description: "Please upload the statement file",
          withBorder: false,
          ml: 20,
          mt: 0,
        },
      },

      {
        key: "section-confirm",
        parent: "t1-3",
        type: ENUM_FORM_LAYOUT_CONTAINER.SECTION,
        props: {
          title: "Confirmation",
          withBorder: false,
          ml: 20,
          mt: 0,
        },
      },
    ],

    fields: [
      {
        name: "code",
        component: AutoCodeInput,
        parent: "section-info",
        props: {
          required: true,
          placeholder: "IMP-CMS-202205",
          label: "Patch ID",
        },
      },

      {
        name: "provider",
        component: RemoteSelect2,
        parent: "section-info",

        props: {
          name: "provider",
          apiEntity: "provider",
          required: true,
          label: "Provider",
          placeholder: "AIA",
          valueField: "_id",
          labelField: "name",
          // labelRender: (data) => data.name,
          searchFields: ["name", "code"],
          sort: {
            by: "name",
            order: "desc",
          },
        },
      },

      {
        name: "period",
        component: TextInput,
        parent: "section-info",
        props: {
          placeholder: "202212",
          label: "Period",
        },
      },
      {
        name: "importRemark",
        component: TextInput,
        parent: "section-info",
        props: {
          placeholder: "",
          label: "Remark",
        },
      },
      {
        name: "rows",
        component: CommissionFileImporter,
        parent: "section-import",
        props: {},
      },
      {
        name: "confirm",
        component: FormConfirmation,
        parent: "section-confirm",
        props: {},
      },
    ],
  },
};
const editFormSchema = {
  title: "Commission Statement",
  titleField: "code",
  display: {
    mode: ENUM_FORM_DISPLAY.LINK,
    size: "xl", //xs, sm, xl  ....100%
    // position:"right"
  },
  apiEntity,
  initialValues,
  layout: {
    containers: [
      {
        key: "main-tab",
        parent: null,
        type: ENUM_FORM_LAYOUT_CONTAINER.TABS,
        props: {
          variant: "outline", //default | pills | outline
          defaultValue: "Commissions",
        },
        tab: [
          {
            key: "tab1",
            label: "Commissions",
            icon: IconSettings,
          },

          {
            key: "tab2",
            label: "Files",
            icon: IconSettings,
          },

          {
            key: "tab3",
            label: "Logs",
            icon: IconSettings,
          },
        ],
      },

      {
        key: "section-files",
        parent: "tab2",
        type: ENUM_FORM_LAYOUT_CONTAINER.SECTION,
        props: {
          title: "Files",
          titleOrder: 5,
          // description: "Please assign the corresponding consultant",
          withBorder: false,
          minHeight: "0px",
          mt: 20,
          mb: 20,
        },
      },
      {
        key: "section-basic",
        parent: "tab1",
        type: ENUM_FORM_LAYOUT_CONTAINER.SECTION,
        props: {
          title: "Statement Info",
          titleOrder: 5,
          // description: "Please assign the corresponding consultant",
          withBorder: false,
          minHeight: "0px",
          mt: 20,
          mb: 20,
        },
      },

      {
        key: "basicInfo-g",
        parent: "section-basic",
        type: ENUM_FORM_LAYOUT_CONTAINER.GRID,
        cols: [
          {
            key: "basicInfo-g1",
            props: {
              span: 6,
            },
          },
          {
            key: "basicInfo-g2",
            props: {
              span: 6,
            },
          },
        ],
      },

      {
        key: "section-commission",
        parent: "tab1",
        type: ENUM_FORM_LAYOUT_CONTAINER.BOX,
        props: {
          mt: 50,
        },
      },

      {
        key: "section-log",
        parent: "tab3",
        type: ENUM_FORM_LAYOUT_CONTAINER.SECTION,
        props: {
          title: "Log",
          titleOrder: 5,
          // description: "Please assign the corresponding consultant",
          withBorder: false,
          minHeight: 0,

          mt: 20,
          mb: 20,
        },
      },
    ],
    fields: [
      {
        name: "period",
        parent: "basicInfo-g1",
        component: TextInput,
        props: {
          label: "Statement Period",
        },
      },

      {
        name: "importRemark",
        parent: "basicInfo-g1",
        component: TextInput,
        props: {
          label: "Remark",
        },
      },
      {
        name: "CommissionStatementStat",
        parent: "section-commission",
        component: CommissionStatementStat,
        props: {},
      },
      {
        name: "commissions",
        // parent: "section-commission",
        parent: "section-commission",
        component: CommissionStatementTable,
        props: {},
      },
      {
        name: "files",
        component: FileList,
        parent: "section-files",
        props: {},
      },

      {
        name: "logs",
        component: ActivityLog,
        parent: "section-log",
        props: {},
      },
    ],
  },
};

const formSchema = {
  add: addFormSchema,
  edit: editFormSchema,
};

const CommissionStatement = () => {
  const [cellRender] = useCellRender();

  const tableSchema = {
    showActionColumn: true,
    columns: [
      { field: "code", headerName: "Code", sortable: true },
      { field: "period", headerName: "Period", sortable: true },
      {
        field: "totalCommission",
        headerName: "Commission",
        sortable: true,
        cellRender: (col, data) =>
          cellRender.Currency(data.totalCommission, "HKD"),
      },

      // {
      //   field: "total",
      //   headerName: "Un Confirmed Commission",
      //   sortable: true,
      //   cellRender: (col, data) => {
      //     const confirmedRows =
      //       data.rows.filter((r) => r.status === "CONFIRMED") ?? [];
      //     const totalCommissionConfirmed = _.isEmpty(confirmedRows)
      //       ? 0
      //       : confirmedRows?.reduce((a, c) => a + c.totalRcv, 0) ?? 0;
      //     return cellRender.Currency(
      //       data.totalCommission - totalCommissionConfirmed,
      //       "HKD"
      //     );
      //   },
      // },

      { field: "total", headerName: "Total", sortable: true },
      { field: "importRemark", headerName: "Remark", sortable: true },

      {
        field: "status",
        cellRender: (col, data) => cellRender.Status(data.status),
      },

      {
        field: "action",
        cellRender: ActionCellRender,
        isAction: true,
        actions: [
          {
            name: "edit",
            icon: IconPencil,
            props: {
              //   color:"red"
            },
          },
        ],
        actionMenu: [
          {
            name: "delete",
            label: "Delete data",
            icon: IconTrash,
            props: {
              color: "red",
            },
          },
        ],
      },
    ],
    select: "code status period provider total totalCommission importRemark",
  };

  return (
    <>
      {/* {
				<ReactJson
					src={initialValues}
					style={{ background: "white" }}
					collapsed
				/>
			} */}

      <PageList
        title={"Commission Statement"}
        apiEntity={apiEntity}
        tableSchema={tableSchema}
        formSchema={formSchema}
      />
    </>
  );
};

export default CommissionStatement;
