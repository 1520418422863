import React, { useState } from "react";
import {
  Button,
  Checkbox,
  Group,
  keyframes,
  List,
  Title,
  Textarea,
  Select,
  NumberInput,
} from "@mantine/core";
import _ from "lodash";
import { useFormRender } from "../hooks/useFormRender";
import { useServerApi } from "../hooks/userServerApi";
import { ENUM_FORM_DISPLAY, ENUM_FORM_LAYOUT_CONTAINER } from "./pageList";
import { IconSettings, IconCircleCheck } from "@tabler/icons";
import FormConfirmation from "./formConfirmation";
import { useDisclosure } from "@mantine/hooks";

import { Modal, Text } from "@mantine/core";
import { showNotification } from "@mantine/notifications";
import { IconExclamationMark, IconAlertCircle } from "@tabler/icons";
import { DatePicker } from "@mantine/dates";
import { DATA_PAYMENT_METHOD } from "../data/options";
import moment from "moment";
import DummyWidget from "./dummyWidget";
import { useFormatter } from "../hooks/useFomatter";
import ReactJson from "react-json-view";

const DebitNoteConfirmPaymentButton = ({ mainForm }) => {
  const [api] = useServerApi();
  const [loading, setLoading] = useState(false);

  const {
    _id: id,
    code,
    total,
    discountAmount,
    costTotal,
    signedBy,
    consultants,
  } = mainForm.values;
  const [formatter] = useFormatter();

  const notValid =
    !id ||
    costTotal <= 0 ||
    total <= 0 ||
    _.isEmpty(signedBy) ||
    _.isEmpty(consultants);

  const formSchema = {
    title: "Confirm Payment",
    description: "Confirm payment received from client for Debit note.",

    display: {
      mode: ENUM_FORM_DISPLAY.MODAL,
      size: "1000px", //xs, sm, xl  ....100%
    },
    showSaveBar: false,
    closeAfterSave: true,
    initialValues: {
      payDate: moment().toISOString(),
      paymentMethod: "Bank Transfer",
    },

    validate: {
      payDate: (value) => (!value ? "Please select the pay date" : null),
      amount: (value, values) =>
        Math.abs(value - (values.total - mainForm.values.discountAmount)) > 0.01
          ? `Amount doesn't match ${formatter.currency(
              values.total - mainForm.values.discountAmount
            )}`
          : null,
    },

    layout: {
      containers: [
        {
          key: "t1",
          parent: null,
          type: ENUM_FORM_LAYOUT_CONTAINER.WIZARD,
          props: {
            variant: "default", //default | pills | outline
            defaultValue: "New Payment",
            orientation: "vertical",
            // style: { minHeight: "800px" },
          },
          steps: [
            {
              key: "t1-1",
              label: "New Payment",
              icon: IconSettings,
            },

            {
              key: "t1-2",
              label: "Confirmation",
              icon: IconSettings,
            },
          ],
        },
      ],
      fields: [
        {
          name: "payDate",
          component: DatePicker,
          type: "datePicker",
          parent: "t1-1",
          props: {
            required: true,
            label: "Pay Date",
            zIndex: 1000,
            placeholder: "Pick Date",
            allowFreeInput: true,
          },
        },
        {
          name: "amount",
          component: NumberInput,
          parent: "t1-1",
          props: {
            required: true,
            label: "Amount",
            precision: 2,
            parser: formatter.currencyParser,
            formatter: formatter.currencyFormat,
          },
        },

        {
          name: "paymentMethod",
          component: Select,
          parent: "t1-1",
          props: {
            required: true,
            label: "Payment Method",
            defaultValue: "Bank Transfer",
            data: DATA_PAYMENT_METHOD,
          },
        },

        {
          name: "remark",
          component: Textarea,
          parent: "t1-1",
          props: {
            label: "Remark",
          },
        },
        {
          name: "confirm",
          component: FormConfirmation,
          parent: "t1-2",
          props: {
            successText: [
              "Please confirm only if the received amount is correct.",
              "Corresponding order will be created once the DN payment confirmed.",
            ],
          },
        },
      ],
    },
  };

  const onSubmit = async ({ values, formMode }) => {
    try {
      formAction.close();
      setLoading(true);

      const result = await api.DebitNote.confirmPayment({
        id,
        payment: values,
      });
      mainForm.setFieldValue("payment", values);
      setLoading(false);
      showNotification({
        title: `Payment. Confirmed`,
        icon: <IconCircleCheck size={18} />,
        message: "Done",
      });

      if (!result.success) throw result.error;
    } catch (error) {
      showNotification({
        title: `Confirm Payment Fail.`,
        icon: <IconAlertCircle size={18} />,
        color: "red",
        message: "Error",
      });
      setLoading(false);
    }
  };

  const [renderForm, formAction, formStatus, formSetting] = useFormRender(
    formSchema,
    null,
    onSubmit
  );

  const handleClick = () => {
    console.log("Handle Click", mainForm.values);
    if (!mainForm.values.product) {
      return showNotification({
        title: `No Product.`,
        color: "red",
        icon: <IconAlertCircle size={18} />,
        message: "Please assign product to the debit note.",
      });
    }

    if (_.isEmpty(mainForm.values.consultants)) {
      return showNotification({
        title: `No Consultant.`,
        icon: <IconAlertCircle size={18} />,
        color: "red",
        message: "Please assign Consultant to the debit note.",
      });
    }

    if (!mainForm?.values.payment) {
      mainForm.values.payment = {
        payDate: null,
        paymentMethod: "",
        amount: 0,
        remark: "",
      };
    }

    const payment = mainForm?.values.payment;
    payment.total = mainForm.values.total;
    formAction.open({ mode: "edit", data: payment });
  };

  return (
    <>
      {/* {values.total - mainForm.values.discountAmount} */}
      {/* <ReactJson src={mainForm.values} style={{ background: "white" }} /> */}
      <Button
        fullWidth
        color={"green"}
        onClick={() => handleClick()}
        disabled={notValid}
        loading={loading}
      >
        Confirm Payment
      </Button>
      {renderForm()}
    </>
  );
};

export default DebitNoteConfirmPaymentButton;
