import {
  Accordion,
  Button,
  Text,
  Title,
  Table,
  Select,
  Group,
} from "@mantine/core";
import React, { useState, useEffect } from "react";
import moment from "moment";
import { useServerApi } from "../hooks/userServerApi";
import { DatePicker } from "@mantine/dates";
import { useTranslation } from "react-i18next";
const ReportService = ({}) => {
  const [fromDate, setFromDate] = useState();
  const [toDate, setToDate] = useState();

  const [loading, setLoading] = useState(false);

  const [api] = useServerApi();
  const { t } = useTranslation();
  const downloadReport = async () => {
    try {
      setLoading(true);

      // console.log(fromDate, toDate);

      const result = await api.OrderService.getReport({ fromDate, toDate });
      setLoading(false);
      // console.log(result);
      if (result && result.data && result.data.url) {
        window.open(result.data.url, "_blank");
      }
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  };

  return (
    <div>
      <Text size="xs">
        {t("Please select the period (creation Date) of records")}
      </Text>
      <Group mt="xs">
        <DatePicker
          title="From"
          size="xs"
          allowFreeInput
          placeholder="From"
          onChange={(d) => setFromDate(d)}
        />
        -
        <DatePicker
          title="To"
          size="xs"
          allowFreeInput
          placeholder="To"
          onChange={(d) => setToDate(d)}
        />
      </Group>
      <Button
        size="xs"
        mt="xl"
        onClick={() => downloadReport()}
        loading={loading}
      >
        {t("Download")}
      </Button>
      <></>
    </div>
  );
};

export default ReportService;
