import React from "react";
import InfoGridList from "./infoGridList";
import { useCellRender } from "../hooks/useCellRender";
import _ from "lodash";

export default function OrderPolicy({ form, name }) {
  const [cellRender] = useCellRender();
  const order = name ? _.get(form.values, name) : form.values;
  const dataFontWeight = 600;

  const fields = [
    {
      title: "Policy Number",
      value: order?.policyNumber,
    },
    {
      title: "Policy Commence Date",
      value: cellRender.Date(
        order?.policyCommenceDate,
        "YYYY-MM-DD",
        dataFontWeight
      ),
    },
    {
      title: "Policy Issue Date",
      value: cellRender.Date(
        order?.policyIssueDate,
        "YYYY-MM-DD",
        dataFontWeight
      ),
    },
    {
      title: "Policy Cool Off Expire",
      value: cellRender.Date(
        order?.policyCoolingOffDate,
        "YYYY-MM-DD",
        dataFontWeight
      ),
    },
    {
      title: "Policy Delivery Method",
      value: order?.policyDeliverMethod ?? "-",
    },
    {
      title: "Delivery Date",
      value: cellRender.Date(
        order?.policyDeliverDate,
        "YYYY-MM-DD",
        dataFontWeight
      ),
    },

    {
      title: "Remark",
      value: order?.policyRemark ?? "-",
    },
    {
      title: "Delivery Remark",
      value: order?.policyDeliverRemark ?? "-",
    },
  ];
  return <InfoGridList fields={fields} values={order} />;
}
