import React, { useState, useEffect } from "react";
import ReactJson from "react-json-view";
import _ from "lodash";
import { useDebouncedValue } from "@mantine/hooks";

import {
  Checkbox,
  Table,
  NumberInput,
  Button,
  ActionIcon,
  Select,
  Text,
} from "@mantine/core";
import RemoteSelect2 from "./remoteSelect2";
import { IconTrash } from "@tabler/icons";
import {
  DATA_MARIAGE_STATUS,
  DATA_OVERRIDING_LEVEL_TYPE,
} from "../data/options";
import { useFormatter } from "../hooks/useFomatter";
import { useTranslation } from "react-i18next";

const OverridingTable = ({ form: mainForm, name }) => {
  const overrideTable = _.get(mainForm.values, name);
  const [formatter] = useFormatter();
  const { t } = useTranslation();
  const allowSkipProviderRate = _.get(mainForm.values, "allowSkipProviderRate");
  const head = (
    <tr>
      <th>#</th>
      <th>{t("Level")}</th>
      <th>{t("Level Type")}</th>
      <th>{t("Percentage")}</th>
      <th>{t("Remain")}</th>
      <th>{t("Actions")}</th>
    </tr>
  );

  const OverrideUserLevelCell = ({ row }) => {
    if (row.level == 0) return "Producer";

    switch (row.levelType) {
      case "upper":
        return "Upper " + row.level;
      case "user":
        return (
          <RemoteSelect2
            size={"xs"}
            apiEntity="user"
            labelField="name"
            labelRender={(data) =>
              `[${data.staffId}] ${data.name ?? ""} ${data.cname ?? ""} ${
                data.aliasName ?? ""
              }`
            }
            searchFields={["name", "staffId", "aliasName"]}
            valueField="_id"
            value={row.user}
            onDataChange={(d) => {
              console.log(d);
              row.user = d?._id ?? null;
              updateFormValue();
            }}
          />
        );
      case "userGroup":
        return (
          <RemoteSelect2
            size={"xs"}
            apiEntity="userGroup"
            labelField="name"
            valueField="_id"
            value={row.userGroup}
            onDataChange={(d) => {
              // console.log(d);
              row.userGroup = d?._id ?? null;
              updateFormValue();
            }}
          />
        );
      default:
        return "Upper " + row.level;
    }
  };

  const hasRemainLevel = () => {
    let index = overrideTable.findIndex((r) => r.isRemainLevel);
    return index != -1;
  };

  const OverrideLevelRow = ({ key, row }) => {
    return (
      <tr key={key}>
        <td>{row.index}</td>
        <td width={400}>
          <OverrideUserLevelCell row={row} />
        </td>
        <td width={200}>
          {row.level == 0 ? (
            ""
          ) : (
            <Select
              data={DATA_OVERRIDING_LEVEL_TYPE}
              defaultValue={row.levelType}
              width="10px"
              onChange={(v) => {
                row.levelType = v;
                switch (v) {
                  case "upper":
                    row.level = getMaxLevel() + 1;
                    break;
                  case "userGroup":
                    row.level = -1;
                    break;
                  case "user":
                    row.level = -2;
                    break;
                  default:
                    break;
                }
                updateFormValue();
              }}
            />
          )}
        </td>

        <td>
          <NumberInput
            size="xs"
            value={row.percentage}
            precision={3}
            noClampOnBlur
            onBlur={(e) => {
              row.percentage = Number(e.target.value);
              updateFormValue();
            }}
          />
        </td>
        <td>
          <Checkbox
            checked={row.isRemainLevel}
            onChange={() => {
              row.isRemainLevel = !row.isRemainLevel;
              updateFormValue();
            }}
            disabled={hasRemainLevel() && !row.isRemainLevel}
          />
        </td>
        <td>
          {row.level != 0 && (
            <ActionIcon onClick={() => removeRow(row)}>
              <IconTrash size={15} />
            </ActionIcon>
          )}
          {""}
        </td>
      </tr>
    );
  };

  const tableRows = overrideTable
    .sort((a, b) => b.index - a.index)
    .map((row, index) => <OverrideLevelRow key={index} row={row} />);

  const getTotalPercentage = () => {
    return overrideTable.reduce((a, c) => a + c.percentage ?? 0, 0);
  };

  const removeRow = (row) => {
    let rIndex = overrideTable.findIndex((r) => r.index === row.index);
    overrideTable.splice(rIndex, 1);
    updateFormValue();
  };

  const updateFormValue = () => {
    mainForm.setFieldValue(name, [...overrideTable]);
  };

  const getMaxIndex = () => {
    let maxIndex = 0;
    overrideTable.forEach(
      (row) => (maxIndex = row.index > maxIndex ? row.index : maxIndex)
    );
    return maxIndex;
  };

  const getMaxLevel = () => {
    let maxLevel = -1;

    overrideTable.forEach(
      (row) => (maxLevel = row.level > maxLevel ? row.level : maxLevel)
    );
    return maxLevel;
  };

  const addRow = () => {
    // console.log("Add Row");
    overrideTable.push({
      index: getMaxIndex() + 1,
      level: getMaxLevel() + 1,
      levelType: "upper",
      userGroup: null,
      percentage: 0,
      isRemainLevel: false,
    });

    updateFormValue();
  };

  const totalPercent = getTotalPercentage();

  const tableSummaryRow = (
    <tr>
      <td></td>
      <td></td>
      <td></td>
      <td></td>

      <td>
        {formatter.percentage(totalPercent)}{" "}
        {
          totalPercent != 100 && !allowSkipProviderRate && (
            <Text color="red"> {t("Total percentage is not 100% !")}</Text>
          ) // t("Total percentage must be 100%")
        }
      </td>
      <td></td>
      <td></td>
    </tr>
  );
  return (
    <>
      {/* <ReactJson
				src={overrideTable}
				name="Table"
				style={{ background: "white" }}
				collapsed
			></ReactJson> */}

      <Table>
        <thead> {head}</thead>
        <tbody>
          {tableRows}
          {tableSummaryRow}
        </tbody>
      </Table>

      <Button size={"xs"} mt={50} onClick={addRow}>
        {t("Add Level")}
      </Button>
    </>
  );
};

export default OverridingTable;
