import React, { useState, useEffect } from "react";
import moment from "moment";
import { DatePicker } from "@mantine/dates";
import {
  IconTrash,
  IconCheck,
  IconCircleCheck,
  IconAlertOctagon,
  IconPencil,
  IconCross,
} from "@tabler/icons";
import _ from "lodash";

import {
  Switch,
  TextInput,
  Select,
  Textarea,
  NumberInput,
  Group,
  Text,
} from "@mantine/core";
import { DATA_GENDERS, DATA_RELATIONSHIP } from "../data/options";

import { ENUM_FORM_DISPLAY, ENUM_FORM_LAYOUT_CONTAINER } from "./pageList";
import LocalPageList from "./localPageList";
import FileList from "./fileList";
import ActionCellRender from "./actionCellRender";
import { useFormatter } from "../hooks/useFomatter";

import { useServerApi } from "../hooks/userServerApi";
import ReactJson from "react-json-view";
import { useCellRender } from "../hooks/useCellRender";
import { use } from "i18next";
import { useTranslation } from "react-i18next";

const OrderInsuredList = ({ form: mainForm, ...props }) => {
  const orderId = _.get(mainForm.values, "_id");
  const [formatter] = useFormatter();

  const [rows, setRows] = useState([]);
  const [api] = useServerApi();
  const [cellRender] = useCellRender();

  const tableSchema = {
    hidePagination: true,
    columns: [
      // {   field: '_id',       headerName:"ID"            },
      {
        field: "Insured Name",
        cellRender: (col, data) =>
          data.sameAsClient
            ? cellRender.ClientInfo(mainForm.values.client)
            : cellRender.ClientInfo(data),
      },
      {
        field: "sameAsClient",
        headerName: "Same as Client ",
        cellRender: (col, data) =>
          data.sameAsClient ? (
            <IconCircleCheck size={15} color="green" />
          ) : (
            "--"
          ),
      },
      {
        field: "isCurrent",
        headerName: "Is Current Insured",
        cellRender: (col, data) =>
          data.isCurrent ? <IconCircleCheck size={15} color="green" /> : "--",
      },
      {
        field: "useAsAge",
        headerName: "Used As Insured Age",
        cellRender: (col, data) =>
          data.useAsAge ? <IconCircleCheck size={15} color="green" /> : "--",
      },

      {
        cellRender: ActionCellRender,
        isAction: true,
        actions: [
          {
            name: "edit",
            label: "Edit",
            icon: IconPencil,
            props: {},
          },
          {
            name: "delete",
            label: "Delete data",
            icon: IconTrash,
            props: {},
          },
        ],
        actionMenu: [],
      },
    ],
    searchableFields: ["code", "name", "cname", "email"],
  };

  const formSchema = {
    title: "Insured Detail",
    description: "Please fill in the form",

    display: {
      mode: ENUM_FORM_DISPLAY.MODAL,
      size: "xl",
    },
    initialValues: {},

    layout: {
      containers: [
        {
          key: "main-tab",
          parent: null,
          type: ENUM_FORM_LAYOUT_CONTAINER.TABS,
          props: {
            variant: "default", //default | pills | outline
            defaultValue: "Basic Info",
          },
          tab: [
            {
              key: "tab-basic",
              label: "Basic Info",
              //icon: IconSettings,
            },
            {
              key: "tab-files",
              label: "Files",
              // icon: IconSettings,
            },
          ],
        },

        {
          key: "pre",
          parent: "tab-basic",
          type: ENUM_FORM_LAYOUT_CONTAINER.BOX,
        },

        {
          key: "main",
          parent: "tab-basic",
          type: ENUM_FORM_LAYOUT_CONTAINER.GRID,
          visibleOnly: (data) => !data.sameAsClient,
          cols: [
            {
              key: "g1",
              props: {
                span: 6,
              },
            },
            {
              key: "g2",
              props: {
                span: 6,
              },
            },
          ],
        },
        {
          key: "file-section",
          parent: "tab-files",
          type: ENUM_FORM_LAYOUT_CONTAINER.SECTION,
          props: {
            title: "",
          },
        },
      ],

      fields: [
        {
          name: "sameAsClient",
          parent: "pre",

          component: Switch,
          props: {
            label: "Same as applicant",
            type: "checkbox",

            mb: 30,
          },
        },

        {
          name: "name",
          component: TextInput,
          parent: "g1",
          props: {
            required: true,
            label: "English Name",
          },
        },

        {
          name: "cname",
          component: TextInput,
          parent: "g1",
          props: {
            required: true,
            label: "Chinese Name",
          },
        },

        {
          name: "id",
          component: TextInput,
          parent: "g1",
          props: {
            required: true,
            label: "ID (HKID/PASSPORT/BR)",
          },
        },

        {
          name: "relationship",
          component: Select,
          parent: "g1",
          props: {
            required: true,
            label: "Relationship with applicant",
            placeholder: "",
            data: DATA_RELATIONSHIP,
            multiple: false,
            searchable: true,
            clearable: true,
          },
        },

        {
          name: "remark",
          component: Textarea,
          parent: "g1",
          props: {
            required: true,
            label: "Remark",
          },
        },
        {
          name: "gender",
          component: Select,
          parent: "g2",
          props: {
            required: true,
            label: "Gender",
            placeholder: "",
            data: DATA_GENDERS,
            multiple: true,
            searchable: true,
            clearable: true,
          },
        },
        {
          name: "phone",
          component: TextInput,
          parent: "g2",
          props: {
            required: true,
            label: "Contact Tel.",
            placeholder: "",
          },
        },
        {
          name: "birthday",
          component: DatePicker,
          parent: "g2",
          type: "datePicker",
          props: {
            required: true,
            label: "Date of Birth",
            zIndex: 1000,
            placeholder: "Pick Date",
            allowFreeInput: true,
          },
        },

        {
          name: "isCurrent",
          component: Switch,
          parent: "g2",
          props: {
            type: "checkbox",
            required: true,
            label: "Is Current Insured",
          },
        },
        {
          name: "useAsAge",
          component: Switch,
          parent: "g2",
          props: {
            type: "checkbox",
            required: true,
            label: "useAsAge",
          },
        },
        {
          name: "files",
          component: FileList,
          parent: "file-section",
          props: {},
        },
      ],
    },
  };

  const onFormSubmit = (values) => {
    // console.log("insured", values);
    if (values && values.length == 1) {
      values[0].isCurrent = true;
      values[0].useAsAge = true;
    }
    setRows(values); //Update UI

    mainForm.setFieldValue("insured", values);

    // _.set(mainForm.values, "beneficiary", values); //Update MainForm to prevent further override

    // api.Client.updateRiskAML(clientID, values); //update server
  };

  const initRowData = async () => {
    // console.log("initRowData", orderId, mainForm.values?.insured);
    if (!mainForm.values || !mainForm.values.insured) return;
    setRows(mainForm.values?.insured);
  };

  useEffect(() => {
    initRowData();
  }, [mainForm.values?.insured, orderId]);

  const { t } = useTranslation();
  return (
    <>
      {/* <ReactJson
        src={mainForm.values}
        collapsed
        style={{ background: "white" }}
      /> */}
      {_.isEmpty(rows) && (
        <Group mb={"xl"}>
          <IconAlertOctagon color="red" />
          <Text size="xs">{t("No Insured")}</Text>
        </Group>
      )}
      <LocalPageList
        rows={rows}
        onFormSubmit={onFormSubmit}
        tableSchema={tableSchema}
        formSchema={formSchema}
        btnLabel={rows?.length > 0 ? t("Change Insured") : t("Add Insured")}
        {...props}
      />
    </>
  );
};

export default OrderInsuredList;
