import React, { useState, useEffect } from "react";

import { IconTrash, IconPencil, IconSettings } from "@tabler/icons";
import {
  PageList,
  ENUM_FORM_DISPLAY,
  ENUM_FORM_LAYOUT_CONTAINER,
} from "../components/pageList";
import { useCellRender } from "../hooks/useCellRender";
import { useNavigate } from "react-router-dom";
import _ from "lodash";
import { useAuthUser } from "react-auth-kit";
import ActionCellRender from "../components/actionCellRender";

const apiEntity = "ticket";

export default function Ticket({}) {
  const [cellRender] = useCellRender();
  const auth = useAuthUser();
  const [preQuery, setPreQuery] = useState();
  const navigate = useNavigate();
  const userRoleName = auth().userRole?.name?.toUpperCase() ?? "";
  const currentURL = window.location.pathname;

  useEffect(() => {}, []);

  const tableSchema = {
    showActionColumn: true,
    preQuery,
    select: ["code status subject type by "],
    columns: [
      {
        field: "code",
        headerName: "Code",
        sortable: true,
      },

      {
        field: "by",
        headerName: "By",
        sortable: true,
        cellRender: (col, data) => cellRender.User(data.by),
      },

      {
        field: "type",
        headerName: "Type",
        sortable: true,
        cellRender: (col, data) => data.type.name,
      },

      {
        field: "itemCode",
        headerName: "Item",
        sortable: true,
        cellRender: (col, data) => data.entityId,
      },
      {
        field: "subject",
        headerName: "Subject",
        sortable: true,
      },
      {
        field: "status",
        headerName: "Status",
        sortable: true,
        cellRender: (col, data) => cellRender.Status(data.status),
      },

      {
        field: "action",
        cellRender: ActionCellRender,
        isAction: true,
        actions: [
          {
            name: "edit",
            icon: IconPencil,
            props: {
              //   color:"red"
            },
          },
        ],
        actionMenu: [
          // {
          //   name: "delete",
          //   label: "Delete data",
          //   icon: IconTrash,
          //   props: {
          //     color: "red",
          //   },
          // },
        ],
      },
    ],

    searchableFields: ["code", "status"],
  };
  const formSchema = {
    // add: addFormSchema,
    // edit: editFormSchema,
  };

  return (
    <>
      {/* {currentURL} {userRoleName} */}
      <PageList
        title={"Tickets"}
        apiEntity={apiEntity}
        tableSchema={tableSchema}
        formSchema={formSchema}
        showImport={false}
        initSort={{ by: "updatedAt", order: "desc" }}
        //   preQueryNotEmpty={role === "CONSULTANT" ? true : false}
        //   stats={{
        //     component: OrderPageStats,
        //     props: {
        //       mb: 35,
        //       role,
        //     },
        //   }}
      />
    </>
  );
}
