import React from "react";
import InfoGridList from "./infoGridList";
import { useCellRender } from "../hooks/useCellRender";
import { DATA_DN_STATUS } from "../data/options";
import { Alert, Group } from "@mantine/core";
import { IconAlertCircle } from "@tabler/icons";
import _ from "lodash";
import moment from "moment";

export default function DebitNoteRelatedDocumentSection({ form, name }) {
  const [cellRender] = useCellRender();
  const dn = form.values;

  const status = DATA_DN_STATUS.find((s) => s.value === dn?.status);

  const fields = [
    {
      title: "Quotation",
      value: dn?.quotation ? (
        <a
          href={`/gi/quotation?id=${
            dn.quotation?._id ?? dn.quotation
          }&mode=${"edit"}`}
          target="_blank"
          style={{ textDecoration: "none" }}
        >
          {dn.quotation?.code}
        </a>
      ) : (
        "-"
      ),
    },
    {
      title: "Renewal",
      value: dn?.quotation ? (
        <a
          href={`/gi/renewal?id=${dn.renewal?._id}&mode=${"edit"}`}
          target="_blank"
          style={{ textDecoration: "none" }}
        >
          {dn.renewal?.code}
        </a>
      ) : (
        "-"
      ),
    },
    {
      title: "Order",
      value: dn?.order ? (
        <a
          href={`/operation/order?id=${dn.order?._id}&mode=${"edit"}`}
          target="_blank"
          style={{ textDecoration: "none" }}
        >
          {dn.order?.code}
        </a>
      ) : (
        "-"
      ),
    },
  ];

  return dn && <InfoGridList fields={fields} values={dn} />;
}
