import React, { useState, useCallback, useEffect } from "react";
import { useServerApi } from "../hooks/userServerApi";
import {
  Group,
  Switch,
  Text,
  Title,
  Accordion,
  Badge,
  Container,
  Box,
  Select,
} from "@mantine/core";
import _ from "lodash";
import ReactJson from "react-json-view";

import { functionLinks } from "../data/navLinks";
import { useAuthUser } from "react-auth-kit";
const accessRights = [
  { value: 0, label: "Hidden", color: "red" },
  { value: 1, label: "Read Only", color: "orange" },
  { value: 15, label: "Access", color: "green" },
];

const LinkRow = ({ fnc: f, index, rights, form }) => {
  const r = rights.find((r) => r.link === f.linkId);
  const right = r ? r.accessRight : 0;
  const color = accessRights.find((r) => r.value === right).color;

  return (
    <Box mb="sm">
      {/* {f.link} */}
      <Group position="apart">
        <Group align="start">
          <Badge color={color} size="xs" mt={5}>
            {index}
          </Badge>
          <div>
            <Text>
              {f.group ? f.group : "Main"} > {f.label}
            </Text>
            <Text size="xs" c="dimmed">
              {f.link}
            </Text>
          </div>
        </Group>
        <Select
          data={accessRights}
          size="xs"
          value={right}
          onChange={(value) => {
            const newRights = [...rights];
            const r = newRights.find((r) => r.link === f.linkId);
            if (r) {
              r.accessRight = value;
            } else {
              newRights.push({ link: f.linkId, accessRight: value });
            }
            form.setFieldValue("links", newRights);
          }}
        />
      </Group>
    </Box>
  );
};
const PageAccessList = ({ form, name, ...props }) => {
  const links = [...functionLinks];

  const rights = _.get(form.values, "links", []);

  return (
    <>
      {/* <ReactJson
        name="rights"
        src={rights}
        style={{ background: "white" }}
        collapsed
      />
      <ReactJson
        name="links"
        src={links}
        style={{ background: "white" }}
        collapsed
      /> */}
      {!_.isEmpty(links) &&
        links?.map((f, index) => (
          <LinkRow fnc={f} index={index} rights={rights} form={form} />
        ))}
    </>
  );
};

export default PageAccessList;
