import React, { useEffect, useState, useRef } from "react";
import {
  Grid,
  Select,
  Group,
  Text,
  Divider,
  Button,
  Modal,
  Box,
  Paper,
  Image,
  Title,
} from "@mantine/core";
import _ from "lodash";
import { FormSectionCard } from "../layout/page";
import { useFormatter } from "../hooks/useFomatter";
import moment from "moment";
import { useReactToPrint } from "react-to-print";
import { ToogleThemeButton } from "./userPopupButton";

import PolicyFundAmountChart from "./policyFundAmountChart";
import PolicyFundAllocation from "./policyFundAllocation";
import PolicyFundDetails from "./policyFundDetail";
const periodFilterOptions = [
  "Year to Date",
  "1 Year",
  "2 Year",
  "5 Year",
  "All History",
];

const OrderProfolio = ({ form }) => {
  const { _id, policyNumber } = form.values;
  const [valuations, setValuations] = useState([]);
  const [opened, setOpened] = useState(false);

  function handleOpen() {
    setOpened(true);
  }

  function handleClose() {
    setOpened(false);
  }

  const printRef = useRef();

  const handlePrint = useReactToPrint({
    content: () => printRef.current,
  });

  const handleValuationsChange = (data) => {
    setValuations(data ?? []);
  };

  return (
    <>
      {!policyNumber && <Text> There are no profolio data yet.</Text>}
      {policyNumber && (
        <>
          <Button onClick={handleOpen} size="xs" variant="light">
            Print
          </Button>

          <PolicyFundAmountChart
            policyNumber={policyNumber}
            onVaulationsChange={handleValuationsChange}
          />
          <PolicyFundAllocation valuations={valuations} />

          <Modal
            opened={opened}
            overlayOpacity={0.8}
            fullScreen
            onClose={handleClose}
          >
            <>
              <Group pos={"apart"}>
                <span></span>
                <>
                  <ToogleThemeButton />
                  <Button onClick={handlePrint} size="xs">
                    Print PDF
                  </Button>
                </>
              </Group>

              <Divider my={"xl"} />

              <Paper
                radius="xs"
                p="sm"
                withBorder={false}
                ref={printRef}
                mx="xl"
                // width={1024}
                // hieght={1445}
                sx={{ width: 1024, height: 1445 }}
              >
                <Grid justify="space-between" align={"center"}>
                  <div>
                    <Title>Portfolio Review Report</Title>
                    <Text color={"dimmed"}>FOR CONSULTANT ONLY</Text>
                  </div>

                  <Image
                    width={120}
                    radius="md"
                    m={0}
                    src="/logo.jpeg"
                    alt="logo"
                  />
                </Grid>
                <Grid>
                  <Grid.Col span={8}>
                    <PolicyFundAmountChart
                      policyNumber={policyNumber}
                      onVaulationsChange={handleValuationsChange}
                      height={420}
                      showPeriodFilter={false}
                    />
                  </Grid.Col>
                  <Grid.Col span={4}>
                    <PolicyFundDetails order={form.values} />
                  </Grid.Col>
                </Grid>
                <PolicyFundAllocation valuations={valuations} printing={true} />
              </Paper>
            </>
          </Modal>
        </>
      )}
    </>
  );
};

export default OrderProfolio;
