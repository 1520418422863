import {
  Accordion,
  Button,
  Text,
  Title,
  Table,
  Select,
  Group,
} from "@mantine/core";
import React, { useState, useEffect } from "react";
import moment from "moment";
import { useServerApi } from "../hooks/userServerApi";
import { DatePicker } from "@mantine/dates";

import { DATA_REPORT_DATE_TYPE } from "../data/options";
import { useTranslation } from "react-i18next";
const ReportOrder = ({}) => {
  const [fromDate, setFromDate] = useState();
  const [toDate, setToDate] = useState();
  const [dateType, setDateType] = useState("submissionDate");
  const [loading, setLoading] = useState(false);

  const [api] = useServerApi();

  const downloadReport = async () => {
    try {
      setLoading(true);
      // console.log(fromDate, toDate);
      const result = await api.Order.getReport({ fromDate, toDate, dateType });
      setLoading(false);
      // console.log(result);
      if (result && result.data && result.data.url) {
        window.open(result.data.url, "_blank");
      }
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  };

  const { t } = useTranslation();

  return (
    <div>
      <Text size="xs">{t("Please select the period of records.")}</Text>
      <Group mt="xs">
        <DatePicker
          title={t("From Date")}
          size="xs"
          allowFreeInput
          placeholder="From"
          onChange={(d) => setFromDate(d)}
        />
        -
        <DatePicker
          title={t("To Date")}
          size="xs"
          allowFreeInput
          placeholder="To"
          onChange={(d) => setToDate(d)}
        />
        <Text size="xs">by</Text>
        <Select
          size="xs"
          defaultValue={"submissionDate"}
          data={DATA_REPORT_DATE_TYPE}
          onChange={(type) => setDateType(type)}
        />
      </Group>
      <Button
        size="xs"
        mt="xl"
        onClick={() => downloadReport()}
        loading={loading}
      >
        {t("Download")}
      </Button>
      <></>
    </div>
  );
};

export default ReportOrder;
