import { PageList } from "../../components/pageList";
import React from "react";

import ActionCellRender from "../../components/actionCellRender";
import { IconPencil, IconTrash, IconSettings } from "@tabler/icons";
import {
  ENUM_FORM_DISPLAY,
  ENUM_FORM_LAYOUT_CONTAINER,
} from "../../components/pageList";
import { useCellRender } from "../../hooks/useCellRender";
import FileList from "../../components/fileList";
import ActivityLog from "../../components/activityLog.js";
import RemoteSelect2 from "../../components/remoteSelect2";
import _ from "lodash";
import ReactJson from "react-json-view";

import {
  Select,
  Checkbox,
  TextInput,
  Text,
  Switch,
  Textarea,
} from "@mantine/core";
import { DatePicker } from "@mantine/dates";
import moment from "moment";
import QuotationDescription from "../../components/quotationDescription";
import QuotationDescriptionBox from "../../components/quotationDescriptionBox";
import QuotationTerms from "../../components/quotationTerm";
import QuotationChargeItems from "../../components/quotationChargeItems.js";
import QuotationCostItems from "../../components/debiteNoteCostItems.js";
import DebitNoteActionList from "../../components/debitNoteActionList";
import QuotationChargeSummary from "../../components/quotationChargeSummary";
import QuotationActionList from "../../components/quotationActionList";
import DummyWidget from "../../components/dummyWidget";
import OrderInsuredList from "../../components/orderInsuredList";
import OrderAddressSelect from "../../components/orderAddressSelect";
import DebitNotePageStats from "../../components/debitNotePageStats";
import OrderConsultantList from "../../components/orderConsultantList";
import OrderApplicantDetail from "../../components/orderApplicantDetail";

import QuotationSummary from "../../components/quotationSummary";
import remoteSelect2 from "../../components/remoteSelect2";
import OrderClientSelect from "../../components/orderClientSelect";
import ProductSelection from "../../components/productSelection";
import ConsultantList from "../../components/consultantList";
import FormConfirmation from "../../components/formConfirmation";
import UniqueTextInput from "../../components/uniqueTextInput";
import QuoRequestFileCheckList from "../../components/quoRequestFileCheckList";
import QuoFileList from "../../components/quofileList";
import QuotationPageStats from "../../components/quotationPageStats";
import QuotationDescriptions from "../../components/quotationDescription";
import RichTextEditor from "../../components/richTextEditor";

const apiEntity = "quotation";

const initialValues = {
  consultants: [],

  date: moment().toISOString(),
  descriptions: [{ title: "", detail: "" }],
  terms: [{ title: "", detail: "" }],
  period: {
    start: null,
    end: null,
  },
  chargeItems: [
    { item: "Premium", currency: "HKD", qty: 1, unitPrice: 0, price: 0 },
    { item: "IA Levy", currency: "HKD", qty: 1, unitPrice: 0, price: 0 },
  ],
  costItems: [
    { item: "Net Premium", currency: "HKD", qty: 1, unitPrice: 0, price: 0 },
    { item: "IA Levy", currency: "HKD", qty: 1, unitPrice: 0, price: 0 },
  ],
  discountAmount: 0,
  total: 0,
  logs: [],
  signedBy: { _id: "64198bbc7378cf27c26f4998" },
  status: "DFT",
};

export const addFormSchema = {
  title: "Create Quotation",
  name: "quotationSchema",
  isMainForm: true,

  description: "Please follow the step to create a New Quotation",
  display: {
    mode: ENUM_FORM_DISPLAY.MODAL,
    size: "xl", //xs, sm, xl  ....100%
    position: "right",
  },
  apiEntity,
  initialValues,
  layout: {
    // containers: [
    //   // {
    //   //   key: "t1",
    //   //   parent: null,
    //   //   type: ENUM_FORM_LAYOUT_CONTAINER.WIZARD,
    //   //   props: {
    //   //     variant: "default", //default | pills | outline
    //   //     defaultValue: "Quotation Code",
    //   //     orientation: "vertical",
    //   //     style: { minHeight: "400px" },
    //   //   },
    //   //   steps: [
    //   //     { key: "t1-0", label: "Quotation Code", icon: IconSettings },
    //   //     { key: "t1-1", label: "Client", icon: IconSettings },
    //   //     { key: "t1-2", label: "Consultant", icon: IconSettings },
    //   //     { key: "t1-3", label: "Product", icon: IconSettings },
    //   //     { key: "t1-4", label: "Period", icon: IconSettings },
    //   //     { key: "t1-5", label: "Confirmation ", icon: IconSettings },
    //   //   ],
    //   // },
    // ],
    fields: [
      {
        name: "consultants",
        component: RemoteSelect2,
        props: {
          apiEntity: "user",
          required: true,
          label: "Consultants",
          placeholder: "Please select consultant(s)",
          valueField: "_id",
          labelField: "name",
          labelRender: (data) =>
            `${data.name} ${data.aliasName ? `,${data.aliasName}` : ""}  ${
              data.cname
            } (${data.staffId ?? " -- "})`,
          multiple: true,
          preQuery: {
            $and: [{ isConsultant: true }, { isActive: true }],
          },
          pageSize: 2000,
          searchFields: [
            "aliasName",
            "name",
            "staffId",
            "cname",
            "email",
            "phone",
          ],
          sort: {
            by: "name",
            order: "asc",
          },
        },
      },
      {
        name: "productType",
        component: RemoteSelect2,
        props: {
          apiEntity: "productType",
          required: true,
          label: "Product Type",
          placeholder: "Select Product Type",
          valueField: "_id",
          labelField: "name",
          labelRender: (data) => `${data.name} ${data.cname}`,

          multiple: false,
          pageSize: 500,
          mt: 0,
          searchFields: ["name", "cname"],
          sort: {
            by: "name",
            order: "asc",
          },
        },
      },
      {
        name: "provider",
        component: RemoteSelect2,
        props: {
          apiEntity: "provider",
          required: true,
          label: "Insurance Company",
          placeholder: "Select Provider",
          valueField: "_id",
          labelField: "name",
          labelRender: (data) =>
            `[${data.shortName ?? data.code}] ${data.name} ${data.cname ?? ""}`,
          multiple: false,
          pageSize: 500,
          searchFields: ["name", "cname"],
          sort: {
            by: "name",
            order: "asc",
          },
        },
      },

      {
        name: "insuredName",
        component: TextInput,
        props: {
          required: true,
          label: "Insured Name",
        },
      },
      {
        name: "address",
        component: TextInput,
        props: {
          label: "Address",
        },
      },

      {
        name: "period.start",
        component: DatePicker,
        type: "datePicker",
        props: {
          label: "Period Start",
          zIndex: 1000,
          placeholder: "Pick Date",
          allowFreeInput: true,
        },
      },
      {
        name: "period.end",
        component: DatePicker,
        type: "datePicker",
        props: {
          label: "Period End",
          zIndex: 1000,
          placeholder: "Pick Date",
          allowFreeInput: true,
        },
      },
    ],
  },
};

export const adjustFormSchema = {
  name: "quotation",
  title: "Adjust Quotation",
  description: "Please follow the step to edit the quotation",
  display: {
    mode: ENUM_FORM_DISPLAY.MODAL,
  },
  apiEntity,
  showSaveBar: false,

  initialValues,

  layout: {
    containers: [
      {
        key: "t1",
        parent: null,
        type: ENUM_FORM_LAYOUT_CONTAINER.WIZARD,

        props: {
          variant: "default", //default | pills | outline
          defaultValue: "DN Code",
          orientation: "vertical",
          style: { minHeight: "400px" },
        },
        steps: [
          { key: "t1-0", label: "Quo Code", icon: IconSettings },
          { key: "t1-3", label: "Product", icon: IconSettings },
          { key: "t1-4", label: "Policy", icon: IconSettings },
          { key: "t1-5", label: "Confirmation ", icon: IconSettings },
        ],
      },
    ],
    fields: [
      {
        name: "code",
        component: UniqueTextInput,
        parent: "t1-0",
        props: {
          required: true,
          uniqueEntity: "quotation",
          errorMessage: "Quotation is already exist.",
          label: "Code (Auto if empty)",
          placeholder: "QUO23100011",
          mt: 0,
        },
      },

      {
        name: "product",
        component: ProductSelection,
        parent: "t1-3",
        props: {
          title: "Product",
          showProductSnapShot: false,
        },
      },

      {
        name: "policyNumber",
        component: TextInput,
        parent: "t1-4",
        props: {
          label: "Policy Number",
          showProductSnapShot: false,
        },
      },

      {
        name: "period.start",
        component: DatePicker,
        parent: "t1-4",
        type: "datePicker",

        props: {
          label: "Period From",
          zIndex: 1000,
          placeholder: "Pick Date",
          allowFreeInput: true,
        },
      },

      {
        name: "period.end",
        component: DatePicker,
        type: "datePicker",
        parent: "t1-4",
        props: {
          label: "Period To",
          zIndex: 1000,
          placeholder: "Pick Date",
          allowFreeInput: true,
        },
      },

      {
        name: "date",
        component: DatePicker,
        type: "datePicker",
        parent: "t1-4",
        props: {
          label: "Debit Note Date",
          zIndex: 1000,
          placeholder: "Pick Date",
          allowFreeInput: true,
        },
      },
      {
        name: "confirm",
        component: FormConfirmation,
        parent: "t1-5",
        props: {
          successText: [
            "Please confirm only if the provided information is correct.",
          ],
        },
      },
    ],
  },
};

const Quotation = ({}) => {
  const [cellRender] = useCellRender();

  const tableSchema = {
    showActionColumn: true,
    columns: [
      {
        field: "code",
        headerName: "Quotation No.",
        sortable: true,
        cellRender: (col, data) => cellRender.DebitNoteCode(data),
      },
      // {
      //   field: "product",
      //   headerName: "Product",
      //   sortable: true,
      //   cellRender: (col, data) => cellRender.ProductInfo(data.product),
      // },
      // {
      //   field: "policyNumber",
      //   headerName: "Policy Number",
      //   sortable: true,
      // },

      {
        field: "productType",
        headerName: "Type",
        sortable: true,
        cellRender: (col, data) => cellRender.ProductType(data.productType),
      },

      {
        field: "insuredName",
        headerName: "Insured",
        sortable: true,
        cellRender: (col, data) =>
          data.client ? cellRender.ClientInfo(data.client) : data.insuredName,
      },

      {
        field: "consultants",
        headerName: "Consultants",
        sortable: true,
        cellRender: (col, data) => cellRender.Users(data.consultants, true),
      },

      {
        field: "action",
        cellRender: ActionCellRender,
        isAction: true,
        actions: [
          {
            name: "edit",
            icon: IconPencil,
            props: {
              //   color:"red"
            },
          },
        ],
        actionMenu: [
          {
            name: "copy",
            label: "Copy",
            icon: IconTrash,
            props: {
              // color: "red",
            },
          },
          {
            name: "delete",
            label: "Delete data",
            icon: IconTrash,
            props: {
              color: "red",
            },
          },
        ],
      },
    ],
  };
  const editFormSchema = {
    display: {
      mode: ENUM_FORM_DISPLAY.LINK,
    },
    title: "Quotation",
    titleField: "code",
    apiEntity,
    initialValues,
    layout: {
      containers: [
        {
          key: "main-g",
          parent: null,
          type: ENUM_FORM_LAYOUT_CONTAINER.GRID,
          props: {
            gutter: "xl",
            columns: 24,
          },
          cols: [
            {
              key: "main-g1",
              props: {
                span: 6,
              },
            },
            {
              key: "main-g2",
              props: {
                span: 18,
              },
            },
          ],
        },

        {
          key: "main-tab",
          parent: "main-g2",
          type: ENUM_FORM_LAYOUT_CONTAINER.TABS,
          props: {
            variant: "default", //default | pills | outline
            defaultValue: "Details",
          },
          tab: [
            {
              key: "tab-details",
              label: "Details",
              //icon: IconSettings,
            },
            {
              key: "tab-internalRemark",
              label: "Remark",
              //icon: IconSettings,
            },
            {
              key: "tab-files",
              label: "Files",
              //icon: IconSettings,
            },
            {
              key: "tab-logs",
              label: "Logs",
              // icon: IconSettings
            },
          ],
        },

        {
          key: "section-summary",
          parent: "tab-details",
          type: ENUM_FORM_LAYOUT_CONTAINER.SECTION,
          props: {
            title: "Details",
            withBorder: false,
            mt: 0,
            mb: "md",
          },
        },
        {
          key: "section-internalRemark",
          parent: "tab-internalRemark",
          type: ENUM_FORM_LAYOUT_CONTAINER.SECTION,
          props: {
            title: "Internal Remark",
            withBorder: false,
            mt: 0,
            mb: "md",
          },
        },

        {
          key: "detail-g",
          parent: "section-summary",
          type: ENUM_FORM_LAYOUT_CONTAINER.GRID,
          props: {
            gutter: "xl",
            columns: 24,
          },
          cols: [
            {
              key: "detail-g1",
              props: {
                span: 12,
              },
            },
            {
              key: "detail-g2",
              props: {
                span: 12,
              },
            },
          ],
        },

        // {
        //   key: "section-address",
        //   parent: "tab-details",
        //   type: ENUM_FORM_LAYOUT_CONTAINER.SECTION,
        //   props: {
        //     title: "Order Address",
        //     titleOrder: 5,
        //     // description: "Please assign the corresponding consultant",
        //     withBorder: false,
        //     minHeight: 0,
        //     mt: 20,
        //     mb: 20,
        //   },
        // },

        {
          key: "section-insured",
          parent: "tab-insured",
          type: ENUM_FORM_LAYOUT_CONTAINER.SECTION,
          props: {
            title: "Insured Details",
            titleOrder: 5,
            // description: "Please assign the corresponding consultant",
            withBorder: false,
            minHeight: 0,

            mt: 20,
            mb: 20,
          },
        },

        {
          key: "section-quo-files",
          parent: "tab-files",
          type: ENUM_FORM_LAYOUT_CONTAINER.SECTION,
          props: {
            title: "File List",
            titleOrder: 5,
            // description: "Please assign the corresponding consultant",
            withBorder: false,
            minHeight: "0px",
            mt: 20,
            mb: 20,
          },
        },

        {
          key: "section-files",
          parent: "tab-files",
          type: ENUM_FORM_LAYOUT_CONTAINER.SECTION,
          props: {
            title: "Files",
            titleOrder: 5,
            // description: "Please assign the corresponding consultant",
            withBorder: false,
            minHeight: "0px",
            mt: 20,
            mb: 20,
          },
        },

        {
          key: "tab-content",
          parent: "tab-details",
          type: ENUM_FORM_LAYOUT_CONTAINER.TABS,
          props: {
            variant: "default", //default | pills | outline
            defaultValue: "Descriptions",
          },
          tab: [
            {
              key: "tab-descriptions",
              label: "Descriptions",
              //icon: IconSettings,
            },
            {
              key: "tab-terms",
              label: "Terms",
              //icon: IconSettings,
            },
          ],
        },
        {
          key: "tab-payment",
          parent: "tab-details",
          type: ENUM_FORM_LAYOUT_CONTAINER.TABS,
          props: {
            variant: "default", //default | pills | outline
            defaultValue: "Charge Items",
          },
          tab: [
            {
              key: "tab-chargeItem",
              label: "Charge Items",
              //icon: IconSettings,
            },
            {
              key: "tab-costItem",
              label: "Cost Items",
              //icon: IconSettings,
            },
          ],
        },
        {
          key: "section-description",
          parent: "tab-descriptions",
          type: ENUM_FORM_LAYOUT_CONTAINER.SECTION,
          props: {
            // title: "Description",
            withBorder: false,
            mt: 0,
            mb: "md",
          },
        },

        {
          key: "section-term",
          parent: "tab-terms",
          type: ENUM_FORM_LAYOUT_CONTAINER.SECTION,
          props: {
            withBorder: false,
            mt: 0,
            mb: "md",
          },
        },
        {
          key: "section-charges",
          parent: "tab-chargeItem",
          type: ENUM_FORM_LAYOUT_CONTAINER.SECTION,
          props: {
            // title: "Charge Items",
            withBorder: false,
            mt: 0,
            mb: "md",
          },
        },
        {
          key: "section-cost",
          parent: "tab-costItem",
          type: ENUM_FORM_LAYOUT_CONTAINER.SECTION,
          props: {
            // title: "Cost Items",
            withBorder: false,
            mt: 0,
            mb: "md",
          },
        },
        // {
        // 	key: "section-payments",
        // 	parent: "tab-details",
        // 	type: ENUM_FORM_LAYOUT_CONTAINER.SECTION,
        // 	props: {
        // 		title: "Payments",
        // 		withBorder: false,
        // 		mt: 0,
        // 		mb: "md",
        // 	},
        // },
        {
          key: "section-log",
          parent: "tab-logs",
          type: ENUM_FORM_LAYOUT_CONTAINER.SECTION,
          props: {
            // title: "Logs",
            titleOrder: 5,
            // description: "Please assign the corresponding consultant",
            withBorder: false,
            mt: 20,
            mb: 20,
          },
        },
        {
          key: "section-action-list",
          parent: "main-g1",
          type: ENUM_FORM_LAYOUT_CONTAINER.SECTION,
          props: {
            title: "Action List",
            // titleOrder: 5,
            // description: "Please assign the corresponding consultant",
            withBorder: false,
            minHeight: "0px",
            mt: 0,
            mb: 20,
          },
        },
      ],
      fields: [
        {
          name: "code",
          component: UniqueTextInput,
          parent: "detail-g2",
          props: {
            required: true,
            uniqueEntity: "quotation",
            errorMessage: "Quotation is already exist.",
            label: "Quotation Number (Auto)",
            placeholder: "QUO23100011",
            mt: 0,
          },
        },
        {
          name: "date",
          component: DatePicker,
          parent: "detail-g2",
          type: "datePicker",
          props: {
            required: true,
            label: "Quotation Date",
            zIndex: 1000,
            placeholder: "Pick Date",
            allowFreeInput: true,
          },
        },

        {
          name: "insuredName",
          component: TextInput,
          parent: "detail-g1",
          props: {
            required: true,
            label: "Insured",
          },
        },

        {
          name: "provider",
          component: RemoteSelect2,
          parent: "detail-g1",
          props: {
            apiEntity: "provider",
            required: true,
            label: "Insurance Company",
            placeholder: "Select Provider",
            valueField: "_id",
            labelField: "name",
            labelRender: (data) =>
              `[${data.shortName ?? data.code}] ${data.name} ${
                data.cname ?? ""
              }`,
            multiple: false,
            pageSize: 500,
            searchFields: ["name", "cname", "code", "shortName"],
            sort: {
              by: "name",
              order: "asc",
            },
          },
        },
        {
          name: "productType",
          component: RemoteSelect2,
          parent: "detail-g2",
          props: {
            apiEntity: "productType",
            required: true,
            label: "Product Type",
            placeholder: "Select Product Type",
            valueField: "_id",
            labelField: "name",
            labelRender: (data) => `${data.name} ${data.cname}`,
            multiple: false,
            pageSize: 500,
            mt: 0,
            preQuery: {
              category: "62f2126bfecf22b7db4ef51c",
            },
            searchFields: ["name", "cname"],
            sort: {
              by: "name",
              order: "asc",
            },
          },
        },
        {
          name: "consultants",
          component: RemoteSelect2,
          parent: "detail-g2",
          props: {
            apiEntity: "user",
            required: true,
            label: "Consultants",
            placeholder: "Please select consultant(s)",
            valueField: "_id",
            labelField: "name",
            labelRender: (data) =>
              `${data.name} ${data.aliasName ? `,${data.aliasName}` : ""}  ${
                data.cname
              } (${data.staffId ?? " -- "})`,
            multiple: true,
            preQuery: {
              $and: [{ isConsultant: true }, { isActive: true }],
            },
            pageSize: 2000,
            searchFields: [
              "aliasName",
              "name",
              "staffId",
              "cname",
              "email",
              "phone",
            ],
            sort: {
              by: "name",
              order: "asc",
            },
          },
        },
        {
          name: "period.start",
          component: DatePicker,
          parent: "detail-g1",
          type: "datePicker",
          props: {
            label: "Period From",
            zIndex: 1000,
            placeholder: "Pick Date",
            allowFreeInput: true,
          },
        },

        {
          name: "period.end",
          component: DatePicker,
          parent: "detail-g1",
          type: "datePicker",
          props: {
            label: "Period To",
            zIndex: 1000,
            placeholder: "Pick Date",
            allowFreeInput: true,
          },
        },

        {
          name: "address",
          component: TextInput,
          parent: "detail-g1",
          props: {
            label: "Address",
          },
        },
        {
          name: "descriptions",
          component: QuotationDescriptions,
          parent: "section-description",
          props: {
            // required: true,
            label: "Description",
            placeholder: "Please enter description",
          },
        },

        {
          name: "terms",
          component: QuotationTerms,
          parent: "section-term",
          props: {
            required: true,
          },
        },

        {
          name: "actions",
          component: QuotationActionList,
          parent: "section-action-list",
          props: {},
        },

        //old
        // {
        //   name: "consultants",
        //   component: OrderConsultantList,
        //   parent: "section-consultant",
        //   props: {},
        // },

        // {
        //   name: "summary",
        //   component: QuotationSummary,
        //   parent: "section-summary",
        //   props: {},
        // },

        // {
        //   name: "insured",
        //   component: OrderInsuredList,
        //   parent: "section-insured",
        //   props: {},
        // },

        // {
        //   name: "descriptions",
        //   component: QuotationDescription,
        //   parent: "section-description",
        //   props: {
        //     required: true,
        //   },
        // },

        {
          name: "costItems",
          component: QuotationCostItems,
          parent: "section-cost",
          props: {
            required: true,
          },
        },
        {
          name: "chargeItems",
          component: QuotationChargeItems,
          parent: "section-charges",
          props: {
            required: true,
          },
        },

        {
          name: "summary",
          component: QuotationChargeSummary,
          parent: "section-charges",
          props: {
            required: true,
          },
        },
        {
          name: "files",
          component: FileList,
          parent: "section-files",
          props: {},
        },

        {
          name: "fileList",
          component: QuoFileList,
          parent: "section-quo-files",
          props: {
            // title: "Please upload related files.",
          },
        },

        {
          name: "log",
          component: ActivityLog,
          parent: "section-log",
          props: {},
        },
        {
          name: "internalRemark",
          component: RichTextEditor,
          parent: "section-internalRemark",
          props: {},
        },
      ],
    },
  };

  const formSchema = {
    add: editFormSchema,
    edit: editFormSchema,
  };
  return (
    <>
      {/* <ReactJson src={form.values} /> */}
      <PageList
        title={"Quotation"}
        apiEntity={apiEntity}
        tableSchema={tableSchema}
        formSchema={formSchema}
        searchByTextField={true}
        stats={{
          component: QuotationPageStats,
          props: {
            mb: 35,
            role: "OPERATION",
          },
        }}
      />
    </>
  );
};

export default Quotation;
