import {
  Table,
  Text,
  Switch,
  Menu,
  Badge,
  Stack,
  Divider,
  Title,
  Group,
  Button,
  Grid,
  Space,
  ActionIcon,
  UnstyledButton,
  ThemeIcon,
} from "@mantine/core";
import {
  TextInput,
  Textarea,
  Checkbox,
  Select,
  MultiSelect,
  NumberInput,
} from "@mantine/core";
import { DatePicker } from "@mantine/dates";
import { showNotification } from "@mantine/notifications";

import {
  IconCircleCheck,
  IconPlus,
  IconCopy,
  IconCircleDashed,
  IconSearch,
  IconMessageCircle,
  IconSettings,
  IconPhoto,
  IconArrowsLeftRight,
  IconTrash,
  IconAdjustments,
} from "@tabler/icons";

import { roundDown } from "../hooks/useFomatter";
import { ENUM_FORM_DISPLAY, ENUM_FORM_LAYOUT_CONTAINER } from "./pageList";
import CommissionRelatedFields from "./commissionRelatedFields";
import CommissionVariation from "./commissionVariation";
import CommissionRateTable from "./commissionRateTable";
import moment from "moment";
import { useState, useEffect, useRef } from "react";
import { useFormRender } from "../hooks/useFormRender";
import { useDateRender } from "../hooks/useDateRender";

import ReactJson from "react-json-view";
import "dayjs/locale/zh-hk";

import _ from "lodash";
import { useFormatter } from "../hooks/useFomatter";
import { useServerApi } from "../hooks/userServerApi";
import { useAuthUser } from "react-auth-kit";
import { useTranslation } from "react-i18next";

export const ProductCommissionTable = ({
  form,
  name,
  disabled,
  rateFactor = 1, //0.525
  firstYearMaxPercentage, // for first year rate limit
  version = "COMPANY", //"CONSULTANT"

  ...props
}) => {
  const [api] = useServerApi();
  const auth = useAuthUser();
  const { t: translate } = useTranslation();
  const truncate = (number, decimal = 1) => {
    try {
      const d = Math.pow(10, decimal);

      return Math.trunc(number * d) / d;
    } catch (error) {
      console.log(error);
    }
  };
  const commissionTables = _.get(form.values, name)?.sort(
    (a, b) => b.createdAt > a.createdAt
  );
  commissionTables?.forEach((t, index) => (t.index = index));

  const [selectedIndex, setSelectedIndex] = useState(0);
  const [selectedLabel, setSelectedLabel] = useState("V");

  const selectedTable = () =>
    !commissionTables || commissionTables.length == 0
      ? null
      : commissionTables[selectedIndex];
  const isSelectTable = (index) => index === selectedIndex;

  const isCurrentTable = (table) => {
    if (!table || !table.effective) return false;

    let now = new Date();
    let effective = _.isDate(table.effective)
      ? table.effective
      : new Date(table.effective);
    let expire = !table.expire
      ? null
      : _.isDate(table.expire)
      ? table.expire
      : new Date(table.expire);

    if (expire) {
      return now >= effective && now <= expire;
    }

    return now >= effective;
  };

  const productVariations = _.get(form.values, "variations");

  const getProductVariationFields = () => {
    return productVariations?.map((v) => ({
      label: v.name,
      key: v.name,
      type: "options",
      options: v.options,
    }));
  };

  const CommissionTableSchema = {
    title: "Commission Table",
    display: {
      mode: ENUM_FORM_DISPLAY.FULLSCREEN,
    },
    initialValues: {
      relatedFields: [],
    },
    closeAfterSave: false,

    layout: {
      containers: [
        {
          key: "basicInfo-section",
          parent: null,
          type: ENUM_FORM_LAYOUT_CONTAINER.SECTION,
          props: {
            title: "Basic Commission Info",
            description: "Please fill in the base info of product",
          },
        },

        {
          key: "basicInfo-g",
          parent: "basicInfo-section",
          type: ENUM_FORM_LAYOUT_CONTAINER.GRID,
          cols: [
            {
              key: "basicInfo-g1",
              props: {
                span: 6,
              },
            },
            {
              key: "basicInfo-g2",
              props: {
                span: 6,
              },
            },
          ],
        },

        {
          key: "relatedField-section",
          parent: null,
          type: ENUM_FORM_LAYOUT_CONTAINER.SECTION,
          props: {
            title: "Commission Related Field",
            description: "Please fill in the base info of product",
          },
        },

        {
          key: "commissionVariation-section",
          parent: null,
          type: ENUM_FORM_LAYOUT_CONTAINER.SECTION,
          props: {
            title: "Commission Variation",
            description: "Please fill in the base info of product",
          },
        },
        {
          key: "commissionTable-section",
          parent: null,
          type: ENUM_FORM_LAYOUT_CONTAINER.SECTION,
          props: {
            title: "Commission Table",
            description: "Please fill in the base info of product",
          },
        },
      ],

      fields: [
        // {
        //   name: "name",
        //   component: TextInput,
        //   parent: "basicInfo-g1",
        //   props: {
        //     required: true,
        //     label: "Version Name",
        //     placeholder: "V1",
        //   },
        // },

        {
          name: "remark",
          component: Textarea,
          parent: "basicInfo-g2",
          props: {
            required: true,
            label: "Remark",
            placeholder: "Remark",
            minRows: 4,
          },
        },

        {
          name: "effective",
          component: DatePicker,
          parent: "basicInfo-g1",
          type: "datePicker",
          props: {
            required: true,
            label: "Effective Date",
            zIndex: 1000,
            placeholder: "Pick Date",
            allowFreeInput: true,
          },
        },

        {
          name: "expire",
          component: DatePicker,
          parent: "basicInfo-g1",
          type: "datePicker",
          props: {
            required: true,
            zIndex: 1000,
            label: "Expire Date",
            placeholder: "Pick Expire date if exist",
            allowFreeInput: true,
          },
        },
        {
          name: "inforceDate",
          component: DatePicker,
          parent: "basicInfo-g1",
          type: "datePicker",
          props: {
            required: true,
            zIndex: 1000,
            label: "Inforce Date",
            placeholder: "Pick Inforce date if exist",
            allowFreeInput: true,
          },
        },

        {
          name: "relatedFields",
          component: CommissionRelatedFields,
          parent: "relatedField-section",
          props: {
            required: true,
            mt: 20,
            variationFields: getProductVariationFields(),
          },
        },

        {
          name: "commissionVariation",
          component: CommissionVariation,
          parent: "commissionVariation-section",
          props: {},
        },

        {
          name: "commissionVariation",
          component: CommissionRateTable,
          parent: "commissionTable-section",
          props: {
            rateFactor,
            firstYearMaxPercentage,
          },
        },
      ],
    },
  };

  const onCommissionTableSubmitSuccess = (values) => {
    console.log(values);
  };

  const onSubmit = ({ values, formMode }) => {
    // console.log("on onSubmit", values, formMode, commissionTables);
    if (formMode === "add") {
      let tables = commissionTables ? [values, ...commissionTables] : [values];
      form.setFieldValue(name, tables);
      form.saveValues({ [name]: tables }); //update to server
      formAction.setMode("edit");
      // formAction.close();
    }

    if (formMode === "edit") {
      console.log(selectedIndex, values);
      commissionTables[selectedIndex] = { ...values };
      form.saveValues(form.values);
      // formAction.close();
    }
  };

  const [renderForm, formAction, formStatus, formSetting] = useFormRender(
    CommissionTableSchema,
    onCommissionTableSubmitSuccess,
    onSubmit
  );

  const [renderDate] = useDateRender();

  const onRemoveSelected = () => {
    // console.log("onRemoveSelected", selectedIndex, commissionTables);
    const ans = window.confirm("Are you sure to delete this version?");
    if (!ans) return;
    commissionTables.splice(selectedIndex, 1);
    form.setFieldValue(name, commissionTables);
    setSelectedIndex(0);
  };

  const onEditSelected = () => {
    let data = { ...selectedTable() };
    // data.effective = new Date(data.effective)
    // data.expire = new Date(data.expire)
    // console.log("onEditSelected", data);
    formAction.open({ mode: "edit", data });
  };

  const onCopySelected = async () => {
    // console.log("onCopySelected", commissionTables);
    if (!commissionTables) return;

    let newTable = _.cloneDeep(selectedTable());
    newTable.name = "V" + moment().format("YYYYMMDDhhssmm");

    delete newTable._id;

    let tables = commissionTables
      ? [newTable, ...commissionTables]
      : [newTable];

    await form.setFieldValue(name, tables);
    await form.saveValues({ [name]: tables }); //update to server

    setSelectedIndex(0);

    // formAction.open({ mode: "edit", data: newTable });
  };

  const onAddVersion = () => {
    formAction.open({ mode: "add" });
  };

  const VersionSelector = () => (
    <>
      {!disabled && (
        <Group position="right" mb={10}>
          <ActionIcon
            variant="filled"
            onClick={onAddVersion}
            disabled={disabled}
          >
            <IconPlus size={16}></IconPlus>
          </ActionIcon>
        </Group>
      )}

      {commissionTables.map((table, index) => (
        <UnstyledButton
          key={index}
          style={{ width: "100%" }}
          onClick={() => {
            setSelectedIndex(index);
            setSelectedLabel("V" + (commissionTables?.length ?? 0 - index));
          }}
        >
          <div
            key={index}
            style={{
              border: "1px solid",
              borderColor: isSelectTable(index) ? "lightgray" : "gray",
              padding: "10px",
              marginBottom: "20px",
              borderRadius: 10,
            }}
          >
            <Group position="apart">
              <Text size="sm" weight={800}>
                {"V" + (commissionTables.length - index)}
              </Text>

              {isCurrentTable(table) && (
                <ThemeIcon color="teal" size={20} radius="xl">
                  <IconCircleCheck size={18} />
                </ThemeIcon>
              )}
            </Group>

            {/* <Group position="apart"> */}
            <Text color="dimmed" size="xs">
              {translate("Start : ")}
              {table.effective
                ? moment(table.effective).format("YYYY-MM-DD")
                : " ---"}
            </Text>

            <Text color="dimmed" size="xs">
              {translate("End : ")}
              {table.expire
                ? moment(table.expire).format("YYYY-MM-DD")
                : " ---"}
            </Text>
            {/* </Group> */}

            {/* <Badge color="dark"> {selectedTable().effective}  </Badge>
                                    <Badge color="red"> {selectedTable().expired??"none" }  </Badge>
                     */}
          </div>
        </UnstyledButton>
      ))}
    </>
  );

  const VersionActionButton = () =>
    !disabled && (
      <Menu shadow="md" width={200}>
        <Menu.Target>
          <ActionIcon variant="default">
            <IconSettings size={20} />
          </ActionIcon>
        </Menu.Target>

        <Menu.Dropdown>
          <Menu.Label>{`Action`}</Menu.Label>
          <Menu.Item onClick={onCopySelected} icon={<IconCopy size={14} />}>
            Copy to New
          </Menu.Item>
          <Menu.Item
            onClick={onEditSelected}
            icon={<IconAdjustments size={14} />}
          >
            Edit
          </Menu.Item>
          <Menu.Item onClick={onRemoveSelected} icon={<IconTrash size={14} />}>
            Delete Version
          </Menu.Item>
        </Menu.Dropdown>
      </Menu>
    );

  //Displate the corresponding rate
  const getDisplayRate = (number, rateFactor, decimal = 2) => {
    const minRateToTruncate = 1;
    if (!number) return 0;
    if (rateFactor === 1) return number;

    if (number <= minRateToTruncate) return roundDown(number * rateFactor);
    return truncate(number * rateFactor, decimal);
  };

  const renderRows = (row, formatter, rateFactor, columns) => {
    let lastYearRemainPercent = 0;

    return columns?.map((col, cindex) => {
      let value = _.get(row, col.key);
      let yearPercent = _.isNumber(value)
        ? value + lastYearRemainPercent
        : value;
      lastYearRemainPercent = 0;

      if (firstYearMaxPercentage && yearPercent > firstYearMaxPercentage) {
        lastYearRemainPercent = yearPercent - firstYearMaxPercentage;
        yearPercent = firstYearMaxPercentage;
      }

      return col.key?.includes("yr") ? (
        <td key={cindex}>
          {formatter.percentage(
            getDisplayRate(
              yearPercent,
              rateFactor,
              process.env.REACT_APP_COMMISSION_CONSULTANT_DISPLAY_DECIMAL
            )
          )}
        </td>
      ) : (
        <td key={cindex}> {yearPercent}</td>
      );
    });
  };

  const getColumns = (version, columns) => {
    if (version === "COMPANY") return columns;
    if (!process.env.REACT_APP_MAX_COMMISSION_YEAR) return columns;

    let c = [];
    if (process.env.REACT_APP_MAX_COMMISSION_YEAR) {
      c = columns.filter((col) => {
        if (col.type !== "year" && col.type !== "withinYear") return true;
        if (
          col.type === "year" &&
          col.key === "yrSubsequent" &&
          process.env.REACT_APP_MAX_COMMISSION_YEAR
        )
          return false;

        if (col.type === "year" && !col.year) return true;
        if (
          col.type === "year" &&
          col.year <= process.env.REACT_APP_MAX_COMMISSION_YEAR
        )
          return true;

        if (
          col.type === "withinYear" &&
          col.start <= process.env.REACT_APP_MAX_COMMISSION_YEAR &&
          col.end <= process.env.REACT_APP_MAX_COMMISSION_YEAR
        )
          return true;

        return false;
      });

      return c;
    }
  };

  const VersionViewer = ({ version = "COMPANY", rateFactor = 1 }) => {
    const [formatter] = useFormatter();
    const { t: translate } = useTranslation();
    const columns = getColumns(
      version,
      selectedTable()?.commissionVariation?.columns
    );

    return (
      <>
        {/* <ReactJson
          src={selectedTable()?.commissionVariation}
          style={{ background: "white" }}
        /> */}

        <Group position="apart">
          <Group mb={10}>
            <VersionActionButton />
            <Group spacing={3}>
              <Badge size="md" radius={0} color="blue">
                {version}
              </Badge>
              <Badge size="md" radius={0}>
                {"V" + (commissionTables.length - selectedIndex)}
              </Badge>
              {isCurrentTable(selectedTable()) && (
                <Badge size="md" color="green" radius={0}>
                  {translate("CURRENT")}
                </Badge>
              )}
            </Group>
          </Group>
        </Group>
        <Table striped highlightOnHover>
          <thead>
            <tr>
              {columns?.map((col, index) => (
                <th key={index}> {translate(col.label)}</th>
              ))}
            </tr>
          </thead>

          <tbody>
            {selectedTable()?.commissionVariation?.rows?.map((row, rindex) => (
              <tr key={rindex}>
                {renderRows(row, formatter, rateFactor, columns)}
              </tr>
            ))}
          </tbody>
        </Table>
        {selectedTable()?.remark && (
          <>
            <Text mt={50} size="sm">
              Remark:
            </Text>
            <Text mt={"xs"} size="sm" style={{ whiteSpace: "pre-wrap" }}>
              {selectedTable()?.remark}
            </Text>
          </>
        )}
      </>
    );
  };

  const [actualRateFactor, setActualRateFactor] = useState(0);

  const setActualRateByConsultant = async () => {
    // console.log(auth().id);
    if (!auth().id) return;

    //get producter rate by consultant
    const result = await api.User.getProducerRate(auth().id);
    // console.log(result);
    if (!result || !result.rate) setActualRateFactor(0);
    setActualRateFactor(result.rate);
  };

  useEffect(() => {
    if (version === "COMPANY") {
      setActualRateFactor(1);
    } else {
      setActualRateByConsultant();
    }
  }, [version, auth().id]);

  return (
    <>
      {/* <ReactJson
        src={commissionTables}
        name="commissionTables"
        style={{ background: "white" }}
      /> */}
      {/* { JSON.stringify(productVariations)} */}
      {/* selectedIndex: {selectedIndex} */}
      {/* <ReactJson
				src={commissionTables}
				name="commissionTables"
				style={{ background: "white" }}
			/> */}
      {/* {selectedTable() && (
        <ReactJson
          src={selectedTable()}
          name="selectedTable"
          style={{ background: "white" }}
          collapsed
        />
      )} */}

      {_.isEmpty(commissionTables) && version === "COMPANY" && (
        <Button onClick={onAddVersion} size="md">
          {translate("Add New Version")}
        </Button>
      )}
      {!_.isEmpty(commissionTables) && (
        <Grid grow gutter="xl">
          <Grid.Col span={10}>
            <VersionViewer rateFactor={actualRateFactor} version={version} />
          </Grid.Col>

          <Grid.Col span={2}>
            <VersionSelector />
          </Grid.Col>
        </Grid>
      )}
      {renderForm()}
    </>
  );
};
