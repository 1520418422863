import React, { useState, useEffect } from "react";
import _ from "lodash";

import { useServerApi } from "../hooks/userServerApi";
import { SimpleGrid, NumberInput } from "@mantine/core";
import { useFormRender } from "../hooks/useFormRender";
import { ENUM_FORM_DISPLAY, ENUM_FORM_LAYOUT_CONTAINER } from "./pageList";
import {
  DATA_CURRENCY,
  DATA_AGE_TYPES,
  DATA_PAY_MODES,
  DATA_PAYMENT_METHOD,
} from "../data/options";
import { Select, MultiSelect } from "@mantine/core";
import ReactJson from "react-json-view";
import { useFormatter } from "../hooks/useFomatter";
import { DatePicker } from "@mantine/dates";

const OrderPaymentDetailForm = ({ form: mainForm }) => {
  const [formatter] = useFormatter();
  const hasNoPayment = _.get(mainForm?.values, "hasNoPayment");
  // Product Limitation
  // const [payTermMax, setPayTermMax] = useState(100);
  // const [payTermMin, setPayTermMin] = useState(1);

  // const payment = _.get(mainForm.values, "payment");
  // const product = _.get(mainForm.values, "productSnapShot");

  const {
    payTermMax,
    payTermMin,
    payment,
    productSnapShot: product, //? need snapshot anymore?
  } = mainForm.values;

  const initData = () => {
    if (!product) return;
  };

  const onSubmit = ({ values, formMode }) => {
    console.log(values);
    // mainForm.setValues(values)
    // mainForm.saveValues(values)
  };

  const formSchema = {
    display: {
      mode: ENUM_FORM_DISPLAY.INSIDE,
      size: "xl", //xs, sm, xl  ....100%
    },

    initialValues: {},

    validate: {
      // paymentTerm: (value) =>
      // 	value < payTermMin
      // 		? `Payment Term must be larger or equal to ${payTermMin}`
      // 		: value > payTermMax
      // 		? `Payment Term must be lesser or equal to ${payTermMax}`
      // 		: null,
      // paymentTerm: (value) => (value > payTermMax ? `Payment Term must be lesser or equal to ${payTermMax}` : null),
    },

    showSaveBar: true,

    layout: {
      containers: [
        {
          key: "section-payment",
          parent: null,
          type: ENUM_FORM_LAYOUT_CONTAINER.GRID,
          cols: [
            {
              key: "paymentInfo-g1",
              props: {
                span: 6,
              },
            },
            {
              key: "paymentInfo-g2",
              props: {
                span: 6,
              },
            },
          ],
        },
      ],

      fields: [
        {
          name: "premium",
          component: NumberInput,
          parent: "paymentInfo-g1",
          props: {
            required: true,
            label: "Premium",
            precision: 2,
            parser: formatter.currencyParser,
            formatter: formatter.currencyFormat,
          },
        },
        {
          name: "payMode",
          component: Select,
          parent: "paymentInfo-g1",
          props: {
            required: true,
            label: "Pay Mode",
            placeholder: "",
            data: DATA_PAY_MODES,
            multiple: true,
            searchable: true,
            clearable: true,
            // dropdownPosition:"top",
          },
        },

        {
          name: "paymentTerm",
          component: NumberInput,
          parent: "paymentInfo-g1",
          props: {
            required: true,
            label: "Payment Term (Yrs)",
          },
        },
        {
          name: "paymentTermUntilAge",
          component: NumberInput,
          parent: "paymentInfo-g1",
          props: {
            required: true,
            label: "Payment Term (Until Age)",
          },
        },
        {
          name: "policyTerm",
          component: NumberInput,
          parent: "paymentInfo-g1",
          props: {
            required: true,
            label: "Policy Term",
          },
        },

        {
          name: "currency",
          component: Select,
          parent: "paymentInfo-g2",
          props: {
            required: true,
            label: "Currency",
            placeholder: "",
            searchable: true,
            data: DATA_CURRENCY,
            default: "HKD",
          },
        },

        {
          name: "levy",
          component: NumberInput,
          parent: "paymentInfo-g2",
          props: {
            required: true,
            label: "Levy",
            precision: 2,
            parser: formatter.currencyParser,
            formatter: formatter.currencyFormat,
          },
        },

        {
          name: "payBy",
          component: Select,
          parent: "paymentInfo-g2",
          props: {
            label: "Inital Payment Methods",
            placeholder: "",
            required: true,

            data: DATA_PAYMENT_METHOD,
            multiple: true,
            searchable: true,
            clearable: true,
            // dropdownPosition:"top",
          },
        },

        {
          name: "initialPaymentCurrency",
          component: Select,
          parent: "paymentInfo-g2",
          props: {
            required: true,
            label: "Initial Payment Currency",
            placeholder: "",
            searchable: true,
            data: DATA_CURRENCY,
            default: "HKD",
          },
        },

        {
          name: "initialPayment",
          component: NumberInput,
          parent: "paymentInfo-g2",
          props: {
            required: true,
            label: "Initial Payment",

            precision: 2,
            parser: formatter.currencyParser,
            formatter: formatter.currencyFormat,
          },
        },
        {
          name: "renewalPayBy",
          component: Select,
          parent: "paymentInfo-g2",
          props: {
            label: "Renewal Payment Methods",
            placeholder: "",
            required: true,

            data: DATA_PAYMENT_METHOD,
            multiple: true,
            searchable: true,
            clearable: true,
            // dropdownPosition:"top",
          },
        },
        {
          name: "sumInsured",
          component: NumberInput,
          parent: "paymentInfo-g1",
          props: {
            required: true,
            label: "Sum Insured",
            precision: 2,
            parser: formatter.currencyParser,
            formatter: formatter.currencyFormat,
          },
        },

        // {
        //   name: "initialPayment",
        //   component: NumberInput,
        //   parent: "paymentInfo-g2",
        //   props: {
        //     required: true,
        //     label: "Initial Payment",
        //     precision: 2,

        //     parser: formatter.currencyParser,
        //     formatter: formatter.currency,
        //   },
        // },
      ],
    },
  };

  const onChange = (values) => {
    // console.log("Payment Detail OnChange ", values);
    values.payCount = getPayCount(values);
    mainForm.setFieldValue("payment", values);
  };

  const getPayCount = ({ payMode, paymentTerm }) => {
    let count = 1;
    switch (payMode) {
      case "MONTHLY":
        count = 12 * paymentTerm;
        break;
      case "QUARTERLY":
        count = 4 * paymentTerm;
        break;
      case "SEMIANNUALLY":
        count = 2 * paymentTerm;
        break;
      case "ANNUALLY":
        count = paymentTerm;
        break;
      case "LUMPSUM":
        count = 1;
        break;
    }

    // console.log("getPayCount", payMode, paymentTerm, count);
    return count;
  };

  const [renderForm, formAction, formStatus, formSetting] = useFormRender(
    formSchema,
    null,
    onSubmit,
    onChange
  );

  useEffect(() => {
    initData();
    formAction.open({ mode: "edit", data: payment });
  }, [product?._id]);

  return (
    <>
      {/* productId:{productId} */}
      {/* <ReactJson
                src={mainForm.values.payment}
                style={{ background: "white" }}
                collapsed={true}
            /> */}
      {/* <ReactJson  src={product} style={{background:"white"}} collapsed={true}/> */}

      {!hasNoPayment && renderForm()}
    </>
  );
};

export default OrderPaymentDetailForm;
