import { Button, Group } from "@mantine/core";
import React, { useState } from "react";
import _ from "lodash";

import { useServerApi } from "../hooks/userServerApi";
import DebitNoteSettlePaymentButton from "./debitNoteSettlePaymentButton";
import CreditNoteSettleButton from "./creditNoteSettleButton";

import { showNotification } from "@mantine/notifications";
import { IconAlertCircle } from "@tabler/icons";

const STATUS_ALLOWED_TO_EDIT = ["DFT", "WAIT", "CAN"];
const STATUS_ALLOWED_TO_ADJUST = ["PAID", "SETTLED"];
const STATUS_ALLOWED_TO_PRINT_RECEIPT = ["PAID", "SETTLED", "EXP"];

const PrintButton = ({ mainForm }) => {
  const [api] = useServerApi();
  const [printing, setPrinting] = useState(false);

  const print = async () => {
    try {
      const { _id } = mainForm.values;
      if (!_id) return;

      setPrinting(true);
      await mainForm.save();
      // console.log("print DN", mainForm.values);
      await new Promise((r) => setTimeout(r, 300));

      let result = await api.CreditNote.print(_id);
      // console.log(result.data.url);
      if (!result || !result.data || !result.data.url) return;
      window.open(result.data.url, "_blank");
      mainForm.setFieldValue("status", result.data.status);
      mainForm.setFieldValue("paymentStatus", result.data.paymentStatus);
      setPrinting(false);
    } catch (error) {
      console.log(error);
      setPrinting(false);
      showNotification({
        title: "Error",
        message: "Error while printing",
        color: "red",
        icon: <IconAlertCircle />,
      });
    }
  };

  return (
    <Button fullWidth onClick={() => print()} loading={printing}>
      {printing ? "Printing" : "Print Credit Note"}
    </Button>
  );
};

const TerminateButton = ({ mainForm }) => {
  const [api] = useServerApi();
  const terminate = () => {
    mainForm.updateAndSave({ status: "CAN" });
  };

  return (
    <Button fullWidth color={"red"} onClick={() => terminate()}>
      Terminate
    </Button>
  );
};

const CreditNoteActionList = ({ form, role }) => {
  const { status, code, consultants, _id, signedBy, costTotal, total } =
    form.values;

  const showTerminate =
    status === "WAIT" || status === "DFT" || status === "CAN";
  const showSettle = status === "WAIT" || status === "DFT";

  return (
    <>
      {/* <ReactJson src={form.values} style={{ background: "white" }} /> */}
      <Group mt={10}>
        {<PrintButton mainForm={form} />}
        {showSettle && <CreditNoteSettleButton mainForm={form} />}
        {showTerminate && <TerminateButton mainForm={form} />}
      </Group>
    </>
  );
};

export default CreditNoteActionList;
