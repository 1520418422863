import {
  Group,
  Paper,
  Space,
  Text,
  SimpleGrid,
  UnstyledButton,
  Accordion,
  Center,
} from "@mantine/core";
import React, { useEffect, useState } from "react";
import { useServerApi } from "../hooks/userServerApi";
import _ from "lodash";

import {
  IconUsers,
  IconUser,
  IconCircleCheck,
  IconNotes,
  IconTableImport,
  IconCirclePlus,
  IconUserCircle,
  IconBuildingBank,
  IconCircleMinus,
  IconActivity,
  IconCircleX,
  IconHeart,
  IconReceipt2,
  IconAlertCircle,
  IconSquareDot,
  IconInfoCircle,
} from "@tabler/icons";

import CardStat from "./cardStat";
import { useFormatter } from "../hooks/useFomatter";
import ReactJson from "react-json-view";

const GIMatchingPaymentStats = ({ form, name, cols = 5, ...props }) => {
  const [formatter] = useFormatter();

  const {
    numOfMatchings,
    code,
    totalMatchedAmount,
    totalReceivedAmount,
    status,
    matchings,
  } = form.values;

  const totalConfirmedMatching =
    matchings?.filter((m) => m.status === "CONFIRM") ?? 0;

  const totalPendingMatching =
    matchings?.filter((m) => m.status === "PENDING") ?? 0;

  const stats = [
    {
      icon: IconNotes,
      color: "#00b341",
      label: "Batch Matching No.",
      msg: code,
    },
    {
      icon: IconReceipt2,
      color: "#8278F2",
      label: "Total Payment Received",
      msg: formatter.currency(totalReceivedAmount),
    },
    {
      icon: IconReceipt2,
      color: "#8278F2",
      label: "Total Matching Amount",
      msg: formatter.currency(totalMatchedAmount),
    },
    {
      icon: IconSquareDot,
      color: "#ffec00",
      label: "Number of  Matching",
      msg: matchings?.length ?? 0,
    },

    {
      icon: IconInfoCircle,
      color: "#00abfb",
      label: "Status",
      msg: status,
    },
  ];

  return (
    <>
      {/* <ReactJson src={form.values} style={{ background: "white" }} /> */}
      <SimpleGrid
        cols={cols}
        breakpoints={[
          { maxWidth: "sm", cols: 1 },
          { maxWidth: "md", cols: 2 },
        ]}
        {...props}
      >
        {stats?.map((stat, index) => (
          <CardStat key={index} stat={stat} />
        ))}
      </SimpleGrid>
    </>
  );
};

export default GIMatchingPaymentStats;
